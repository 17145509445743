import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEdit, faPlus, faMinus  } from '@fortawesome/free-solid-svg-icons';

const TreeItem = ({ item, handleClickExpandTree, handleClickEditParam, handleClickCreateParam, fetchParametrosChildData, expandedItemId }) => (
    <div className={`Tree__item animate__animated animate__fadeIn ${item.expand ? 'Tree__item--active' : ''}`}>
        <div className={`Tree__head ${item.param_admite_hijos ? 'Tree__folder' : 'Tree__file'}`}>
            <div className='Tree__name' onClick={() => item.param_admite_hijos ? handleClickExpandTree(item) : null}>
                {item.param_admite_hijos && (
                    <>
                        <FontAwesomeIcon icon={item.expand ? faMinus : faFolder} className='me-25 color-primary' />
                    </>
                )}
                {item.param_nombre}
                {(fetchParametrosChildData.loading && item.param_id === expandedItemId) && (
                    <div className="spinner-border spinner-border-sm ms-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                )}
            </div>
            <div className='Tree__options'>
                <FontAwesomeIcon icon={faEdit} className='p-1 color-secondary' onClick={() => handleClickEditParam(item)} />
                {item.param_admite_hijos && (
                    <FontAwesomeIcon icon={faPlus} className='p-1 color-secondary' onClick={() => handleClickCreateParam(item)} />
                )}
            </div>
        </div>
        {item.child.length > 0 && (
            <div className='Tree__body'>
                {createTreeItem(item.child, handleClickExpandTree, handleClickEditParam, handleClickCreateParam, fetchParametrosChildData, expandedItemId)}
            </div>
        )}
    </div>
);

const createTreeItem = (items, handleClickExpandTree, handleClickEditParam, handleClickCreateParam, fetchParametrosChildData, expandedItemId) => {
    return items.map((item) => (
        <TreeItem 
            key={item.param_id}
            item={item}
            handleClickExpandTree={handleClickExpandTree}
            handleClickEditParam={handleClickEditParam}
            handleClickCreateParam={handleClickCreateParam}
            fetchParametrosChildData={fetchParametrosChildData}
            expandedItemId={expandedItemId}
        />
    ));
};

export default TreeItem;

