import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Form, Button } from 'react-bootstrap';
import { Loader } from '../Loader/Loader';

import './Input.css'

const InputImages = ({ label, labelRsl, width = 'auto', height = 'auto', urlImage = '', setUrlImage = (() => null),
    onChange, setInputFiles, InputFiles, handleSubirImagen, fetchSubirImagenData, handleEliminarImagen, 
    fetchEliminarImagenData, handleOrdenarImagenes, fetchOrdenarImagenesData, multipleImages }) => {

    const inputFileRef = useRef(null)

    useEffect(() => {

        if (urlImage) {
            setInputFiles([
                urlImage
            ])
        }

    }, [urlImage])

    const onFileChange = (e) => {

        const newFiles = e.target.files

        handleSubirImagen(newFiles[0])
        setInputFiles([
            ...InputFiles,
            ...newFiles
        ])

        e.target.value = null
    }

    const handleClickInputFile = () => {
        inputFileRef.current.click();
    }

    const handleClickRemoveFile = (index) => {

        let InputFilesTemp = [...InputFiles]

        console.log(InputFilesTemp[index])

        handleEliminarImagen(InputFilesTemp[index])

        InputFilesTemp.splice(index, 1)

        setInputFiles([
            ...InputFilesTemp
        ])
        inputFileRef.current.value = null
        setUrlImage('')
    }

    const [indexDrag, setIndexDrag] = useState(-1)
    const [indexDrop, setIndexDrop] = useState(-1)


    const drop = (ev) => ev.preventDefault();

    const allowDrop = (ev) => ev.preventDefault();

    function allowDropItem(ev, index) {

        ev.preventDefault();
        // console.log(index)
        // setItemDropOver(index)
        let InputFilesTemp = [...InputFiles]

        if (
            !InputFilesTemp[index].fake // que no cree nuevos fakes cuando esta sobre un fake
            && indexDrag !== -1 // flag
            && indexDrag !== index // no puedo crear un fake antes de mi mismo
            // && !InputFilesTemp[index - 1].fake // el -1 es porque cuando se posiciona encima, se crea el fake
            && index !== (indexDrag + 1)
        ) {

            InputFilesTemp = InputFilesTemp.filter(item => !item['fake'])

            InputFilesTemp.splice(index, 0, { fake: true });

            setInputFiles(InputFilesTemp)

            setIndexDrop(index)
        }
    }
    
    const handleDraggingItem = (e, index) => {
        // console.log('index drag', index)
        setIndexDrag(index)
    }

    const itemDropped = (e, index) => {
        
        let InputFilesTemp = [...InputFiles]

        // const itemDrag = InputFilesTemp[indexDrag]

        console.log('InputFilesTemp', InputFilesTemp[index])

        let indexDragCorregido = indexDrag > index ? indexDrag + 1 : indexDrag

        InputFilesTemp[index] = InputFilesTemp[indexDragCorregido]

        InputFilesTemp.splice(indexDragCorregido, 1);

        console.log('index drop', index)
        console.log('index drag', indexDrag)
        console.log('files', InputFilesTemp)

        setInputFiles(InputFilesTemp.filter(item => !item['fake']))

        handleOrdenarImagenes(InputFilesTemp.map(item => item.id))
        
        // setItemDragging(-1)

        setIndexDrag(-1)
        setIndexDrop(-1)
    }

    const multipleTag = multipleImages ? { multiple : 'multiple'} : {}

    return (
        <div className='InputImages d-flex flex-column' style={{ 'width': '100%', 'height': 'auto' }}>
            {
                label && <Form.Group controlId="formFile" className="d-flex justify-content-between">
                    <Form.Label>{label}</Form.Label>
                </Form.Group>
            }
            <Form.Control type="file" {...multipleTag} accept="image/png, .jpeg, .jpg, image/gif" ref={inputFileRef} onChange={(e) => {
                if (onChange) {
                    onChange(e)
                } else {
                    onFileChange(e)
                }
            }} className='d-none' />
            <div className="InputImages__container d-grid flex-wrap container-files-input mb-4 justify-content-around align-items-center"
                onDrop={drop} onDragOver={allowDrop}
            >
                {
                    InputFiles.map((item, index) => {
                        let src = item?.path
                        if (!src) {
                            // console.log(item)
                            if (!item.fake) {
                                src = URL.createObjectURL(item)
                            }
                        }
                        return (
                            <div className={`box-input-file-elem m-2 p-0 ${indexDrop === index ? 'isDragOver' : ''}`}
                                key={'2' + index}
                                style={{ 'width': 'auto', 'height': height }}
                                draggable
                                onDragStart={(e) => handleDraggingItem(e, index)}
                                // onDragEnd={() => setisDragging(true)}
                                // onMouseOver={() => onMouseOverImage(index)}
                                onDragOver={(e) => allowDropItem(e, index)}
                                onDrop={(e) => itemDropped(e, index)}
                            >
                                <img src={src} alt="" />
                                <div className='box-input-file-elem-options'>
                                    <div className='box-input-file-elem-option elem-option-1 text-danger' onClick={() => handleClickRemoveFile(index)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div style={{ 'width': 'auto', minHeight: height }}
                    className='InputImages__input box-input-file d-inline-flex flex-column justify-content-center align-items-center m-2'
                    onClick={handleClickInputFile}>
                    <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                    <span className='pt-3'>Agregar una imagen</span>
                </div>


            </div>
            {
                (fetchSubirImagenData.loading || fetchEliminarImagenData.loading || fetchOrdenarImagenesData.loading) && <div className='InputImages__Containerloader d-flex align-items-center justify-content-center'>
                    <Loader />
                </div>
            }
        </div>

    )
}


export default InputImages