import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'
import { AuthContext } from '../../../auth/AuthContext'
import { InputSelect, InputText } from '../../../components/Inputs'
import { isModuleAdministrador } from '../../../helpers/helpers'
import { useLang } from '../../../language'

export const ConsultoresFilter = ({ handleSearch, formValuesSearch, handleInputChangeSearch, fetchParametersData, handleFormChange }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const Lang = useLang();

    const handleChangeComisionista = (e) => {
        handleFormChange({
            ...formValuesSearch,
            es_comisionista: e.target.checked ? true : ''
        })
    }

    return (
        <div className='col-xl-3'>
            <div className='Column-small shadow-sm sticky-top'>

                <h5>{Lang('FILTRO')}</h5>
                <Form className='row' onSubmit={handleSearch}>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3">
                        <InputText
                            label='Nombre o apellidos:'
                            className=''
                            name='filter_name'
                            value={formValuesSearch.filter_name}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3">
                        <InputText
                            label='NIF:'
                            className=''
                            name='filter_nif'
                            value={formValuesSearch.filter_nif}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3">
                        <InputSelect
                            label='Es verificador:'
                            name='filter_verificador'
                            values={
                                [
                                    {id: true, nombre: 'Si'},
                                    {id: false, nombre: 'No'},
                                ]
                            }
                            value={formValuesSearch.filter_verificador}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3">
                        <Form.Check
                            type='checkbox'
                            name='es_comisionista'
                            value={formValuesSearch.es_comisionista}
                            onChange={handleChangeComisionista}
                            checked={formValuesSearch.es_comisionista}
                            id={`default-checkbox`}
                            label='Es comisionista'
                        />
                    </Form.Group>


                    <div className='mt-2'>
                        <button className="px-3 btn btn-primary float-end">
                            <FontAwesomeIcon icon={faSearch} /> {Lang('BUSCAR')}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
