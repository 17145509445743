import React from 'react';
import './Footer.css'

export const Footer = ({ style, setAreaAdmin }) => {
  return (
    <section id='Footer' className='px-3' style={style}>
      <div className='d-flex flex-column align-items-center flex-sm-row justify-content-between '>
        <div className='pt-2 py-sm-2'>
          © 2022 Cateb
        </div>
        <span className='ms-sm-3 py-2'>
          <a onClick={() => setAreaAdmin( (val) => !val)} className='cursor-pointer'>Área de administración</a>
          <a href="https://www.cateb.cat/avis-legal-i-politica-de-privacitat/" target='_blank' rel="noopener noreferrer">Aviso legal y Politica de privacidad</a>
          <a href="https://www.apabcn.cat/ca_es/altres/Pagines/avislegal.aspx" target='_blank' rel="noopener noreferrer">Cookies</a>
        </span>
      </div>
    </section>
  );
};
