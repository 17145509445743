import React, { useEffect, useState, useRef } from 'react'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, Table, Badge } from 'react-bootstrap'
import { Loader } from '../../../components/Loader/Loader'
import { useAxios } from '../../../hooks/useAxios';

export const ModalDetalle = ({ data, handleCloseModal}) => {

	 const [fetchContenido, fetchContenidoData] = useAxios('GET', `/contenidos-web-public`)

	 useEffect(() => {
        if(data.value){
            fetchContenido({
            	id: data?.id
            });
        }
    }, [data.value])

	 return (
        <Modal show={data.value} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>
                	{fetchContenidoData.loading ? 'Cargando...'  : fetchContenidoData.data?.data?.data?.titulo}
                </Modal.Title>
            </Modal.Header>
            {fetchContenidoData.loading ? <Loader /> : <>
            <Modal.Body className='px-4 py-4'>
        		<div className="card shadow-sm mb-2 mr-3  h-100">
                 {/*
        		 <div className="card-header border-bottom pb-2 bg-white">
				    Típo: <Badge bg="success">{fetchContenidoData.data?.data?.data?.tipo_noticia_texto}</Badge>
				 </div>
                 */}
        		{fetchContenidoData.data?.data?.data?.ruta &&
        		   <div className="card-header border-bottom-0 text-center bg-white mb-2">
        		   		<div className="row d-flex align-items-center justify-content-center">
        		   			<div className="col-lg-8">
				    			<img src={ fetchContenidoData.data?.data?.data?.ruta} className="card-img-top img-fluid" style={{
                                  maxWidth: '100%', 
                                  maxHeight: '300px',
                                  objectFit: 'contain', 
                                  borderRadius: '8px'
                                }} />
				    		</div>	
				    	</div>	
				   </div>
				}  
                <div className="card-body d-flex flex-column">
                  <p className="card-text text-break text-wrap" dangerouslySetInnerHTML={ {__html: fetchContenidoData.data?.data?.data?.descripcion_larga} }></p>
                </div>
                </div>

                {fetchContenidoData.data?.data?.data.archivos.length > 0 &&
                <div className="card shadow-sm mb-2 mr-3  h-100">
                   <div className="card-header border-bottom pb-2 bg-white">
				    Archivos Adjuntos
				   </div>
                    <div className="card-body d-flex flex-column">
                      <Table hover bordered responsive className='tableMain'>
                        <thead>
                            <tr className="text-center">
                                <th className='text-nowrap'>Título</th>
                                <th className='text-nowrap text-center'>Archivos</th>
                            </tr>    
                        </thead>
                        <tbody>
                            {fetchContenidoData.data?.data?.data.archivos?.map((item) => {
                                return (
                            	<tr key={item.id} className="text-center">
                                    <td valign='middle'>{item.titulo}</td>
                                    <td valign='middle'>
                                         <Button href={item.ruta} target="_blank" download variant="info" size='sm' className='ms-3 my-0'>
                                            <FontAwesomeIcon icon={faDownload} /> Descargar
                                         </Button>
                                    </td>
                            	</tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    </div>
                </div>
             }  
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
            </>
         }
        </Modal>
    )
}