import React, { useContext, useEffect } from 'react'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAxios } from '../../../hooks/useAxios'
import { useModalData } from '../../../hooks/useModalData';
import { DocumentosResaltadosModal } from './DocumentosResaltadosModal'
export const DocumentosResaltados = () => {

    const [fetchContarDocumento, fetchContarDocumentoData] = useAxios('get', `/biblioteca-documentos-contar-resaltados`)
    const [dataModal_Documentos, handleOpenModal_Documentos, handleCloseModal_Documentos] = useModalData()
     useEffect(() => {
        fetchContarDocumento()
     },[])

    const mostrarDocumentoResaltados = (e) => {
        e.preventDefault();
        handleOpenModal_Documentos({
            action: 'SHOW',
        })
    }

    return (
            <>
                <div className="col-12">
                    <div className="row justify-content-end">
                        <div className="col flex-grow-0 text-nowrap">
                            <ButtonSpinner
                                variant="light" type="submit" className="ms-2 mt-2 mb-3" disabled={fetchContarDocumentoData?.data?.data?.data?.total == 0 ? true : false}
                                handleSubmit={mostrarDocumentoResaltados} fetchDataLoading={[fetchContarDocumentoData.loading]}
                                value={`Documentos ${fetchContarDocumentoData?.data?.data?.data?.total || 0}`} icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                            />
                        </div>
                    </div>
                </div>

                <DocumentosResaltadosModal
                    data={dataModal_Documentos}
                    handleCloseModal={handleCloseModal_Documentos}
                />
            </>
    )
}
