import { Modal, Button, InputGroup, Form } from 'react-bootstrap'
import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from './CropImage'
import { dataURLtoFile } from '../../helpers/helpers'

export const ModalCropImage = ({ show, handleClose, height, setInputFiles, escalas, handleSubirImagen }) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    // const [croppedImage, setCroppedImage] = useState(null)
    const [escala, setEscala] = useState(escalas[0])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {

        try {
            console.log(show.file)
            if (show.file) {
                const img = URL.createObjectURL(show.file)
                const croppedImage = await getCroppedImg(
                    img,
                    croppedAreaPixels,
                    rotation
                )
                console.log('donee', { croppedImage })
                // setCroppedImage(croppedImage)

                // let file = new File([croppedImage], "img.png",{type:"image/png", lastModified:new Date().getTime()});
                // let container = new DataTransfer();
                // container.items.add(file);

                let file = dataURLtoFile(croppedImage, "img.png")

                // inputFileRef.current.files = container.files
                // console.log(container.files)

                setInputFiles([file])
                if (handleSubirImagen) {
                    handleSubirImagen(file)
                }
                handleClose()
            }


        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const showCroppedImageDefault = async () => {

        if (show.file) {

            setInputFiles([show.file])
            if (handleSubirImagen) {
                handleSubirImagen(show.file)
            }
            handleClose()
        }
    }

    return (
        <Modal show={show.value} onHide={handleClose}>
            <Modal.Header closeButton className='border-0 pt-4'>
                <Modal.Title className='h5'>Recortar imagen</Modal.Title>

            </Modal.Header>
            <Modal.Body className='d-flex flex-column' style={{ 'height': height }}>
                {
                    escalas.length > 1 &&
                    <InputGroup className="mb-3">
                        {
                            escalas.map((item,index) => (
                                <Button variant='outline-secondary'
                                    key={index}
                                    onClick={() => setEscala(item)}
                                    className={JSON.stringify(item) === JSON.stringify(escala) ? 'active' : ''}
                                >
                                    {item.label}
                                </Button>
                            ))
                        }
                    </InputGroup>
                }

                <div className="position-relative flex-grow-1">
                    {
                        show.file && <Cropper
                            image={URL.createObjectURL(show.file)}
                            crop={crop}
                            zoom={zoom}
                            aspect={escala.x / escala.y}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    }
                </div>
                {
                    <div className='mt-3'>
                        <Form.Label>
                            Range
                        </Form.Label>
                        <Form.Range
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => setZoom(e.target.value)}
                        />
                    </div>
                }

            </Modal.Body>
            <Modal.Footer className='border-0 d-flex justify-content-between'>
                <Button variant="default" onClick={showCroppedImageDefault}>
                    Mantener original
                </Button>
                <Button variant="primary" onClick={showCroppedImage}>
                    Recortar imagen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}