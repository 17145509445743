import React, { useContext, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faFileExcel, faSort } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../components/Loader/Loader';
import { PaginationT } from '../../../components/PaginationT/PaginationT';

import { useLang } from '../../../language/index';
import { useHistory } from 'react-router-dom';
import ButtonOrderBy from '../../../components/ButtonOrderBy/ButtonOrderBy';
import { useAxios } from '../../../hooks/useAxios';
import { AuthContext } from '../../../auth/AuthContext';
import { getDataFile, isModuleOrganizacion } from '../../../helpers/helpers';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { ModalStatusServer2 } from '../../../components/ModalStatusServer/ModalStatusServer2';

export const ConsultoresMain = ({ fetchConsultoresData, formValuesSearch, namePage = 'default', handleFormChange, modulo_app, estaVerificando }) => {

    console.log('fetchConsultoresData', fetchConsultoresData)
    const history = useHistory();

    const Lang = useLang();

    // Crear Consultores basico

    const [showModalCrearConsultores, setShowModalCrearConsultores] = useState(false);

    const handleCloseModalCrearConsultores = () => setShowModalCrearConsultores(false)
    const handleShowModalCrearConsultores = () => setShowModalCrearConsultores(true);

    // const [fetchRCP, fetchRCPData] = useAxios('get', `/rcp`)
    // const [fetchVerificadores, fetchVerificadoresData] = useAxios('get', `/verificadores`)
    // const [fetchIdiomas, fetchIdiomasData] = useAxios('get', `/idiomas`)

    useEffect(() => {
        // fetchRCP()
        // fetchVerificadores()
        // fetchIdiomas()
    }, [])


    // Start --- Handle Click Descargar Excel

    const [fetchConsultoresExcel, fetchConsultoresExcelData, resetfetchConsultoresExcelData]
        = useAxios('GET', `/consultores`, '', 'blob')

    const handleClickDownloadFile = () => {

        let formValuesSearchTemp = { ...formValuesSearch }

        Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

        fetchConsultoresExcel({
            params: {
                ...formValuesSearchTemp,
                to_excel: true
            }
        })
    }

    useEffect(() => {

        const nombreExcel = formValuesSearch.filter_verificador ? 'Verificadores' : 'Consultores'

        if (fetchConsultoresExcelData.data) {
            getDataFile(fetchConsultoresExcelData, resetfetchConsultoresExcelData, nombreExcel, 'xlsx')
        }
    }, [fetchConsultoresExcelData])

    return (
        <>
            <div className="col">
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto">

                    <div className="d-flex justify-content-between mb-3 pb-2 flex-wrap">
                        <div>
                            <h5 className='mb-3 mb-lg-0'>{Lang('LISTA_DE')} {namePage}</h5>
                        </div>
                        {/* {
                            isModuleOrganizacion(modulo_app) && <div className='d-flex justify-content-end ms-auto'>
                                <Button variant="primary" className="px-3 ms-3 d-flex align-items-center"
                                    onClick={handleShowModalCrearConsultores}
                                >
                                    <FontAwesomeIcon icon={faFile} className='me-2' /> Alta <span className=''>&nbsp;DAPcon</span>
                                </Button>
                            </div>
                        } */}

                        <ButtonSpinner
                            variant="outline-success" type="submit" className="ms-3 px-3"
                            handleSubmit={handleClickDownloadFile} fetchDataLoading={[fetchConsultoresExcelData.loading]}
                            value='Descargar Excel' icon={<FontAwesomeIcon icon={faFileExcel} className='me-2' />}
                        />

                    </div>

                    {
                        fetchConsultoresData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    fetchConsultoresData?.data?.total ?

                                        <>
                                            <Table hover bordered responsive className='tableMain'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`NIF`}
                                                                id='nif'
                                                            />
                                                        </th>
                                                        <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`Nombre`}
                                                                id='nombre'
                                                            />
                                                        </th>
                                                        <th className='text-nowrap text-center'>Teléfono</th>
                                                        <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`Es verificador`}
                                                                className='text-center'
                                                                id='es_verificador'
                                                            />
                                                        </th>
                                                        <th className='text-nowrap text-center'>Es Baja</th>
                                                        {/* <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={Lang('FECHA_ESTADO')}
                                                                id='fecha_estado'
                                                            />
                                                        </th> */}
                                                        <th className='text-center'>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fetchConsultoresData?.data?.items?.map((item) => {
                                                        // console.log(`/consultores/${item.id}`)
                                                        return (
                                                            <tr key={item.id} className={item?.fecha_baja ? 'bg-danger text-white': ''}>
                                                                <td valign='middle'>{item.nif} {item?.fecha_baja}</td>
                                                                <td valign='middle'>{item.nombres_completo}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{item.telefono}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{item.es_verificador}</td>
                                                                <td valign='middle' className="text-center">{item?.fecha_baja ? 'Si' : 'No'}</td>
                                                                <td valign='middle' className='text-center'>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <OverlayTrigger
                                                                                        key={item.id + '1'}
                                                                                        placement={'top'}
                                                                                        overlay={
                                                                                            <Tooltip id={`tooltip-${item.id}`}>
                                                                                                {Lang('VER_MAS')}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <Button variant="transparent" size="sm" className={`btn-modalVermas ${item?.fecha_baja ? 'text-white' : ''}`}
                                                                                            onClick={() => history.push(`/consultores/${item.nif}`)}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faBook} />
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchConsultoresData?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchConsultoresData?.data?.per_page}
                                            />
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No es van trobar resultats</h5>
                                }
                            </>
                    }
                </div>
            </div>

            <ModalStatusServer2 fetchData={fetchConsultoresExcelData} onlyError />

        </>
    )
}
