import { faArrowDown, faArrowRight, faCheckCircle, faFile, faFilePdf, faPencilAlt, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'


import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { InputSelect, InputText } from '../../../../components/Inputs';
import { useForm } from '../../../../hooks/useForm';
import { PostIncidenciaJSON } from '../../../../services/incidencia.service';
import { getIncidenciasAdapterSchema } from '../../../../adapters/incidencia.adapter';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';

// import './ProcessosMain.css'

export const ModalCrearIncidencia = ({ fetchCrearIncidencia, fetchCrearIncidenciaData, showModalCrearIncidencia, handleCloseModalCrearIncidencia, id, idiomaDAPcon, fetchDAPconsDatReq }) => {


    const handleEditorChange = (content, editor) => {
        handleFormChangeIncidencia({
            ...formIncidencia,
            descripcion: content
        })
    }

    const [
        formIncidencia,
        handleInputChangeIncidencia,
        handleFormChangeIncidencia,
        handleResetIncidencia
    ] = useForm(getIncidenciasAdapterSchema())

    useEffect(() => {
        if (showModalCrearIncidencia.item) {
            handleFormChangeIncidencia({
                ...showModalCrearIncidencia.item,
                dapcons_id: id,
                idioma_id: idiomaDAPcon
            })
        }

    }, [showModalCrearIncidencia])

    const handleSubmitCrearIncidencia = () => {

        fetchCrearIncidencia({
            body: {
                ...formIncidencia
            }
        })
    }

    return (
        <>

            <Modal show={showModalCrearIncidencia.value} onHide={handleCloseModalCrearIncidencia} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Ficha de creación de incidencia</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-4'>

                    <Form className='row'>

                        <div className="col-12 mb-4">
                            <InputText
                                label='Título / Asunto'
                                className=''
                                name='titulo'
                                value={formIncidencia.titulo}
                                onChange={handleInputChangeIncidencia}
                            // fetchData={fetchDAPconsDatReqEditData}
                            // validation={valuesValidationDAPcon}
                            />
                        </div>

                        <Form.Group className="col-lg-12">
                            <Form.Label className='fw-500'>Descripción</Form.Label>

                            <Editor
                                initialValue={''}
                                init={{
                                    skin: false,
                                    branding: false,
                                    content_css: false,
                                    height: 400,
                                    menubar: false,
                                    plugins: [
                                        'link image',
                                        'table paste'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | removeformat | help'
                                }}
                                value={formIncidencia.descripcion}
                                onEditorChange={handleEditorChange}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleCloseModalCrearIncidencia}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="danger" type="submit" className="ms-2"
                        handleSubmit={handleSubmitCrearIncidencia} fetchDataLoading={[fetchCrearIncidenciaData.loading]}
                        value='Crear Incidencia'
                    />
                </Modal.Footer>
            </Modal>
            
        </>
    )
}
