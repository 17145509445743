import { faCheckCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { Table17 } from '../../../../components/DAPconTables/Table17';
import { Table8To16 } from '../../../../components/DAPconTables/Table8To16';
import { Loader } from '../../../../components/Loader/Loader';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';
import { ModalStatusError } from '../../../../components/ModalStatusServer/ModalStatusError';
import { getLabelByModulesv2 } from '../../../../helpers/helpers';
import { useAxios } from '../../../../hooks/useAxios';
import { useForm } from '../../../../hooks/useForm';
import _ from 'lodash';

export const DAPconConsultorSeccion6 = ({ series, idDapcon, idiomaDAPcon, datBasic, fetchCheckForms, bloqueado }) => {

    const [fetchInfoTecnicaAdicional, fetchInfoTecnicaAdicionalData] = useAxios('get', `/dapcons/${idDapcon}/form/info-tecnica-adicional`)

    useEffect(() => {

        fetchInfoTecnicaAdicional({
            params: {
                idioma_id: idiomaDAPcon
            }
        })

    }, [])

    useEffect(() => {

        if (fetchInfoTecnicaAdicionalData.data) {
            // console.log(fetchInfoTecnicaAdicionalData.data?.data)
            const formTemp = fetchInfoTecnicaAdicionalData.data?.data?.data
            handleFormChangeInfoTecnicaAdicional({
                ...formTemp,
                dv_supuestos_desarrollo_escenarios: formTemp.dv_supuestos_desarrollo_escenarios || '',
                form_errors: formTemp.form_completed?.form_errors?.[0] || {}

            })
            setisEditForm(isEditf(formTemp))

        }

    }, [fetchInfoTecnicaAdicionalData])

    const [formInfoTecnicaAdicional, handleInputChangeInfoTecnicaAdicional,
        handleFormChangeInfoTecnicaAdicional, handleResetInfoTecnicaAdicional] = useForm({
            idioma_id: idiomaDAPcon,
            dv_supuestos_desarrollo_escenarios: '',
            dapcons_tabla_fin_vida_tiene_col_mix: '',
            table4pt1: [],
            table4pt2: [],
            table4pt3: [],
            table4pt4B2: [],
            table4pt4B3: [],
            table4pt4B4: [],
            table4pt4B5: [],
            table4pt5: [],
            table4pt6: [],
            table4pt7: [],
            form_errors: {},
            form_completed: {},
        })

    // useEffect(() => {

    //     console.log(formInfoTecnicaAdicional)

    // }, [formInfoTecnicaAdicional])

    const isEditf = (form) => {

        const formTemp = _.cloneDeep({
            dv_supuestos_desarrollo_escenarios: form.dv_supuestos_desarrollo_escenarios,
        })

        for (const key in formTemp) {
            if (formTemp[key]) {
                return true
            }
        }

        return false
    }

    const [isEditForm, setisEditForm] = useState(false)


    // Modulos activos

    const modulosActivos = datBasic?.modulos?.filter(item => item.modulo_activado) || []

    const modulosConstruccion = {
        a4: {
            name: 'dv_a4_transporte',
            label: 'Transporte del producto a la obra'
        },
        a5: {
            name: 'dv_a5_proceso_instalacion',
            label: 'Instalación del producto y construcción'
        }
    }

    const modulosActivosConsA = modulosActivos.map(item => {
        if (modulosConstruccion[item.modulo_param_id]) {
            return {
                ...item,
                ...modulosConstruccion[item.modulo_param_id],
                idUpper: String(item.modulo_param_id).toUpperCase()
            }
        }
        return null
    }).filter(item => item)


    const modulosUsoProducto = {
        b1: {
            name: 'dv_b1_uso',
            label: 'Uso'
        },
        b2: {
            name: 'dv_b2_uso',
            label: 'Mantenimiento (B2)'
        },
        b3: {
            name: 'dv_b3_uso',
            label: 'Reparación (B3)'
        },
        b4: {
            name: 'dv_b4_uso',
            label: 'Substitución (B4)'
        },
        b5: {
            name: 'dv_b5_uso',
            label: 'Rehabilitación (B5)'
        },
        b6: {
            name: 'dv_b6_uso',
            label: 'Uso de la energía operacional (B6)'
        },
        b7: {
            name: 'dv_b7_uso',
            label: 'Uso del agua operacional (B7)'
        }
    }

    const modulosActivosUsoProdB = modulosActivos.map(item => {
        if (modulosUsoProducto[item.modulo_param_id]) {
            return {
                ...item,
                ...modulosUsoProducto[item.modulo_param_id],
                idUpper: String(item.modulo_param_id).toUpperCase()
            }
        }
        return null
    }).filter(item => item)

    const modulosFinVida = {
        c1: {
            name: 'dv_c1_fin_de_vida',
            label: 'Decosntrucción y derribo'
        },
        c2: {
            name: 'dv_c2_fin_de_vida',
            label: 'Transporte'
        },
        c3: {
            name: 'dv_c3_fin_de_vida',
            label: 'Gestión de los residuos para reutilización, recuperación y reciclaje'
        },
        c4: {
            name: 'dv_c4_fin_de_vida',
            label: 'Eliminación final'
        }
    }

    const modulosActivosFinVidaC = modulosActivos.map(item => {
        if (modulosFinVida[item.modulo_param_id]) {
            return {
                ...item,
                ...modulosFinVida[item.modulo_param_id],
                idUpper: String(item.modulo_param_id).toUpperCase()
            }
        }
        return null
    }).filter(item => item)

    const [exista1a3, setExista1a3] = useState(datBasic?.dapcons_tabla_fin_vida_tiene_col_mix)

    const handleClickMergeA1A3 = () => {
        setExista1a3(!exista1a3)
    }

    const [indexSerie, setIndexSerie] = useState(0)

    const [matrizAll, setMatrizAll] = useState([])

    useEffect(() => {

        let dataTemp = new Array(series.length)

        for (let index = 0; index < dataTemp.length; index++) {
            dataTemp[index] = ['0', '1', '2', '3', '4', '5']
        }

        setMatrizAll(dataTemp)

    }, [])

    const setMatriz = (index, matriz) => {
        let matrizAllTemp = [...matrizAll]
        matrizAllTemp[index] = matriz
        setMatrizAll(matrizAllTemp)
    }

    const handleInputChangeTableN = (e, key, name, nameTable) => {

        const tableTemp = [...formInfoTecnicaAdicional[name]]

        tableTemp[0][nameTable][key]['value'] = e.target.value

        handleFormChangeInfoTecnicaAdicional({
            ...formInfoTecnicaAdicional,
            [name]: [...tableTemp]
        })
    }

    const handleInputChangeTable17 = (e, index, key, name) => {

        const tableTemp = [...formInfoTecnicaAdicional[name]]

        tableTemp[index][key] = e.target.value

        handleFormChangeInfoTecnicaAdicional({
            ...formInfoTecnicaAdicional,
            [name]: [...tableTemp]
        })
    };

    const [fetchInfoTecnicaAdicionalPatch, fetchInfoTecnicaAdicionalPatchData] = useAxios('patch', `/dapcons/${idDapcon}/form/info-tecnica-adicional`)

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        // console.log(fetchInfoTecnicaAdicionalPatchData.data)
        if (fetchInfoTecnicaAdicionalPatchData.data?.status === 201) {
            setShowSuccessEdit(true)
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchInfoTecnicaAdicionalPatchData])


    const handleSubmitInfoTecAd = (e) => {

        e.preventDefault()

        const bodyTemp = {
            ...formInfoTecnicaAdicional,
            dapcons_tabla_fin_vida_tiene_col_mix: exista1a3,
            idioma_id: idiomaDAPcon,
            table4pt1: tableToPatch(formInfoTecnicaAdicional.table4pt1, 'parametro_transporte_expresado_por_unidad_funcional'),
            table4pt2: tableToPatch(formInfoTecnicaAdicional.table4pt2, 'parametro_proceso_instalacion_expresado_por_unidad_funcional'),
            table4pt3: tableToPatch(formInfoTecnicaAdicional.table4pt3, 'parametro_vida_util_expresado_por_unidad_funcional'),
            table4pt4B2: tableToPatch(formInfoTecnicaAdicional.table4pt4B2, 'parametro_mantenimiento_expresado_por_unidad_funcional'),
            table4pt4B3: tableToPatch(formInfoTecnicaAdicional.table4pt4B3, 'parametro_reparacion_expresado_por_unidad_funcional'),
            table4pt4B4: tableToPatch(formInfoTecnicaAdicional.table4pt4B4, 'parametro_substitucion_expresado_por_unidad_funcional'),
            table4pt4B5: tableToPatch(formInfoTecnicaAdicional.table4pt4B5, 'parametro_rehabilitacion_expresado_por_unidad_funcional'),
            table4pt5: tableToPatch(formInfoTecnicaAdicional.table4pt5, 'parametro_vida_util_referencia_expresado_por_unidad_funcional'),
            table4pt6: tableToPatch(formInfoTecnicaAdicional.table4pt6, 'parametro_uso_energia_expresado_por_unidad_funcional'),
            table4pt7: formInfoTecnicaAdicional.table4pt7?.map(item => {
                return {
                    modelo_id: item.modelo_id,
                    kg_recogidos_con_mezcla_de_residuos_construccion: item.kg_recogidos_con_mezcla_de_residuos_construccion,
                    kg_para_reutilizacion: item.kg_para_reutilizacion,
                    kg_para_reciclado: item.kg_para_reciclado,
                    kg_para_valorizacion_energetica: item.kg_para_valorizacion_energetica,
                    kg_mix_reutilizacion_reciclado_valorizacion_energetica: item.kg_mix_reutilizacion_reciclado_valorizacion_energetica,
                    kg_para_eliminacion_final: item.kg_para_eliminacion_final
                }
            }) || []
        }

        delete bodyTemp['dv_idioma_id']
        delete bodyTemp['form_completed']
        delete bodyTemp['form_errors']

        fetchInfoTecnicaAdicionalPatch({
            body: {
                ...bodyTemp
            }
        })
    }

    const tableToPatch = (table, nameTable) => {

        const tableTemp = _.cloneDeep(table[0])

        Object.keys(tableTemp[nameTable]).forEach(key => tableTemp[nameTable][key] = tableTemp[nameTable][key].value)

        delete tableTemp['y_name']
        delete tableTemp['y_param']

        // console.log(table)

        return [tableTemp]
    }

    return (
        <>
            <div className='MainInner shadow-sm '>
                {
                    fetchInfoTecnicaAdicionalData.loading ? <div className="py-5">
                        <Loader />
                    </div> : (

                        <div>
                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <h5>4. Información técnica adicional y escenarios</h5>
                                </div>
                            </div>

                            {
                                modulosActivosConsA.find(item => item.modulo_param_id === 'a4') && (
                                    <>
                                        <h5 className='mb-3'>
                                            <small>4.1. Transporte de la fàbrica a la obra (A4)</small>
                                        </h5>
                                        {
                                            !formInfoTecnicaAdicional.form_completed?.['table4pt1']?.isCompleted &&
                                            <Form.Text className='text-danger d-block mb-2'>Los campos de la tabla son obligatorios</Form.Text>
                                        }
                                        <Table8To16
                                            data={formInfoTecnicaAdicional.table4pt1}
                                            name='table4pt1'
                                            handleInputChangeTableN={handleInputChangeTableN}
                                            nameTable='parametro_transporte_expresado_por_unidad_funcional'
                                        />

                                        <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                                            <ButtonSpinner
                                                variant="info" type="submit" className="me-2"
                                                handleSubmit={handleSubmitInfoTecAd} fetchDataLoading={[fetchInfoTecnicaAdicionalPatchData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                            />
                                        </div>

                                        <div className="py-2"></div>
                                    </>
                                )
                            }

                            {
                                modulosActivosConsA.find(item => item.modulo_param_id === 'a5') && (
                                    <>
                                        <h5 className='mb-3'>
                                            <small>4.2. Procesos de instalación (A5)</small>
                                        </h5>

                                        {
                                            !formInfoTecnicaAdicional.form_completed?.['table4pt2']?.isCompleted &&
                                            <Form.Text className='text-danger d-block mb-2'>Los campos de la tabla son obligatorios</Form.Text>
                                        }

                                        <Table8To16
                                            data={formInfoTecnicaAdicional.table4pt2}
                                            name='table4pt2'
                                            handleInputChangeTableN={handleInputChangeTableN}
                                            nameTable='parametro_proceso_instalacion_expresado_por_unidad_funcional'
                                        />

                                        <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                                            <ButtonSpinner
                                                variant="info" type="submit" className="me-2"
                                                handleSubmit={handleSubmitInfoTecAd} fetchDataLoading={[fetchInfoTecnicaAdicionalPatchData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                            />
                                        </div>

                                        <div className="py-2"></div>
                                    </>
                                )
                            }

                            {
                                modulosActivosUsoProdB.find(item => item.modulo_param_id === 'b1') && (
                                    <>
                                        <h5 className='mb-3'>
                                            <small>4.3. Vida útil de referencia (B1)</small>
                                        </h5>
                                        {
                                            !formInfoTecnicaAdicional.form_completed?.['table4pt3']?.isCompleted &&
                                            <Form.Text className='text-danger d-block mb-2'>Los campos de la tabla son obligatorios</Form.Text>
                                        }
                                        <Table8To16
                                            data={formInfoTecnicaAdicional.table4pt3}
                                            name='table4pt3'
                                            nameTable='parametro_vida_util_expresado_por_unidad_funcional'
                                            handleInputChangeTableN={handleInputChangeTableN}
                                        />

                                        <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                                            <ButtonSpinner
                                                variant="info" type="submit" className="me-2"
                                                handleSubmit={handleSubmitInfoTecAd} fetchDataLoading={[fetchInfoTecnicaAdicionalPatchData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                            />
                                        </div>

                                        <div className="py-2"></div>
                                    </>
                                )
                            }

                            {
                                getLabelByModulesv2(modulosActivosUsoProdB, ['b2', 'b3', 'b4', 'b5']) && (
                                    <>
                                        <h5 className='mb-3'>
                                            <small>4.4. {getLabelByModulesv2(modulosActivosUsoProdB, ['b2', 'b3', 'b4', 'b5'])}</small>
                                        </h5>
                                        {
                                            modulosActivosUsoProdB.find(item => item.modulo_param_id === 'b2') &&
                                            <>
                                                {
                                                    !formInfoTecnicaAdicional.form_completed?.['table4pt4B2']?.isCompleted &&
                                                    <Form.Text className='text-danger d-block mb-2'>Los campos de la tabla son obligatorios</Form.Text>
                                                }
                                                <Table8To16
                                                    data={formInfoTecnicaAdicional.table4pt4B2}
                                                    name='table4pt4B2'
                                                    nameTable='parametro_mantenimiento_expresado_por_unidad_funcional'
                                                    handleInputChangeTableN={handleInputChangeTableN}
                                                    title='B2 Mantenimiento'
                                                />
                                            </>
                                        }

                                        {
                                            modulosActivosUsoProdB.find(item => item.modulo_param_id === 'b3') && (
                                                <>
                                                    {
                                                        !formInfoTecnicaAdicional.form_completed?.['table4pt4B3']?.isCompleted &&
                                                        <Form.Text className='text-danger d-block mb-2'>Los campos de la tabla son obligatorios</Form.Text>
                                                    }
                                                    <Table8To16
                                                        data={formInfoTecnicaAdicional.table4pt4B3}
                                                        name='table4pt4B3'
                                                        nameTable='parametro_reparacion_expresado_por_unidad_funcional'
                                                        handleInputChangeTableN={handleInputChangeTableN}
                                                        title='Reparación (B3)'
                                                    />
                                                </>

                                            )
                                        }

                                        {
                                            modulosActivosUsoProdB.find(item => item.modulo_param_id === 'b4') && (
                                                <>
                                                    {
                                                        !formInfoTecnicaAdicional.form_completed?.['table4pt4B4']?.isCompleted &&
                                                        <Form.Text className='text-danger d-block mb-2'>Los campos de la tabla son obligatorios</Form.Text>
                                                    }
                                                    <Table8To16
                                                        data={formInfoTecnicaAdicional.table4pt4B4}
                                                        name='table4pt4B4'
                                                        nameTable='parametro_substitucion_expresado_por_unidad_funcional'
                                                        handleInputChangeTableN={handleInputChangeTableN}
                                                        title='B4 Substitución'
                                                    />
                                                </>

                                            )
                                        }

                                        {
                                            modulosActivosUsoProdB.find(item => item.modulo_param_id === 'b5') && (
                                                <>
                                                    {
                                                        !formInfoTecnicaAdicional.form_completed?.['table4pt4B5']?.isCompleted &&
                                                        <Form.Text className='text-danger d-block mb-2'>Los campos de la tabla son obligatorios</Form.Text>
                                                    }
                                                    <Table8To16
                                                        data={formInfoTecnicaAdicional.table4pt4B5}
                                                        name='table4pt4B5'
                                                        nameTable='parametro_rehabilitacion_expresado_por_unidad_funcional'
                                                        handleInputChangeTableN={handleInputChangeTableN}
                                                        title='B5 Rehabilitación'
                                                    />
                                                </>

                                            )
                                        }

                                        <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                                            <ButtonSpinner
                                                variant="info" type="submit" className="me-2"
                                                handleSubmit={handleSubmitInfoTecAd} fetchDataLoading={[fetchInfoTecnicaAdicionalPatchData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                            />
                                        </div>

                                        <div className="py-2"></div>
                                    </>
                                )
                            }

                            {
                                modulosActivosUsoProdB.length > 0 && <>
                                
                                    <h5 className='mb-3'>
                                        <small>4.5. Vida útil de referencia</small>
                                    </h5>
                                    {
                                        !formInfoTecnicaAdicional.form_completed?.['table4pt5']?.isCompleted &&
                                        <Form.Text className='text-danger d-block mb-2'>Los campos de la tabla son obligatorios</Form.Text>
                                    }
                                    <Table8To16
                                        data={formInfoTecnicaAdicional.table4pt5}
                                        name='table4pt5'
                                        nameTable='parametro_vida_util_referencia_expresado_por_unidad_funcional'
                                        handleInputChangeTableN={handleInputChangeTableN}
                                    />

                                    <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                                        <ButtonSpinner
                                            variant="info" type="submit" className="me-2"
                                            handleSubmit={handleSubmitInfoTecAd} fetchDataLoading={[fetchInfoTecnicaAdicionalPatchData.loading]}
                                            value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                        />
                                    </div>

                                    <div className="py-2"></div>
                                </>
                            }


                            {
                                getLabelByModulesv2(modulosActivosUsoProdB, ['b6', 'b7']) && (
                                    <>
                                        <h5 className='mb-3'>
                                            <small>4.6. {getLabelByModulesv2(modulosActivosUsoProdB, ['b6', 'b7'])}</small>
                                        </h5>
                                        {
                                            !formInfoTecnicaAdicional.form_completed?.['table4pt6']?.isCompleted &&
                                            <Form.Text className='text-danger d-block mb-2'>Los campos de la tabla son obligatorios</Form.Text>
                                        }
                                        <Table8To16
                                            data={formInfoTecnicaAdicional.table4pt6}
                                            name='table4pt6'
                                            nameTable='parametro_uso_energia_expresado_por_unidad_funcional'
                                            handleInputChangeTableN={handleInputChangeTableN}
                                        />

                                        <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                                            <ButtonSpinner
                                                variant="info" type="submit" className="me-2"
                                                handleSubmit={handleSubmitInfoTecAd} fetchDataLoading={[fetchInfoTecnicaAdicionalPatchData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                            />
                                        </div>

                                        <div className="py-2"></div>
                                    </>
                                )
                            }

                            {
                                getLabelByModulesv2(modulosActivosFinVidaC, ['c1', 'c2', 'c3', 'c4']) && (
                                    <>
                                        <h5 className='mb-3'>
                                            <small>4.7. Fin de vida (C1-C4)</small>
                                        </h5>
                                        {
                                            !formInfoTecnicaAdicional.form_completed?.['table4pt7']?.isCompleted &&
                                            <Form.Text className='text-danger d-block mb-2'>Los campos de la tabla son obligatorios</Form.Text>
                                        }
                                        <Table17
                                            data={formInfoTecnicaAdicional.table4pt7}
                                            supuestos={formInfoTecnicaAdicional.dv_supuestos_desarrollo_escenarios}
                                            handleClickMergeA1A3={handleClickMergeA1A3}
                                            exista1a3={exista1a3}
                                            handleInputChangeTable17={handleInputChangeTable17}
                                            name='table4pt7'
                                            datBasic={datBasic}
                                            handleInputChangeInfoTecnicaAdicional={handleInputChangeInfoTecnicaAdicional}
                                            bloqueado={bloqueado}
                                        />
                                        {
                                            console.log('formInfoTecnicaAdicional.form_errors', formInfoTecnicaAdicional.form_errors)
                                        }
                                        {
                                            !formInfoTecnicaAdicional.dv_supuestos_desarrollo_escenarios && formInfoTecnicaAdicional.form_errors?.['dv_supuestos_desarrollo_escenarios'] &&
                                            <Form.Text className='text-danger d-block mt-0 text-center'>Supuestos para el desarrollo de escenarios es obligatorio</Form.Text>
                                        }

                                        <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                                            <ButtonSpinner
                                                variant="info" type="submit" className="me-2"
                                                handleSubmit={handleSubmitInfoTecAd} fetchDataLoading={[fetchInfoTecnicaAdicionalPatchData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                            />
                                        </div>
                                    </>
                                )
                            }


                        </div>
                    )
                }
            </div>
            <ModalStatusError fetchData={fetchInfoTecnicaAdicionalPatchData} />

            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

        </>
    )
}
