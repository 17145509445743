import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'

import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { InputFile, InputSelect } from '../../../../components/Inputs';
import { useAxios } from '../../../../hooks/useAxios';
import { ModalStatusServer2 } from '../../../../components/ModalStatusServer/ModalStatusServer2';
import { isStatusSucess } from '../../../../helpers/helpers';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';

// import './ProcessosMain.css'

export const ModalCambiarEstado = ({ showModalCambiarEstado, handleCloseModalCambiarEstado, id, idiomaDAPcon,
    fetchEstadosDAPconsData, fetchFichaResumen }) => {

    const doc_file = useRef(null)

    const [formCambiarEstado, handleInputCambiarEstado, handleFormCambiarEstado, handleResetCambiarEstado] = useForm({
        estado: '',
        delete_pdf: false,
        send_notifications: false,
    })

    const [valuesValidationDAPcon, handleValidationDAPcon, handleResetValidationDAPcon] = useValidator({
        estado: { isOk: true, msgError: [`El estado es obligatorio.`] },
    })


    const [fetchReemplazarPDF, fetchReemplazarPDFData] = useAxios('patch', `/dapcons/${id}/form/change-status?_method=PATCH`)

    const handleSubmitReemplazarPDF = () => {

        if (handleValidationDAPcon(formCambiarEstado)) {

            fetchReemplazarPDF({
                body: {
                    ...formCambiarEstado,
                    idioma_id: idiomaDAPcon
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSucess(fetchReemplazarPDFData.data?.status)) {
            fetchFichaResumen({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
            handleCloseModalCambiarEstado()
        }

    }, [fetchReemplazarPDFData]);

    const handleInputCheck = (e) => {

        handleFormCambiarEstado({
            ...formCambiarEstado,
            [e.target.name]: e.target.checked
        })
    }

    if (showModalCambiarEstado.value) {
        
    }

    useEffect(() => {
     
        if (showModalCambiarEstado.value) {
            
        } else {
            handleResetCambiarEstado()
        }

    }, [showModalCambiarEstado.value]);
    

    return (
        <>

            <Modal show={showModalCambiarEstado.value} onHide={handleCloseModalCambiarEstado}>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Cambiar estado DAPcons</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-3'>

                    <Form className='row'>

                        <Form.Group className="col-12 col-lg-8">
                            <InputSelect
                                label='Estado DAPcons:'
                                className=''
                                name='estado'
                                values={fetchEstadosDAPconsData.data?.map(item => {
                                    return {
                                        id: item.id,
                                        nombre: item.nombre,
                                    }
                                }) || []}
                                // values={poblaciones}
                                value={formCambiarEstado.estado}
                                onChange={handleInputCambiarEstado}
                                validation={valuesValidationDAPcon}
                            // fetchData={fetchCrearOrganizacionesData}
                            // disabled={!fetchPoblacionesData.data || formCrearOrganizaciones.codigo_postal?.length !== 5}
                            />
                        </Form.Group>

                        {
                            (formCambiarEstado.estado === 'borrador' || formCambiarEstado.estado === 'incidencias') &&
                            <div className="col-12 mt-3">
                                <Form.Check
                                    type='checkbox'
                                    name='delete_pdf'
                                    id='delete_pdf'
                                    checked={formCambiarEstado.delete_pdf}
                                    label='Borrar PDF después de cambiar el estado'
                                    className=''
                                    onChange={(e) => handleInputCheck(e)}
                                />
                            </div>
                        }

                        <div className="col-12 mt-3">
                            <Form.Check
                                type='checkbox'
                                name='send_notifications'
                                id='send_notifications'
                                checked={formCambiarEstado.send_notifications}
                                label='Enviar notificaciones por email'
                                className=''
                                onChange={(e) => handleInputCheck(e)}
                            />
                        </div>

                    </Form>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <Button variant="default" onClick={handleCloseModalCambiarEstado}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleSubmitReemplazarPDF} fetchDataLoading={[fetchReemplazarPDFData.loading]}
                        value='Guardar'
                    />
                </Modal.Footer>
            </Modal>

            <ModalStatusServer2 fetchData={fetchReemplazarPDFData} />

        </>
    )
}
