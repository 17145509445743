import React, { useContext, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversity, faUserCog, faListUl, faFileAlt, faCogs, faCheckCircle, faCheckSquare, faUser, faHome, faEnvelopeOpen, faUsers, faWarehouse, faList, faDollarSign, faMoneyBill, faArchive, faNewspaper, faEnvelope, faPlane, faChevronDown, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../auth/AuthContext';
import { useLang } from '../../language/';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import NavLinkMenu from './NavLinkMenu';
import { isAdmin, isCaateeb, isModuleAdministrador, isModuleConsultor, isModuleOrganizacion, isVerificador, isConsultor, isOrganizacion  } from '../../helpers/helpers';
import './NavLinksInner.css'
import { Nav } from 'react-bootstrap';
import { images } from '../../helpers/images';
import { useLocation } from 'react-router-dom';

export const NavLinksInner = ({ close, setClose }) => {
    const { user: { perfil, modulo_app } } = useContext(AuthContext);
    console.log('perfil', perfil)
    const Lang = useLang();

    const [openItem, setOpenItem] = useState(null);

    const handleToggle = (item) => {
        setOpenItem(openItem === item ? null : item);
    };

    const getChevronStyle = (item) => ({
        transition: 'transform 0.3s ease',
        transform: openItem === item ? 'rotate(-180deg)' : 'rotate(0deg)' 
    });

    const location = useLocation();
    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith('/biblioteca-documentos') ||  path.startsWith('/contenidos') || path.startsWith('/comunicaciones')) {
          setOpenItem('comunicaciones');
        }else if(path.startsWith('/usuarios') ||  path.startsWith('/parametros') || path.startsWith('/plantillas-mensajes') || path.startsWith('/RCPs') || path.startsWith('/tarifas')){
            setOpenItem('administracion');
        }else {
          setOpenItem(null);
        }
  }, [location]);

    return (
        <div className={`NavLinksInner py-2 ${close ? 'mt-1' : 'mt-5'}`} >
           {close &&
               <div className="d-flex align-items-center d-flex justify-content-end">
                    <button className="sidebar-toggle" onClick={() => setClose(!close)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
              </div>  
           }
           <Nav className="flex-column">
             <NavLinkMenu
                label='Inicio'
                to="/inicio"
                icon={<FontAwesomeIcon icon={faHome} />}
                close={close}
            />
            <NavLinkMenu
                label='DAPcons'
                to="/DAPcons"
                icon={<FontAwesomeIcon icon={faFileAlt} />}
                close={close}
            />
            {
                isVerificador(perfil) &&
                <NavLinkMenu
                    label='Verficar DAPcons'
                    to="/verificar-DAPcons"
                    icon={<FontAwesomeIcon icon={faCheckSquare} />}
                    close={close}
                />
            }
            {
                isModuleAdministrador(modulo_app) &&
                 <>
                 <NavLinkMenu
                    label='Empresas'
                    to="/empresas"
                    icon={<FontAwesomeIcon icon={faWarehouse} />}
                    close={close}
                />
                <NavLinkMenu
                    label='Consultores'
                    to="/consultores"
                    icon={<FontAwesomeIcon icon={faUsers} />}
                    close={close}
                />
                </>
            }
            {
                (isAdmin(perfil) || isCaateeb(perfil)) &&
                <>
                <Nav.Item className={`nav-item ${openItem === 'comunicaciones' ? 'open' : ''}`}>
                  <Nav.Link className={`${openItem === 'comunicaciones' ? 'active' : ''}`} onClick={() => handleToggle('comunicaciones')}>
                    <div style={{ 'width': '1.5rem' }} className="me-2">
                        <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                    </div>       
                    <span>Comunicación</span>
                     <div style={{ 'width': '100%' }} className="text-end">
                        <FontAwesomeIcon icon={faChevronDown} style={getChevronStyle('comunicaciones')}/>
                    </div>
                  </Nav.Link>
                  <Nav className="nav-dropdown">
                     <NavLinkMenu
                            label='Documentos'
                            to="/biblioteca-documentos"
                            icon={<FontAwesomeIcon icon={faArchive} />}
                            close={close}
                        />

                     <NavLinkMenu
                        label='Contenidos'
                        to="/contenidos"
                        icon={<FontAwesomeIcon icon={faNewspaper} />}
                        close={close}
                     />
                     <NavLinkMenu
                        label='Comunicaciones'
                        to="/comunicaciones"
                        icon={<FontAwesomeIcon icon={faEnvelope} />}
                        close={close}
                     /> 
                 </Nav>
                </Nav.Item>
              </>
            }   
            {
                isModuleAdministrador(modulo_app) &&
                <>
                <Nav.Item className={`nav-item ${openItem === 'administracion' ? 'open' : ''}`}>
                  <Nav.Link className={`${openItem === 'administracion' ? 'active' : ''}`} onClick={() => handleToggle('administracion')}>
                    <div style={{ 'width': '1.5rem' }} className="me-2">
                        <FontAwesomeIcon icon={faCogs} className="me-2" />
                    </div>       
                    <span>Administración</span>
                     <div style={{ 'width': '100%' }} className="text-end">
                        <FontAwesomeIcon icon={faChevronDown} style={getChevronStyle('administracion')}/>
                    </div>
                  </Nav.Link>
                  <Nav className="nav-dropdown">
                    <NavLinkMenu
                        label={Lang('USUARIOS')}
                        to="/usuarios"
                        icon={<FontAwesomeIcon icon={faUsers} />}
                        close={close}
                    />
                    {/* <NavLinkMenu
                        label='Pagos'
                        to="/pagos"
                        icon={<FontAwesomeIcon icon={faMoneyBill} />}
                        close={close}
                    /> */}
                    <NavLinkMenu
                        label='Plantillas'
                        to="/plantillas-mensajes"
                        icon={<FontAwesomeIcon icon={faEnvelopeOpen} />}
                        close={close}
                    />
                    <NavLinkMenu
                        label='Parámetros'
                        to="/parametros"
                        icon={<FontAwesomeIcon icon={faCogs} />}
                        close={close}
                    />
                    <NavLinkMenu
                        label='RCPs'
                        to="/RCPs"
                        icon={<FontAwesomeIcon icon={faList} />}
                        close={close}
                    />
                    <NavLinkMenu
                        label='Tarifas'
                        to="/tarifas"
                        icon={<FontAwesomeIcon icon={faPlane} />}
                        close={close}
                    />     
                 </Nav>
                </Nav.Item>
              </>
            }   
            {/* {
                isAdmin(perfil) &&
                <>
                    <NavLinkMenu
                        label='Logs'
                        to="/logs"
                        icon={<FontAwesomeIcon icon={faListUl} />}
                        close={close}
                        disabled
                    />
                    <NavLinkMenu
                        label={Lang('MANTENIMIENTO')}
                        to="/maintenance"
                        icon={<FontAwesomeIcon icon={faCogs} />}
                        close={close}
                        disabled
                    />
                </>
            } */}
            {
                (isConsultor(perfil) || isVerificador(perfil) || isOrganizacion(perfil))  &&
                <>
                    <NavLinkMenu
                            label='Documentos'
                            to="/public-documentos"
                            icon={<FontAwesomeIcon icon={faArchive} />}
                            close={close}
                        />    
                    {/*    
                    <NavLinkMenu
                            label='Noticias'
                            to="/public-noticias"
                            icon={<FontAwesomeIcon icon={faNewspaper} />}
                            close={close}
                        />  
                    */}          
                </>
            }          
            {
                <NavLinkMenu
                    label='Mis datos'
                    to="/mis-datos"
                    icon={<FontAwesomeIcon icon={faUser} />}
                    close={close}
                />
            }
          </Nav>
        </div>
    )
}
