import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faClone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal, Table, Form, Badge } from 'react-bootstrap'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../hooks/useAxios'
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import { useForm } from '../../../hooks/useForm';
import { Loader } from '../../../components/Loader/Loader'
import Wizard from "../../../components/Wizard/Wizard";

export const ModalDuplicar = ({ data, handleCloseModal, history}) => {
    const [fetchDuplicar, fetchDupliarData] = useAxios('POST', '/dapcons/renovar')
    const [fetchTarifa, fetchTarifaData] = useAxios('GET', `/dapcons/detalle_tarifa`)
    const [formDuplicar, handleInputChangeDuplicar, handleFormChangeDuplicar, handleResetDuplicar] = useForm({
        iscopy: '',
    })

    const handleSubmitDuplicar = (e) => {
        e.preventDefault();
        fetchDuplicar({
            body: {
                ...formDuplicar, 
                dapcons_id: data?.id,
            }
        })
    }

     useEffect(() => {
        if(data?.id){
            fetchTarifa({
                params: {
                    dapcons_id: data?.id,
                    es_renovacion: true,
                }
            });
        }
    },[data?.value])

    useEffect(() => {
      if(fetchDupliarData?.data?.status == 200){
            handleCloseModal();
            history.push(`/DAPcons/${fetchDupliarData.data?.data?.data?.id}/1?is_modal_price=true`)
      }  
    },[fetchDupliarData.data])

    const [indexFocusWizard, setIndexFocusWizard] = useState(0);
    const listWizard = [
       'Renovación de Dapcons'
    ];

    return (
        <Modal show={data.value} onHide={handleCloseModal} size='lg' backdrop="static" keyboard={false}>
            {/*
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'>Renovar DAPcons a partir de: <Badge bg="secondary" className="p-2">{data?.id}</Badge></Modal.Title>
                </Modal.Header>
            */}
            {fetchTarifaData.loading ? <Loader /> : <>
            <Modal.Body className='px-4 py-4'>
            <Wizard>
                    <Wizard.Header
                        listWizard={listWizard}
                        indexFocusWizard={indexFocusWizard}
                        setIndexFocusWizard={setIndexFocusWizard}
                        enableBack={false}
                    />
                    <Wizard.Body indexFocusWizard={indexFocusWizard}>
                        <section className="bg-white">
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-12 col-lg-8">
                                <Alert variant="info" className="mt-3 p-2">Renovación de DAPcons a partir de: <Badge bg="primary" className="p-2">{data?.id}</Badge></Alert>
                            </div>
                            <div className="col-12 col-lg-6">
                            <Table bordered hover responsive className="mt-3">
                                <tbody>
                                {fetchTarifaData.data?.data?.data?.es_convenio != 1  && <>
                                  <tr>
                                    <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                      <strong>Tarifa:</strong><br/>
                                    </td>
                                    <td>
                                     {fetchTarifaData.data?.data?.data?.descripcion || ''}
                                    </td>
                                  </tr>
                
                                  {fetchTarifaData.data?.data?.data?.descripcion_extra &&

                                      <tr>  
                                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                          <strong>Costo por Idioma</strong><br/>
                                        </td>
                                        <td>
                                         €  {fetchTarifaData.data?.data?.data?.precio_extra || 0}
                                        </td>
                                      </tr>                  
                                  }

                                  <tr>
                                    <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                      <strong>Importe (Iva no incluido) :</strong><br/>
                                    </td>
                                    <td>
                                       <b>€ {((parseInt(fetchTarifaData.data?.data?.data?.precio || 0)) + (parseInt(fetchTarifaData.data?.data?.data?.precio_extra || 0))).toFixed(2) }</b>
                                    </td>
                                  </tr>
                                </>
                                }  
                                {fetchTarifaData.data?.data?.data?.es_convenio == 1  &&
                                    <tr>
                                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                          <strong>Convenio:</strong><br/>
                                        </td>
                                        <td>
                                         {fetchTarifaData.data?.data?.data?.convenio || ''}
                                        </td>
                                   </tr>
                                 }  
                                </tbody>
                            </Table>
                            <MsgInputServer fetchData={fetchDupliarData} className='mt-2' />
                        </div>  
                        </div>
                      </section>
                      <section>
                      </section>
                    </Wizard.Body>
                </Wizard>   
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button variant="default" onClick={() => {
                    handleCloseModal()
                }}>
                    Cerrar
                </Button>
                <ButtonSpinner
                    variant="primary" type="submit" className="ms-2 mt-2"
                    handleSubmit={handleSubmitDuplicar} fetchDataLoading={[fetchDupliarData.loading]}
                    value={'Continuar'} icon={<FontAwesomeIcon icon={faClone} className='me-1' />}
                />
            </Modal.Footer>
            </>
          }  
        </Modal>
    )
}
