//general utils
const schema = (item = {}) => {
    const data = {
        id: item.id || '',
        codigo: item.codigo || '',
        descripcion: item.descripcion || '',
        codigo_contable: item.codigo_contable || '',
        estado: item.estado || '',
        precio: item.precio || '',
        tipo: item.tipo || '',
    }
    return data;
}

const adaterPaginate = (data) => {
    const dataTemp = data?.data.data
    const items = dataTemp?.data?.map(item => (schema(item)))
    return {
        data: {
            total: dataTemp?.total,
            per_page: dataTemp?.per_page,
            items
        },
        status: data?.status
    }
}

const adaterGeneral = (data) => {
    const dataTemp = data?.data?.data
    const item = schema(dataTemp)
    return {
        data: item,
        status: data?.status
    }
}

export default {schema, adaterPaginate, adaterGeneral}