import { faCheckCircle, faFile, faFilePdf, faHome, faSave, faTimes, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Breadcrumb, Button, Form, InputGroup, FormControl } from 'react-bootstrap'
import { Loader } from '../../../components/Loader/Loader'
import { useAxios } from '../../../hooks/useAxios'
import { useModalData } from '../../../hooks/useModalData';
import {ModalDetalle} from './ModalDetalle'
import { PaginationT } from '../../../components/PaginationT/PaginationT';
import queryString from 'query-string';
import { useForm } from '../../../hooks/useForm'
import { useLocation } from 'react-router-dom';
import { getURLQuery, getValuesParams, isModuleOrganizacion } from '../../../helpers/helpers'
import './ContenidosPublic.css'

export const ContenidosPublic = () => {
    const location = useLocation()
    const [fetchContenidos, fetchContenidosData] = useAxios('GET', `/contenidos-web-public`)
    const [dataModal_Detalle, handleOpenModal_Detalle, handleCloseModal_Detalle] = useModalData()

    const {
        magic: magic = '',
        page: page = 1,
    } = queryString.parse(location.search);

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        magic: magic,
        page: page,
    });

    const handleSearch = (event) => {
        event.preventDefault();
         // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        // history.push(`?${query}`);
        window.history.replaceState(null, null, `?${query}`);

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1,
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchContenidos({
                params: {
                    ...formValuesSearchTemp,
                    paginate: true,
                }
            })
        }
    };

    useEffect(() => {

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        window.history.replaceState(null, null, `?${query}`);

        fetchContenidos({
            params: {
                ...urlParams,
                paginate: true,
            }
        })
    }, [formValuesSearch.page, formValuesSearch.orderby])

    const truncate = (str) => {
         return str.length > 200 ? str.substring(0, 200) + "..." : str;
    }

    return (
        <>
            <div className="container-inner row justify-content-between">
                {/*<Breadcrumb className="Breadcrumb-inner">
                    <Breadcrumb.Item href="#">
                        <FontAwesomeIcon icon={faHome} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                         Módulo Noticias
                    </Breadcrumb.Item>
                </Breadcrumb>
               */}

                <div className="container">
                      <div className="col-12 col-lg-12">
                        <div className="animate__animated animate__fadeInUp">

                            <div className="container mt-3">
                                <div className="row d-flex justify-content-center"> 
                                    <div className="col-12 col-md-6 mb-4">
                                        <form onSubmit={handleSearch}>
                                            <InputGroup>
                                              <FormControl
                                                placeholder="Buscar título, descripción"
                                                aria-label="Buscar"
                                                aria-describedby="basic-addon2"
                                                name="magic"
                                                value={formValuesSearch.magic} 
                                                onChange={handleInputChangeSearch}
                                              />
                                              <Button variant="primary" type="submit">Buscar</Button>
                                            </InputGroup>
                                          </form>
                                      </div>  
                                  </div>  
                            </div>

                            {fetchContenidosData.loading ?
                                <div className="py-5">
                                    <Loader />
                                </div> : 
                                <>

                                <div className="container">  
                                 <div className="row d-flex justify-content-center"> 
                                 {
                                    fetchContenidosData?.data?.data?.data?.total ?
                                    <>
                                      <PaginationT
                                            total={fetchContenidosData?.data?.data?.data?.total}
                                            handleFormChange={handleFormChange}
                                            formValuesSearch={formValuesSearch}
                                            perPage={fetchContenidosData?.data?.data?.data?.per_page}
                                        />
                                      {fetchContenidosData.data?.data?.data?.data?.map((row, index) => (
                                             <div className="col-12 col-md-6 col-xl-3 mb-4" key={index}>
                                                <div className="card  border-0 shadow-sm mb-2 mr-3  h-100">
                                                    <img src={row.ruta} className="card-img-top img-cover" alt="..."/>
                                                    <div className="card-body d-flex flex-column">
                                                      <h5 className="card-title border-bottom pb-2">{row.titulo}</h5>
                                                      <p className="card-text d-inline-block" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                      {truncate(row.descripcion_corta)}</p>
                                                      <div className="text-center border-top pt-4">
                                                       <Button variant="primary" size='sm' className='mb-3'
                                                            onClick={
                                                                () => handleOpenModal_Detalle({
                                                                    id: row.id,
                                                                })
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faEye} /> Leer más
                                                        </Button>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        ))}
                                      <PaginationT
                                            total={fetchContenidosData?.data?.data?.data?.total}
                                            handleFormChange={handleFormChange}
                                            formValuesSearch={formValuesSearch}
                                            perPage={fetchContenidosData?.data?.data?.data?.per_page}
                                        />
                                      </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No se ha encontrado resultados</h5>
                                      }     
                                  </div>
                                </div>
                                </>  
                            }  
                         </div>    
                    </div>     

                </div>
            </div>

            <ModalDetalle
            data={dataModal_Detalle}
            handleCloseModal={handleCloseModal_Detalle}
            />

        </>

    )
}
