import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faClone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal, Table, Form, Check } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../../hooks/useAxios'
import { MsgInputServer } from '../../../../components/MsgInput/MsgInputServer';
import { useForm } from '../../../../hooks/useForm';

export const ModalDuplicar = ({ data, handleCloseModal, history}) => {
    const [fetchDuplicar, fetchDupliarData] = useAxios('POST', '/dapcons/renovar')

    const [formDuplicar, handleInputChangeDuplicar, handleFormChangeDuplicar, handleResetDuplicar] = useForm({
        iscopy: '',
    })

    const handleSubmitDuplicar = (e) => {
        e.preventDefault();
        fetchDuplicar({
            body: {
                ...formDuplicar, 
                dapcons_id: data?.id
            }
        })
    }

    const handleChangeCopy = (e) => {
        handleFormChangeDuplicar({
            ...formDuplicar,
            iscopy: e.target.checked ? true : ''
        })
    }

    useEffect(() => {
      if(fetchDupliarData?.data?.status == 200){
            handleCloseModal();
            history.push(`/DAPcons/${fetchDupliarData.data?.data?.data?.id}/1`)
      }  
    },[fetchDupliarData.data])
    return (
        <Modal show={data.value} onHide={handleCloseModal} size='' backdrop="static" keyboard={false}>
             <Modal.Header closeButton className='border-0'>
                <Modal.Title className='h5'>Dapcons N° {data?.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                <Alert key={'info'} variant={'info'}>
                  <span className="text-primary mb-3">El DAPcons se renovara si no esta checkeado la opción de copiar</span>
                </Alert>  
                  <div className="col-12 mt-3">
                    <Form.Check
                        type='checkbox'
                        name='iscopy'
                        value={formDuplicar.iscopy}
                        onChange={handleChangeCopy}
                        id={`default-checkbox`}
                        label='Copiar Dapcons'
                    />
                </div>
                <MsgInputServer fetchData={fetchDupliarData} className='mt-2' />
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button variant="default" onClick={() => {
                    handleCloseModal()
                }}>
                    Cerrar
                </Button>
                <ButtonSpinner
                        variant="success" type="submit" className="ms-2 mt-2"
                        handleSubmit={handleSubmitDuplicar} fetchDataLoading={[fetchDupliarData.loading]}
                        value={formDuplicar.iscopy ? 'Copiar': 'Renovar'} icon={<FontAwesomeIcon icon={faClone} className='me-1' />}
                    />
            </Modal.Footer>
        </Modal>
    )
}
