import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Modal, Spinner, Tab, Table, Tabs } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputSelect, InputText } from '../../../components/Inputs';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { PatchRCPJSON, PostRCPJSON } from '../../../services/rcp.service';
import { getRCPAdapterSchema } from '../../../adapters/rcp.adapter';
import { ModalPrompt } from '../../../components/ModalStatusServer/ModalPrompt';
import { Loader } from '../../../components/Loader/Loader';
import _ from 'lodash';
import { ModalStatusError } from '../../../components/ModalStatusServer/ModalStatusError';

// import './ProcessosMain.css'

export const ModalCrearRCPs = ({ showModalCrearRCPs, handleCloseModalCrearRCPs, fetchRCPs, setShowSuccessEdit }) => {

    const history = useHistory();

    const [fetchCrearRCPs, fetchCrearRCPsData, resetfetchCrearRCPs] = PostRCPJSON()

    const [fetchEditarRCPs, fetchEditarRCPsData, resetfetchEditarRCPs] = PatchRCPJSON()

    const [fetchEliminarRCPs, fetchEliminarRCPsData, resetfetchEliminarRCPs] = PatchRCPJSON()

    const [
        formCrearRCPs,
        handleInputChangeCrearRCPs,
        handleFormChangeCrearRCPs,
        handleResetCrearRCPs
    ] = useForm(getRCPAdapterSchema())

    useEffect(() => {
        console.log('formCrearRCPs', formCrearRCPs)
    }, [formCrearRCPs])

    const [valuesValidCrearRCPs, handleVerifiedValuesCrearRCPs, handleResetValuesCrearRCPs] = useValidator({
        dv_nombre_producto: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        Organizaciones_rcp_id: { isOk: true, msgError: [`El RCP es obligatorio.`] },
        verificador_nif: { isOk: true, msgError: [`El verificador es obligatorio.`] },
        Organizaciones_confrm_veracidad_datos: { isOk: true, msgError: [`Ha de aceptar la veracidad de los datos`] },
    })

    const handleSubmitCrearPRCs = () => {
        const formCrearRCPsTemp = _.cloneDeep(formCrearRCPs)
        const listIdiomasFirmasTemp = _.cloneDeep(listIdiomasFirmas)

        formCrearRCPsTemp.rcp_idiomas = listIdiomasFirmasTemp.map(item => {
            delete item['nombre']
            return item
        })
        fetchCrearRCPs({
            body: formCrearRCPsTemp
        })
    }

    const handleSubmitEditarPRCs = () => {
        
        const formEditarRCPsTemp = _.cloneDeep(formCrearRCPs)
        const listIdiomasFirmasTemp = _.cloneDeep(listIdiomasFirmas)
        
        formEditarRCPsTemp.rcp_idiomas = listIdiomasFirmasTemp.map(item => {
            delete item['nombre']
            return item
        })

        fetchEditarRCPs({
            id: formEditarRCPsTemp.id,
            body: formEditarRCPsTemp
        })
    }

    useEffect(() => {

        if (showModalCrearRCPs.value) {
            console.log(showModalCrearRCPs.item)
            fetchIdiomas()
            if (showModalCrearRCPs.item?.id) {

                handleFormChangeCrearRCPs(showModalCrearRCPs.item)
            } else {

                handleFormChangeCrearRCPs(getRCPAdapterSchema())

            }
        } else {
            handleResetCrearRCPs()
            resetFetchIdiomas()
            setListIdiomasFirmas([])

            resetfetchCrearRCPs()
            resetfetchEditarRCPs()
            resetfetchEliminarRCPs()
            
            handleResetValuesCrearRCPs()
        }

    }, [showModalCrearRCPs])


    const [fetchIdiomas, fetchIdiomasData, resetFetchIdiomas] = useAxios('get', `/idiomas`)

    const [listIdiomasFirmas, setListIdiomasFirmas] = useState([])

    useEffect(() => {

        if (fetchIdiomasData.data?.status === 200) {
            const listTemp = fetchIdiomasData?.data?.data?.data?.map(item => {
                return {
                    idioma_id: item.id,
                    nombre: `${item.idioma_nombre}`
                }
            })

            for (let i = 0; i < listTemp.length; i++) {

                const itemExtraido = showModalCrearRCPs.item?.rcp_idiomas?.find(item => item.idioma_id === listTemp[i].idioma_id) || {}
                console.log('itemExtraido', itemExtraido)

                listTemp[i]['rcp_nombre'] = itemExtraido.rcp_nombre || ''
                listTemp[i]['rcp_sobrenombre'] = itemExtraido.rcp_sobrenombre || ''
                listTemp[i]['descripcion'] = itemExtraido.descripcion || ''
            }
            console.log('itemExtraido', listTemp)
            setListIdiomasFirmas(listTemp)
        }

    }, [fetchIdiomasData])

    const handleInputChangePaginaFirma = (e, index) => {

        const listIdiomasFirmasTemp = [...listIdiomasFirmas]
        listIdiomasFirmasTemp[index][e.target.name] = e.target.value

        setListIdiomasFirmas(listIdiomasFirmasTemp)
    }
    useEffect(() => {
        console.log('listIdiomasFirmas', listIdiomasFirmas)
    }, [listIdiomasFirmas])

    useEffect(() => {

        if (fetchCrearRCPsData.status === 201 || fetchEditarRCPsData.status === 201 || fetchEliminarRCPsData.status === 201) {
            handleCloseModalCrearRCPs()
            setShowSuccessEdit(true)
            fetchRCPs()
            resetfetchCrearRCPs()
            resetfetchEditarRCPs()
            resetfetchEliminarRCPs()
            setShowConfirmacion({ value: false, index: -1 })
        }

    }, [fetchCrearRCPsData, fetchEditarRCPsData, fetchEliminarRCPsData])


    const handleChangeVerificador = (e) => {
        handleFormChangeCrearRCPs({
            ...formCrearRCPs,
            vigente: e.target.checked ? true : false
        })
    }

    // Confirmar eliminar 

    const [showConfirmacion, setShowConfirmacion] = useState({
        value: false,
        index: -1
    })

    const handleEliminarRCP = (e) => {
        
        const formEliminarRCPsTemp = _.cloneDeep(formCrearRCPs)
        const listIdiomasFirmasTemp = _.cloneDeep(listIdiomasFirmas)

        formEliminarRCPsTemp.rcp_idiomas = listIdiomasFirmasTemp.map(item => {
            delete item['nombre']
            return item
        })

        fetchEliminarRCPs({
            id: formEliminarRCPsTemp.id,
            body: {
                ...formEliminarRCPsTemp,
                anulada: true
            }
        })
    }

    return (
        <>

            <Modal show={showModalCrearRCPs.value} onHide={handleCloseModalCrearRCPs} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>{showModalCrearRCPs.item?.id ? 'Editar RCP' : 'Crear RCP'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-4'>

                    <Form className='row'>

                        <Form.Group className="mb-3 col-sm-12 col-lg-4">
                            <InputText
                                label='Equivalencia:'
                                className=''
                                name='equivalencia'
                                value={formCrearRCPs.equivalencia}
                                onChange={handleInputChangeCrearRCPs}
                                validation={valuesValidCrearRCPs}
                                fetchData={fetchCrearRCPsData}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 col-sm-12 col-lg-3">
                            <InputText
                                label='Año:'
                                className=''
                                name='anyo'
                                value={formCrearRCPs.anyo}
                                onChange={handleInputChangeCrearRCPs}
                                validation={valuesValidCrearRCPs}
                                fetchData={fetchCrearRCPsData}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 col-sm-12 col-lg-4">
                            <InputText
                                label='Versión:'
                                className=''
                                name='version'
                                value={formCrearRCPs.version}
                                onChange={handleInputChangeCrearRCPs}
                                validation={valuesValidCrearRCPs}
                                fetchData={fetchCrearRCPsData}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 col-sm-12 col-lg-4">
                            <InputText
                                label='Codigo para codificación dapcons:'
                                className=''
                                name='codigo_para_codificacion_dapcons'
                                value={formCrearRCPs.codigo_para_codificacion_dapcons}
                                onChange={handleInputChangeCrearRCPs}
                                validation={valuesValidCrearRCPs}
                                fetchData={fetchCrearRCPsData}
                            />
                        </Form.Group>

                        <div className="col-12 col-lg-3 mt-2 pb-4" key='emacs'>
                            <Form.Check
                                type='checkbox'
                                name='vigente'
                                // value={formConsultor.vigente}
                                onChange={handleChangeVerificador}
                                checked={formCrearRCPs.vigente}
                                id='emacs'
                                className='mb-0 fw-500 mt-lg-4'
                                // style={{ 'fontSize': '1.2rem' }}
                                label={
                                    <>
                                        <div>RCP Vigente</div>
                                    </>
                                }
                            />
                            {/* <MsgInput obj={valuesValidRegistroEmpresa} name='emacs' /> */}

                        </div>
                        {
                            formCrearRCPs.codigo_para_codificacion_dapcons === '100' && 
                            <div className="col-12 mb-3">
                                <small>Al usar el código 100, se obligará el ingreso de la Ficha de producto</small>
                            </div>
                        }


                        <div className="col-12 input-multi-tabs mt-2">
                            {
                                listIdiomasFirmas.length === 0 ?
                                    <div className="py-5">
                                        <Loader />
                                    </div> :
                                    <Tabs defaultActiveKey={listIdiomasFirmas[0]?.idioma_id} id="tab-findevida" className="mb-3">
                                        {
                                            listIdiomasFirmas.map((item, index) => {
                                                return (
                                                    <Tab eventKey={item.idioma_id} title={item.nombre}>
                                                        <div className='row'>

                                                            <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput7">
                                                                <InputText
                                                                    label='Nombre:'
                                                                    className=''
                                                                    name='rcp_nombre'
                                                                    value={listIdiomasFirmas[index].rcp_nombre}
                                                                    onChange={(e) => handleInputChangePaginaFirma(e, index)}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput7">
                                                                <InputText
                                                                    className=''
                                                                    label='Sobrenombre:'
                                                                    name='rcp_sobrenombre'
                                                                    value={listIdiomasFirmas[index].rcp_sobrenombre}
                                                                    onChange={(e) => handleInputChangePaginaFirma(e, index)}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput7">
                                                                <InputText
                                                                    className=''
                                                                    label='Material:'
                                                                    name='descripcion'
                                                                    // as="textarea"
                                                                    // rows={4}
                                                                    value={listIdiomasFirmas[index].descripcion}
                                                                    onChange={(e) => handleInputChangePaginaFirma(e, index)}
                                                                />
                                                            </Form.Group>

                                                        </div>
                                                    </Tab>
                                                )
                                            })
                                        }
                                    </Tabs>
                            }
                        </div>

                    </Form>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    {
                        showModalCrearRCPs.item?.id ?
                            <>
                                <Button variant="danger" onClick={() => setShowConfirmacion({ value: true, index: -1 })}>
                                    Eliminar
                                </Button>
                                <ButtonSpinner
                                    variant="info" type="submit" className="ms-2"
                                    handleSubmit={handleSubmitEditarPRCs} fetchDataLoading={[fetchEditarRCPsData.loading]}
                                    value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                />
                            </>
                            :
                            <ButtonSpinner
                                variant="info" type="submit" className="ms-auto px-3"
                                handleSubmit={handleSubmitCrearPRCs} fetchDataLoading={[fetchCrearRCPsData.loading]}
                                value='Crear' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                            />
                    }
                </Modal.Footer>
            </Modal>
            <ModalPrompt setShowStatus={setShowConfirmacion} showStatus={showConfirmacion}>
                <Modal.Body>
                    <div className="py-0 text-center">
                        <h5 className='mb-4 fw-normal'>
                            Eliminar RCP
                        </h5>
                        <p className='mb-0 px-3'>
                            ¿Está seguro que desea eliminar este RCP?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={() => setShowConfirmacion({ value: false, index: -1 })}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="danger" type="submit" className="me-2"
                        handleSubmit={handleEliminarRCP} fetchDataLoading={[fetchEliminarRCPsData.loading]}
                        value='Eliminar'
                    />
                </Modal.Footer>
            </ModalPrompt>

            <ModalStatusError fetchData={fetchEditarRCPsData} />
            <ModalStatusError fetchData={fetchCrearRCPsData} />
            <ModalStatusError fetchData={fetchEliminarRCPsData} />

        </>

    )
}
