import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../../hooks/useAxios';
import { MsgInputServer } from '../../../../components/MsgInput/MsgInputServer';

export const ModalSincronizar = ({data, handleCloseModal, consultor, setConsultor, reloadComisiones}) => {
     const [fetchSincronizarRema, fetchSincronizarRemaData] = useAxios('POST', `/comisiones/sincronizar`); 
     const handleSubmitSincronizarRema = (e) => {
            fetchSincronizarRema({
                body: {
                    org_id : consultor?.org_id,
                    comisionista_id : consultor?.isConsultor
                }
            })
    }
    
    useEffect(() => {
        if (fetchSincronizarRemaData?.data?.status === 200) {
            reloadComisiones(consultor?.isConsultor);
            handleCloseModal();
        }
    }, [fetchSincronizarRemaData.data])

	 return (
        <Modal show={data?.value} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Sincronizar Datos</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                 <b className="text-dark">¿Realmente desea sincronizar este REMA?</b>
                 <div className="col-12 text-center">
                    <MsgInputServer fetchData={fetchSincronizarRemaData} className='mt-2' />
                 </div>  
            </Modal.Body>
            <Modal.Footer>
                <ButtonSpinner
                    variant="primary" type="submit" className="ms-2"
                    handleSubmit={handleSubmitSincronizarRema} fetchDataLoading={[fetchSincronizarRemaData.loading]}
                    value='Sincronizar' icon={<FontAwesomeIcon icon={faInfoCircle} className='me-1' />}
                />   
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
           
        </Modal>
     )   
}