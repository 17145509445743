import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputText } from '../../../components/Inputs';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { ModalStatusServer2 } from '../../../components/ModalStatusServer/ModalStatusServer2';
import { isStatusSucess } from '../../../helpers/helpers';
import { Loader } from "../../../components/Loader/Loader";
import {formatFecha} from '../../../helpers/helpers'

// import './ProcessosMain.css'

export const ModalPagoDAPcons = ({ showModalPagoDAPcons, setshowModalPagoDAPcons, handleCloseModalPagoDAPcons, fetchRCPData,
    fetchVerificadoresData, fetchIdiomasData, fetchSuccess }) => {

    const history = useHistory();

    // const [fetchPagoPOST, fetchPagoPOSTData] = useAxios('POST', '/cuotas/registro-pago')

    // ------------ Pagar DAPcons

    const [fetchVerificarPagoDapcons, fetchVerificarPagoData, resetFetchVerificarPago] = useAxios('get', `/dapcons`)

    const [fetchPagarDapcons, fetchPagarDapconsData, resetFetchPagarDapcons] = useAxios('patch', `/dapcons/registro-pagos`)

    const [formPago, handleInputChangePago, handleFormChangeCrearDAPcons, handleResetCrearDAPcons]
        = useForm({pagado: '', importe: ''})

    const [valuesValidCrearDAPcons, handleVerifiedValuesCrearDAPcons, handleResetValuesCrearDAPcons] = useValidator({
        dapcons_id: { isOk: true, msgError: [`El campo es obligatorio.`] },
        // year: { isOk: true, msgError: [`El campo es obligatorio.`] },
        pagado: { isOk: true, msgError: [`El campo es obligatorio.`] },
        //importe: { isOk: true, msgError: [`El campo es obligatorio.`] },
    })

    const handleSubmitAltaDAPcons = () => {

        if (handleVerifiedValuesCrearDAPcons(formPago)) {
            fetchPagarDapcons({
                body: {
                    ...formPago,
                    dapcons_id: Number(formPago.dapcons_id || 0)
                }
            })
        }
    }

    useEffect(() => {
        console.log(fetchPagarDapconsData)

        if (isStatusSucess(fetchPagarDapconsData?.data?.status)) {
            fetchSuccess()
            handleCloseModalPagoDAPcons()
        }

    }, [fetchPagarDapconsData])

    // --------- Seleccion de organizacion

    const [organizacionSelect, setOrganizacionSelect] = useState({
        id: '',
        nombre: ''
    })

    // Modal

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        handleInputChangePago({
            target: {
                name: 'org_id',
                value: organizacionSelect.id
            }
        })
    }, [organizacionSelect])

    useEffect(() => {

        if (showModalPagoDAPcons.value) {

            if (showModalPagoDAPcons?.payload?.item) {

                console.log('showModalPagoDAPcons?.payload?.item', showModalPagoDAPcons?.payload?.item);

                handleFormChangeCrearDAPcons({
                    ...showModalPagoDAPcons?.payload?.item
                })
            }

            fetchVerificarPagoDapcons({
                id: showModalPagoDAPcons?.payload?.item?.id+'/verificar_pago'
            })

        } else {
            
            setOrganizacionSelect({
                id: '',
                nombre: ''
            })
            handleResetCrearDAPcons()
            handleResetValuesCrearDAPcons()
        }

    }, [showModalPagoDAPcons.value]);

    useEffect(() => {
        if(fetchVerificarPagoData.data?.data?.data){
            const data = fetchVerificarPagoData.data?.data?.data;
            handleFormChangeCrearDAPcons({...formPago, pagado: data.pagado || '', importe: data.pago?.importe || ''})
        }
    },[fetchVerificarPagoData.data])

    return (
        <>

            <Modal show={showModalPagoDAPcons.value} onHide={handleCloseModalPagoDAPcons}>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Ficha de registro de pagos DAPcons</Modal.Title>
                </Modal.Header>
                {
                    fetchVerificarPagoData?.loading 
                    ? <Loader />
                    : <>
                    <Modal.Body className='px-4 py-4'>
                        <Form className='row'>
                            <Form.Group className="col-lg-6">
                                <InputText
                                    label='Fecha de pago:'
                                    className=''
                                    type='date'
                                    name='pagado'
                                    value={formatFecha(formPago.pagado)}
                                    onChange={handleInputChangePago}
                                    validation={valuesValidCrearDAPcons}
                                    fetchData={fetchPagarDapconsData}
                                />
                            </Form.Group>
                           
                            <Form.Group className="col-lg-6">
                                <InputText
                                    label='Importe:'
                                    type="number"
                                    className=''
                                    name='importe'
                                    value={formPago.importe}
                                    onChange={handleInputChangePago}
                                    validation={valuesValidCrearDAPcons}
                                    fetchData={fetchPagarDapconsData}
                                />
                            </Form.Group>
                

                            {/* <Form.Group className="col-12">
                                {
                                    showModalPagoDAPcons.payload?.action === 'CREATE' ?
                                        <InputText
                                            label='Observaciones:'
                                            className=''
                                            as='textarea'
                                            rows={2}
                                            name='observaciones'
                                            value={formPago.observaciones}
                                            onChange={handleInputChangePago}
                                            validation={valuesValidCrearDAPcons}
                                            fetchData={fetchPagarDapconsData}
                                        />
                                        :
                                        <>
                                            <Form.Label className='fw-500'>
                                                <span className='pe-2'>Observaciones:</span>
                                            </Form.Label>
                                            <p className='mt-0 mb-0'>{formPago.observaciones}</p>
                                        </>
                                }

                            </Form.Group> */}

                        </Form>
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-between'>
                        {
                            showModalPagoDAPcons.payload?.action === 'CREATE' &&
                            <>
                                <Button variant="default" onClick={() =>{
                                    handleCloseModalPagoDAPcons()
                                }}>
                                    Salir
                                </Button>
                                <ButtonSpinner
                                    variant="info" type="submit" className="ms-2"
                                    handleSubmit={handleSubmitAltaDAPcons} fetchDataLoading={[fetchPagarDapconsData.loading]}
                                    value='Registrar Pago' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                                />
                            </>
                        }
                        {/* {
                            showModalPagoDAPcons.payload?.action === 'EDIT' &&
                            <>
                                <ButtonSpinner
                                    variant="danger" type="submit" className="ms-2"
                                    handleSubmit={handleSubmitDeletePago} fetchDataLoading={[fetchPagoDELETEData.loading]}
                                    value='Eliminar Pago' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                                />
                                <div></div>
                            </>
                        } */}

                    </Modal.Footer>
                </>
                }
                </Modal>
            
            {/* <ModalStatusServer2 fetchData={fetchPagoPOSTData} /> */}
            <ModalStatusServer2 fetchData={fetchPagarDapconsData} />

            {/* <ModalTableOrganizacion show={show} handleClose={handleClose} setOrganizacionSelect={setOrganizacionSelect} /> */}

        </>
    )
}
