import React from 'react'
import { faInf, faInfoCircle, faInfoCircleoCirclefaInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Overlay, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap'
import './Tablas.css'

export const Table7 = ({ carbonoBiogenico, formAnalisisCicloVida, handleInputChangeTable7 }) => {
    return (
        <div>
            <div className='caption text-center d-block'>Tabla Kg de carbono biogénico</div>
            <Table responsive id='Table7' className='DAPconTable w-auto mx-auto'>
                {/* <thead>
                    <tr>
                        <th>Destino</th>
                        <th>Tipo de transporte</th>
                        <th>Porcentaje (%)</th>
                        <th>Km medios</th>
                    </tr>
                </thead> */}
                <tbody>
                    {
                        carbonoBiogenico && Object.keys(carbonoBiogenico).map(key => {
                            return (
                                <tr>
                                    <td valign='middle'>
                                        <strong>{carbonoBiogenico[key]?.pt_texto}</strong>
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            autoComplete="one-time-code"
                                            value={formAnalisisCicloVida.table7[key]}
                                            onChange={(e) => handleInputChangeTable7(e, key)}
                                            name={key}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </Table>
        </div>
    )
}
