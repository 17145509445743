import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import { useLang } from '../../language/';
import { AuthContext } from '../../auth/AuthContext';
import { UsuariosContent } from './UsuariosContent/UsuariosContent';
import { Usuario } from './Usuario/Usuario';
import { useLocation } from 'react-router-dom';

export const Usuarios = () => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const location = useLocation();
    console.log(location.pathname);

    return (
        <div className="container-inner row justify-content-between">
            <Switch>
                <Route
                    path="/usuarios/:id"
                    component={(props) => <Usuario {...props} namePage='Consultor' perfil={perfil} nameProcess='' />}
                />

                <Route
                    exact
                    path="/usuarios"
                    component={() => <UsuariosContent namePage='Usuarios' perfil={perfil} />}
                />
            </Switch>
        </div>
    )
}
