import { faRetweet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useEffect} from 'react'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../hooks/useAxios';

export const BtnReload = ({data, reloadTable,handleReloadTable}) => {
	const [fetchDestinatario, fetchDestinatarioData] = useAxios('PATCH', `/comunicaciones/admin`)
	const handleSendMail = () => {
        if(window.confirm("Realmente desea enviar este correo?")){
             fetchDestinatario({
                id: data+'/enviar_email',
            })
        }
    }

    useEffect(() => {
        if (fetchDestinatarioData?.data?.data?.status === 'Success') {
            alert(fetchDestinatarioData?.data?.data?.message);
            handleReloadTable();
        }
    }, [fetchDestinatarioData])

	return (
        <>
        	 <ButtonSpinner
                variant="primary" type="submit" size="sm" 
                handleSubmit={handleSendMail} fetchDataLoading={[fetchDestinatarioData.loading]}
                icon={<FontAwesomeIcon icon={faRetweet} className='me-1' />}
            />
        </>
     )    
}