import { faFile, faHome, faCheckCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Breadcrumb, Button, Form, ListGroup, Tab, Tabs } from 'react-bootstrap'
import { AuthContext } from '../../auth/AuthContext'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner'
import { InputFile, InputSelect, InputText } from '../../components/Inputs'
import InputImage from '../../components/Inputs/InputImage'
import { ModalCropImage } from '../../components/ModalCropImage/ModalCropImage'
import { MsgInput } from '../../components/MsgInput/MsgInput'
import { MsgInputServer } from '../../components/MsgInput/MsgInputServer'
import { isModuleOrganizacion, isVerificador } from '../../helpers/helpers'
import { useAxios } from '../../hooks/useAxios'
import { useForm } from '../../hooks/useForm'
import { ModalStatus } from '../../components/ModalStatusServer/ModalStatus';
import { Loader } from '../../components/Loader/Loader'
import { useValidator } from '../../hooks/useValidator'
import { GetConsultorJSON, PostConsultorJSON } from '../../services/consultor.service'
import { ModalHelpUpFirma } from '../../components/Modals/ModalsHelp/ModalHelpUpFirma'
import { IconHelp } from '../../components/Modals/IconHelp'
import { ModalHelpDownFirma } from '../../components/Modals/ModalsHelp/ModalHelpDownFirma'
import { getConsultorAdapterSchemaMisDatos } from '../../adapters/consultor.adapter'
import { GetPaisJSON } from '../../services/pais.service'

export const MisDatosConsultor = () => {

    const { user } = useContext(AuthContext);

    const inputFileRef = useRef();

    const [fetchConsultor, fetchConsultorData, resetFetchConsultor] = GetConsultorJSON()

    const [fetchIdiomas, fetchIdiomasData] = useAxios('get', `/idiomas`)


    useEffect(() => {

        fetchConsultor({
            params: {
                consultor_nif: user.user.usuario
            }
        })
        fetchIdiomas()

    }, [])

    useEffect(() => {

        if (fetchConsultorData.status === 200) {

            handleFormChangeConsultor(fetchConsultorData.data)
        }

    }, [fetchConsultorData])



    const [fetchRegistroConsultor, fetchRegistroConsultorData] = PostConsultorJSON()

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {

        console.log(fetchRegistroConsultorData)

        if (fetchRegistroConsultorData.status === 201) {
            // handleCloseModalRegistroConsultor()
            setShowSuccessEdit(true)
            // setShowStatusVerify2(true)
        }

    }, [fetchRegistroConsultorData])

    const [
        formConsultor,
        handleInputChangeConsultor,
        handleFormChangeConsultor,
        handleResetConsultor
    ] = useForm(getConsultorAdapterSchemaMisDatos())


    const [listIdiomasFirmas, setListIdiomasFirmas] = useState([])

    useEffect(() => {

        if (fetchIdiomasData.data && formConsultor.id) {

            if (fetchIdiomasData.data?.status === 200) {
                const listTemp = fetchIdiomasData?.data?.data?.data?.map(item => {
                    return {
                        idioma_id: item.id,
                        nombre: `${item.idioma_nombre}`
                    }
                })

                for (let i = 0; i < listTemp.length; i++) {

                    const itemExtraido = formConsultor.verificador_casillas?.find(item => item.idioma_id === listTemp[i].idioma_id) || {}
                    listTemp[i]['casilla_pagina_firma'] = itemExtraido.casilla_pagina_firma || ''
                    listTemp[i]['casilla_ultima_pagina'] = itemExtraido.casilla_ultima_pagina || ''
                }

                setListIdiomasFirmas(listTemp)
            }
        }

    }, [fetchIdiomasData, formConsultor.id])

    const [valuesValidRegistroConsultor, handleVerifiedValuesRegisterExterno, handleResetValuesRegisterExterno] = useValidator({
        email: { isOk: true, msgError: [`El correo es obligatorio.`] },
        nif: { isOk: true, msgError: [`El NIF es obligatorio.`] },
        nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        apellidos: { isOk: true, msgError: [`Los apellidos son obligatorios.`] },
        codigo_postal: { isOk: true, msgError: [`El código postal es obligatorio.`] },
        poblacion: { isOk: true, msgError: [`La población es obligatoria.`] },
        pais: { isOk: true, msgError: [`El país es obligatorio.`] },
        direccion: { isOk: true, msgError: [`La dirección es obligatoria.`] },
    })

    const handleSubmitFormConsultor = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesRegisterExterno(formConsultor)) {

            const sendConsultor = { ...formConsultor }

            if (formConsultor.es_verificador) {

                sendConsultor.verificador_casillas = JSON.stringify(listIdiomasFirmas)
                // sendConsultor.verificador_casillas = {
                //     '1': {},
                //     '2': {},
                // }

                // .map(function(obj) {
                //     return Object.keys(obj).sort().map(function(key) { 
                //       return obj[key];
                //     });
                //   });

                console.log(sendConsultor.verificador_casillas)

                // for (let i = 0; i < sendConsultor.verificador_casillas?.length; i++) {
                //     sendConsultor.verificador_casillas[i] = new Map(sendConsultor.verificador_casillas[i].map(x => [x.id, x]))
                // }
            }

            fetchRegistroConsultor({ body: sendConsultor })
        }
    }

    const [fetchGetImage, fetchGetImageData] = useAxios('get', `/consultor-logo-img`, '', 'blob')

    useEffect(() => {

        if (formConsultor.logo && typeof formConsultor.logo === 'string') {
            console.log(formConsultor)

            fetchGetImage({
                id: `${formConsultor.id}/img?token_img=${formConsultor.logo}`
            })
        }

    }, [formConsultor.logo])

    useEffect(() => {

        if (fetchGetImageData.data) {

            console.log(fetchGetImageData.data.data)
            setInputFiles([new File([fetchGetImageData.data?.data], 'logo.png')])

        }

    }, [fetchGetImageData])

    useEffect(() => {
        console.log(formConsultor)

    }, [formConsultor])

    // Recortar imagen

    const [InputFiles, setInputFiles] = useState([])

    useEffect(() => {

        handleFormChangeConsultor({
            ...formConsultor,
            logo: InputFiles?.[0],
        })

    }, [InputFiles])

    // Modal

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        file: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        file: null
    })
    const handleShowModalCropImage = (file) => setShowModalCropImage({
        value: true,
        file
    });

    const handleChangeInputImage = (e) => {
        if (e.target.files.length > 0) {
            handleShowModalCropImage(e.target.files[0])
        }
    }


    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useAxios('get', `/poblacion`)

    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (activeEspana) {

            if (formConsultor.codigo_postal?.length === 5) {

                fetchPoblaciones({
                    params: {
                        cp: formConsultor.codigo_postal
                    }
                })
            } else {
                setPoblaciones([])
                handleFormChangeConsultor({
                    ...formConsultor,
                    provincia: '',
                    poblacion: '',
                    // pais: '',
                })
            }
        }

    }, [formConsultor.codigo_postal])

    useEffect(() => {

        console.log(fetchPoblacionesData)

        if (fetchPoblacionesData.data) {

            if (fetchPoblacionesData.data?.status === 201) {

                const poblaciones = fetchPoblacionesData.data?.data?.data

                let poblacionesTemp = poblaciones.map(item => {
                    return ({
                        id: item.poblacion,
                        nombre: item.poblacion
                    })
                })

                setPoblaciones(poblacionesTemp)

            } else {
                handleFormChangeConsultor({
                    ...formConsultor,
                    provincia: '',
                    poblacion: '',
                    // pais: '',
                })
            }

        }

        if (fetchPoblacionesData.error) {
            handleFormChangeConsultor({
                ...formConsultor,
                provincia: '',
                poblacion: '',
                // pais: '',
            })
        }

    }, [fetchPoblacionesData])

    useEffect(() => {

        if (formConsultor.poblacion && fetchPoblacionesData.data) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data.data]

            console.log(poblacionesTemp)

            const resp = poblacionesTemp.find(item => item.poblacion === formConsultor.poblacion)

            handleFormChangeConsultor({
                ...formConsultor,
                provincia: resp?.provincia,
                // pais: resp?.pais,
            })
        }

    }, [formConsultor.poblacion, fetchPoblacionesData.data])

    const [showModalHelpUpFirma, setShowModalHelpUpFirma] = useState(false);
    const [showModalHelpDownFirma, setShowModalHelpDownFirma] = useState(false);


    // funcionalidad de pais

    const [fetchPaises, fetchPaisesData] = GetPaisJSON()

    useEffect(() => {
        fetchPaises()
    }, [])


    const [activeEspana, setactiveEspana] = useState(false)

    useEffect(() => {

        if (formConsultor.pais === '1' || formConsultor.pais === 1) {

            setactiveEspana(true)
            fetchPoblaciones({
                params: {
                    cp: formConsultor.codigo_postal
                }
            })
        } else {
            setactiveEspana(false)
            handleFormChangeConsultor({
                ...formConsultor,
            })
        }

    }, [formConsultor.pais])

    return (
        <>

            <div className="container-inner row justify-content-between">
                <Breadcrumb className="Breadcrumb-inner">
                    <Breadcrumb.Item href="#">
                        <FontAwesomeIcon icon={faHome} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Mis datos
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="d-flex justify-content-center">
                    <div className="col-12 col-xl-9">
                        <div className="MainInner shadow-sm animate__animated animate__fadeInUp">
                            {
                                fetchConsultorData.loading ?
                                    <div className="py-5">
                                        <Loader />
                                    </div> : <>

                                        <div className="row pt-3 pb-4 px-3">
                                            <div className="col-12">
                                                <h5 className='mb-4'>Datos personales</h5>
                                            </div>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-3">
                                                <Form.Label className='fw-500'>NIF:</Form.Label>
                                                <p className='mt-0 mb-0'>{formConsultor.nif}</p>
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-4">
                                                <InputText
                                                    label='Nombre *'
                                                    className=''
                                                    name='nombre'
                                                    value={formConsultor.nombre}
                                                    onChange={handleInputChangeConsultor}
                                                    validation={valuesValidRegistroConsultor}
                                                    fetchData={fetchRegistroConsultorData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-5">
                                                <InputText
                                                    label='Apellidos *'
                                                    className=''
                                                    name='apellidos'
                                                    value={formConsultor.apellidos}
                                                    onChange={handleInputChangeConsultor}
                                                    validation={valuesValidRegistroConsultor}
                                                    fetchData={fetchRegistroConsultorData}
                                                />
                                            </Form.Group>

                                            <div className="col-12">
                                                <h5 className='mb-3 mt-2'>Datos de empresa</h5>
                                            </div>

                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-8">
                                                        <div className="row">
                                                            <Form.Group className="mb-3 col-12">
                                                                <InputText
                                                                    label='Nombre de la empresa'
                                                                    className=''
                                                                    name='empresa_nombre'
                                                                    value={formConsultor.empresa_nombre}
                                                                    onChange={handleInputChangeConsultor}
                                                                    validation={valuesValidRegistroConsultor}
                                                                    fetchData={fetchRegistroConsultorData}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-3 col-lg-12">
                                                                <InputText
                                                                    label='Dirección *'
                                                                    className=''
                                                                    name='direccion'
                                                                    value={formConsultor.direccion}
                                                                    onChange={handleInputChangeConsultor}
                                                                    validation={valuesValidRegistroConsultor}
                                                                    fetchData={fetchRegistroConsultorData}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-3 col-sm-6 col-lg-5">
                                                                <InputSelect
                                                                    label='País *'
                                                                    className=''
                                                                    name='pais'
                                                                    // values={[]}
                                                                    values={fetchPaisesData.data || []}
                                                                    value={formConsultor.pais}
                                                                    onChange={handleInputChangeConsultor}
                                                                    validation={valuesValidRegistroConsultor}
                                                                    fetchData={fetchRegistroConsultorData}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-3 col-sm-6 col-lg-4">
                                                                <InputText
                                                                    label='Código Postal *'
                                                                    className=''
                                                                    name='codigo_postal'
                                                                    value={formConsultor.codigo_postal}
                                                                    onChange={handleInputChangeConsultor}
                                                                    validation={valuesValidRegistroConsultor}
                                                                    fetchData={fetchRegistroConsultorData}
                                                                />
                                                            </Form.Group>

                                                            {
                                                                activeEspana ? <>
                                                                    <Form.Group className="mb-3 col-sm-6 col-lg-8">
                                                                        <InputSelect
                                                                            label='Población *'
                                                                            className=''
                                                                            name='poblacion'
                                                                            // values={[]}
                                                                            values={poblaciones}
                                                                            value={formConsultor.poblacion}
                                                                            onChange={handleInputChangeConsultor}
                                                                            validation={valuesValidRegistroConsultor}
                                                                            fetchData={fetchRegistroConsultorData}
                                                                            disabled={!fetchPoblacionesData.data || formConsultor.codigo_postal?.length !== 5}
                                                                        />

                                                                    </Form.Group>
                                                                </> : <>
                                                                    <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                                        <InputText
                                                                            label='Región *'
                                                                            className=''
                                                                            name='poblacion'
                                                                            value={formConsultor.poblacion}
                                                                            onChange={handleInputChangeConsultor}
                                                                            validation={valuesValidRegistroConsultor}
                                                                            fetchData={fetchRegistroConsultorData}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                                        <InputText
                                                                            label='Ciudad *'
                                                                            className=''
                                                                            name='provincia'
                                                                            value={formConsultor.provincia}
                                                                            onChange={handleInputChangeConsultor}
                                                                            validation={valuesValidRegistroConsultor}
                                                                            fetchData={fetchRegistroConsultorData}
                                                                        />
                                                                    </Form.Group>
                                                                </>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 col-sm-9 col-lg-12">
                                                                <InputImage
                                                                    label='Logotipo'
                                                                    width='100%'
                                                                    height='13rem'
                                                                    onChange={handleChangeInputImage}
                                                                    InputFiles={InputFiles}
                                                                    setInputFiles={setInputFiles}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-12">
                                                <h5 className='mb-3 mt-2'>Datos de contacto</h5>
                                            </div>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                                <InputText
                                                    label='Teléfono *'
                                                    className=''
                                                    name='movil'
                                                    value={formConsultor.movil}
                                                    onChange={handleInputChangeConsultor}
                                                    validation={valuesValidRegistroConsultor}
                                                    fetchData={fetchRegistroConsultorData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-6">
                                                <InputText
                                                    label='Dirección electrónica *'
                                                    className=''
                                                    name='email'
                                                    value={formConsultor.email}
                                                    onChange={handleInputChangeConsultor}
                                                    validation={valuesValidRegistroConsultor}
                                                    fetchData={fetchRegistroConsultorData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-6">
                                                <InputText
                                                    label='Web:'
                                                    className=''
                                                    name='consultor_web'
                                                    value={formConsultor.consultor_web}
                                                    onChange={handleInputChangeConsultor}
                                                    validation={valuesValidRegistroConsultor}
                                                    fetchData={fetchRegistroConsultorData}
                                                />
                                            </Form.Group>

                                            <div className="col-12 mt-2 text-center">
                                                <MsgInputServer fetchData={fetchRegistroConsultorData} className='mt-4' />
                                            </div>

                                        </div>
                                        <div className=' pt-3 d-flex justify-content-end border-top'>
                                            <ButtonSpinner
                                                variant="info" type="submit" className="me-2"
                                                handleSubmit={handleSubmitFormConsultor} fetchDataLoading={[fetchRegistroConsultorData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                                            />
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
                </div>

            </div>
            <ModalCropImage
                show={showModalCropImage}
                handleClose={handleCloseModalCropImage}
                height='500px'
                setInputFiles={setInputFiles}
                escalas={[
                    { x: 1, y: 1, label: 'Cuadrado' },
                    { x: 4, y: 2, label: 'Rectángulo' }
                ]}
            />
            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalHelpUpFirma setShowModalHelp={setShowModalHelpUpFirma} showModalHelp={showModalHelpUpFirma} />

            <ModalHelpDownFirma setShowModalHelp={setShowModalHelpDownFirma} showModalHelp={showModalHelpDownFirma} />
        </>

    )
}
