import React from 'react'
import { faCreditCard, faArrowRight, faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, Table, Badge } from 'react-bootstrap'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { InputFile } from '../../../components/Inputs';

export const Pago = ({fetchTarifaData, formTarifa, handleInputChangeTarifa, valuesValidTarifa, fetchPagarData, setIndexFocusWizard, handlePaymentSubmit, inputFileRef}) => {

	 return (
        <div className="row d-flex mt-3 justify-content-center align-items-center">
          <div className="col-8">
                <Table hover responsive>
                  <tbody>
                    <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          Subir Documento SEPA
                        </td>
                        <td>
                            <InputFile
                              label={'Seleccione un documento'}
                              name='org_sepa'
                              referencia={inputFileRef}
                              onChange={handleInputChangeTarifa}
                              fetchData={fetchPagarData}
                            />
                        </td>
                      </tr>
                  </tbody>
                </Table>   
            </div>   
            <div className="text-center">
                <Button variant="outline-secondary" className="mt-2 mb-1" size="" onClick={() => setIndexFocusWizard(1)}><FontAwesomeIcon icon={faArrowLeft} className='me-1'/>Regresar</Button>
                <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handlePaymentSubmit} fetchDataLoading={[fetchPagarData.loading]}
                        value='Pagar Cuota' icon={<FontAwesomeIcon icon={faCreditCard} className='me-1' />}
                    />
            </div>
        </div>  
    )
}