import { faCheck, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner'

export const ButtonAprobarDAPcon = ({ formDAPcon, onClick }) => {

    console.log(formDAPcon)

    return (
        <>
            {
                (formDAPcon?.fecha_informe_verificacion_producto && formDAPcon?.doc_inf_verificacion_dapcons?.[0]?.token_doc) ?
                    <>
                        <Button variant="warning" size='' className="px-3 ms-3 d-flex align-items-center"
                            onClick={onClick}
                        >
                            <FontAwesomeIcon icon={faCheckDouble} className='me-2' /> Aprobar DAPcons
                        </Button>
                    </> : (
                        <>
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-disabled">Debe ingresar la fecha y el informe de verificación para poder aprobar el DAPcons</Tooltip>}
                                placement='bottom'
                                key='bottom'
                            >
                                <span className="d-inline-block">
                                    <Button variant="warning" size='' className="px-3 ms-3 d-flex align-items-center"
                                        onClick={onClick} disabled={true}
                                    >
                                        <FontAwesomeIcon icon={faCheckDouble} className='me-2' /> Aprobar DAPcons
                                    </Button>
                                </span>
                            </OverlayTrigger>
                        </>
                    )
            }
        </>

    )
}
