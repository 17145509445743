import { faBook, faCheck, faCheckCircle, faDownload, faExchangeAlt, faFile, faFilePdf, faPencilAlt, faPlus, faSave, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Form, FormControl, InputGroup, ListGroup, Modal, Table } from 'react-bootstrap'
import { Prompt } from 'react-router-dom';
import { useAxios } from '../../../../hooks/useAxios';
import { Table4 } from '../../../../components/DAPconTables/Table4'
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';
import { InputFile, InputSelect, InputText } from '../../../../components/Inputs';
import { Loader } from '../../../../components/Loader/Loader';
import { InputRadios } from '../../../../components/Inputs/InputRadios';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';
import { ModalPrompt } from '../../../../components/ModalStatusServer/ModalPrompt';
import { ModalCrearIncidencia } from './ModalCrearIncidencia';
import { AuthContext } from '../../../../auth/AuthContext';
import { getDataFile, isConsultor, isModuleAdministrador, isModuleOrganizacion, isStatusSucess, isVerificador } from '../../../../helpers/helpers';
import { images } from '../../../../helpers/images';
import { FileList } from '../../../../components/FileList/FileList';
import { TooltipLock } from '../../../../components/TooltipLock/TooltipLock';
import { ModalStatusError } from '../../../../components/ModalStatusServer/ModalStatusError';
import {formatFecha} from '../../../../helpers/helpers';

export const DAPconVerificadorSeccion0 = ({ id, setSeries, series, fetchDAPconsDatReqData, dapcons_codigo,
    showModalCrearIncidencia, handleCloseModalCrearIncidencia, idiomaDAPcon, datBasic, user, fetchDAPconsDatReq }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);


    const [fetchRCP, fetchRCPData] = useAxios('get', `/rcp`)
    const [fetchVerificadores, fetchVerificadoresData] = useAxios('get', `/verificadores`)

    useEffect(() => {
        fetchRCP()
        fetchVerificadores()
    }, [])

    const [listRCP, setListRCP] = useState([])

    useEffect(() => {

        if (fetchRCPData.data?.status === 200) {
            const listTemp = fetchRCPData?.data?.data?.data?.map(item => {

                const item2 = item.rcp_idiomas?.find(item2 => item2.idioma_id === idiomaDAPcon) || {}

                return {
                    id: Number(item.rcp_id),
                    nombre: `${item.rcp_equivalencia} ${item2.rcp_nombre || ''}`
                }
            })
            setListRCP(listTemp)
        }

    }, [fetchRCPData])

    // Preparando select de Verificadores

    const [listVerificadores, setListVerificadores] = useState([])

    useEffect(() => {

        if (fetchVerificadoresData.data?.status === 200) {
            const listTemp = fetchVerificadoresData?.data?.data?.data?.map(item => {
                return {
                    id: item.consultor_nif,
                    nombre: `${item.consultor_nombre} ${item.consultor_apellidos}`
                }
            })
            setListVerificadores(listTemp)
        }

    }, [fetchVerificadoresData])


    // const [fetchDAPconsDatReq, fetchDAPconsDatReqData] = useAxios('get', `/dapcons/${id}/form/datos-requeridos`)

    const [formDAPcon, handleInputChangeDAPcon, handleFormChangeDAPcon, handleResetDAPcon] = useForm({
       
        consultor: {},
        doc_inf_verificacion_dapcons: [],
        doc_opcionales_no_verificables: [],
        doc_opcionales_verificables: [],
        documento_ficha_producto: '',
        documento_inf_analisis_ciclo_vida: '',
        dv_descripcion_producto: '',
        dv_nombre_producto: '',
        organizacion: {},
        rcp: {},
        verificador: {},
        nif: '',

        dapcons_codigo: '',
        all_idiomas: [],
        dapcons_estado_param_id: '',
        dapcons_idioma_estado: '',
        bloqueado: false,

        // Existentes
        org_nombre: '',
        dapcons_rcp_id: '',
        verificador_nif: '',
        estadoDAPcons: '',
        fecha_informe_verificacion_producto: '',
    })

    // CRUD modelos / series


    const [valuesValidationDAPcon, handleValidationDAPcon, handleResetValidationDAPcon] = useValidator({
        dv_nombre_producto: { isOk: true, msgError: [`El nombre del producto es obligatorio.`] },
        dv_descripcion_producto: { isOk: true, msgError: [`La descripción del producto es obligatoria.`] },
        dapcons_verificador_id: { isOk: true, msgError: [`El verificador es obligatorio.`] },
        dapcons_rcp_id: { isOk: true, msgError: [`El RCP de referencia es obligatorio.`] },
    })

    // useEffect(() => {
    //     fetchDAPconsDatReq()
    // }, [])

    useEffect(() => {
        console.log('section', fetchDAPconsDatReqData)
        if (fetchDAPconsDatReqData?.data) {

            const formDAPconTemp = fetchDAPconsDatReqData.data?.data?.data
            handleFormChangeDAPcon({
                ...formDAPconTemp,
                org_nombre: formDAPconTemp.organizacion?.org_nombre,
                verificador_nif: formDAPconTemp.verificador?.consultor_nif,
                dapcons_rcp_id: formDAPconTemp.rcp?.rcp_id,
                dapcons_verificador_id: formDAPconTemp.verificador?.id,
                dv_nombre_producto: formDAPconTemp.dv_nombre_producto,
                dv_descripcion_producto: formDAPconTemp.dv_descripcion_producto,
                dv_planta_produccion: formDAPconTemp.dv_planta_produccion,
                dv_representatividad_geografica: formDAPconTemp.dv_representatividad_geografica,
                dv_idioma_id: idiomaDAPcon,
                dapcons_incluir_tabla_5: formDAPconTemp.dapcons_fecha_creacion === formDAPconTemp.dapcons_fecha_modificacion ? true : formDAPconTemp.dapcons_incluir_tabla_5,
                dapcons_incluir_tabla_6: formDAPconTemp.dapcons_fecha_creacion === formDAPconTemp.dapcons_fecha_modificacion ? true : formDAPconTemp.dapcons_incluir_tabla_6,
                fecha_informe_verificacion_producto: formDAPconTemp.fecha_informe_verificacion_producto?.split(' ')?.[0]
            })
            // handleFormChangeInformeVer({
            //     ...formDAPconTemp[]
            // })
        }
    }, [fetchDAPconsDatReqData])

    useEffect(() => {
        console.log(formDAPcon)
    }, [formDAPcon])

    const [isBlocking, setIsBlocking] = useState(false);


    const [fetchDAPconsDatReqEdit, fetchDAPconsDatReqEditData] = useAxios('patch', `/dapcons/${id}/form/datos-requeridos`)

    const handleSubmitDAPconDatReqEdit = (e) => {

        e.preventDefault()

        if (handleValidationDAPcon(formDAPcon) && handleValidationDAPconModulos(formDAPcon)) {

            fetchDAPconsDatReqEdit({
                body: {
                    ...formDAPcon,
                    modelos_series: formDAPcon.contiene_series_modelos === 'true' ? formDAPcon.modelos_series.filter(item => item !== '') : []
                }
            })
        }
    }

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        console.log(fetchDAPconsDatReqEditData.data)
        if (fetchDAPconsDatReqEditData.data?.status === 201) {
            setShowSuccessEdit(true)
            fetchDAPconsDatReq({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchDAPconsDatReqEditData])

    // Verificador de almenos 1 modulo declarado

    const [showErrorModulos, setShowErrorModulos] = useState(null)

    const handleValidationDAPconModulos = (formDAPcon) => {

        const res = Object.keys(formDAPcon.modulos_declarados).filter(key => formDAPcon.modulos_declarados[key])

        console.log(res)

        if (res.length > 0) {

            setShowErrorModulos(null)
        } else {

            setShowErrorModulos(<Form.Text className='text-danger d-block'>Ha de marcar almenos 1 módulo</Form.Text>)
        }

        return res.length > 0
    }

    // Confirmar eliminar 

    const [showConfirmacion, setShowConfirmacion] = useState({
        value: false,
        index: -1
    })


    const [fetchDAPconHtml, fetchDAPconHtmlData] = useAxios('get', `/dapcons-pdf/${id}`, '', 'blob')


    const handleFetchDAPcons = () => {
        // const arrayLang = ['es', 'en']

        // console.log(arrayLang[idiomaDAPcon])
        fetchDAPconHtml({
            params: {
                idioma_id: idiomaDAPcon
            }
        })
    }

    const [pdf, setpdf] = useState('')

    useEffect(() => {
        if (fetchDAPconHtmlData.data) {
            // 2. Create blob link to download
            const instanceBlob = new Blob([fetchDAPconHtmlData.data?.data])
            
            const namePDF = `${formDAPcon.dapcons_codigo || 'DAPcons-' + id}.${instanceBlob.type || 'pdf'}`

            const date = new Date()

            const url = window.URL.createObjectURL(instanceBlob);
            const link = document.createElement('a');

            link.href = url;
            // link.setAttribute('download', `DAPcons_${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}.${instanceBlob.type || 'pdf'}`);
            link.setAttribute('download', namePDF);
            // 3. Append to html page
            document.body.appendChild(link);

            // 4. Force download
            link.click();

            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);

            fetchDAPconHtmlData.setState({
                loading: false,
                error: null,
                data: null,
            })

        }
    }, [fetchDAPconHtmlData])

    // Informe y fecha de verificacion

    const [formInformeVer, handleInputChangeInformeVer, handleFormChangeInformeVer, handleResetInformeVer] = useForm({
        doc_id: '',
        doc_descripcion: '',
        doc_file: '',
        idioma_id: '',
        fecha_informe_verificacion_producto: '',
    })

    const [fetchPatchInformeVer, fetchPatchInformeVerData] = useAxios('post', `/dapcons/${id}/informe-verificacion-dapcons/documento?_method=PATCH`)

    useEffect(() => {
        console.log(fetchPatchInformeVerData)
      if (fetchPatchInformeVerData.data?.status === 201) {
        fetchDAPconsDatReq({
            params: {
                idioma_id: idiomaDAPcon
            }
        })
      }
    }, [fetchPatchInformeVerData])
    

    const handleSubmitInformeVer = (e) => {

        e.preventDefault()

        let formData = new FormData();


        formData.append("doc_descripcion", formDAPcon.doc_inf_verificacion_dapcons[0]?.doc_descripcion || '')
        if (ref_informe_ciclo_vida.current?.files?.[0]) {
            
            formData.append("doc_id", formDAPcon.doc_inf_verificacion_dapcons[0]?.doc_id || '')
            formData.append("doc_file", formDAPcon.doc_inf_verificacion_dapcons[0]?.token_doc || ref_informe_ciclo_vida.current?.files?.[0] || '')
            formData.append("idioma_id", idiomaDAPcon)
        }

        if (formDAPcon.fecha_informe_verificacion_producto?.indexOf('-') !== -1) {
            formData.append("fecha_informe_verificacion_producto", formDAPcon.fecha_informe_verificacion_producto?.split('-')?.reverse()?.join('/'))
        } else {
            formData.append("fecha_informe_verificacion_producto", formDAPcon.fecha_informe_verificacion_producto)
        }

        fetchPatchInformeVer({
            body: formData
        })
    }

    const [activeClick, setActiveClick] = useState()


    // ------------------ Descargar informe de verificacion

    const [fetchDocInfoVeri, fetchDocInfoVeriData, resetfetchDocInfoVeriData] = useAxios('get', `/dapcons/${id}/informe-verificacion-dapcons/obtener-documento`, '', 'blob')

    const handleFetchDocInfoVeri = (token, type) => {

        fetchDocInfoVeri({
            params: {
                token_doc: token,
                doc_type: type,
            }
        })
    }

    useEffect(() => {

        if (fetchDocInfoVeriData.data) {
            getDataFile(fetchDocInfoVeriData, resetfetchDocInfoVeriData)
        }
    }, [fetchDocInfoVeriData])


    // Documentos informacion adicional

    const [fetchDocsISOEMAS, fetchDocsISOEMASData, resetfetchDocsISOEMASData] = useAxios('get', `/organizaciones/${formDAPcon.organizacion?.org_id}/obtener-documento`, '', 'blob')

    const handleFetchDocsISOEMAS = (token, type) => {

        fetchDocsISOEMAS({
            params: {
                token_doc: token,
                doc_type: type,
            }
        })
    }

    useEffect(() => {

        if (fetchDocsISOEMASData.data) {
            getDataFile(fetchDocsISOEMASData, resetfetchDocsISOEMASData)
        }
    }, [fetchDocsISOEMASData])


    // Descargar Documentos adjuntos obligatorios

    const [fetchDocAdjOblig, fetchDocAdjObligData, resetfetchDocAdjObligData] = useAxios('get', `/dapcons/${id}/form/doc-adjuntos-obligatorios/obtener-documento`, '', 'blob')

    const handleFetchDocAdjOblig = (token, type) => {

        fetchDocAdjOblig({
            params: {
                token_doc: token,
                doc_type: type,
            }
        })
    }

    useEffect(() => {

        if (fetchDocAdjObligData.data) {
            getDataFile(fetchDocAdjObligData, resetfetchDocAdjObligData)
        }
    }, [fetchDocAdjObligData])


    // Documentos informacion adicional

    const [fetchDocInfoAdic, fetchDocInfoAdicData, resetfetchDocInfoAdicData] = useAxios('get', `/dapcons/${id}/form/informacion-adicional/obtener-documento`, '', 'blob')

    const handleFetchDocInfoAdic = (token, type) => {

        fetchDocInfoAdic({
            params: {
                token_doc: token,
                doc_type: type,
            }
        })
    }

    useEffect(() => {

        if (fetchDocInfoAdicData.data) {
            getDataFile(fetchDocInfoAdicData, resetfetchDocInfoAdicData)
        }
    }, [fetchDocInfoAdicData])


    // Eliminar Documentos adjuntos olbigatorios

    const [fetchEliminarDoc, fetchEliminarDocData] = useAxios('delete', `/dapcons/${id}/informe-verificacion-dapcons/eliminar-documento`)

    const handleFetchEliminarDoc = (token_doc) => {

        fetchEliminarDoc({
            params: {
                token_doc
            }
        })
    }


    useEffect(() => {

        if (fetchEliminarDocData?.data?.status === 200) {
            setShowSuccessEdit(true)
            fetchDAPconsDatReq({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchEliminarDocData])

    const ref_informe_ciclo_vida = useRef(null)


    const [showLoadingDownload, setShowLoadingDownload] = useState(false);

    useEffect(() => {
        setShowLoadingDownload(fetchDAPconHtmlData.loading)
    }, [fetchDAPconHtmlData.loading])

    return (
        <>
            <Prompt when={isBlocking} message="Are you sure you want to leave?" />

            <div className='MainInner shadow-sm animate-width-disabled'>
                {(fetchRCPData.loading || fetchVerificadoresData.loading || fetchDAPconsDatReqData?.loading) ?
                    <div className="py-5">
                        <Loader />
                    </div>
                    : <>

                        <div className="d-flex justify-content-center mb-2">
                            <div>
                                <h5 className='mt-2 mb-4'>Ficha general {dapcons_codigo}</h5>

                            </div>
                            {/* <Button variant="warning" size='' className="px-3 ms-3 d-flex align-items-center"
                                onClick={() => null}
                            >
                                <FontAwesomeIcon icon={faExchangeAlt} className='me-2' /> Crear en Inglés
                            </Button> */}
                        </div>

                        <Form className='row'>

                            <div className="col-lg-8">
                                <div className="row">
                                    <Form.Group className="col-12 col-sm-12 mb-4" controlId="exampleForm.ControlInput2">
                                        <Form.Label className='fw-500'>Nombre de la empresa:</Form.Label>
                                        <p className='mt-0 mb-0'>{formDAPcon.organizacion?.org_nombre}</p>
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-12">
                                        <Form.Label className='fw-500'>Nombre del Producto:</Form.Label>
                                        <p className='mt-0 mb-0'>{formDAPcon.dv_nombre_producto}</p>
                                    </Form.Group>

                                    <Form.Group className="col-12 mb-4">
                                        <Form.Label className='fw-500'>Descripción del Producto:</Form.Label>
                                        <p className='mt-0 mb-0'>{formDAPcon.dv_descripcion_producto}</p>
                                    </Form.Group>

                                    <div className="col-12"></div>


                                    <Form.Group className="mb-3 col-sm-12 col-lg-12">
                                        <Form.Label className='fw-500'>RCP de referencia:</Form.Label>
                                        <p className='mt-0 mb-0'>
                                            {listRCP.find(item => item.id === formDAPcon.dapcons_rcp_id)?.nombre}
                                        </p>
                                    </Form.Group>


                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 text-center d-flex flex-column align-items-center">
                                <div className="px-4 w-100">

                                    <img src={images('./preview.png')} className="DAPcon__imagen_portada" alt="logo" />
                                </div>
                                <ButtonSpinner
                                    variant="primary" type="submit" className="mt-3"
                                    handleSubmit={handleFetchDAPcons} fetchDataLoading={[fetchDAPconHtmlData.loading]}
                                    value='Descargar' icon={<FontAwesomeIcon icon={faDownload} className='me-1' />}
                                />
                            </div>

                            <div className="col-12"></div>

                            <Form.Group className="col-lg-4 mb-4" controlId="exampleForm.ControlInput2">
                                <Form.Label className='fw-500'>Consultor:</Form.Label>
                                <p className='mt-0 mb-0'>{formDAPcon.consultor?.consultor_nombre} {formDAPcon.consultor?.consultor_apellidos}</p>
                            </Form.Group>


                        </Form>

                        {/* <div className="row">
                            <div className="col-12 mb-4">
                                <div className="border-top"></div>
                            </div>
                        </div> */}

                        <div className="row mb-3">
                            <Form.Group className="col-lg-4 mb-4">
                                {
                                    datBasic?.verificador?.consultor_nif === user.user.usuario ?
                                        (

                                            (datBasic?.estadoDAPcons === 'incidencias' || datBasic?.estadoDAPcons === 'pendiente_de_verificar') ?
                                                <InputText
                                                    label='Fecha Verificación:'
                                                    className='col-lg-6'
                                                    name='fecha_informe_verificacion_producto'
                                                    value={formatFecha(formDAPcon.fecha_informe_verificacion_producto)}
                                                    onChange={handleInputChangeDAPcon}
                                                    // validation={valuesValidationDAPcon}
                                                    fetchData={fetchDAPconsDatReqEditData}
                                                    type='date'
                                                /> : <>
                                                    <Form.Label className='fw-500'>
                                                        <span className='pe-2'>Fecha Verificación: </span>
                                                    </Form.Label>
                                                    <p className='mt-0 mb-0'>{formDAPcon.fecha_informe_verificacion_producto}</p>
                                                </>
                                        ) : (
                                            <>
                                                <Form.Label className='fw-500'>
                                                    <span className='pe-2'>Fecha Verificación:</span>
                                                </Form.Label>
                                                {
                                                    formDAPcon.fecha_informe_verificacion_producto ?
                                                        (
                                                            <p className='mt-0 mb-0'>{formDAPcon.fecha_informe_verificacion_producto}</p>
                                                        )
                                                        : (
                                                            <p>No hay fecha de verificación</p>
                                                        )
                                                }
                                            </>
                                        )
                                }


                            </Form.Group>

                            <div className="col-lg-6">
                                <h5 className='h6 fw-500 mb-3 d-flex justify-content-between'>
                                    Informe de verificación del producto (IV)
                                </h5>
                                {
                                    (datBasic?.verificador?.consultor_nif === user.user.usuario && 
                                        (datBasic?.estadoDAPcons === 'incidencias' || datBasic?.estadoDAPcons === 'pendiente_de_verificar') ) ?
                                        (
                                            formDAPcon.doc_inf_verificacion_dapcons?.length > 0 ?
                                                <div className='d-flex align-items-center Files__container'>
                                                    <ListGroup className='col-12 col-lg-8 d-flex align-items-center Files__container'>
                                                        <FileList
                                                            eventClick={() => {
                                                                handleFetchDocInfoVeri(formDAPcon.doc_inf_verificacion_dapcons?.[0]?.token_doc, 'documento_informe_verificacion_dapcons')
                                                                setActiveClick('documento_informe_verificacion_dapcons')
                                                            }}
                                                            eventDelete={() => {
                                                                handleFetchEliminarDoc(formDAPcon.doc_inf_verificacion_dapcons?.[0]?.token_doc)
                                                                setActiveClick(`documento_informe_verificacion_dapcons`)
                                                            }}
                                                            name='Informe de verificación'
                                                            isLoading={(fetchDocInfoVeriData.loading) && activeClick === 'documento_informe_verificacion_dapcons'}

                                                        />
                                                    </ListGroup>
                                                </div> : (

                                                    <Form.Group controlId="formFile" className=" mb-3">
                                                        {/* <label class="fw-500 form-label">Documento:</label> */}
                                                        <InputFile
                                                            name='informe_ciclo_vida'
                                                            // onChange={handleInputChangeDocsAdjObligatorios}
                                                            referencia={ref_informe_ciclo_vida}
                                                        // validation={valuesValidISO}
                                                        />
                                                    </Form.Group>
                                                )
                                        ) : (

                                            formDAPcon.doc_inf_verificacion_dapcons?.length > 0 ?
                                                <div className='d-flex align-items-center Files__container'>
                                                    <ListGroup className='col-12 col-lg-8 d-flex align-items-center Files__container'>
                                                        <FileList
                                                            eventClick={() => {
                                                                handleFetchDocInfoVeri(formDAPcon.doc_inf_verificacion_dapcons?.[0]?.token_doc, 'documento_informe_verificacion_dapcons')
                                                                setActiveClick('documento_informe_verificacion_dapcons')
                                                            }}
                                                            name='Informe de verificación'
                                                            isLoading={(fetchDocInfoVeriData.loading) && activeClick === 'documento_informe_verificacion_dapcons'}

                                                        />
                                                    </ListGroup>
                                                </div> : (
                                                    <p>No hay informe de verificación</p>
                                                )
                                        )
                                }

                            </div>
                        </div>
                        {
                            (datBasic?.verificador?.consultor_nif === user.user.usuario &&
                                (datBasic?.estadoDAPcons === 'incidencias' || datBasic?.estadoDAPcons === 'pendiente_de_verificar') ) &&
                            <div className='col-12 pt-3 pb-5 d-flex justify-content-end border-top'>
                                <ButtonSpinner
                                    variant="info" type="submit" className="me-2"
                                    handleSubmit={handleSubmitInformeVer} fetchDataLoading={[fetchPatchInformeVerData.loading]}
                                    value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                />
                            </div>
                        }

                        {
                            (formDAPcon.documento_inf_analisis_ciclo_vida || formDAPcon.documento_ficha_producto) &&
                            (

                                <div className="row">
                                    {
                                        formDAPcon.documento_inf_analisis_ciclo_vida &&
                                        <div className="col-12 col-lg-6 mb-4">
                                            <h5 className='fs-105 mb-3 d-flex justify-content-between'>
                                                Documento de Informe de análisis de ciclo de vida
                                            </h5>

                                            <div className='d-flex align-items-center Files__container'>
                                                <ListGroup className='col-12 d-flex align-items-center Files__container'>
                                                    <FileList
                                                        eventClick={() => {
                                                            handleFetchDocAdjOblig(formDAPcon.documento_inf_analisis_ciclo_vida.token_doc, 'documento_inf_analisis_ciclo_vida')
                                                            setActiveClick('documento_inf_analisis_ciclo_vida')
                                                        }}
                                                        name='Informe de análisis de ciclo de vida'
                                                        isLoading={(fetchDocAdjObligData.loading) && activeClick === 'documento_inf_analisis_ciclo_vida'}

                                                    />
                                                </ListGroup>

                                            </div>
                                        </div>
                                    }
                                    {
                                        formDAPcon.documento_ficha_producto &&
                                        <div className="col-12 col-lg-6 mb-4">
                                            <h5 className='fs-105 mb-3 d-flex justify-content-between'>
                                                Documento de Ficha de producto
                                            </h5>

                                            <div className='d-flex align-items-center Files__container'>
                                                <ListGroup className='col-12 d-flex align-items-center Files__container'>
                                                    <FileList
                                                        eventClick={() => {
                                                            handleFetchDocAdjOblig(formDAPcon.documento_ficha_producto.token_doc, 'ficha_de_producto')
                                                            setActiveClick('ficha_de_producto')
                                                        }}
                                                        name='Ficha de producto'
                                                        isLoading={(fetchDocAdjObligData.loading) && activeClick === 'ficha_de_producto'}
                                                    />
                                                </ListGroup>

                                            </div>
                                        </div>
                                    }

                                    <div className="col-12 mb-4">
                                        <div className="border-top"></div>
                                    </div>

                                </div>
                            )
                        }


                        {
                            (formDAPcon.doc_opcionales_verificables.length > 0 || formDAPcon.doc_opcionales_no_verificables.length > 0) &&
                            (

                                <div className="row">
                                    {
                                        formDAPcon.doc_opcionales_verificables.length > 0 &&
                                        <div className="col-12 col-lg-6 mb-4">
                                            <h5 className='fs-105 mb-3 d-flex justify-content-between'>
                                                Documentos opcionales verificables
                                            </h5>

                                            <div className='d-flex align-items-center Files__container'>
                                                <ListGroup className='col-12 d-flex align-items-center Files__container'>
                                                    {
                                                        formDAPcon.doc_opcionales_verificables.map(item => {
                                                            return (
                                                                <FileList
                                                                    eventClick={() => {
                                                                        handleFetchDocInfoAdic(item.token_doc, 'documentos_opcionales_verificables')
                                                                        setActiveClick(`documentos_opcionales_verificables_${item.doc_descripcion}_${item.doc_id}`)
                                                                    }}
                                                                    name={item.doc_descripcion}
                                                                    isLoading={(fetchDocInfoAdicData.loading) && activeClick === `documentos_opcionales_verificables_${item.doc_descripcion}_${item.doc_id}`}

                                                                />
                                                            )
                                                        })
                                                    }
                                                </ListGroup>

                                            </div>
                                        </div>
                                    }
                                    {
                                        formDAPcon.doc_opcionales_no_verificables.length > 0 &&

                                        <div className="col-12 col-lg-6 mb-4">
                                            <h5 className='fs-105 mb-3 d-flex justify-content-between'>
                                                Documentos opcionales no verificables
                                            </h5>

                                            <div className='d-flex align-items-center Files__container'>
                                                <ListGroup className='col-12 d-flex align-items-center Files__container'>
                                                    {
                                                        formDAPcon.doc_opcionales_no_verificables.map(item => {
                                                            return (
                                                                <FileList
                                                                    eventClick={() => {
                                                                        handleFetchDocInfoAdic(item.token_doc, 'documentos_opcionales_no_verificables')
                                                                        setActiveClick(`documentos_opcionales_no_verificables_${item.doc_descripcion}_${item.doc_id}`)
                                                                    }}
                                                                    name={item.doc_descripcion}
                                                                    isLoading={(fetchDocInfoAdicData.loading) && activeClick === `documentos_opcionales_no_verificables_${item.doc_descripcion}_${item.doc_id}`}

                                                                />
                                                            )
                                                        })
                                                    }

                                                </ListGroup>

                                            </div>
                                        </div>
                                    }
                                    <div className="col-12 mb-4">
                                        <div className="border-top"></div>
                                    </div>
                                </div>

                            )
                        }

                    </>
                }
            </div>

            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatus setShowStatus={setShowLoadingDownload} showStatus={showLoadingDownload}>
                <div className="py-0 text-center">
                    <Loader />
                    <p className='text-center'>Este proceso puede durar hasta 1 minuto...</p>
                </div>
            </ModalStatus>

            <ModalStatusError fetchData={fetchDAPconHtmlData} />

            <ModalStatusError fetchData={fetchDocInfoVeriData} />
            <ModalStatusError fetchData={fetchDocAdjObligData} />
            <ModalStatusError fetchData={fetchDocInfoAdicData} />
            <ModalStatusError fetchData={fetchDocsISOEMASData} />
        </>
    )
}
