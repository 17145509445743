import React, { useEffect, useState } from 'react'
import { faInf, faInfoCircle, faInfoCircleoCirclefaInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Overlay, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap'
import './Tablas.css'
import { Loader } from '../Loader/Loader'

export const Table8To16 = ({ data, handleInputChangeTableN, name, nameTable, title }) => {

    console.log(data)

    // const [param, setParam] = useState({})

    // useEffect(() => {
    //     if (paramsText.data) {

    //         const paramsTemp = [...paramsText.data?.data?.data]

    //         let paramObj = {}

    //         for (let i = 0; i < paramsTemp.length; i++) {
    //             paramObj[paramsTemp[i].pt_param_id] = paramsTemp[i].pt_texto
    //         }

    //         setParam({
    //             ...paramObj
    //         })
    //     }

    // }, [paramsText])

    return (
        <div>
            {
                data?.loading ? <div className="py-5">
                    <Loader />
                </div> : (
                    <Table responsive id='Table8' className='DAPconTable w-auto mx-auto'>
                        <thead className='text-center'>
                            {
                                title && <tr>
                                    <th colSpan={2}>{title}</th>
                                </tr>
                            }
                            <tr>
                                <th>Parámetro</th>
                                <th>Parámetro expresado por unidad funcional</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.[0]?.[nameTable] && Object.keys(data?.[0]?.[nameTable]).map(key => {
                                    return (
                                        <tr key={key}>
                                            <td valign='middle'>
                                                {data?.[0]?.[nameTable]?.[key]?.x_name}
                                            </td>
                                            <td>
                                                <Form.Control
                                                    as="textarea" rows={2}
                                                    autoComplete="one-time-code"
                                                    value={data?.[0]?.[nameTable]?.[key]?.value}
                                                    onChange={(e) => handleInputChangeTableN(e, key, name, nameTable)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                )
            }
        </div>
    )
}
