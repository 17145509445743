import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { getNavigator } from '../../helpers/helpers';
import { getUrlGuia } from '../../helpers/helpers';

import guia_android from '../../assets/guias_es/guia_android.pdf';
import guia_chrome from '../../assets/guias_es/guia_chrome.pdf';
import guia_edge from '../../assets/guias_es/guia_edge.pdf';
import guia_firefox from '../../assets/guias_es/guia_firefox.pdf';
import guia_ios from '../../assets/guias_es/guia_ios.pdf';


const ModalPlugin = ({ showModalPlugin, handleCloseModalPlugin, pki }) => {

    const handleClickInstallPlugin = _ => {
        pki.redirectToInstallPage();
    }

    const nameNavigator = getNavigator()?.split(' ')[0]

    let guia = null

    if (nameNavigator === 'Chrome') {
        guia = guia_chrome
    } else if (nameNavigator === 'Firefox') {
        guia = guia_firefox
    } else if (nameNavigator === 'Edg') {
        guia = guia_edge
    } else {
        guia = guia_chrome
    }

    const guiaName = getUrlGuia(nameNavigator)

    const [show, setshow] = useState(false)

    return (
        <Modal show={showModalPlugin} onHide={handleCloseModalPlugin} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="modal-body p-4" id="instalarPlugin-content">
                <h4 className="text-center my-4">Firma de documentos</h4>
                <p className="text-center mb-4">Para poder continuar con el proceso de firmas, por favor descargue e instale el plugin</p>
                <div className="row justify-content-center mb-4">
                    <div className="col-12 col-lg-5">
                        <div className="card px-2 py-4 px-lg-3 py-lg-4 shadow-sm">
                            <h4 className="text-center">Plugin de Firma</h4>
                            <ul>
                                <li className="my-2">
                                    <small>No es necesario descargar los documentos para firmarlos.</small>
                                </li>
                                <li className="my-2">
                                    <small>Puedes firmar múltiples documentos a la vez.</small>
                                </li>
                                <li className="my-2">
                                    <small>Podrás añadir marcas de agua de forma personalizada.</small>
                                </li>
                                <li className="my-2">
                                    <small>Agiliza el proceso de firma.</small>
                                </li>
                            </ul>
                            <button className="btn btn-primary mx-auto px-3 py-2" id="btn-install-plugin" onClick={handleClickInstallPlugin}>
                                Descargar e instalar Plugin
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 text-center">
                        <div className="px-3">
                            <strong className="d-block my-3">¿Cómo instalar el plugin?</strong>
                            <p>Si tiene problemas con el complemento, baje la guía de instalación.</p>
                            <a href={guia} className="btn btn-outline-primary mx-auto px-3 py-2" id="btn-guia" download="">
                                <FontAwesomeIcon icon={faFilePdf} /> Guía de instalación
                            </a>

                            <div>
                                <div id="otras-guias" className="d-inline-block mt-4 mb-3 cursor-pointer" onClick={() => setshow(!show)}>Ver otras guías</div>
                                {
                                    show && <div className="d-flex flex-column" id="otras-guias-links">
                                        { !(guiaName === 'guia_chrome') && <a href={guia_chrome} id="guia_chrome" download="">Guía Google Chrome</a> }
                                        { !(guiaName === 'guia_firefox') && <a href={guia_firefox} id="guia_firefox" download="">Guía Mozilla Firefox</a> }
                                        { !(guiaName === 'guia_edge') && <a href={guia_edge} id="guia_edge" download="">Guía Microsoft Edge</a> }
                                        { !(guiaName === 'guia_android') && <a href={guia_android} id="guia_android" download="">Guía móviles Android</a> }
                                        { !(guiaName === 'guia_ios') && <a href={guia_ios} id="guia_ios" download="">Guía móviles IOS</a> }
                                        
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="modal-body p-4 d-none" id="content-descargar-navegador">
                <h4 className="text-center my-4">Signatura de documents</h4>
                <p className="text-center mb-4">El vostre navegador no és compatible amb el Plugin, si us plau utilitzeu un dels següents navegadors per continuar amb el procés de signatura</p>
                <div className="d-flex justify-content-center flex-wrap mb-4">
                    <a href="https://www.google.com/intl/es/chrome/" className="m-3 d-flex flex-column align-items-center">
                        {/* <FontAwesomeIcon icon={faChrome} /> */}
                        <span>Google Chrome</span>
                    </a>
                    <a href="https://www.mozilla.org/es-ES/firefox/new/" className="m-3 d-flex flex-column align-items-center">
                        {/* <FontAwesomeIcon icon={faFirefox} /> */}
                        <span>Mozilla Firefox</span>
                    </a>
                    <a href="https://www.microsoft.com/es-es/edge" className="m-3 d-flex flex-column align-items-center">
                        {/* <FontAwesomeIcon icon={faEdge} /> */}
                        <span>Microsoft Edge</span>
                    </a>
                </div>
            </div>
        </Modal>
    )
}

export default ModalPlugin