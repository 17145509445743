import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext'
import { isModuleAdministrador, isModuleConsultor, isModuleOrganizacion } from '../../helpers/helpers'
import { DashboardAdmin } from './DashboardAdmin'
import { DashboardConsultor } from './DashboardConsultor'
import { DashboardOrganizacion } from './DashboardOrganizacion'

import './ListDashboard.css'

export const Dashboard = () => {

    const history = useHistory()

    const { user: { perfil, modulo_app } } = useContext(AuthContext);


    return (
        <>
            {
                isModuleOrganizacion(modulo_app) && <DashboardOrganizacion /> 
            }
            {
                isModuleConsultor(modulo_app) && <DashboardConsultor />
            }
            {
                isModuleAdministrador(modulo_app) && <DashboardAdmin />
            }
        </>
    )
}
