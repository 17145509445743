import { faCheckCircle, faFile, faPlus, faSave, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, FormControl, InputGroup, Modal, Table } from 'react-bootstrap'
import { Prompt } from 'react-router-dom';
import { useAxios } from '../../../../hooks/useAxios';
import { Table4 } from '../../../../components/DAPconTables/Table4'
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';
import { InputSelect, InputText } from '../../../../components/Inputs';
import { Loader } from '../../../../components/Loader/Loader';
import { InputRadios } from '../../../../components/Inputs/InputRadios';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';
import { ModalPrompt } from '../../../../components/ModalStatusServer/ModalPrompt';
import { TooltipHelp } from '../../../../components/TooltipHelp/TooltipHelp';
import { getModelos } from '../../../../helpers/helpers';
import { ModalStatusError } from '../../../../components/ModalStatusServer/ModalStatusError';
import { MsgInputServer } from '../../../../components/MsgInput/MsgInputServer';
import { TooltipLock } from '../../../../components/TooltipLock/TooltipLock';
import _ from 'lodash';
import { AuthContext } from '../../../../auth/AuthContext';

export const DAPconConsultorSeccion0 = ({ id, setSeries, series, fetchDAPconsDatReqData, idiomaDAPcon,
    fetchDAPconsDatReq, bloqueado, fetchCheckForms }) => {

    const [fetchRCP, fetchRCPData] = useAxios('get', `/rcp`)
    const [fetchVerificadores, fetchVerificadoresData] = useAxios('get', `/verificadores`)

    const { user } = useContext(AuthContext);

    useEffect(() => {
        fetchRCP()
        fetchVerificadores()
    }, [])

    const [listRCP, setListRCP] = useState([])

    useEffect(() => {

        console.log(fetchRCPData)

        if (fetchRCPData.data?.status === 200) {
            const listTemp = fetchRCPData?.data?.data?.data?.map(item => {

                const item2 = item.rcp_idiomas?.find(item2 => item2.idioma_id === idiomaDAPcon) || {}

                return {
                    id: Number(item.rcp_id),
                    nombre: `${item.rcp_equivalencia} ${item2.rcp_nombre || ''}`
                }
            })
            setListRCP(listTemp)
        }

    }, [fetchRCPData])

    // Preparando select de Verificadores

    const [listVerificadores, setListVerificadores] = useState([])

    useEffect(() => {

        if (fetchVerificadoresData.data?.status === 200) {
            const listTemp = fetchVerificadoresData?.data?.data?.data?.map(item => {
                return {
                    id: item.id,
                    nombre: `${item.consultor_nombre} ${item.consultor_apellidos}`,
                    usuario: item.consultor_nif
                }
            })

            console.log(listTemp, user.user?.usuario)
            const listTemp2 = listTemp.filter(item => item.usuario !== user.user?.usuario)

            setListVerificadores(listTemp2)
        }

    }, [fetchVerificadoresData])


    // const [fetchDAPconsDatReq, fetchDAPconsDatReqData] = useAxios('get', `/dapcons/${id}/form/datos-requeridos`)

    const [formDAPcon, handleInputChangeDAPcon, handleFormChangeDAPcon, handleResetDAPcon] = useForm({
        dapcons_confrm_veracidad_datos: '',
        dapcons_consultor_id: '',
        dapcons_estado_param_id: '',
        dapcons_fecha_creacion: '',
        dapcons_fecha_modificacion: '',
        dapcons_incluir_tabla_5: '',
        dapcons_incluir_tabla_6: '',
        dapcons_modelos: [],
        modulos: [],
        dapcons_organizacion_id: '',
        dapcons_producto_id: '',
        dapcons_rcp_id: '',
        dapcons_valido_desde: '',
        dapcons_valido_hasta: '',
        dapcons_valores: '',
        dapcons_verificacion_independiente_param_id: '',
        dapcons_verificador_id: '',
        id: '',
        idiomas: '',
        organizacion: '',
        rcp: '',
        verificador: '',
        contiene_series_modelos: 'false',
        contiene_series_modelos_static: 'false',
        modulos_declarados: {}
    })

    // CRUD modelos / series

    useEffect(() => {

        if (formDAPcon.contiene_series_modelos === 'true' && formDAPcon.modelos_series.length === 0) {
            handleNuevaSerie()
        }
    }, [formDAPcon.contiene_series_modelos])

    const handleChangeSerie = (e, index) => {

        const formDAPconTemp = { ...formDAPcon }
        formDAPconTemp.modelos_series[index]['modelo_nombre'] = e.target.value

        handleFormChangeDAPcon(formDAPconTemp)
    }

    const handleNuevaSerie = () => {

        const formDAPconTemp = { ...formDAPcon }
        formDAPconTemp.modelos_series = [...formDAPconTemp.modelos_series, {
            modelo_id: null,
            modelo_nombre: ''
        }]

        handleFormChangeDAPcon(formDAPconTemp)
    }

    const handleEliminarSerie = (index) => {

        const formDAPconTemp = { ...formDAPcon }

        if (formDAPconTemp.modelos_series.length === 1) {

            formDAPconTemp.modelos_series[0] = {
                modelo_id: null,
                modelo_nombre: ''
            }
        } else {

            formDAPconTemp.modelos_series.splice(index, 1)
        }

        handleFormChangeDAPcon(formDAPconTemp)
        setShowConfirmacion({ value: false, index: -1 })
    }

    const [valuesValidationDAPcon, handleValidationDAPcon, handleResetValidationDAPcon] = useValidator({
        dv_nombre_producto: { isOk: true, msgError: [`El nombre del producto es obligatorio.`] },
        dv_descripcion_producto: { isOk: true, msgError: [`La descripción del producto es obligatoria.`] },
        dapcons_verificador_id: { isOk: true, msgError: [`El verificador es obligatorio.`] },
        dapcons_rcp_id: { isOk: true, msgError: [`El RCP de referencia es obligatorio.`] },
        // dapcons_data_base: { isOk: true, msgError: [`El campo es obligatorio.`] },
    })

    // useEffect(() => {
    //     fetchDAPconsDatReq()
    // }, [])

    useEffect(() => {
        console.log('fetchDAPconsDatReqData', fetchDAPconsDatReqData)

        if (fetchDAPconsDatReqData?.data) {

            const formDAPconTemp = fetchDAPconsDatReqData.data?.data?.data
            handleFormChangeDAPcon({
                ...formDAPconTemp,
                dapcons_rcp_id: formDAPconTemp.rcp?.rcp_id,
                dapcons_verificador_id: formDAPconTemp.verificador?.id,
                dv_nombre_producto: formDAPconTemp.dapcons_valores?.[0]?.dv_nombre_producto,
                dv_descripcion_producto: formDAPconTemp.dapcons_valores?.[0]?.dv_descripcion_producto,
                dv_planta_produccion: formDAPconTemp.dapcons_valores?.[0]?.dv_planta_produccion,
                dv_representatividad_geografica: formDAPconTemp.dapcons_valores?.[0]?.dv_representatividad_geografica,
                dv_varabilidad_productos: formDAPconTemp.dapcons_valores?.[0]?.dv_varabilidad_productos,
                contiene_series_modelos: formDAPconTemp.dapcons_modelos?.length > 0 ? 'true' : 'false',
                contiene_series_modelos_static: formDAPconTemp.dapcons_modelos?.length > 0 ? 'true' : 'false',
                modelos_series: getModelos(formDAPconTemp.dapcons_modelos),
                modulos_declarados: getModulos(formDAPconTemp.modulos),
                dv_idioma_id: idiomaDAPcon,
                // dapcons_incluir_tabla_5: formDAPconTemp.dapcons_incluir_tabla_5,
                // dapcons_incluir_tabla_6: formDAPconTemp.dapcons_incluir_tabla_6,
                dapcons_incluir_tabla_5: true,
                dapcons_incluir_tabla_6: true,
                form_errors: formDAPconTemp.form_completed?.form_errors?.[0] || {}

            })
            setisEditForm(isEditf(formDAPconTemp))

        }
    }, [fetchDAPconsDatReqData])

    const isEditf = (form) => {

        const formTemp = _.cloneDeep({
            dv_descripcion_producto: form.dapcons_valores?.[0]?.dv_descripcion_producto,
            dv_planta_produccion: form.dapcons_valores?.[0]?.dv_planta_produccion,
            dv_representatividad_geografica: form.dapcons_valores?.[0]?.dv_representatividad_geografica,
        })

        for (const key in formTemp) {
            if (formTemp[key]) {
                return true
            }
        }

        return false
    }


    const [isEditForm, setisEditForm] = useState(false)


    const getModulos = (modulos) => {

        console.log(modulos)

        let result = {}

        if (modulos.length > 0) {

            for (let i = 0; i < modulos.length; i++) {
                result[modulos[i]['modulo_param_id']] = modulos[i]['modulo_activado']
            }

        } else {

            result = {
                'a1': false, 'a2': false, 'a3': false, 'a4': false, 'a5': false, 'b1': false,
                'b2': false, 'b3': false, 'b4': false, 'b5': false, 'b6': false, 'b7': false, 'c1': false,
                'c2': false, 'c3': false, 'c4': false, 'd': false
            }
        }

        result = Object.keys(result)
            .sort()
            .reduce(function (acc, key) {
                acc[key] = result[key];
                return acc;
            }, {});

        console.log(result)

        delete result['a1a3']

        return result
    }


    const handleChangeModulos = (e, key) => {

        const formDAPconTemp = { ...formDAPcon }
        formDAPconTemp.modulos_declarados[key] = e.target.checked

        handleFormChangeDAPcon(formDAPconTemp)
    }

    const handleChangeTabla5 = (e) => {

        handleFormChangeDAPcon({
            ...formDAPcon,
            dapcons_incluir_tabla_5: JSON.parse(e.target.checked)
        })
    }

    const handleChangeTabla6 = (e) => {

        handleFormChangeDAPcon({
            ...formDAPcon,
            dapcons_incluir_tabla_6: JSON.parse(e.target.checked)
        })
    }

    useEffect(() => {
        console.log(formDAPcon)
    }, [formDAPcon])

    const [isBlocking, setIsBlocking] = useState(false);

    const [fetchDAPconsDatReqEdit, fetchDAPconsDatReqEditData] = useAxios('patch', `/dapcons/${id}/form/datos-requeridos`)

    const handleSubmitDAPconDatReqEdit = (e) => {

        e.preventDefault()

        if (handleValidationDAPcon(formDAPcon) && handleValidationDAPconModulos(formDAPcon) && handleErrorModelosSeries()) {

            fetchDAPconsDatReqEdit({
                params: {
                    idioma_id: idiomaDAPcon
                },
                body: {
                    ...formDAPcon,
                    modelos_series: formDAPcon.contiene_series_modelos === 'true' ? formDAPcon.modelos_series.filter(item => item !== '') : [],
                    contiene_series_modelos: formDAPcon.contiene_series_modelos === "true" ? true : false
                }
            })
        }
    }

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        console.log(fetchDAPconsDatReqEditData.data)
        if (fetchDAPconsDatReqEditData.data?.status === 201) {
            setShowSuccessEdit(true)
            fetchDAPconsDatReq({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchDAPconsDatReqEditData])

    // Verificador de almenos 1 modulo declarado

    const [showErrorModulos, setShowErrorModulos] = useState(null)

    const handleValidationDAPconModulos = (formDAPcon) => {

        const res = Object.keys(formDAPcon.modulos_declarados).filter(key => formDAPcon.modulos_declarados[key])

        console.log(res)

        if (res.length > 0) {

            setShowErrorModulos(null)
        } else {

            setShowErrorModulos(<Form.Text className='text-danger d-block'>Ha de marcar almenos 1 módulo</Form.Text>)
        }

        return res.length > 0
    }

    // Confirmar eliminar 

    const [showConfirmacion, setShowConfirmacion] = useState({
        value: false,
        index: -1
    })




    const [errorModelosSeries, seterrorModelosSeries] = useState(false)

    const handleErrorModelosSeries = () => {

        if (formDAPcon.modelos_series?.filter(item => item.modelo_nombre === '')?.length > 0 && formDAPcon.contiene_series_modelos === 'true') {

            seterrorModelosSeries(true)
            return false
        } else {
            seterrorModelosSeries(false)
            return true
        }

    }

    // Confirmar cambiar a no

    const [showConfirmacion2, setShowConfirmacion2] = useState({ value: false, index: -1 })

    // Base de datos

    const [fetchParametros, fetchParametrosData] = useAxios('get', '/parametros')

    const [database, setdatabase] = useState([]);

    useEffect(() => {

        fetchParametros({
            id: 'database_dapcons',
            params: {
                arbol: false
            }
        })

    }, []);

    useEffect(() => {

        if (fetchParametrosData.data?.status === 200) {

            const arrayParams = fetchParametrosData.data?.data?.data['database_dapcons'].parametros?.map(item => {
                return {
                    id: item.param_id || '',
                    nombre: item.param_textos?.[0]?.pt_texto || '',
                }
            })

            setdatabase(arrayParams)
        }

    }, [fetchParametrosData]);

    return (
        <>
            <Prompt when={isBlocking} message="Are you sure you want to leave?" />

            <div className='MainInner shadow-sm animate-width-disabled'>

                {(fetchRCPData.loading || fetchVerificadoresData.loading || fetchDAPconsDatReqData?.loading) ?
                    <div className="py-5">
                        <Loader />
                    </div>
                    : <>
                        <div className="d-flex justify-content-between mb-2">
                            <div>
                                <h5>Datos requeridos</h5>
                            </div>
                        </div>

                        <Form className='row'>

                            <Form.Group className="col-12 col-sm-12 mb-4" controlId="exampleForm.ControlInput2">
                                <Form.Label className='fw-500'>Nombre de la empresa:</Form.Label>
                                <p className='mt-1 mb-0'>{formDAPcon.organizacion?.org_nombre}</p>
                            </Form.Group>

                            <Form.Group className="-12 col-lg-4 col-lg-4 col-xl-5 mb-4" controlId="exampleForm.ControlInput2">
                                <Form.Label className='fw-500'>Consultor ACV:</Form.Label>
                                <p className='mt-1 mb-0'>{formDAPcon.consultor?.consultor_nombre} {formDAPcon.consultor?.consultor_apellidos}</p>
                            </Form.Group>

                            <Form.Group className="col-12 col-lg-4 col-lg-4 mb-4">
                                {
                                    !bloqueado && formDAPcon.dapcons_estado_param_id === 'borrador' ?
                                        (
                                            <InputSelect
                                                label='Verificador:'
                                                className=''
                                                name='dapcons_verificador_id'
                                                values={listVerificadores}
                                                value={formDAPcon.dapcons_verificador_id}
                                                onChange={handleInputChangeDAPcon}
                                                fetchData={fetchDAPconsDatReqEditData}
                                                validation={valuesValidationDAPcon}
                                            />
                                        ) : (
                                            <>
                                                <Form.Label className='fw-500'>
                                                    <span className='pe-2'>Verificador: </span>
                                                    <TooltipLock id='dapcons_verificador_id' position='top' />
                                                </Form.Label>
                                                <p className='mt-0 mb-0'>
                                                    {listVerificadores.find(item => item.id === formDAPcon.dapcons_verificador_id)?.nombre}
                                                </p>
                                            </>
                                        )
                                }

                            </Form.Group>

                            <div className="col-12"></div>

                            <Form.Group className="mb-3 col-sm-12 col-lg-12">
                                <Form.Label className='fw-500'>
                                    RCP de referencia:
                                </Form.Label>
                                <p className='mt-0 mb-0'>
                                    {listRCP.find(item => item.id === formDAPcon.dapcons_rcp_id)?.nombre}
                                </p>
                            </Form.Group>

                            <Form.Group className="mb-3 col-sm-12 col-lg-4">
                                <InputSelect
                                    label='Base de datos: *'
                                    className=''
                                    name='dapcons_data_base'
                                    values={database}
                                    value={formDAPcon.dapcons_data_base}
                                    onChange={handleInputChangeDAPcon}
                                    fetchData={fetchDAPconsDatReqEditData}
                                    validation={valuesValidationDAPcon}
                                />
                                {
                                    isEditForm && !formDAPcon.dapcons_data_base && formDAPcon.form_errors?.['dapcons_data_base'] &&
                                    <Form.Text className='text-danger d-block'>{formDAPcon.form_errors?.['dapcons_data_base']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3 col-12">
                                <InputText
                                    label='Nombre del Producto'
                                    className=''
                                    name='dv_nombre_producto'
                                    value={formDAPcon.dv_nombre_producto}
                                    onChange={handleInputChangeDAPcon}
                                    fetchData={fetchDAPconsDatReqEditData}
                                    validation={valuesValidationDAPcon}
                                />
                            </Form.Group>

                            <Form.Group className="col-12 mb-4">
                                <InputText
                                    label='Descripción del Producto'
                                    className=''
                                    as="textarea"
                                    rows={3}
                                    name='dv_descripcion_producto'
                                    value={formDAPcon.dv_descripcion_producto}
                                    onChange={handleInputChangeDAPcon}
                                    fetchData={fetchDAPconsDatReqEditData}
                                    validation={valuesValidationDAPcon}
                                />
                            </Form.Group>
                            {
                                formDAPcon.contiene_series_modelos_static === 'true' &&
                                <>
                                    <Form.Group className="col-12 col-xl-8 mb-0" controlId="exampleForm.ControlInput7">
                                        <Form.Label className='mb-0 d-flex align-items-center mb-2 fw-500'>
                                            <span className='me-3'>¿El producto posee Modelos / Series?</span>
                                            <TooltipHelp
                                                id='tooltip-modelos-series'
                                                position='top'
                                                text='Para cada modelo/serie, será necesario introducir
                                         los datos correspondientes en las tablas 5 y 6'
                                            />
                                            {
                                                bloqueado && <Form.Label className='fw-500 mb-0 ms-3'>
                                                    <TooltipLock id='dapcons_verificador_id' position='top' text="Contenido bloqueado, si posee Modelos/series no podrá eliminar ni agregar" />
                                                </Form.Label>
                                            }
                                        </Form.Label>
                                        <InputRadios
                                            name='contiene_series_modelos'
                                            onChange={handleInputChangeDAPcon}
                                            values={[{ id: 'false', nombre: 'No' }, { id: 'true', nombre: 'Si' }]}
                                            value={formDAPcon.contiene_series_modelos}
                                            disabled={bloqueado}
                                            onClick={formDAPcon.contiene_series_modelos === 'true' ? () => setShowConfirmacion2({ value: true, index: -1 }) : () => null}
                                        />
                                    </Form.Group>

                                    {
                                        formDAPcon.contiene_series_modelos === 'true' &&
                                        <Form.Group className="col-12 col-xl-10 mt-2">
                                            <Form.Label><small className=''>Ejemplo: <i>ARS-62 HO 1,23 x 1,48 </i></small></Form.Label>
                                            {
                                                formDAPcon.modelos_series?.map((item, index) => {

                                                    return (
                                                        <div className='position-relative Series__container' key={index}>
                                                            <Form.Control
                                                                type="text"
                                                                name='nif'
                                                                autoComplete="one-time-code"
                                                                className='mb-3'
                                                                value={item.modelo_nombre}
                                                                onChange={(e) => handleChangeSerie(e, index)}
                                                            />
                                                            {
                                                                !bloqueado &&
                                                                <FontAwesomeIcon onClick={() => setShowConfirmacion({ value: true, index })} icon={faTimes} className='me-2 position-absolute' />
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }

                                            {
                                                errorModelosSeries && <Form.Text className='text-danger d-block'>Los nombres de los Modelos / series son obligatorios.</Form.Text>
                                            }

                                            <MsgInputServer fetchData={fetchDAPconsDatReqEditData} dynamic />
                                            {/* {
                                        !bloqueado && <Button variant="primary float-end" size='sm' onClick={handleNuevaSerie}>
                                            <FontAwesomeIcon icon={faPlus} className='me-2' />
                                            <span>Agregar Modelo / serie</span>
                                        </Button>
                                    } */}

                                        </Form.Group>
                                    }
                                </>
                            }

                            <Form.Group className="col-12 my-4">
                                <InputText
                                    label='Planta producción'
                                    className=''
                                    as="textarea"
                                    rows={3}
                                    name='dv_planta_produccion'
                                    value={formDAPcon.dv_planta_produccion}
                                    onChange={handleInputChangeDAPcon}
                                    fetchData={fetchDAPconsDatReqEditData}
                                    maxLength={225}
                                />
                                <small className='text-end d-block'>
                                    Máximo 225 caracteres.
                                </small>

                                {
                                    isEditForm && !formDAPcon.dv_planta_produccion && formDAPcon.form_errors?.['dv_planta_produccion'] &&
                                    <Form.Text className='text-danger d-block'>{formDAPcon.form_errors?.['dv_planta_produccion']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group className="col-12 mb-4">
                                <InputText
                                    label='Representatividad Geográfica'
                                    className=''
                                    as="textarea"
                                    rows={3}
                                    name='dv_representatividad_geografica'
                                    value={formDAPcon.dv_representatividad_geografica}
                                    onChange={handleInputChangeDAPcon}
                                    fetchData={fetchDAPconsDatReqEditData}
                                    maxLength={225}
                                />
                                <small className='text-end d-block'>
                                    Máximo 225 caracteres.
                                </small>
                                {
                                    isEditForm && !formDAPcon.dv_representatividad_geografica && formDAPcon.form_errors?.['dv_representatividad_geografica'] &&
                                    <Form.Text className='text-danger d-block'>{formDAPcon.form_errors?.['dv_representatividad_geografica']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group className="col-12 mb-4">
                                <InputText
                                    label='Variabilidad entre diferentes productos'
                                    className=''
                                    as="textarea"
                                    rows={3}
                                    name='dv_varabilidad_productos'
                                    value={formDAPcon.dv_varabilidad_productos}
                                    onChange={handleInputChangeDAPcon}
                                    fetchData={fetchDAPconsDatReqEditData}
                                    maxLength={225}
                                />
                                <small className='text-end d-block'>
                                    Máximo 225 caracteres.
                                </small>

                                {
                                    isEditForm && !formDAPcon.dv_varabilidad_productos && formDAPcon.form_errors?.['dv_varabilidad_productos'] &&
                                    <Form.Text className='text-danger d-block'>{formDAPcon.form_errors?.['dv_varabilidad_productos']}</Form.Text>
                                }
                            </Form.Group>
                            {/* <h5 className='col-12 mb-3 h6'>
                                Representatividad Geográfica
                            </h5> */}


                            <h5 className='col-12 mb-0 h6'>
                                Módulos declarados
                                {
                                    bloqueado && <Form.Label className='fw-500 mb-0 ms-3'>
                                        <TooltipLock id='dapcons_verificador_id' position='top' />
                                    </Form.Label>
                                }
                            </h5>

                            <Table4
                                data={formDAPcon.modulos_declarados}
                                handleChangeModulos={handleChangeModulos}
                                bloqueado={bloqueado}
                            />
                            {showErrorModulos}

                            {/* <h5 className='col-12 mb-3 mt-4 h6'>
                                Declaración de parametros
                                {
                                    bloqueado && <Form.Label className='fw-500 mb-0 ms-3'>
                                        <TooltipLock id='dapcons_verificador_id' position='top' />
                                    </Form.Label>
                                }
                            </h5>

                            <div className="col-12">
                                <Form.Check
                                    type='checkbox'
                                    id='Tabla5'
                                    label='Incluir Tabla 5. Parámetros de impacto ambiental'
                                    className='Checkbox__DeclaracionParametros mb-3'
                                    onChange={handleChangeTabla5}
                                    checked={formDAPcon.dapcons_incluir_tabla_5}
                                    disabled={true}
                                />
                            </div>

                            <div className="col-12">
                                <Form.Check
                                    type='checkbox'
                                    id='Tabla6'
                                    label='Incluir Tabla 6. Parámetros de uso de recursos, residuos y flujos materiales de salida'
                                    onChange={handleChangeTabla6}
                                    checked={formDAPcon.dapcons_incluir_tabla_6}
                                    className='Checkbox__DeclaracionParametros'
                                    disabled={true}
                                />
                            </div> */}
                        </Form>

                        <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                            <ButtonSpinner
                                variant="info" type="submit" className="me-2"
                                handleSubmit={handleSubmitDAPconDatReqEdit} fetchDataLoading={[fetchDAPconsDatReqEditData.loading]}
                                value='Guardar' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                            />
                        </div>
                    </>
                }
            </div>

            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalPrompt setShowStatus={setShowConfirmacion} showStatus={showConfirmacion}>
                <Modal.Body>
                    <div className="py-0 text-center">
                        <h5 className='mb-4 fw-normal'>
                            Eliminar Modelo / Serie
                        </h5>
                        <p className='mb-0 px-3'>
                            También se eliminarán sus tablas asociadas
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={() => setShowConfirmacion({ value: false, index: -1 })}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="danger" type="submit" className="me-2"
                        handleSubmit={() => handleEliminarSerie(showConfirmacion.index)}
                        value='Eliminar'
                    />
                </Modal.Footer>
            </ModalPrompt>

            <ModalPrompt setShowStatus={setShowConfirmacion2} showStatus={showConfirmacion2}>
                <Modal.Body>
                    <div className="py-0 text-center">
                        <h5 className='mb-4 fw-normal'>
                            Eliminación de Modelos / Series
                        </h5>
                        <p className='mb-0 px-3'>
                            Si posee Modelos / Series, se eliminarán sus tablas asociadas
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={() => setShowConfirmacion2({ value: false, index: -1 })}>
                        Salir
                    </Button>
                </Modal.Footer>
            </ModalPrompt>

            <ModalStatusError fetchData={fetchDAPconsDatReqEditData} />

        </>
    )
}
