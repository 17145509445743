import React, { useEffect } from 'react'
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { InputSelect, InputText } from '../../../components/Inputs'

export const ModalFilter = ({data, handleCloseModal, handleFormChange, formValuesSearch, handleInputChangeSearch, handleSearch}) => {


    const handleChangePendientePago = (e) => {
        handleFormChange({
            ...formValuesSearch,
            pendiente_pago: e.target.checked ? true : ''
        })
    }

	 return (
        <Modal show={data?.value} onHide={handleCloseModal} size="">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Filtros de busqueda</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                <Form className='row'>
                    <Form.Group className="col-12 col-lg-12 mb-3">
                        <Form.Check
                            type='checkbox'
                            name='pendiente_pago'
                            value={formValuesSearch.pendiente_pago}
                            onChange={handleChangePendientePago}
                            checked={formValuesSearch.pendiente_pago}
                            id={`default-checkbox`}
                            label='Pendiente de pago'
                        />
                   </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary"  onClick={handleSearch}>
                    <FontAwesomeIcon icon={faSearch} /> Filtrar
                </Button>
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
     )   
}