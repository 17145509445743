import React, { useState } from 'react'
import { faExchangeAlt, faInf, faInfoCircle, faInfoCircleoCirclefaInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Overlay, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap'
import './Tablas.css'
import { breakExistA1A3, breakExistRecuperacion } from '../../helpers/helpers'
import AutosizeInput from 'react-input-autosize'
import { Loader } from '../Loader/Loader'
import { InputText } from '../Inputs/InputText'

export const Table17 = ({ data, handleClickMergeA1A3, exista1a3, handleInputChangeTable17, name, datBasic, supuestos,
    handleInputChangeInfoTecnicaAdicional, bloqueado }) => {

    console.log(data)

    // const [temp, settemp] = useState('El transporte de los materiales residuales se realiza con un camión 14t-20t EURO VI y se ha estimado una distancia media desde el punto de demolición hasta el punto de gestión de 100 km.')

    return (
        <div>
            {
                data ? (data?.length === 0 ?
                    <div className="py-5">
                        <Loader />
                    </div> : (
                        <Table responsive id='Table17' className='DAPconTable w-auto mx-auto'>
                            <thead className='text-center'>
                                <tr>
                                    <th rowSpan='3'>{datBasic.modelos_series?.length === 0 ? 'Producto' : 'Series / Modelos'}</th>
                                    <th colSpan={`${exista1a3 ? '5' : '9'}`}>Proceso</th>
                                </tr>
                                <tr>
                                    <th>
                                        <div>Procesos de recogida <br /> (especificados por tipos)</div>
                                    </th>
                                    <th colSpan={`${exista1a3 ? '1' : '3'}`} className='position-relative'>
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{exista1a3 ? 'Expandir' : 'Combinar'}</Tooltip>} placement='bottom'>
                                            <Button variant="light" size='' className={`px-3 ms-3 d-inline-block ${exista1a3 ? 'Table17-btn-resizeActive' : ''}`}
                                                onClick={handleClickMergeA1A3}
                                                id='Table17-btn-resize'
                                                disabled={bloqueado}
                                            >
                                                <FontAwesomeIcon icon={faExchangeAlt} className='' />

                                            </Button>
                                        </OverlayTrigger>
                                        <div>Sistemas de recuperación <br /> (especificado por tipo)</div>

                                    </th>
                                    <th>Eliminación</th>
                                    {/* <th>Supuestos para el desarrollo de escenarios.</th> */}
                                    {/* <th>Supuestos para el desarrollo de escenarios. Por ejemplo, transporte</th> */}
                                </tr>
                                <tr>
                                    <th>kg recogidos con mezcla de residuos construcción</th>
                                    {
                                        exista1a3 ? <>
                                            <th>kg</th>
                                        </> : <>
                                            <th>kg para reutilización</th>
                                            <th>kg para reciclado</th>
                                            <th>kg para valorización energética</th>
                                        </>
                                    }
                                    <th>kg para eliminación final</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((item, index) => {
                                        console.log(item)
                                        return (
                                            <tr style={{ 'height': '60px' }}>
                                                <td valign='middle'>{datBasic.modelos_series?.length === 0 ? datBasic.dv_nombre_producto : item.modelo_nombre}</td>
                                                {
                                                    Object.keys(item).map((key) => {

                                                        if (key === 'modelo_nombre' || key === 'modelo_id') return null

                                                        if (breakExistRecuperacion(exista1a3, key)) return null

                                                        return (
                                                            <td valign='middle' key={'1' + key + 1} className={`${bloqueado ? 'disabledCursorInput' : ''}`}>
                                                                {/* <AutosizeInput
                                                                    name="form-field-name"
                                                                    className='container-form-control'
                                                                    value={item[key]}
                                                                    onChange={
                                                                        (e) => handleInputChangeTable17(e, index, key, name)
                                                                    }
                                                                /> */}
                                                                {
                                                                    bloqueado ? (
                                                                        <span>{item[key]}</span>
                                                                    ) : (
                                                                        <InputText
                                                                            // as="textarea"
                                                                            // rows='4'
                                                                            type='number'
                                                                            value={item[key]}
                                                                            onChange={
                                                                                (e) => handleInputChangeTable17(e, index, key, name)
                                                                            }
                                                                        />
                                                                    )
                                                                }

                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td valign='middle'>
                                        <strong>Supuestos para el desarrollo de escenarios. </strong>
                                    </td>
                                    <td colSpan={`${exista1a3 ? '5' : '9'}`}>
                                        <Form.Control
                                            as="textarea" rows={3}
                                            autoComplete="one-time-code"
                                            value={supuestos}
                                            onChange={handleInputChangeInfoTecnicaAdicional}
                                            name='dv_supuestos_desarrollo_escenarios'
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    )) : null
            }
        </div>
    )
}
