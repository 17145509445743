import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import { ButtonSpinner } from '../ButtonSpinner/ButtonSpinner';

const ModalProteccionDatos = ({ showModalProteccionDatos, setShowModalProteccionDatos, handleCloseModalProteccionDatos,
    handleSubmit, fixed, fetchDataLoading }) => {

    return (
        <Modal show={showModalProteccionDatos} onHide={handleCloseModalProteccionDatos} size='lg' scrollable backdrop={fixed ? "static" : true}>

            <Modal.Header className='border-bottom-0 py-4' closeButton></Modal.Header>

            <Modal.Body className='px-md-5 py-md-4 pt-0'>

                <h5 className='text-center mb-4'>INFORMACIÓN RELATIVA A LA PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</h5>

                <p>
                    El COL·LEGI D´APARELLADORS, ARQUITECTES TÈCNICS I ENGINYERS D´EDIFICACIÓ DE BARCELONA trata los
                    datos personales que ustedes nos facilitan y aquellos que sean obtenidos fruto de la relación que nos
                    ocupa, éstos serán incorporados a un o unos fichero/s, responsabilidad del COL·LEGI D´APARELLADORS,
                    ARQUITECTES TÈCNICS I ENGINYERS D´EDIFICACIÓ DE BARCELONA, y serán dedicados exclusivamente a las
                    finalidades relacionadas con la gestión, el mantenimiento, el cumplimiento, el desarrollo y el control
                    de la solicitud de afiliación y pertenencia al programa DAPconstrucción. En este sentido, se comunica
                    que se realizaran análisis de perfiles y de uso a partir del historial de servicios y actividades del
                    interesado con el objetivo de mejorar y dotar de valor añadido los servicios y actividades a
                    disposición de los usuarios.
                </p>

                <p>
                    Los datos se conservarán durante todo el periodo en que se mantenga su inscripción al programa, así
                    como posteriormente durante el plazo de prescripción de las acciones de responsabilidad para el
                    ejercicio y/o defensa de eventuales reclamaciones.
                </p>
                <p>
                    Los datos tratados están protegidos con las medidas y sistemas técnicos necesarios para preservar su
                    confidencialidad y evitar la pérdida, alteración y acceso no autorizado a las mismas, de acuerdo con
                    los niveles de seguridad legalmente demandados.

                </p>
                <p>
                    Derechos de los afectados: en caso necesario, pueden ejercer los derechos de acceso, rectificación,
                    supresión, limitación, portabilidad y oposición al uso de los datos, dirigiéndose por escrito o por
                    correo electrónico y acompañando una copia del documento oficial que les identifique al COL·LEGI
                    D´APARELLADORS, ARQUITECTES TÈCNICS I ENGINYERS D´EDIFICACIÓ DE BARCELONA, con domicilio en la
                    calle Bon Pastor, nº 5, CP 08021, Barcelona, o enviando un e-mail a informacio@apabcn.cat,
                    indicando en el sobre o en el asunto del mensaje: Aviso legal y de privacidad. También tienen el
                    derecho a formular reclamación delante de la autoridad de control. Y a revocar el consentimiento
                    otorgado.
                </p>
                <p>
                    Otros datos de interés:
                </p>

                <p>
                    <strong>Responsable:</strong> COL·LEGI D´APARELLADORS, ARQUITECTES TÈCNICS I ENGINYERS D´EDIFICACIÓ DE BARCELONA
                </p>
                <p>
                    <strong>CIF</strong> Q0875009C
                </p>
                <p>
                    <strong>Dirección: </strong>  C. Bon Pastor, 5. 08021 Barcelona. España
                </p>
                <p>
                    <strong>Contacto: </strong> informacio@apabcn.cat o tel. 93 240 20 60
                </p>
                <p>
                    <strong>Delegado de Protección de Datos:</strong> Institut Qualitas d’Assessoria BCN, S.L.
                </p>
                <p>
                    <strong>Contacto del Delegado de Protección de Datos: </strong> dpd@apabcn.cat.
                </p>
                <p>
                    <strong>Legitimación: </strong>la base legal es el consentimiento del interesado.
                </p>
                <p>
                    <strong>Destinatarios: </strong> sus datos solamente podrán ser comunicados a terceros en el supuesto que lo
                    autoricen expresamente, así como para el cumplimiento de obligaciones legales y/o contractuales.
                    En este sentido, les informamos que, en el caso de que nos faciliten los datos bancarios para el pago
                    del servicio, se procederá a la comunicación de éstos a nuestra entidad financiera a exclusivos
                    efectos de gestionar el cobro.
                </p>
                <small className='mt-3 pt-2 border-top d-block'>Administrador del Programa: CAATEEB. Bon Pastor 5 08021 Barcelona. www.csostenible.net -932402060- sostenible@apabcn.cat</small>

            </Modal.Body>
            <Modal.Footer className=''>
                <Button variant="default" onClick={handleCloseModalProteccionDatos} className='px-3'>
                    Salir
                </Button>
                <ButtonSpinner
                    variant="primary" type="submit" className="ms-3 px-3"
                    handleSubmit={handleSubmit} fetchDataLoading={[fetchDataLoading ? fetchDataLoading.loading : false]}
                    value='Aceptar'
                />
            </Modal.Footer>
        </Modal>
    )
}

export default ModalProteccionDatos