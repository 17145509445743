const lang = {
    ES: {
        INICIAR_SESION: "Iniciar sesión",
        INSCRIPCION: "Inscripción",
        OLVIDASTE_CONTRASENA: "¿Olvidaste tu contraseña?",
        AVISO_LEGAL: "Aviso Legal",
        POLITICA_PRIVACIDAD: "Política de Privacidad",
        POLITICA_COOKIES: "Política de Cookies",
        ESPANIOL: "Español",
        CATALAN: "Catalán",
        CONTRASENIA: "Contraseña",
        PROCESOS: "Procesos",
        PROCESO: "Proceso",
        CAMBIAR_CONTRASENIA: "Cambiar contraseña",
        SALIR: "Salir",
        NUM: "Núm",
        LISTA_DE: "Lista de",
        FILTRO: "Filtro",
        BUSCAR: "Buscar",
        PLANTILLAS_MENSAJES: "Plantillas Mensajes",
        VER_MAS: "Ver más",
        NOMBRE_DEL_TRAMITADOR: 'Nombre del tramitador',
        DESDE: "Desde",
        HASTA: "Hasta",
        ESTADO: "Estado",
        FECHA_ESTADO: "Fecha estado",
        AVISAR_PENDIENTES_FIRMAR: "Avisar a pendientes de firmar",
        DATOS_GENERALES: "Datos generales",
        HISTORICO: "Histórico",
        PLANTILLAS: "Plantillas",
        DESCRIPCION_PLANTILLA: "Descripción de la plantilla",
        REMITENTE: "Remitente",
        CORREO_REMITENTE: "Correo del remitente",
        ASUNTO: "Asunto",
        MENSAJE: "Mensaje",
        CODIGOS_ADICIONALES: "Códigos adicionales",
        PERTENECE_PROCESO_DE: "Pertenece al proceso de",
        TRAMITADOR: "Tramitador",
        TAMANIO: "Tamaño",
        FORMATO: "Formato",
        DESCARGAR: "Descargar",
        DOCUMENTO: "Documento",
        USUARIOS: "Usuarios",
        LOGS: "Logs",
        MANTENIMIENTO: "Mantenimiento",
        ACTUALIZAR: "Actualizar",
        COPYRIGHT: "© Col·legi d'Aparelladors, Arquitectes Tècnics i Enginyers d'Edificació de Barcelona",

    }
}

export default lang;