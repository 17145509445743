
// ----------------------- Mis Datos ------------------------

export const getAdminAdapterSchemaMisDatos = (item = {}) => {

    const data = {
        id: item.id || '',
        nombre: item.nombre || '',
        apellidos: item.apellidos || '',
        telefono: item.telefono || '',
        usuario: item.usuario?.usuario || '',
        email: item.usuario?.email || '',
    }

    return {
        ...data
    }
}

export const getAdminAdapterMisDatos = (data) => {

    const dataTemp = data?.data?.data
    
    const item = getAdminAdapterSchemaMisDatos(dataTemp)

    return {
        data: item,
        status: data?.status
    }
}


export const postAdminAdapterSchemaMisDatos = (item = {}) => {

    const data = {
        id: item.id || '',
        nombre: item.nombre || '',
        apellidos: item.apellidos || '',
        telefono: item.telefono || '',
        usuario: item.usuario || '',
        email: item.email || '',
    }

    return data
}


export const postAdminAdapterMisDatos = (form) => {

    const formAdapter = postAdminAdapterSchemaMisDatos(form)

    console.log(form, formAdapter)

    return formAdapter
}
