import React, { useEffect } from 'react'
import { Button, Modal, Badge  } from 'react-bootstrap';

export const ModalResumen = ({data, handleCloseModal, dataChart, totalChart, totalRenovado, totalPublicado}) => {
     const total = dataChart.reduce((acc, item) => acc + (item.value || 0), 0);
     let start = 0;
	 return (
        <Modal show={data?.value} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Total Dapcons: {totalChart} ( {totalChart - totalRenovado} altas y {totalRenovado} renovaciones )</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                <div className="row d-flex justify-content-center">
                  <div className="col-12 col-lg-12">
                       <table className="charts-css pie hide-data mb-3" style={{ width: '300px' }}>
                          <tbody>
                            {dataChart.map((item, index) => {
                            const end = start + (item.value / total);
                            const style = {
                              '--start': start,
                              '--end': end,
                            };
                            start = end; 
                            return (
                              <tr key={index}>
                                <td style={style}>
                                  <span className="data">{item.value}</span>
                                </td>
                              </tr>
                            );
                          })}
                          </tbody>
                        </table>
                    </div>
                    <div className="col-12 col-lg-12 mb-3">
                     <b className="mb-3 text-muted">Dapcons publicados: {totalPublicado}</b>
                     <ul  className="charts-css legend legend-square mt-2">
                        {dataChart.map((item, index) => (
                          <li key={index}>
                            {item.label} {item.value}
                          </li>
                        ))}
                      </ul>
                      </div>
                  </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
     )   
}