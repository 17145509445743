import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../hooks/useAxios';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';

export const ModalBajaAlta = ({data, handleCloseModal, nif, history}) => {
     const [fetchStatus, fetchStatusData] = useAxios('GET', `/usuarios`); 
     
     const handleSubmitStatus = (e) => {
        fetchStatus({
            id: data?.id+'/update-status'
        })
    }
    
    useEffect(() => {
        if (fetchStatusData?.data?.status === 200) {
            history.push(`/consultores/${nif}`)
        }
    }, [fetchStatusData.data])

	 return (
        <Modal show={data?.value} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Confirmar Acción</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                {data?.action === 'BAJA_USUARIO'
                 ? <p>¿Estás seguro de que deseas dar de baja a este usuario?</p>
                 : <p>¿Estás seguro de que deseas dar de alta a este usuario?</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <ButtonSpinner
                    variant="primary" type="submit" className="ms-2"
                    handleSubmit={handleSubmitStatus} fetchDataLoading={[fetchStatusData.loading]}
                    value='Confirmar' icon={<FontAwesomeIcon icon={faUser} className='me-1' />}
                />   
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
     )   
}