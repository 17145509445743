import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useAxios } from '../../../hooks/useAxios'
import { useForm } from '../../../hooks/useForm'
import { UsuariosFilter } from '../UsuariosFilter/UsuariosFilter'
import { UsuariosMain } from '../UsuariosMain/UsuariosMain'
import queryString from 'query-string';
import { AuthContext } from '../../../auth/AuthContext'
import { getURLQuery, getValuesParams, isModuleOrganizacion } from '../../../helpers/helpers'
import { GetUsuariosJSON } from '../../../services/usuario.service'

export const UsuariosContent = ({ namePage, estaVerificando }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const location = useLocation()

    const [fetchParameters, fetchParametersData] = useAxios('GET', `/parametros`)

    useEffect(() => {

        fetchParameters({
            params: {
                'colegios': 1,
                'perfiles_usuario': 1,
                'estados_colegiacion': 1,
            }
        });

    }, [])
    
    const [fetchUsuarios, fetchUsuariosData] = GetUsuariosJSON()

    const {
        estado_id: estado_idParam = '',
        nif: nifParam = '',
        num_colegiado: num_colegiadoParam = '',
        search_text: search_textParam = '',
        colegio_id: colegio_idParam = '',
        page: pageParam = 1,
        orderby: orderbyParam = ''
    } = queryString.parse(location.search);

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        nif: nifParam,
        num_colegiado: num_colegiadoParam,
        search_text: search_textParam,
        colegio_id: colegio_idParam,
        page: pageParam,
        orderby: orderbyParam,
        estado_id: estado_idParam
    });

    const handleSearch = (e) => {

        if (e) {
            e.preventDefault();
        }

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        // history.push(`?${query}`);
        window.history.replaceState(null, null, `?${query}`);

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchUsuarios({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    }

    useEffect(() => {

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        window.history.replaceState(null, null, `?${query}`);

        fetchUsuarios({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.orderby])

    return (
        <>
        
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {namePage}
                </Breadcrumb.Item>
            </Breadcrumb>

            <UsuariosFilter
                handleSearch={handleSearch}
                handleInputChangeSearch={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
                fetchParametersData={fetchParametersData}
            />

            <UsuariosMain
                fetchUsuariosData={fetchUsuariosData}
                fetchUsuarios={fetchUsuarios}
                formValuesSearch={formValuesSearch}
                namePage={namePage}
                handleFormChange={handleFormChange}
                fetchParametersData={fetchParametersData}
                modulo_app={modulo_app}
                estaVerificando={estaVerificando}
            />
        </>
    )
}
