import { faCheckCircle, faExclamationCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Tab, Tabs } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { Table3 } from '../../../../components/DAPconTables/Table3';
import { InputSelect, InputText } from '../../../../components/Inputs';
import { Loader } from '../../../../components/Loader/Loader';
import { getLabelByModules } from '../../../../helpers/helpers';
import { useAxios } from '../../../../hooks/useAxios';
import { useForm } from '../../../../hooks/useForm';
import _, { cloneDeep, isEmpty } from 'lodash'
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';
import { ModalStatusError } from '../../../../components/ModalStatusServer/ModalStatusError';
import { TooltipWarning } from '../../../../components/TooltipWarning/TooltipWarning';
import { useValidator } from '../../../../hooks/useValidator';
import { TextInformationSaltoPagina } from '../../../../components/TextInformation/TextInformationSaltoPagina';

export const DAPconConsultorSeccion4 = ({ datBasic, idiomaDAPcon, id, fetchCheckForms, bloqueado }) => {

    const [fetchEtapasCicloV, fetchEtapasCicloVData] = useAxios('get', `/dapcons/${id}/form/etapas-ciclo-vida`)

    const [tablaEscenariosTransporte, setTablaEscenariosTransporte] = useState({})

    const [destinos, setDestinos] = useState({})

    useEffect(() => {

        fetchEtapasCicloV({
            params: {
                dapcons_id: id,
                idioma_id: idiomaDAPcon
            }
        })

    }, [])

    useEffect(() => {

        if (fetchEtapasCicloVData.data && !isEmpty(tablaEscenariosTransporte) && !isEmpty(destinos)) {

            const formEtapasTemp = _.cloneDeep(fetchEtapasCicloVData.data?.data?.data)

            let table3Temp = {}

            if (isEmpty(formEtapasTemp.table3)) {

                // Crear index 0 para todos los destinos

                for (const key in destinos) {

                    table3Temp[key] = []

                    let objTemp = {}

                    for (const key2 in tablaEscenariosTransporte) {

                        objTemp[key2] = ''

                    }

                    table3Temp[key].push(objTemp)
                }

            } else {

                for (let i = 0; i < formEtapasTemp.table3.length; i++) {
                    for (const key in formEtapasTemp.table3[i]) {
                        table3Temp[key] = formEtapasTemp.table3[i][key]
                    }
                }

                // Agregar keys destinos y transporte faltantes unicas

                for (const key in destinos) {

                    if (!table3Temp[key]) {

                        table3Temp[key] = []

                        let objTemp = {}

                        for (const key2 in tablaEscenariosTransporte) {

                            objTemp[key2] = ''
                        }

                        table3Temp[key].push(objTemp)

                    }
                }
            }

            // Cambiando a Arrays

            console.log('change table3', formEtapasTemp.table3, table3Temp)

            handleFormChangeEtapasCiclo({
                ...formEtapasTemp,
                form_errors: formEtapasTemp.form_completed?.form_errors?.[0] || {},
                table3: table3Temp
            })
            setisEditForm(isEditf(formEtapasTemp))
        }

    }, [fetchEtapasCicloVData, tablaEscenariosTransporte, destinos])


    const modulosActivos = datBasic?.modulos?.filter(item => item.modulo_activado) || []

    const modulosFabricacion = {
        a1: {
            name: 'dv_a1_materias_primas',
            label: 'Extracción y procesado de materias primas'
        },
        a2: {
            name: 'dv_a2_transporte',
            label: 'Transporte al fabricante'
        },
        a3: {
            name: 'dv_a3_fabricacion',
            label: 'Fabricación'
        }
    }

    const modulosActivosFabA = modulosActivos.map(item => {
        if (modulosFabricacion[item.modulo_param_id]) {
            return {
                ...item,
                ...modulosFabricacion[item.modulo_param_id],
                idUpper: String(item.modulo_param_id).toUpperCase()
            }
        }
        return null
    }).filter(item => item)

    const modulosConstruccion = {
        a4: {
            name: 'dv_a4_transporte',
            label: 'Transporte del producto a la obra'
        },
        a5: {
            name: 'dv_a5_proceso_instalacion',
            label: 'Instalación del producto y construcción'
        }
    }

    const modulosActivosConsA = modulosActivos.map(item => {
        if (modulosConstruccion[item.modulo_param_id]) {
            return {
                ...item,
                ...modulosConstruccion[item.modulo_param_id],
                idUpper: String(item.modulo_param_id).toUpperCase()
            }
        }
        return null
    }).filter(item => item)

    const modulosUsoProducto = {
        b1: {
            name: 'dv_b1_uso',
            label: 'Uso'
        },
        b2: {
            name: 'dv_b2_uso',
            label: 'Mantenimiento'
        },
        b3: {
            name: 'dv_b3_uso',
            label: 'Reparación'
        },
        b4: {
            name: 'dv_b4_uso',
            label: 'Substitución'
        },
        b5: {
            name: 'dv_b5_uso',
            label: 'Rehabilitación'
        },
        b6: {
            name: 'dv_b6_uso',
            label: 'Uso de la energía operacional'
        },
        b7: {
            name: 'dv_b7_uso',
            label: 'Uso del agua operacional'
        }
    }

    const modulosActivosUsoProdB = modulosActivos.map(item => {
        if (modulosUsoProducto[item.modulo_param_id]) {
            return {
                ...item,
                ...modulosUsoProducto[item.modulo_param_id],
                idUpper: String(item.modulo_param_id).toUpperCase()
            }
        }
        return null
    }).filter(item => item)

    const modulosFinVida = {
        c1: {
            name: 'dv_c1_fin_de_vida',
            label: 'Decosntrucción y derribo'
        },
        c2: {
            name: 'dv_c2_fin_de_vida',
            label: 'Transporte'
        },
        c3: {
            name: 'dv_c3_fin_de_vida',
            label: 'Gestión de los residuos para reutilización, recuperación y reciclaje'
        },
        c4: {
            name: 'dv_c4_fin_de_vida',
            label: 'Eliminación final'
        }
    }

    const modulosActivosFinVidaC = modulosActivos.map(item => {
        if (modulosFinVida[item.modulo_param_id]) {
            return {
                ...item,
                ...modulosFinVida[item.modulo_param_id],
                idUpper: String(item.modulo_param_id).toUpperCase()
            }
        }
        return null
    }).filter(item => item)

    const modulosBeneCargas = {
        d: {
            name: 'dv_d_beneficios_cargas_ambientales',
            label: 'Decosntrucción y derribo'
        }
    }

    const modulosActivosBeneCargasD = modulosActivos.map(item => {
        if (modulosBeneCargas[item.modulo_param_id]) {
            return {
                ...item,
                ...modulosBeneCargas[item.modulo_param_id],
                idUpper: String(item.modulo_param_id).toUpperCase()
            }
        }
        return null
    }).filter(item => item)

    const [formEtapasCiclo, handleInputChangeEtapasCiclo, handleFormChangeEtapasCiclo, handleResetEtapasCiclo] = useForm({
        dv_idioma_id: idiomaDAPcon,
        dv_a1_materias_primas: '',
        dv_a2_transporte: '',
        dv_a3_fabricacion: '',
        dv_a4_transporte: '',
        dv_a5_proceso_instalacion: '',
        dv_b1_uso: '',
        dv_b2_uso: '',
        dv_b3_uso: '',
        dv_b4_uso: '',
        dv_b5_uso: '',
        dv_b6_uso: '',
        dv_b7_uso: '',
        dv_c1_fin_de_vida: '',
        dv_c2_fin_de_vida: '',
        dv_c3_fin_de_vida: '',
        dv_c4_fin_de_vida: '',
        dv_d_beneficios_cargas_ambientales: '',
        form_completed: {},
        form_errors: {},
        table3: {}
    })


    useEffect(() => {
        console.log(formEtapasCiclo)
    }, [formEtapasCiclo])

    // Select destinos

    const [fetchDestinos, fetchDestinosData] = useAxios('get', `/parametros-select?&idioma_id=1`)

    useEffect(() => {

        fetchDestinos({
            params: {
                idioma_id: 1,
                param_padre_id: 'destinos',
            }
        })
    }, [])


    useEffect(() => {
        if (fetchDestinosData.data) {

            const destinosTemp = [...fetchDestinosData.data?.data?.data]

            let destinosObj = {}

            for (let i = 0; i < destinosTemp.length; i++) {
                destinosObj[destinosTemp[i].pt_param_id] = destinosTemp[i]
            }

            setDestinos({
                ...destinosObj
            })
        }

    }, [fetchDestinosData])

    // Select destinos

    const [fetchTablaEscenariosTransporte, fetchTablaEscenariosTransporteData] = useAxios('get', `/parametros-select?&idioma_id=1`)

    useEffect(() => {

        fetchTablaEscenariosTransporte({
            params: {
                idioma_id: 1,
                param_padre_id: 'tabla_escenarios_transporte',
            }
        })
    }, [])


    useEffect(() => {
        if (fetchTablaEscenariosTransporteData.data) {

            const tablaEscenariosTransporteTemp = [...fetchTablaEscenariosTransporteData.data?.data?.data]

            let tablaEscenariosTransporteObj = {}

            for (let i = 0; i < tablaEscenariosTransporteTemp.length; i++) {
                tablaEscenariosTransporteObj[tablaEscenariosTransporteTemp[i].pt_param_id] = tablaEscenariosTransporteTemp[i]
            }

            setTablaEscenariosTransporte({
                ...tablaEscenariosTransporteObj
            })
        }

    }, [fetchTablaEscenariosTransporteData])


    const [fetchEtapasCicloVida, fetchEtapasCicloVidaData] = useAxios('patch', `/dapcons/${id}/form/etapas-ciclo-vida`)

    const handleSubmitEtapaCicloVida = (e) => {

        e.preventDefault()

        const table3Temp = cloneDeep(formEtapasCiclo.table3)

        for (const key in table3Temp) {

            table3Temp[key] = table3Temp[key].filter(item => item.km_medios || item.porcentaje || item.tipo_transporte)

        }

        console.log('table3Temp', table3Temp);

        for (const key in table3Temp) {

            if (table3Temp[key]?.length === 0) {

                let objTemp = {}

                for (const key2 in tablaEscenariosTransporte) objTemp[key2] = ''

                table3Temp[key].push(objTemp)
            }

        }

        const table3TempAdapted = Object.keys(table3Temp).map(item => {
            return {
                [item]: [...table3Temp[item]]
            }
        })

        fetchEtapasCicloVida({
            params: {
                idioma_id: idiomaDAPcon
            },
            body: {
                ...formEtapasCiclo,
                table3: table3TempAdapted
            }
        })

        handleFormChangeEtapasCiclo({
            ...formEtapasCiclo,
            table3: { ...table3Temp },
        })
    }

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        // console.log(fetchEtapasCicloVidaData.data)
        if (fetchEtapasCicloVidaData.data?.status === 201) {
            setShowSuccessEdit(true)
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
            // fetchEtapasCicloV({
            //     params: {
            //         dapcons_id: id,
            //         idioma_id: idiomaDAPcon
            //     }
            // })
        }

    }, [fetchEtapasCicloVidaData])

    const handleInputChangeTable3 = (e, key, name, index) => {

        const table3Temp = { ...formEtapasCiclo.table3 }

        // console.log(table3Temp)

        table3Temp[key] = table3Temp[key] || {}

        table3Temp[key][index][name] = e.target.value

        handleFormChangeEtapasCiclo({
            ...formEtapasCiclo,
            table3: { ...table3Temp },
        })
    }

    const handleAgregarTipoTransporte = (e, key, name, index) => {

        if (handleVerifiedValuesTransporte(formTransporte)) {

            const table3Temp = _.cloneDeep(formEtapasCiclo.table3)

            let objEmpty = {}

            for (const key in tablaEscenariosTransporte) {
                objEmpty[key] = ''
            }

            table3Temp[formTransporte.id_param_tipotransporte] = [...table3Temp[formTransporte.id_param_tipotransporte], objEmpty]

            handleFormChangeEtapasCiclo({
                ...formEtapasCiclo,
                table3: table3Temp,
            })

            handleCloseModalCrearTransporte()
            handleResetValuesTransporte()
            handleResetTransporte()
        }
    }

    function handleDeleteTransporte(key, index) {

        const table3Temp = _.cloneDeep(formEtapasCiclo.table3)

        console.log('table3Temp[key]', table3Temp[key]);
        table3Temp[key].splice(index, 1)

        handleFormChangeEtapasCiclo({
            ...formEtapasCiclo,
            table3: { ...table3Temp },
        })

    }

    const isEditf = (form) => {

        const formTemp = _.cloneDeep(form)

        delete formTemp['dv_idioma_id']
        delete formTemp['form_completed']
        delete formTemp['form_errors']
        delete formTemp['table3']

        for (const key in formTemp) {
            if (formTemp[key]) {
                return true
            }
        }

        return false
    }

    const [isEditForm, setisEditForm] = useState(false)


    // Modal Nuevo destino

    const [showModalCrearTransporte, setShowModalCrearTransporte] = useState(false);

    const handleCloseModalCrearTransporte = () => setShowModalCrearTransporte(false)
    const handleShowModalCrearTransporte = () => setShowModalCrearTransporte(true);

    const [formTransporte, handleInputChangeTransporte, handleFormChangeTransporte, handleResetTransporte] = useForm({
        id_param_tipotransporte: '',
    })

    const [valuesValidTransporte, handleVerifiedValuesTransporte, handleResetValuesTransporte] = useValidator({
        id_param_tipotransporte: { isOk: true, msgError: [`El Destino es obligatorio.`] },
    })

    return (
        <>
            <div className='MainInner shadow-sm animate-width-disabled'>
                {
                    fetchEtapasCicloVData.loading ?
                        <div className="py-5">
                            <Loader />
                        </div>
                        : <>
                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <h5> 2. Descripción etapas ciclo de vida</h5>
                                </div>
                            </div>

                            {
                                modulosActivosFabA.length !== 0 && (
                                    <>

                                        <h5 className='mb-3'>
                                            <small>2.1 Fabricación {getLabelByModules(modulosActivosFabA)}</small>
                                        </h5>
                                        <div className='input-multi-tabs mb-3 mt-3'>
                                            <Tabs defaultActiveKey={modulosActivosFabA[0]?.modulo_param_id} id="tab-findevida" className="mb-3">
                                                {
                                                    modulosActivosFabA.map((item, index) => {

                                                        return (
                                                            <Tab
                                                                eventKey={item.modulo_param_id}
                                                                title={<span>{isEditForm && !formEtapasCiclo[item.name] && <TooltipWarning position={'top'} text='Todos los campos son requeridos' />} {String(item.modulo_param_id).toUpperCase()}</span>}
                                                            >
                                                                <Form className='row'>

                                                                    <Form.Group className="col-12 mb-0" controlId="exampleForm.ControlInput7">
                                                                        <Form.Label className='pb-1'>{item.label}</Form.Label>
                                                                        <InputText
                                                                            className=''
                                                                            as="textarea"
                                                                            rows={4}
                                                                            name={item.name}
                                                                            onChange={handleInputChangeEtapasCiclo}
                                                                            value={formEtapasCiclo[item.name]}
                                                                        />
                                                                        {
                                                                            isEditForm && !formEtapasCiclo[item.name] && formEtapasCiclo.form_errors?.[item.name] && <Form.Text key={index} className='text-danger d-block'>{formEtapasCiclo.form_errors[item.name]}</Form.Text>
                                                                        }

                                                                    </Form.Group>

                                                                </Form>
                                                            </Tab>
                                                        )
                                                    })
                                                }
                                            </Tabs>
                                            <TextInformationSaltoPagina />

                                        </div>
                                    </>
                                )
                            }

                            {
                                modulosActivosConsA.length !== 0 &&
                                <>
                                    <h5 className='mb-3'>
                                        <small>2.2 Construcción {getLabelByModules(modulosActivosConsA)}</small>
                                    </h5>
                                    {
                                        modulosActivosConsA.find(item => item.modulo_param_id === 'a4') && (
                                            <>
                                                <Form className='row'>

                                                    <Form.Group className="col-12 mb-4">
                                                        <Form.Label>Transporte del producto a la obra (A4)</Form.Label>
                                                        <InputText
                                                            className=''
                                                            as="textarea"
                                                            rows={4}
                                                            name='dv_a4_transporte'
                                                            onChange={handleInputChangeEtapasCiclo}
                                                            value={formEtapasCiclo['dv_a4_transporte']}

                                                        />
                                                        <TextInformationSaltoPagina />

                                                        {
                                                            isEditForm && !formEtapasCiclo['dv_a4_transporte'] && formEtapasCiclo.form_errors?.['dv_a4_transporte'] &&
                                                            <Form.Text className='text-danger d-block'>{formEtapasCiclo.form_errors?.['dv_a4_transporte']}</Form.Text>
                                                        }
                                                    </Form.Group>

                                                </Form>

                                                <Table3
                                                    destinos={destinos}
                                                    tablaEscenariosTransporte={tablaEscenariosTransporte}
                                                    formEtapasCiclo={formEtapasCiclo}
                                                    handleInputChangeTable3={handleInputChangeTable3}
                                                    handleAgregarTipoTransporte={handleAgregarTipoTransporte}
                                                    handleShowModalCrearTransporte={handleShowModalCrearTransporte}
                                                    handleDeleteTransporte={handleDeleteTransporte}
                                                    bloqueado={bloqueado}
                                                />
                                                {
                                                    isEditForm && !formEtapasCiclo.form_completed?.['table3']?.isCompleted &&
                                                    <Form.Text className='text-danger d-block mt-0 mb-4 text-center'>Los campos de la tabla son obligatorios</Form.Text>
                                                }
                                            </>
                                        )
                                    }

                                    {
                                        modulosActivosConsA.find(item => item.modulo_param_id === 'a5') && (
                                            <div className='row'>

                                                <Form.Group className="col-12 mb-4">
                                                    <Form.Label>Proceso de instalación del producto y construcción (A5)</Form.Label>
                                                    <InputText
                                                        className=''
                                                        as="textarea"
                                                        rows={4}
                                                        name='dv_a5_proceso_instalacion'
                                                        onChange={handleInputChangeEtapasCiclo}
                                                        value={formEtapasCiclo['dv_a5_proceso_instalacion']}
                                                    />
                                                    <TextInformationSaltoPagina />

                                                    {
                                                        isEditForm && !formEtapasCiclo['dv_a5_proceso_instalacion'] && formEtapasCiclo.form_errors?.['dv_a5_proceso_instalacion'] && <Form.Text className='text-danger d-block'>{formEtapasCiclo.form_errors['dv_a5_proceso_instalacion']}</Form.Text>
                                                    }
                                                </Form.Group>

                                            </div>
                                        )
                                    }
                                </>
                            }

                            {
                                modulosActivosUsoProdB.length !== 0 && (
                                    <>
                                        <h5 className='mb-3'>
                                            <small>2.3 Uso del producto {modulosActivosUsoProdB.length > 0 ? '(B1-B7)' : ''}</small>
                                        </h5>

                                        <div className='input-multi-tabs mb-3 mt-3'>
                                            <Tabs defaultActiveKey={modulosActivosUsoProdB[0]?.modulo_param_id} id="tab-findevida" className="mb-3">
                                                {
                                                    modulosActivosUsoProdB.map((item, index) => {

                                                        return (
                                                            <Tab
                                                                eventKey={item.modulo_param_id}
                                                                title={<span>{isEditForm && !formEtapasCiclo[item.name] && <TooltipWarning position={'top'} text='Todos los campos son requeridos' />} {String(item.modulo_param_id).toUpperCase()}</span>}

                                                            >
                                                                <Form className='row'>

                                                                    <Form.Group className="col-12 mb-0" controlId="exampleForm.ControlInput7">
                                                                        <Form.Label className='pb-1'>{item.label}</Form.Label>
                                                                        <InputText
                                                                            className=''
                                                                            as="textarea"
                                                                            rows={4}
                                                                            name={item.name}
                                                                            onChange={handleInputChangeEtapasCiclo}
                                                                            value={formEtapasCiclo[item.name]}
                                                                        />
                                                                        {
                                                                            isEditForm && !formEtapasCiclo[item.name] && formEtapasCiclo.form_errors?.[item.name] && <Form.Text key={index} className='text-danger d-block'>{formEtapasCiclo.form_errors[item.name]}</Form.Text>
                                                                        }
                                                                    </Form.Group>

                                                                </Form>
                                                            </Tab>
                                                        )
                                                    })
                                                }
                                            </Tabs>
                                            <TextInformationSaltoPagina />

                                        </div>
                                    </>
                                )
                            }

                            {
                                modulosActivosFinVidaC.length !== 0 && (
                                    <>
                                        <h5 className='mb-3'>
                                            <small>2.4. Fin de vida {modulosActivosFinVidaC.length > 0 ? '(C1-C4)' : ''}</small>
                                        </h5>

                                        <div className='input-multi-tabs mb-3 mt-3'>
                                            <Tabs defaultActiveKey={modulosActivosFinVidaC[0]?.modulo_param_id} id="tab-findevida" className="mb-3">
                                                {
                                                    modulosActivosFinVidaC.map((item, index) => {

                                                        return (
                                                            <Tab
                                                                eventKey={item.modulo_param_id}
                                                                title={<span>{isEditForm && !formEtapasCiclo[item.name] && <TooltipWarning position={'top'} text='Todos los campos son requeridos' />} {String(item.modulo_param_id).toUpperCase()}</span>}

                                                            >
                                                                <Form className='row'>

                                                                    <Form.Group className="col-12 mb-0" controlId="exampleForm.ControlInput7">
                                                                        <Form.Label className='pb-1'>{item.label}</Form.Label>
                                                                        <InputText
                                                                            className=''
                                                                            as="textarea"
                                                                            rows={4}
                                                                            name={item.name}
                                                                            onChange={handleInputChangeEtapasCiclo}
                                                                            value={formEtapasCiclo[item.name]}
                                                                        />
                                                                        {
                                                                            isEditForm && !formEtapasCiclo[item.name] && formEtapasCiclo.form_errors?.[item.name] && <Form.Text key={index} className='text-danger d-block'>{formEtapasCiclo.form_errors[item.name]}</Form.Text>
                                                                        }
                                                                    </Form.Group>

                                                                </Form>
                                                            </Tab>
                                                        )
                                                    })
                                                }
                                            </Tabs>
                                            <TextInformationSaltoPagina />

                                        </div>
                                    </>
                                )
                            }

                            {modulosActivosBeneCargasD.find(item => item.modulo_param_id === 'd') &&
                                <>
                                    <h5 className='mb-3'>
                                        <small>2.5. Beneficios y cargas ambientales potenciales más allá del límite del sistema (D)</small>
                                    </h5>
                                    <div className='row'>

                                        <Form.Group className="col-12 mb-4">
                                            <InputText
                                                className=''
                                                as="textarea"
                                                rows={4}
                                                name='dv_d_beneficios_cargas_ambientales'
                                                onChange={handleInputChangeEtapasCiclo}
                                                value={formEtapasCiclo.dv_d_beneficios_cargas_ambientales}
                                            />
                                            <TextInformationSaltoPagina />

                                            {
                                                isEditForm && !formEtapasCiclo['dv_d_beneficios_cargas_ambientales'] && formEtapasCiclo.form_errors?.['dv_d_beneficios_cargas_ambientales'] && <Form.Text className='text-danger d-block'>{formEtapasCiclo.form_errors['dv_d_beneficios_cargas_ambientales']}</Form.Text>
                                            }
                                        </Form.Group>

                                    </div>
                                </>
                            }

                            <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                                <ButtonSpinner
                                    variant="info" type="submit" className="me-2"
                                    handleSubmit={handleSubmitEtapaCicloVida} fetchDataLoading={[fetchEtapasCicloVidaData.loading]}
                                    value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                />

                            </div>
                        </>
                }
            </div>
            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatusError fetchData={fetchEtapasCicloVidaData} />

            <Modal show={showModalCrearTransporte} onHide={handleCloseModalCrearTransporte} >
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Agregar Transporte</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-4'>

                    <Form className='row justify-content-center'>

                        <Form.Group className="col-12 col-lg-8">
                            <InputSelect
                                label='Destino:'
                                className=''
                                name='id_param_tipotransporte'
                                values={fetchDestinosData.data?.data?.data?.map(item => {
                                    return {
                                        id: item.pt_param_id,
                                        nombre: item.pt_texto,
                                    }
                                }) || []}
                                // values={poblaciones}
                                value={formTransporte.id_param_tipotransporte}
                                onChange={handleInputChangeTransporte}
                                validation={valuesValidTransporte}
                            // fetchData={fetchCrearOrganizacionesData}
                            // disabled={!fetchPoblacionesData.data || formCrearOrganizaciones.codigo_postal?.length !== 5}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonSpinner
                        variant="info" type="submit" className="ms-2"
                        handleSubmit={handleAgregarTipoTransporte} fetchDataLoading={[false]}
                        value='Agregar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                    />
                </Modal.Footer>
            </Modal>

        </>
    )
}
