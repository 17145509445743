import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'

import './TooptipHelp.css'

export const TooltipHelp = ({ id, text, position }) => {
    return (
        <div className="position-relative d-inline-flex TooltipHelp">
            <OverlayTrigger
                key={id}
                placement={position}
                classname=''
                overlay={
                    <Popover id={id}>
                        <Popover.Body>
                            <span>{text}</span>
                        </Popover.Body>
                    </Popover>
                }
            >
                <div className='shadow-sm TooltipHelp__Icon'>
                    <FontAwesomeIcon icon={faInfoCircle} className='text-info cursor-pointer' />
                </div>

            </OverlayTrigger>
        </div>
    )
}
