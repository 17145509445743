export const archivosAdapterSchema = (item = {}) => {
    const data = {
        id: item.id || '',
        titulo: item.titulo || '',
        archivo: item.archivo || '',
        ruta: item.ruta || '',
        contenido_web_id: item.contenido_web_id || '',
    }  

    return {
        ...data,
    }
}

export const archivosAdapter = (data) => {
    const dataTemp = data?.data.data
    const items = dataTemp?.data?.map(item => (archivosAdapterSchema(item)))
    return {
        data: {
            total: dataTemp?.total,
            per_page: dataTemp?.per_page,
            items
        },
        status: data?.status
    }
}

export const postArchivoAdapterSchema = (item = {}) => {
    const data = {
        id: item.id || '',
        contenido_web_id: item.contenido_web_id || '',
        titulo: item.titulo || '',
        archivo: item.archivo || '',
    }
    return data
}

export const patchArchivoAdapterSchema = (item = {}) => {
    const data = {
        id: item.id || '',
        contenido_web_id: item.contenido_web_id || '',
        titulo: item.titulo || '',
        archivo: item.archivo || '',
        _method: 'PATCH'
    }
    return data
}

export const getArchivoAdapter = (data) => {
    const dataTemp = data?.data?.data
    const item = archivosAdapterSchema(dataTemp)
    return {
        data: item,
        status: data?.status
    }
}

export const postArchivoMultipar = (form) => {
    const formAdapter = postArchivoAdapterSchema(form)
    let formData = new FormData();
    for (const key in formAdapter) {
        formData.append(key, formAdapter[key])
    }
    return formData
}

export const patchContenidoMultipar = (form) => {
    const formAdapter = patchArchivoAdapterSchema(form)
    let formData = new FormData();
    for (const key in formAdapter) {
        formData.append(key, formAdapter[key])
    }
    return formData
}
