import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal, Table } from 'react-bootstrap'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../hooks/useAxios'
import { MsgInputServer } from '../../components/MsgInput/MsgInputServer';

export const ModalEstadoDapcons = ({ data, handleCloseModal, fetchConsolidado}) => {
    const [fetchActualizar, fetchActualizarData] = useAxios('PATCH', '/panel/actualizar_dapcons')

    const handleSubmitActualizar = (e) => {
        e.preventDefault();
        fetchActualizar({
            id: data?.id
        })
    }

    useEffect(() => {
      if(fetchActualizarData?.data?.status == 200){
            handleCloseModal();
            fetchConsolidado();
      }  
    },[fetchActualizarData.data])
    return (
        <Modal show={data.value} onHide={handleCloseModal} size='' backdrop="static" keyboard={false}>
             <Modal.Header closeButton className='border-0'>
                <Modal.Title className='h5'>{data?.codigo}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                Realmente desea cambiar el estado a (<span className="text-danger">Caducado</span>)
                <MsgInputServer fetchData={fetchActualizarData} className='mt-2' />
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button variant="default" onClick={() => {
                    handleCloseModal()
                }}>
                    Cancelar
                </Button>
                <ButtonSpinner
                        variant="danger" type="submit" className="ms-2 mt-2"
                        handleSubmit={handleSubmitActualizar} fetchDataLoading={[fetchActualizarData.loading]}
                        value='Actualizar' icon={<FontAwesomeIcon icon={faArrowRight} className='me-1' />}
                    />
            </Modal.Footer>
        </Modal>
    )
}
