import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export const ModalPrompt = ({setShowStatus, showStatus, title, children , fixed}) => {

    // Modal de success

    // const [showSuccess, setShowSuccess] = useState(true);

    const handleCloseSuccess = () => {
        setShowStatus({value: false, index: -1})
    };

    // const handleShowSuccess = () => setShowSuccess(true);

    return (
        <div>
            <Modal show={showStatus.value} onHide={handleCloseSuccess} backdrop={fixed ? "static" : true}>
                <Modal.Header closeButton className='border-0 pt-4'>
                    <Modal.Title className='h5'>{title}</Modal.Title>
                </Modal.Header>
                { children }
                
            </Modal>
        </div>
    )
}