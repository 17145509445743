import { useAxiosFake } from "../hooks/useAxiosFake";
import { useAxios2 } from "../hooks/useAxios2";
import { 
    getConsultorAdapter, 
    consultoresAdapter, 
    getConsultorAdapterSchema, 
    consultoresAdapterSchema, 
    postConsultorAdapter,
    postConsultorAdapterSchema,
    getConsultorAdapterMisDatos,
    getConsultorAdapterSchemaMisDatos,
    postConsultorAdapterMisDatos
} from "../adapters/consultor.adapter";

export const GetConsultoresJSON = () => {
    return useAxios2('get', '/consultores', 'json', {
        receiveAdapter: consultoresAdapter
    });
};

export const getConsultorJSON = () => {
    return () => useAxios2('get', '/consultores', 'json', getConsultorAdapter, getConsultorAdapterSchema);
};

export const postConsultorJSON = () => {
    return () => useAxiosFake('post', '/objeto_unico', 'json', postConsultorAdapter, postConsultorAdapterSchema);
};

export const GetConsultorJSON = () => {
    return useAxios2('get', '/consultores/mis-datos/mostrar', 'json', {
        receiveAdapter: getConsultorAdapterMisDatos
    });
};

export const PostConsultorJSON = () => {
    return useAxios2('post', '/consultores/mis-datos/actualizar?_method=PATCH', 'json', {
        receiveSchemaAdapter: getConsultorAdapterSchemaMisDatos,
        sendAdapter: postConsultorAdapterMisDatos
    });
};