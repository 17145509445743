import { useState } from "react";

export const useModalData = () => {

    const [show, setShow] = useState({ value: false });

    const handleClose = () => setShow({ value: false })

    const handleShow = (dataObj) => setShow({
        value: true, ...dataObj
    });

    return [show, handleShow, handleClose];
};
