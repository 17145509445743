import { faArrowDown, faArrowRight, faSave, faTrash, faArrowLeft, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useRef, useContext } from 'react'
import { Alert, Button, Form, Spinner, Table, InputGroup } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { Loader } from '../../../components/Loader/Loader';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputSelect, InputText, InputFile } from '../../../components/Inputs';
import { InputRadios } from '../../../components/Inputs/InputRadios';
import InputImage from '../../../components/Inputs/InputImage';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { MsgInput } from '../../../components/MsgInput/MsgInput';
import { postContenidoMultipar, postContenidoAdapterSchema } from '../../../adapters/contenido.adapter';
import { PostContenidoJSON, GetContenidoJson, PatchContenidoJson, DeleteContenidoJson } from '../../../services/contenido.service';

import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';
import { ModalCropImage } from "../../../components/ModalCropImage/ModalCropImage";
import { AuthContext } from '../../../auth/AuthContext';
import axios from 'axios';
import './TinyStyle.css'
import { useModalData } from '../../../hooks/useModalData';
import {ModalCategoria} from './ModalCategoria'

// import './ProcessosMain.css'

export const FormContenido = ({ data, handleCloseModal, fetchDocumentos, setIndexFocus, setIsForm, idContenido, setIdContenido }) => {
    const { user: { token, lang }, dispatch } = useContext(AuthContext);
    const history = useHistory();

    const inputFile = useRef(null)

    const [dataModal_Categoria, handleOpenModal_Categoria, handleCloseModal_Categoria] = useModalData()

    const [fetchCrearDocumento, fetchCrearDocumentoData] = PostContenidoJSON()

    const [fetchGetDocumento, fetchGetDocumentoData] = GetContenidoJson()

    const [fetchPatchDocumento, fetchPatchDocumentoData] = PatchContenidoJson()

    const [fetchDeleteDocumento, fetchDeleteDocumentoData] = DeleteContenidoJson()

    
    const [fetchParameters, fetchParametersData] = useAxios('POST', `/parametros_generales`)

    const [formContenido, 
        handleInputChangeContenido, 
        handleFormChangeContenido, 
        handleResetContenido] = useForm(postContenidoAdapterSchema())

    const [valuesValidDocumento, handleVerifiedValuesDocumento, handleResetValuesDocumento] = useValidator({
        titulo: { isOk: true, msgError: [`Ingrese un título`] },
        tipo: { isOk: true, msgError: [`Seleccione un tipo.`] },
        descripcion_corta: { isOk: true, msgError: [`Ingrese una descripción`] },
        descripcion_larga: { isOk: true, msgError: [`Ingrese una descripción.`] },
        estado: { isOk: true, msgError: [`Seleccione un estado`] },
        clasificacion: { isOk: true, msgError: [`Seleccione una clasificación`] },
        fecha_publicacion: { isOk: true, msgError: [`Ingrese una fecha de publicación`] },
    })

    const handleSubmitDocumento = () => {
        if (handleVerifiedValuesDocumento(formContenido)) {
            fetchCrearDocumento({
                body: {
                    ...formContenido,
                }
            })
        }
    }

    const handleSubmitUpdateDocumento = () => {
        if (handleVerifiedValuesDocumento(formContenido)) {
            fetchPatchDocumento({
                id: idContenido,
                body: {
                    ...formContenido,
                    _method: 'PATCH'
                }
            })
        }
    }

    const handleDeleteDocumento = () => {
        if(window.confirm("Realmente desea borrar este contenido ?")){
             fetchDeleteDocumento({
                id: idContenido,
            })
        }
    }

    useEffect(() => {
        if (fetchCrearDocumentoData?.status === 200) {
            setIsForm(false);
            setIndexFocus(0);
            fetchDocumentos()
        }

    }, [fetchCrearDocumentoData])

    useEffect(() => {
        if (fetchPatchDocumentoData?.status === 200) {
            //fetchDocumentos()
            //handleCloseModal()
            setIsForm(false);
            setIndexFocus(0);
            fetchDocumentos()
            setIdContenido('');
        }

    }, [fetchPatchDocumentoData])

    useEffect(() => {
        if (fetchDeleteDocumentoData?.status === 200) {
            /*fetchDocumentos()
            handleCloseModal()*/
            setIsForm(false);
            setIndexFocus(0);
            fetchDocumentos()
            setIdContenido('');
        }

    }, [fetchDeleteDocumentoData])

    useEffect(() => {
        //if(data.value){
            fetchParameters({
                body: [
                    {
                        "param": "tipo_noticias",
                        "locale": 1,
                        "active": true
                    },
                    {
                        param: "clasificacion_noticias",
                        locale: 1,
                        active: true,
                    },
                ]
            });

            if (idContenido) {
                fetchGetDocumento({
                    id: idContenido
                })
            }
        /*    
        }else{
            handleResetContenido();
            handleResetValuesDocumento();
            setInputFiles([])
        }*/
    }, [])

    const handleChange = (event) => {
        const { checked, value } = event.target;

        console.log(checked);

        if (checked) {
            handleFormChangeContenido({
                ...formContenido,
                perfil: [...formContenido.perfil, parseInt(value)],
            });
        } else {
            const items = formContenido.perfil.filter((item) => item !== parseInt(value));
            handleFormChangeContenido({
                ...formContenido,
                perfil: [...items],
            });
        }
    };

    const handleEditorChange = (content, editor) => {

        const target = {
            name: 'descripcion_larga',
            value: content,
        }
        handleInputChangeContenido({ target })
    }

    async function getUrlAsBlob(url) {
      const response = await fetch(url+'?v='+Math.floor(Math.random() * 1000000));
      const blob = await response.blob();
      return blob;
    }

    useEffect( async () => {
        if (fetchGetDocumentoData?.data) {
            const documento = fetchGetDocumentoData.data
            handleFormChangeContenido({...documento})
            if(documento.ruta){
                const blob = await getUrlAsBlob(documento.ruta);
                setInputFiles([new File([blob], 'logo.png')]);
            }
        }
    }, [fetchGetDocumentoData.data])
    /*Imagen*/

    const [InputFiles, setInputFiles] = useState([])

    useEffect(() => {
        
        handleFormChangeContenido({
            ...formContenido,
            imagen: InputFiles?.[0],
        })

    }, [InputFiles])

    // Recortar imagen

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        file: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        file: null
    })
    const handleShowModalCropImage = (file) => setShowModalCropImage({
        value: true,
        file
    });

    const handleChangeInputImage = (e) => {
        if (e.target.files.length > 0) {
            handleShowModalCropImage(e.target.files[0])
        }
    }
    /*Fin de imagen*/
    /*Carga de archivo para el editor*/
    const editorRef = useRef(null);
    const handleImageUpload = (blobInfo, success, failure) => {
        const formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        axios({
          url: '/upload_file_editor',
          method: 'POST',
          data: formData,
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
        .then(result => {
          if (result.data && result.data.data.url) {
            success(result.data.data.url);
          } else {
            failure('Error al cargar la imagen');
          }
        })
        .catch(error => {
          failure(error.message);
        });
    };

    return (
            <div>
            <div className="d-flex justify-content-between mb-3 pb-2 flex-wrap">
                <div>
                    <h5 className='mb-3 mb-lg-0'>{idContenido ? 'Editar contenido' : 'Nuevo contenido'}</h5>
                </div>

                <Button variant="default" className="px-3 ms-3 d-flex align-items-center"
                    onClick={() => {
                        setIsForm(false);
                        setIndexFocus(0)
                        fetchDocumentos()
                        setIdContenido('')
                    }}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className='me-2' /> <span className=''>&nbsp;Regresar</span>
                </Button>

            </div>
            {fetchParametersData.loading || fetchGetDocumentoData.loading ? <Loader /> : <>
                <Form className='row'>
                    <Form.Group className="mb-3 col-lg-4">
                        <InputText
                            label='Título:'
                            className=''
                            name='titulo'
                            placeholder="Ingrese un título"
                            value={formContenido.titulo}
                            onChange={handleInputChangeContenido}
                            validation={valuesValidDocumento}
                            fetchData={fetchCrearDocumentoData}
                        />
                    </Form.Group>

                    <Form.Group className="col-lg-4 mb-3">
                        <InputSelect
                            label='Estado:'
                            name='estado'
                            values={[
                                {
                                    id: 'Publicado', nombre: 'Publicado'
                                },
                                {
                                    id: 'Despublicado', nombre: 'Despublicado'
                                },
                            ]}
                            value={formContenido.estado}
                            onChange={handleInputChangeContenido}
                            validation={valuesValidDocumento}
                        />
                    </Form.Group>

                    <Form.Group className="col-lg-4 mb-3">
                        <InputSelect
                            label='Tipo:'
                            name='tipo'
                            values={
                                fetchParametersData.data?.data?.data?.tipo_noticias?.map((item, index) => {
                                    return {
                                       id: item.param_id,
                                       nombre: item.pt_texto
                                    }
                                })
                              }
                            value={formContenido.tipo}
                            onChange={handleInputChangeContenido}
                            validation={valuesValidDocumento}
                        />
                    </Form.Group>

                    <Form.Group className="col-lg-4 mb-3">
                        <Form.Label className='fw-500'>Clasificación</Form.Label>
                        <InputGroup className="mb-3">
                            <InputSelect
                                name='clasificacion'
                                values={
                                    fetchParametersData.data?.data?.data?.clasificacion_noticias?.map((item, index) => {
                                        return {
                                           id: item.param_id,
                                           nombre: item.pt_texto
                                        }
                                    })
                                  }
                                value={formContenido.clasificacion}
                                onChange={handleInputChangeContenido}
                                validation={valuesValidDocumento}
                            />  
                             <Button 
                                variant="outline-success" 
                                id="button-addon1" 
                                onClick={
                                    () => handleOpenModal_Categoria({
                                        show: 'Create',
                                    })
                                }>
                              <FontAwesomeIcon icon={faPlusCircle} className='me-2' />
                            </Button>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-4 col-lg-4">
                        <InputText
                            label='Descripción corta:'
                            className=''
                            name='descripcion_corta'
                            placeholder="Ingrese una descripción"
                            as="textarea" 
                            rows={1}
                            value={formContenido.descripcion_corta}
                            onChange={handleInputChangeContenido}
                            validation={valuesValidDocumento}
                            fetchData={fetchCrearDocumentoData}
                        />
                    </Form.Group>

                    { formContenido.tipo == 'noticia' &&
                      
                        <Form.Group className="mb-4 col-lg-4 text-center">
                            <InputImage
                                label='Imagen'
                                width='100%'
                                height='10rem'
                                onChange={handleChangeInputImage}
                                InputFiles={InputFiles}
                                setInputFiles={setInputFiles}
                            />
                        </Form.Group>

                    }

                     <Form.Group className="col-lg-12 mb-4" controlId="exampleForm.ControlInput7">
                        <Form.Label>Contenido</Form.Label>

                        <Editor
                            init={{

                                skin: false,
                                branding: false,
                                content_css: false,
                                height: 360,
                                menubar: false,
                                plugins: [
                                    'link image',
                                    'table paste',
                                    'code',
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help code | link | image | table',  
                                setup: (editor) => {
                                        editor.on('init', () => {
                                        editor.focus();
                                    });   
                                },
                                image_advtab: true,  // Muestra una pestaña de "Avanzado" para la imagen
                                images_upload_handler: handleImageUpload,
                            }}
                            value={formContenido.descripcion_larga}
                            onEditorChange={handleEditorChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-lg-4">
                        <InputText
                            label='Fecha de publicación:'
                            type="date"
                            className=''
                            name='fecha_publicacion'
                            placeholder="Ingrese un fecha"
                            value={formContenido.fecha_publicacion}
                            onChange={handleInputChangeContenido}
                            validation={valuesValidDocumento}
                            fetchData={fetchCrearDocumentoData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-lg-4">
                        <InputText
                            label='Fecha fin de publicación:'
                            type="date"
                            className=''
                            name='fecha_fin_publicacion'
                            placeholder="Ingrese una fecha"
                            value={formContenido.fecha_fin_publicacion}
                            onChange={handleInputChangeContenido}
                            validation={valuesValidDocumento}
                            fetchData={fetchCrearDocumentoData}
                        />
                    </Form.Group>


                    <Form.Group className="col-12 col-sm-4 mt-2 col-xl-4 mb-3">
                        <Form.Label className="fw-500 form-label">Perfiles de usuario:</Form.Label>
                        <div className="col-12 mt-1">
                        {[
                            {
                                id: 3, nombre: 'Consultor'
                            },
                            {
                                id: 4, nombre: 'Verificador'
                            },
                            {
                                id: 5, nombre: 'Organización'
                            },
                        ].map((check, index) => ( 
                          <div className="d-inline-block me-4" key={index}> 
                              <Form.Check
                                    type="checkbox"
                                    id={`${check.id}`}
                                    label={check.nombre}
                                    value={check.id}
                                    checked={formContenido.perfil.some((item) => item === check.id)}
                                    onChange={handleChange}
                                />
                            </div>
                         ))}    
                        </div>
                    </Form.Group>
                    <div className="text-center">
                    {idContenido ? 
                        <>
                        <ButtonSpinner
                            variant="warning" type="button" className="ms-2"
                            handleSubmit={handleSubmitUpdateDocumento} fetchDataLoading={[fetchPatchDocumentoData.loading]}
                            value='Actualizar Contenido' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                        />

                         <ButtonSpinner
                            variant="danger" type="button" className="ms-2"
                            handleSubmit={handleDeleteDocumento} fetchDataLoading={[fetchDeleteDocumentoData.loading]}
                            value='Eliminar' icon={<FontAwesomeIcon icon={faTrash} className='me-1' />}
                        />
                        </>
                     : 
                        <ButtonSpinner
                            variant="info" type="button" className="ms-2"
                            handleSubmit={handleSubmitDocumento} fetchDataLoading={[fetchCrearDocumentoData.loading]}
                            value='Crear Contenido' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                        />
                    }
                    </div>
                </Form>   

                 <ModalCropImage
                    show={showModalCropImage}
                    handleClose={handleCloseModalCropImage}
                    height='500px'
                    setInputFiles={setInputFiles}
                    escalas={[
                        { x: 1, y: 1, label: 'Cuadrado' },
                        { x: 4, y: 2, label: 'Rectángulo' }
                    ]}
                />

                <ModalCategoria
                    data={dataModal_Categoria}
                    handleCloseModal={handleCloseModal_Categoria}
                    formContenido={formContenido}
                    handleFormChangeContenido={handleFormChangeContenido}
                    fetchParameters={fetchParameters}
                />

            </>
         }
        </div>
    )
}
