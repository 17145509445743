import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'
import { AuthContext } from '../../../auth/AuthContext'
import { InputSelect, InputText } from '../../../components/Inputs'
import { isModuleAdministrador } from '../../../helpers/helpers'
import { useLang } from '../../../language'
import { Loader } from '../../../components/Loader/Loader';

export const OrganizacionesFilter = ({ handleSearch, formValuesSearch, handleInputChangeSearch, fetchParametersData, fetchConsultoresData }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const Lang = useLang();

    return (
        <div className='col-xl-3'>
            <div className='Column-small shadow-sm sticky-top'>

                <h5>{Lang('FILTRO')}</h5>
                {fetchConsultoresData.loading || fetchParametersData.loading ? <Loader /> :
                <>
                <Form className='row' onSubmit={handleSearch}>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3">
                        <InputText
                            label='Nombre:'
                            className=''
                            name='filter_name'
                            value={formValuesSearch.filter_name}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3">
                        <InputText
                            label='CIF:'
                            className=''
                            name='filter_cif'
                            value={formValuesSearch.filter_cif}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3">

                        <InputSelect
                            label='Estado:'
                            name='filter_estado_alta'
                            values={
                                [
                                    { id: 'alta', nombre: 'Alta' },
                                    { id: 'baja', nombre: 'baja' },
                                    { id: 'todos', nombre: 'todos' },
                                ]
                            }
                            value={formValuesSearch.filter_estado_alta}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3">

                        <InputSelect
                            label='Integración API:'
                            name='integracion_api'
                            values={
                                [
                                    { id: 'true', nombre: 'Si' },
                                    { id: 'false', nombre: 'No' },
                                ]
                            }
                            value={formValuesSearch.integracion_api}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3">
                        <InputSelect
                            label='Cuotas pendientes:'
                            name='cuotas_pendientes'
                            values={[
                                {
                                    id: false, nombre: 'Pagados'
                                },
                                {
                                    id: true, nombre: 'No pagados'
                                },
                            ]}
                            value={formValuesSearch.cuotas_pendientes}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-12 col-sm-4 col-xl-12">
                        <InputSelect
                            label='Convenios:'
                            name='org_convenio'
                            values={
                                fetchParametersData.data?.data?.data?.empresa_convenios?.map((item, index) => {
                                    return {
                                       id: item.param_id,
                                       nombre: item.pt_texto
                                    }
                                })
                              }
                            value={formValuesSearch.org_convenio}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-lg-12">
                        <InputSelect
                            //label='REMA:'
                            label='Comisionista'
                            name='comisionista_id'
                            values={
                                fetchConsultoresData.data?.data?.data?.map((item, index) => {
                                    return {
                                       id: item.id,
                                       nombre: item.full_name
                                    }
                                })
                              }
                            value={formValuesSearch.comisionista_id}
                            onChange={handleInputChangeSearch}
                        />        
                    </Form.Group>   

                    <Form.Group className="mb-3 col-12 col-sm-4 col-xl-12">
                        <InputSelect
                            label='Típo de comisión:'
                            name='tipo_comision'
                            values={
                                fetchParametersData.data?.data?.data?.tipo_comision?.map((item, index) => {
                                    return {
                                       id: item.param_id,
                                       nombre: item.pt_texto
                                    }
                                })
                              }
                            value={formValuesSearch.tipo_comision}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <div className='mt-2'>
                        <button className="px-3 btn btn-primary float-end">
                            <FontAwesomeIcon icon={faSearch} /> {Lang('BUSCAR')}
                        </button>
                    </div>
                </Form>
               </> 
            }
            </div>
        </div>
    )
}
