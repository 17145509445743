import React, { useContext, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faFileExcel, faSort } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../components/Loader/Loader';
import { PaginationT } from '../../../components/PaginationT/PaginationT';

import { useLang } from '../../../language/index';
import { useHistory } from 'react-router-dom';
import { ModalCrearOrganizaciones } from './ModalCrearOrganizaciones';
import ButtonOrderBy from '../../../components/ButtonOrderBy/ButtonOrderBy';
import { useAxios } from '../../../hooks/useAxios';
import { AuthContext } from '../../../auth/AuthContext';
import { getDataFile, isModuleAdministrador, isModuleOrganizacion } from '../../../helpers/helpers';
import { useFetch } from '../../../hooks/useFetch';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { ModalStatusServer2 } from '../../../components/ModalStatusServer/ModalStatusServer2';

export const OrganizacionesMain = ({ fetchOrganizacionesData, formValuesSearch, namePage = 'default', handleFormChange, modulo_app, estaVerificando }) => {

    console.log('fetchOrganizacionesData', fetchOrganizacionesData)
    const history = useHistory();

    const Lang = useLang();

    // Crear Organizaciones basico

    const [showModalCrearOrganizaciones, setShowModalCrearOrganizaciones] = useState(false);

    const handleCloseModalCrearOrganizaciones = () => setShowModalCrearOrganizaciones(false)
    const handleShowModalCrearOrganizaciones = () => setShowModalCrearOrganizaciones(true);

    const [fetchRCP, fetchRCPData] = useAxios('get', `/rcp`)
    const [fetchVerificadores, fetchVerificadoresData] = useAxios('get', `/verificadores`)
    const [fetchIdiomas, fetchIdiomasData] = useAxios('get', `/idiomas`)

    useEffect(() => {
        fetchRCP()
        fetchVerificadores()
        fetchIdiomas()
    }, [])


    // Start --- Handle Click Descargar Excel

    const [fetchOrganizacionesExcel, fetchOrganizacionesExcelData, resetfetchOrganizacionesExcelData]
        = useAxios('GET', `/organizaciones`, '', 'blob')

    const handleClickDownloadFile = () => {

        let formValuesSearchTemp = { ...formValuesSearch }

        Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

        fetchOrganizacionesExcel({
            params: {
                ...formValuesSearchTemp,
                to_excel: true
            }
        })
    }

    useEffect(() => {

        if (fetchOrganizacionesExcelData.data) {
            getDataFile(fetchOrganizacionesExcelData, resetfetchOrganizacionesExcelData, 'Organizaciones', 'xlsx')
        }
    }, [fetchOrganizacionesExcelData])

    return (
        <>
            <div className="col">
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto">

                    <div className="d-flex justify-content-between mb-3 pb-2 flex-wrap">
                        <div>
                            <h5 className='mb-3 mb-lg-0'>{Lang('LISTA_DE')} {namePage}</h5>
                        </div>
                        <ButtonSpinner
                            variant="outline-success" type="submit" className="ms-3 px-3"
                            handleSubmit={handleClickDownloadFile} fetchDataLoading={[fetchOrganizacionesExcelData.loading]}
                            value='Descargar Excel' icon={<FontAwesomeIcon icon={faFileExcel} className='me-2' />}
                        />
                        {/* {
                            isModuleAdministrador(modulo_app) &&
                            <>

                                <div className='d-flex justify-content-end ms-auto'>
                                    <Button variant="primary" className="px-3 ms-3 d-flex align-items-center"
                                        onClick={handleShowModalCrearOrganizaciones}
                                    >
                                        <FontAwesomeIcon icon={faFile} className='me-2' /> Alta <span className=''>&nbsp;Organizacion</span>
                                    </Button>
                                </div>
                            </>

                        } */}

                    </div>
                    {
                        fetchOrganizacionesData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    fetchOrganizacionesData?.data?.total ?

                                        <>
                                            <Table hover bordered responsive className='tableMain'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-nowrap'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`CIF`}
                                                                id='cif'
                                                            />
                                                        </th>
                                                        <th className='text-nowrap'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`Nombre`}
                                                                id='nombre'
                                                            />
                                                        </th>
                                                        <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                className='text-center'
                                                                label={`Fecha alta`}
                                                                id='fecha_creacion'
                                                            />
                                                        </th>
                                                        <th className='text-nowrap text-center'>Teléfono</th>
                                                        <th className='text-nowrap text-center'>Integración API</th>
                                                        <th className='text-nowrap text-center'>Convenio</th>
                                                        <th className='text-nowrap text-center'>Acciones</th>
                                                        {/* <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={Lang('FECHA_ESTADO')}
                                                                id='fecha_estado'
                                                            />
                                                        </th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fetchOrganizacionesData?.data?.items?.map((item) => {
                                                        return (
                                                            <tr key={item.id} style={{
                                                                    backgroundColor: item.org_convenio ? '#f0f0f0' : '',
                                                                    color:  item.org_convenio ? '#000000' : '',
                                                                  }}>
                                                                <td valign='middle' className='text-nowrap text-center'>  
                                                                    {item.cif}
                                                                </td>
                                                                <td valign='middle'>{item.nombre}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{item.fecha_alta}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{item.telefono}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{item.withIntegracionApi}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{item.org_convenio ? <><span className="text-success">Sí</span></> : 'No'}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>
                                                                     <OverlayTrigger
                                                                        key={item.id + '1'}
                                                                        placement={'top'}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-${item.id}`}>
                                                                                {Lang('VER_MAS')}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Button variant="transparent" size="sm" className="text-primary"
                                                                            onClick={() => history.push(`/empresas/${item.cif}`)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faBook} />
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </td>

                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchOrganizacionesData?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchOrganizacionesData?.data?.per_page}
                                            />
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No es van trobar resultats</h5>
                                }
                            </>
                    }
                </div>
            </div>

            <ModalCrearOrganizaciones
                showModalCrearOrganizaciones={showModalCrearOrganizaciones}
                handleCloseModalCrearOrganizaciones={handleCloseModalCrearOrganizaciones}
                fetchRCPData={fetchRCPData}
                fetchVerificadoresData={fetchVerificadoresData}
                fetchIdiomasData={fetchIdiomasData}
            />

            <ModalStatusServer2 fetchData={fetchOrganizacionesExcelData} onlyError />

        </>
    )
}
