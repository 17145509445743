import { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../auth/AuthContext';
import { types } from '../types/types';
import axios from 'axios';

export const useAxios = (method, url, headers, type = 'json', contentType) => {

    const { user: { token, lang }, dispatch } = useContext(AuthContext);
    
    const history = useHistory();

    const isMounted = useRef(true)
    
    const [state, setState] = useState({ data: null, loading: null, error: null });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const existenErroresAuth = (data) => {
        // console.log(data)
        if (data && token && data.status === 'Error' &&
            (data.message === 'El token ha expirat' ||
                data.message === `No s'ha trobat el token` ||
                data.message === `La sessió no existeix` ||
                data.message === `El token és invàlid` ||
                data.message === `No autoritzat`)) {
            console.log(window.location.pathname)
            if (window.location.pathname.indexOf('/login') === -1) {
                history.replace('/login');
                dispatch({
                    type: types.logout,
                    lang
                });
            }
            return true
        }
        return false
    }

    const handleFetch = (values) => {

        setState({ data: null, loading: true, error: null });

        axios({
            method,
            url: values?.id ? url + '/' + values.id : url,
            headers: {
                Authorization: token ? 'Bearer ' + token : '',
                
                // 'Content-Type': contentType
            },
            responseType: type,
            data: values?.body,
            params: values?.params
          })
            .then((res) => {
                if (!existenErroresAuth(res) && isMounted.current) {
                    setState({
                        loading: false,
                        error: null,
                        data: res,
                        setState // En caso sea un file, limpiar el state con null luego de la descarga
                    });
                }
            })
            .catch((err) => {
                console.log(err?.response?.data?.message)
                if (err?.response?.data?.message === 'La sessió no existeix') {
                    if (window.location.pathname.indexOf('/login') === -1) {
                        history.replace('/login');
                        dispatch({
                            type: types.logout,
                            lang
                        });
                    }
                }
                setState({
                    data: null,
                    loading: false,
                    error: err
                })
            })
            .finally(() => {
                // setloading(false);
            });
            
    }

    const handleResetData = () =>{
        setState({ data: null, loading: null, error: null });
    }

    return [handleFetch, state, handleResetData];
}