import { getIncidenciasAdapter, getIncidenciasAdapterSchema, postIncidenciaAdapter } from "../adapters/incidencia.adapter";
import { useAxios2 } from "../hooks/useAxios2";

export const GetIncidenciasJSON = () => {
    return useAxios2('get', '/incidencias/listar/todas', 'json', {
        receiveAdapter: getIncidenciasAdapter
    });
};

export const PostIncidenciaJSON = () => {
    return useAxios2('post', '/incidencias/crear', 'json', {
        receiveSchemaAdapter: getIncidenciasAdapterSchema,
        sendAdapter: postIncidenciaAdapter
    });
};
