import { faBook, faCheck, faCheckCircle, faExchangeAlt, faFile, faFilePdf, faPlus, faSave, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, FormControl, InputGroup, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { Prompt } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { Loader } from '../../../components/Loader/Loader';

import { AuthContext } from '../../../auth/AuthContext';
import { GetSeguimientosJSON } from '../../../services/seguimiento.service';
import { PaginationT } from '../../../components/PaginationT/PaginationT';
import { getURLQuery, getValuesParams } from '../../../helpers/helpers';
import IconIdioma from '../../../components/IconIdioma/IconIdioma';
import { GetParametroJSON } from '../../../services/parametro.service';
import ButtonOrderBy from '../../../components/ButtonOrderBy/ButtonOrderBy';

export const DAPconSeccionSeguimiento = ({ id, dapcons_codigo, idiomaDAPcon, fetchEstadosDAPconsData }) => {

    const [fetchIncidencias, fetchIncidenciasData] = GetSeguimientosJSON(id)

    const { user } = useContext(AuthContext);

    // useEffect(() => {
    //     fetchIncidencias({
    //         params: {
    //             // idioma_id: idiomaDAPcon
    //         }
    //     })
    // }, [])

    useEffect(() => {
        console.log(fetchIncidenciasData.data)
        if (fetchIncidenciasData.status === 201) {
        }

    }, [fetchIncidenciasData])

    // const [fetchDAPconsDatReq] = useAxios('get', `/dapcons/${id}/form/datos-requeridos`)

    const [valuesValidationDAPcon, handleValidationDAPcon, handleResetValidationDAPcon] = useValidator({
        dv_nombre_producto: { isOk: true, msgError: [`El nombre del producto es obligatorio.`] },
        dv_descripcion_producto: { isOk: true, msgError: [`La descripción del producto es obligatoria.`] },
        dapcons_verificador_id: { isOk: true, msgError: [`El verificador es obligatorio.`] },
        dapcons_rcp_id: { isOk: true, msgError: [`El RCP de referencia es obligatorio.`] },
    })

    const [fetchDAPconsDatReqEdit, fetchDAPconsDatReqEditData] = useAxios('patch', `/dapcons/${id}/form/datos-requeridos`)

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        console.log(fetchDAPconsDatReqEditData.data)
        if (fetchDAPconsDatReqEditData.data?.status === 201) {
            setShowSuccessEdit(true)
        }

    }, [fetchDAPconsDatReqEditData])


    const [showModalVerSeguimiento, setShowModalVerSeguimiento] = useState({
        value: false,
        item: {}
    });

    const handleCloseModalVerSeguimiento = () => setShowModalVerSeguimiento({
        value: false,
        item: {}
    })
    const handleShowModalVerSeguimiento = (item) => setShowModalVerSeguimiento({
        value: true,
        item
    });

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        page: 1,
        order_by: 'fecha desc',
    });

    // const [page, setPage] = useState(second)

    useEffect(() => {

        fetchIncidencias({
            params: {
                // idioma_id: idiomaDAPcon,
                page: formValuesSearch.page,
                order_by: formValuesSearch.order_by
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order_by])

    // Parametro ID

    const [fetchTiposSeguim, fetchTiposSeguimData] = GetParametroJSON('tipos_seguimiento_dapcons')

    useEffect(() => {

        fetchTiposSeguim();

    }, [])

    return (
        <>
            <div className='MainInner shadow-sm animate-width-disabled'>
                {fetchIncidenciasData.loading ?
                    <div className="py-5">
                        <Loader />
                    </div>
                    : <>

                        <div className="d-flex justify-content-center mb-2">
                            <div>
                                <h5 className='mt-2 mb-4'>Seguimiento {dapcons_codigo}</h5>

                            </div>
                            {/* <Button variant="warning" size='' className="px-3 ms-3 d-flex align-items-center"
                                onClick={() => null}
                            >
                                <FontAwesomeIcon icon={faExchangeAlt} className='me-2' /> Crear en Inglés
                            </Button> */}
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <Table hover bordered responsive className='tableMain'>
                                    <thead>
                                        <tr>
                                            <th className='text-nowrap'>
                                                <ButtonOrderBy
                                                    formValuesSearch={formValuesSearch}
                                                    handleFormChange={handleFormChange}
                                                    // className='text-center'
                                                    label={`Fecha`}
                                                    id='fecha'
                                                />
                                            </th>
                                            <th className='text-nowrap'>
                                                <ButtonOrderBy
                                                    formValuesSearch={formValuesSearch}
                                                    handleFormChange={handleFormChange}
                                                    // className='text-center'
                                                    label={`Estado`}
                                                    id='estado'
                                                />
                                            </th>
                                            <th className='text-nowrap'>
                                                <ButtonOrderBy
                                                    formValuesSearch={formValuesSearch}
                                                    handleFormChange={handleFormChange}
                                                    // className='text-center'
                                                    label={`Persona`}
                                                    id='persona'
                                                />
                                            </th>
                                            <th className='text-nowrap text-center'>
                                                <ButtonOrderBy
                                                    formValuesSearch={formValuesSearch}
                                                    handleFormChange={handleFormChange}
                                                    // className='text-center'
                                                    label={`Idioma`}
                                                    id='idioma'
                                                />
                                            </th>
                                            <th className='text-nowrap text-center'>
                                                <ButtonOrderBy
                                                    formValuesSearch={formValuesSearch}
                                                    handleFormChange={handleFormChange}
                                                    // className='text-center'
                                                    label={`Tipo de seguimiento`}
                                                    id='tipo'
                                                />
                                            </th>
                                            <th className='text-nowrap'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fetchIncidenciasData.data?.total > 0 ? <>
                                                {
                                                    fetchIncidenciasData.data?.items.map(item => {
                                                        return (
                                                            <tr>
                                                                <td>{item.fecha_creacion}</td>
                                                                <td>
                                                                    {fetchEstadosDAPconsData?.data?.find(item2 => item2.id === item.estado)?.nombre}
                                                                </td>
                                                                <td>{item.nombre} {item.apellidos}</td>
                                                                <td className='text-center'>
                                                                    <IconIdioma
                                                                        idioma_id={item.idioma_id}
                                                                    />
                                                                </td>
                                                                <td className='text-center'>{fetchTiposSeguimData?.data?.find(item2 => item2.id === item.dapcons_seguimiento_tipo)?.nombre}</td>
                                                                {
                                                                    item.dapcons_seguimiento_descripcion !== '' ?
                                                                        <td>
                                                                            <OverlayTrigger
                                                                                key={item.id + '1'}
                                                                                placement={'top'}
                                                                                overlay={
                                                                                    <Tooltip id={`tooltip-${item.id}`}>
                                                                                        Ver más
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <Button variant="transparent" size="sm" className='btn-modalVermas'
                                                                                    onClick={() => handleShowModalVerSeguimiento(item)}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faBook} />
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </td> : <td></td>
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </> : <tr>
                                                <td className='py-5 text-center' colSpan={5}>
                                                    <h5>No hay seguimiento de este DAPcons</h5>
                                                </td>
                                            </tr>
                                        }

                                    </tbody>
                                </Table>
                                <PaginationT
                                    total={fetchIncidenciasData.data?.total}
                                    handleFormChange={handleFormChange}
                                    formValuesSearch={formValuesSearch}
                                    perPage={fetchIncidenciasData.data?.per_page}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
            <Modal show={showModalVerSeguimiento.value} onHide={handleCloseModalVerSeguimiento} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Seguimiento: {showModalVerSeguimiento.item.dapcons_seguimiento_tipo}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-4'>

                    <Form className='row'>

                        <Form.Group className="col-12 col-lg-3 mb-4" controlId="exampleForm.ControlInput2">
                            <Form.Label className='fw-500'>Fecha:</Form.Label>
                            <p className='mt-0 mb-0'>{showModalVerSeguimiento.item.fecha_creacion}</p>
                        </Form.Group>

                        <Form.Group className="col-12 col-lg-3 mb-4" controlId="exampleForm.ControlInput2">
                            <Form.Label className='fw-500'>Estado:</Form.Label>
                            <p className='mt-0 mb-0'>{fetchEstadosDAPconsData?.data?.find(item2 => item2.id === showModalVerSeguimiento.item.estado)?.nombre}</p>
                        </Form.Group>

                        <Form.Group className="col-12 col-lg-6 mb-4" controlId="exampleForm.ControlInput2">
                            <Form.Label className='fw-500'>Persona quien genero el evento:</Form.Label>
                            <p className='mt-0 mb-0'>{showModalVerSeguimiento.item?.nombre} {showModalVerSeguimiento.item?.apellidos}</p>
                        </Form.Group>

                        <Form.Group className="col-12 col-sm-12 mb-4" controlId="exampleForm.ControlInput2">
                            <Form.Label className='fw-500'>Descripción:</Form.Label>
                            <div className='mt-0 mb-0' dangerouslySetInnerHTML={{ __html: showModalVerSeguimiento.item.dapcons_seguimiento_descripcion }}></div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleCloseModalVerSeguimiento}>
                        Salir
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
