import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch, faWarehouse, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, InputGroup, Modal, Spinner, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputSelect, InputText } from '../../../components/Inputs';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { MsgInput } from '../../../components/MsgInput/MsgInput';
import { postUsuarioAdapterSchema } from '../../../adapters/usuario.adapter';
import { PostConsultorJSON } from '../../../services/consultor.service';
import { PostUsuarioJSON } from '../../../services/usuario.service';
import { ModalStatusServer2 } from '../../../components/ModalStatusServer/ModalStatusServer2';
import { getValuesParams, isStatusSucess, generateYears } from '../../../helpers/helpers';
import { ModalTableOrganizacion } from '../../DAPcons/DAPconsFilter/ModalTableOrganizacion';
import { Loader } from '../../../components/Loader/Loader';


// import './ProcessosMain.css'

export const ModalPagoCuota = ({ data, handleCloseModal, org_id, fetchSuccess}) => {
    const currentYear = new Date().getFullYear();
    const startYear = 2000;
    const years = generateYears(startYear, currentYear);

    const [fetchPagoPOST, fetchPagoPOSTData] = useAxios('POST', '/cuotas/registro-pago')
    const [fetchPagoPATCH, fetchPagoPATHData] = useAxios('PATCH', '/cuotas/actualizar-pago')
    const [fetchPagoGET, fetchPagoGETData] = useAxios('GET', '/cuotas/mostrar-pago')
    const [fetchPagoDELETE, fetchPagoDELETEData] = useAxios('DELETE', '/cuotas/borrado-pago')


    const [formPago, handleInputChangePago, handleFormChangeCrearDAPcons, handleResetCrearDAPcons]
        = useForm({
            pagado: '', 
            importe: '', 
            year: currentYear, 
            observaciones: '',
            org_id: '',
            num_order: '',
            num_factura:'',
            tipo: '',
            no_comisionable: '',
        })
    const [valuesValidCrearDAPcons, handleVerifiedValuesCrearDAPcons, handleResetValuesCrearDAPcons] = useValidator({
        org_id: { isOk: true, msgError: [`El campo es obligatorio.`] },
        year: { isOk: true, msgError: [`El campo es obligatorio.`] },
        pagado: { isOk: true, msgError: [`El campo es obligatorio.`] },
        //observaciones: { isOk: true, msgError: [`Ingrese una observación`] },
        importe: { isOk: true, msgError: [`Ingrese una observación`] },
        tipo: { isOk: true, msgError: [`Seleccione el tipo`] },
    })

    const handleSubmitAltaDAPcons = () => {
        if (handleVerifiedValuesCrearDAPcons(formPago)) {
            fetchPagoPOST({
                body: formPago
            })
        }
    }

    const handleSubmitActualizarPago = () => {
        if (handleVerifiedValuesCrearDAPcons(formPago)) {
            fetchPagoPATCH({
                id: data?.org_id+'/'+data?.year,
                body: formPago
            })
        }
    }

    const handleSubmitDeletePago = (item) => {
        fetchPagoDELETE({
            body: formPago
        })
    }


    useEffect(() => {
        if(data.value){
           
            if(data?.year){
                fetchPagoGET({
                    id: data?.org_id+'/'+data?.year
                })
            }else{
              handleFormChangeCrearDAPcons({...formPago, org_id})
            }
        }else{
           handleResetCrearDAPcons();
           handleResetValuesCrearDAPcons();
        }
    }, [data.value])

    useEffect(() => {
        if (fetchPagoPOSTData?.data?.status == 201) {
            handleCloseModal();
            fetchSuccess()
        }
    }, [fetchPagoPOSTData.data])

    useEffect(() => {
        if (fetchPagoPATHData.data?.data) {
            handleCloseModal();
            fetchSuccess()
        }
    }, [fetchPagoPATHData.data])

    useEffect(() =>{
        if(fetchPagoGETData.data?.data?.data){
            const data = fetchPagoGETData.data?.data?.data;
            handleFormChangeCrearDAPcons(data);
        }
    }, [fetchPagoGETData.data])

    useEffect(() => {
        if (fetchPagoDELETEData.data?.data) {
            handleCloseModal();
            fetchSuccess()
        }
    }, [fetchPagoDELETEData.data])


    const handleChangeCheck = (e) =>{
         handleFormChangeCrearDAPcons({
            ...formPago,
            no_comisionable: e.target.checked ? true : false
        })
    }

    return (
        <>

            <Modal show={data?.value} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Ficha de registro de cuotas</Modal.Title>
                </Modal.Header>
                {fetchPagoGETData.loading ? <Loader /> : <>
                <Modal.Body className='px-4 py-4'>

                    <Form className='row'>
                        <Form.Group className="mb-4 col-lg-4">
                             <InputSelect
                                    label='Año:'
                                    name='year'
                                    values={years}
                                    value={formPago.year}
                                    onChange={handleInputChangePago}
                                    validation={valuesValidCrearDAPcons}
                                    fetchData={fetchPagoPOSTData}
                                />
                        </Form.Group>
                        <Form.Group className="mb-4 col-lg-4">
                             <InputText
                                    label='Fecha de pago:'
                                    type="datetime-local"
                                    className=''
                                    name='pagado'
                                    placeholder="Ingrese una fecha"
                                    value={formPago.pagado}
                                    onChange={handleInputChangePago}
                                    validation={valuesValidCrearDAPcons}
                                    fetchData={fetchPagoPOSTData}
                                />
                        </Form.Group>
                        <Form.Group className="mb-4 col-lg-4">
                            <InputText
                                label='Importe:'
                                type="number"
                                className=''
                                name='importe'
                                placeholder="Ingrese un valor"
                                value={formPago.importe}
                                onChange={handleInputChangePago}
                                validation={valuesValidCrearDAPcons}
                                fetchData={fetchPagoPOSTData}
                            />
                         </Form.Group>

                         <Form.Group className="mb-4 col-lg-6">
                            <InputText
                                label='N° Orden:'
                                type="number"
                                className=''
                                name='num_order'
                                placeholder="Ingrese un valor"
                                value={formPago.num_order}
                                onChange={handleInputChangePago}
                                validation={valuesValidCrearDAPcons}
                                fetchData={fetchPagoPOSTData}
                            />
                         </Form.Group>

                         <Form.Group className="mb-4 col-lg-6">
                            <InputText
                                label='N° Factura:'
                                type="number"
                                className=''
                                name='num_factura'
                                placeholder="Ingrese un valor"
                                value={formPago.num_factura}
                                onChange={handleInputChangePago}
                                validation={valuesValidCrearDAPcons}
                                fetchData={fetchPagoPOSTData}
                            />
                         </Form.Group>

                         <Form.Group className="mb-4 col-lg-6">
                             <InputText
                                label='Observaciones:'
                                className=''
                                as='textarea'
                                rows={2}
                                name='observaciones'
                                placeholder="Ingrese una observación"
                                value={formPago.observaciones}
                                onChange={handleInputChangePago}
                                validation={valuesValidCrearDAPcons}
                                fetchData={fetchPagoPOSTData}
                            />
                        </Form.Group>

                         <Form.Group className="mb-4 col-lg-6">
                             <InputSelect
                                    label='Tipo:'
                                    name='tipo'
                                    values={[
                                        {id: 'Alta', nombre: 'Alta'},
                                        {id: 'Renovado', nombre: 'Renovación'},
                                        //{id: 'Renovación 5 años', nombre: 'Renovación 5 años'}
                                    ]}
                                    value={formPago.tipo}
                                    onChange={handleInputChangePago}
                                    validation={valuesValidCrearDAPcons}
                                    fetchData={fetchPagoPOSTData}
                                />
                        </Form.Group>

                        <Form.Group className=" mb-3 col-lg-12">
                            <Form.Check
                                type='checkbox'
                                name='renovacion'
                                checked={formPago.no_comisionable}
                                onChange={handleChangeCheck}
                                id={`default-checkbox`}
                                className="mb-0 fw-500"
                                label='No comisionable'
                            />
                       </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
  
                    <Button variant="default" onClick={() => handleCloseModal()}>
                        Salir
                    </Button>
                    {data?.action === 'CREATE' &&
                        <ButtonSpinner
                            variant="info" type="submit" className="ms-2"
                            handleSubmit={handleSubmitAltaDAPcons} fetchDataLoading={[fetchPagoPOSTData.loading]}
                            value='Crear Pago' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                        />
                    }
               
                    {
                        data?.action === 'EDIT' &&
                        <>
                            <ButtonSpinner
                                variant="primary" type="submit" className="ms-2"
                                handleSubmit={handleSubmitActualizarPago} fetchDataLoading={[fetchPagoPATHData.loading]}
                                value='Actualizar pago' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                            />
                            <ButtonSpinner
                                variant="danger" type="submit" className="ms-2"
                                handleSubmit={handleSubmitDeletePago} fetchDataLoading={[fetchPagoDELETEData.loading]}
                                value='Borrar pago' icon={<FontAwesomeIcon icon={faTrash} className='me-1' />}
                            />
                        </>
                    } 

                </Modal.Footer>
                </>
            }
            </Modal>

            <ModalStatusServer2 fetchData={fetchPagoPOSTData} />
            <ModalStatusServer2 fetchData={fetchPagoPATHData} />
            <ModalStatusServer2 fetchData={fetchPagoDELETEData} />

            {/* <ModalTableOrganizacion show={show} handleClose={handleClose} setOrganizacionSelect={setOrganizacionSelect} /> */}

        </>
    )
}
