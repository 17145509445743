import { faSearch, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { AuthContext } from '../../../auth/AuthContext'
import { InputSelect, InputText } from '../../../components/Inputs'
import { getFormatDiaMesAnio, isConsultor, isModuleAdministrador } from '../../../helpers/helpers'
import { useLang } from '../../../language'
import { ModalTableOrganizacion } from './ModalTableOrganizacion'

export const DAPconsFilter = ({ handleSearch, formValuesSearch, handleInputChangeSearch, fetchParametersData,
    fetchEstadosDAPconsData, idiomas, estaVerificando, handleFormChange, fetchConsultoresData, fetchRemaData, data, handleCloseModal, fetchVerificadorData }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const Lang = useLang();

    // useEffect(() => {

    //     console.log(fetchEstadosDAPconsData)
    // }, [fetchEstadosDAPconsData])

    // Preparando select de Estados

    const [listEstados, setListEstados] = useState([])

    useEffect(() => {

        if (fetchEstadosDAPconsData.data) {
            const estados_dapcons = fetchEstadosDAPconsData?.data
            console.log(estados_dapcons)

            const listTemp = estados_dapcons.map(item => {

                if (estaVerificando && item.id === 'borrador') {
                    return null
                }
                return item
            })
            setListEstados(listTemp.filter(item => item))
        }

    }, [fetchEstadosDAPconsData])

    // 

    const [organizacionSelect, setOrganizacionSelect] = useState({
        id: '',
        nombre: ''
    })

    // Modal

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        handleInputChangeSearch({
            target: {
                name: 'organizacion_id',
                value: organizacionSelect.id
            }
        })
    }, [organizacionSelect])

    const handleChangeRenovar = (e) => {
        handleFormChange({
            ...formValuesSearch,
            renovacion: e.target.checked ? true : ''
        })
    }

    return (
        <>
          <Modal show={data?.value} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Filtros de busqueda</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                <Form className='row'>
                    <Form.Group className="col-12 col-lg-4 mb-3">
                        <InputText
                            label='Núm DAPcons:'
                            name='search'
                            value={formValuesSearch.search}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    {
                        isModuleAdministrador(modulo_app) && <Form.Group className="col-12 col-lg-4 mb-3">
                            <Form.Label className='fw-500'>Empresa:</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    value={organizacionSelect.nombre}
                                    onChange={() => setOrganizacionSelect({ id: '', nombre: '' })}
                                />
                                <InputGroup.Text className='btn btn-sm btn-primary d-flex align-items-center' onClick={handleShow}>
                                    <FontAwesomeIcon icon={faWarehouse} className='' />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                    }


                    <Form.Group className="col-12 col-lg-4 mb-3">
                        <InputSelect
                            label='Estado:'
                            name='estado_id'
                            values={listEstados}
                            value={formValuesSearch.estado_id}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-lg-3 col-12 mb-3">
                        <InputSelect
                            label='Idioma:'
                            name='idioma_id'
                            values={idiomas}
                            value={formValuesSearch.idioma_id}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-lg-3 col-12 mb-3">
                        <InputSelect
                            label='Pagados:'
                            name='pagado'
                            values={[
                                {
                                    id: true, nombre: 'Pagados'
                                },
                                {
                                    id: false, nombre: 'No pagados'
                                },
                            ]}
                            value={formValuesSearch.pagado}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-lg-3 col-12 mb-3">
                        <InputText
                            label='Desde:'
                            type='date'
                            className=''
                            name='desde'
                            value={formValuesSearch.desde}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-lg-3 col-12 mb-3">
                        <InputText
                            label='Hasta:'
                            type='date'
                            className=''
                            name='hasta'
                            value={formValuesSearch.hasta}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-lg-4 col-12">
                        <InputSelect
                            label='Consultor:'
                            name='consultor_id'
                            values={
                                fetchConsultoresData.data?.data?.data?.map((item, index) => {
                                    return {
                                       id: item.id,
                                       nombre: item.full_name
                                    }
                                })
                              }
                            value={formValuesSearch.consultor_id}
                            onChange={handleInputChangeSearch}
                        />        
                     </Form.Group>

                     <Form.Group className="mb-3 col-lg-4 col-12">
                        <InputSelect
                            label='Verificador:'
                            name='verificador_id'
                            values={
                                fetchVerificadorData.data?.data?.data?.map((item, index) => {
                                    return {
                                       id: item.id,
                                       nombre: item.full_name
                                    }
                                })
                              }
                            value={formValuesSearch.verificador_id}
                            onChange={handleInputChangeSearch}
                        />        
                     </Form.Group>

                    <Form.Group className="mb-3 col-lg-4 col-12">
                        <InputSelect
                            label='Comisionista:'
                            name='comisionista_id'
                            values={
                                fetchRemaData.data?.data?.data?.map((item, index) => {
                                    return {
                                       id: item.id,
                                       nombre: item.full_name
                                    }
                                })
                              }
                            value={formValuesSearch.comisionista_id}
                            onChange={handleInputChangeSearch}
                        />        
                     </Form.Group>

                    <Form.Group className="col-6 col-sm-4 col-xl-4 mb-3">
                    <Form.Check
                        type='checkbox'
                        name='renovacion'
                        value={formValuesSearch.renovacion}
                        onChange={handleChangeRenovar}
                        checked={formValuesSearch.renovacion}
                        id={`default-checkbox`}
                        label='Renovación'
                    />
                   </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary"  onClick={handleSearch}>
                    <FontAwesomeIcon icon={faSearch} /> Filtrar
                </Button>
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
        {
            isModuleAdministrador(modulo_app) &&
            <ModalTableOrganizacion show={show} handleClose={handleClose} setOrganizacionSelect={setOrganizacionSelect} />
        }
        </>
    )
}
