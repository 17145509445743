import React, { useContext, useEffect, useState, useRef } from 'react'
import { Button, OverlayTrigger, Table, Tooltip, Form} from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faSort, faEdit, faDownload, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';
import { Loader } from '../../../../components/Loader/Loader';
import { PaginationT } from '../../../../components/PaginationT/PaginationT';
import { InputText, InputFile } from '../../../../components/Inputs';
import { useLang } from '../../../../language/index';
import { useHistory } from 'react-router-dom';
import ButtonOrderBy from '../../../../components/ButtonOrderBy/ButtonOrderBy';
import { useModalData } from '../../../../hooks/useModalData';
import { postArchivoMultipar, postArchivoAdapterSchema } from '../../../../adapters/contenidoarchivo.adapter';
import { PostArchivoJSON, GetArchivoJson, DeleteArchivoJson } from '../../../../services/contenidoarchivo.service';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';

export const ArchivosForm = ({reloadTable, formArchivo, valuesValidDocumento, handleSubmitArchivo, handleSubmitEditArchivo, handleInputChangeArchivo, fetchCrearArchivoData, inputFile, fetchPatchArchivoData}) => {
    const Lang = useLang();
    return (
        <>
            <div className="col">
                  <Form.Group className="mb-4 col-lg-12">
                     <InputText
                            label='Título:'
                            className=''
                            name='titulo'
                            placeholder="Ingrese un título"
                            value={formArchivo.titulo}
                            onChange={handleInputChangeArchivo}
                            validation={valuesValidDocumento}
                            fetchData={fetchCrearArchivoData}
                        />
                 </Form.Group>

                 <Form.Group className="mb-4 col-lg-12">
                    <InputFile
                        label='Archivo:'
                        className=''
                        referencia={inputFile}
                        name='archivo'
                        onChange={handleInputChangeArchivo}
                        fetchData={fetchCrearArchivoData}
                    />
                </Form.Group>
                 <Form.Group className="mb-4 col-lg-12 text-center">
                 {!formArchivo?.id ?
                     <ButtonSpinner
                            variant="info" type="submit" className="ms-2"
                            handleSubmit={handleSubmitArchivo} fetchDataLoading={[fetchCrearArchivoData.loading]}
                            value='Subir documento' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                        />
                     :
                     <ButtonSpinner
                            variant="warning" type="submit" className="ms-2"
                            handleSubmit={handleSubmitEditArchivo} fetchDataLoading={[fetchPatchArchivoData.loading]}
                            value='Editar documento' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                        />   
                 }
                 </Form.Group>
                  
            </div>
        </>
    )
}
