import React, { useContext, useEffect } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useLang } from '../../language/';
import { AuthContext } from '../../auth/AuthContext';
import { useLocation } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios'
import queryString from 'query-string';
import { useForm } from '../../hooks/useForm'
import { getURLQuery, getValuesParams, isModuleOrganizacion } from '../../helpers/helpers'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComunicacionesFilter } from './ComunicacionesFilter/ComunicacionesFilter'
import { ComunicacionesMain } from './ComunicacionesMain/ComunicacionesMain'

export const Comunicaciones = () => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const location = useLocation()

    const [fetchComunicaciones, fetchComunicacionesData] = useAxios('GET', `/comunicaciones/admin`)

    const {
        magic: magic = '',
        page: page = 1
    } = queryString.parse(location.search);

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        magic: magic,
        page: page
    });

    const handleSearch = (e) => {

        if (e) {
            e.preventDefault();
        }

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        // history.push(`?${query}`);
        window.history.replaceState(null, null, `?${query}`);

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchComunicaciones({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    }

    useEffect(() => {

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        window.history.replaceState(null, null, `?${query}`);

        fetchComunicaciones({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.orderby])

    return (
        <div className="container-inner row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Comunicaciones
                </Breadcrumb.Item>
            </Breadcrumb>
             <ComunicacionesFilter
                handleSearch={handleSearch}
                handleInputChangeSearch={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
            />
            <ComunicacionesMain
                fetchComunicacionesData={fetchComunicacionesData}
                fetchDocumentos={fetchComunicaciones}
                formValuesSearch={formValuesSearch}
                namePage={'Comunicaciones'}
                handleFormChange={handleFormChange}
            />
        </div>
    )
}
