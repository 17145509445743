import { Tooltip } from 'react-bootstrap'
import React from 'react'
import { OverlayTrigger, Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const NavLinkMenu = ({to, close, icon, label, disabled}) => {
    const location = useLocation();
    const path = location.pathname;
    return (
        <>
        <Nav.Link as={Link} to={to} className={path.startsWith(to) ? 'active' : ''}>
            <div style={{ 'width': '1.5rem' }} className="me-2">
                {icon}
            </div>
            <span>{label}</span>
        </Nav.Link>
        {/*
        <NavLink
            activeClassName="active"
            className={`d-block my-3 ${disabled ? 'disabledCursor' : ''}`}
            exact
            to={to}
        >
            {
                close ? <>
                    <OverlayTrigger overlay={<Tooltip className='NavStartInner-tooltip-inClose'>{label}</Tooltip>} placement='right'>
                        <div className="d-inline-block nav-link">
                            {icon}
                        </div>
                    </OverlayTrigger>
                </> : <>
                    <div className='nav-link'>
                        <div style={{ 'width': '1.5rem' }} className="me-2">
                            {icon}
                        </div>
                        <span>{label}</span>
                    </div>
                </>
            }
        </NavLink>
        */}
        </>
    )
}

export default NavLinkMenu