import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

export const ModalStatusServer = ({fetchData}) => {

    console.log(fetchData)

    const [showStatus, setShowStatus] = useState(false);

    const [status, setStatus] = useState({});

    const handleCloseStatus = () => {
        setShowStatus(false)
    };

    const handleShowStatus = () => setShowStatus(true);

    useEffect(() => {
        if(fetchData?.error && fetchData.error?.message !== 'form_error') {
                setStatus({
                    status: fetchData?.error?.name,
                    message: <>
                        <p>{fetchData.error?.response?.data?.message}</p>
                        <p>{fetchData.error?.data?.errors}</p>
                    </>
                })
                handleShowStatus()
        }else if (fetchData?.data?.status && fetchData?.data?.message) {
            setStatus({
                status: fetchData.data.status, 
                message: fetchData.data.message
            })
            handleShowStatus()
        }

    }, [fetchData])

    return (
        <div>
            <Modal show={showStatus} onHide={handleCloseStatus}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'>{status.status}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    { status.message }
                </Modal.Body>
                <Modal.Footer className='border-0 '>
                    <Button variant="default" onClick={handleCloseStatus}>
                        Salir
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}