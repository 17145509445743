

// ----------------------- Get list RCPS ------------------------

export const getRCPAdapterSchema = (item = {}) => {

    const data = {
        id: item.rcp_id || '',
        anyo: item.rcp_anyo || '',
        equivalencia: item.rcp_equivalencia || '',
        anulada: item.rcp_anulada || false,
        vigente: item.rcp_vigente === false ? false : true,
        rcp_idiomas: item.rcp_idiomas || [],
        version: item.version || '',
        codigo_para_codificacion_dapcons: item.codigo_para_codificacion_dapcons || '',
    }

    return {
        ...data
    }
}

export const getRCPsAdapter = (data) => {

    
    const dataTemp = data?.data
    
    const items = dataTemp?.data?.map(item => (getRCPAdapterSchema(item)))
    
    console.log(items)

    return {
        data: [
            ...items
        ]
    }
}


export const postRCPAdapterSchema = (item = {}) => {

    const data = {
        // rcp_id: item.id || '',
        rcp_anyo: item.anyo || '',
        rcp_equivalencia: item.equivalencia || '',
        rcp_anulada: item.anulada || false,
        rcp_vigente: item.vigente || false,
        rcp_idioma: item.rcp_idiomas || [],
        version: item.version || '',
        codigo_para_codificacion_dapcons: item.codigo_para_codificacion_dapcons || '',
    }

    return data
}


export const postRCPAdapter = (form) => {

    const formAdapter = postRCPAdapterSchema(form)

    console.log(form, formAdapter)

    return formAdapter
}
