import React, { useMemo } from 'react';
import { Table, Accordion } from 'react-bootstrap';

export const ResumenDapcons = ({ fetchResumenData }) => {
    const {
        total = 0,
        renovado = 0,
        caducados = 0,
        publicados = 0,
        total_es = 0,
        total_en = 0,
        proceso = 0
    } = fetchResumenData?.data?.data?.data || {};

    const altas = total - renovado;

    const resumenDAPcons = useMemo(() => [
        {
            label: 'Dapcons total',
            value: `${total} (${altas} altas y ${renovado} renovaciones, ${caducados} caducadas)`
        },
        {
            label: 'Dapcons publicados',
            value: `${publicados} (Español: ${total_es}, Inglés: ${total_en})`
        },
        {
            label: 'Dapcons en proceso',
            value: `${proceso}`
        }
    ], [total, altas, renovado, caducados, publicados, total_es, total_en, proceso]);

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-12">
                <Accordion defaultActiveKey={null} className="mb-3">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Resumen de DAPcons</Accordion.Header>
                        <Accordion.Body>
                            <Table striped className="tableMain">
                                <tbody>
                                    {resumenDAPcons.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ width: '30%' }}><strong>{item.label}</strong></td>
                                            <td>{item.value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
};
