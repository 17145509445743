import { useContext } from 'react'
import { AuthContext } from '../auth/AuthContext';

let language = ''

export const useLang = () => {

    const { user: { lang } } = useContext(AuthContext)

    const Lang = (text) => {
    
        if (lang) {
    
            if (language[lang]) {
    
                return language[lang][text]
    
            } else {
    
                language = require(`./${lang}`).default;
            }
    
            return language[lang][text]
        }
    
    }

    return Lang;
}
