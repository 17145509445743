import React from 'react'
import { imagesFlags } from '../../helpers/imagesFlags'
import './IconIdioma.css'

const IconIdioma = ({className, idioma_id}) => {
  return (
    <img className={`IconIdioma ${className ? className : ''}`} src={imagesFlags(`./${idioma_id}.png`)} alt="" />
  )
}

export default IconIdioma