import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../../../auth/AuthContext';
import { useAxios } from '../../../../hooks/useAxios';
import { Loader } from '../../../../components/Loader/Loader';
import { useLocation } from 'react-router-dom';
import { DAPconConsultor } from './DAPconConsultor';
import { DAPconVerificador } from './DAPconVerificador';
import { GetParametroJSON } from '../../../../services/parametro.service';
import { isAdmin, isModuleAdministrador } from '../../../../helpers/helpers';

export const DAPconConsultorVerificador = ({ match, namePage, nameDAPcon, setVistaConsultorAdmin}) => {

    const location = useLocation()

    const { user } = useContext(AuthContext);

    const [fetchDAPconsValores, fetchDAPconsValoresData] = useAxios('get', `/dapcons/${match.params?.id}/dapcons-valores`)

    const [idiomaDAPcon, setIdiomaDAPcon] = useState(Number(match.params?.idioma_id))

    useEffect(() => {
        fetchDAPconsValores({
            params: {
                idioma_id: idiomaDAPcon
            }
        })
    }, [idiomaDAPcon])

    const [datBasic, setDatBasic] = useState(null)

    useEffect(() => {
        if (fetchDAPconsValoresData.data) {

            const formDAPconTemp = fetchDAPconsValoresData.data?.data?.data

            if (formDAPconTemp) {

                setDatBasic({
                    bloqueado: formDAPconTemp.bloqueado,
                    verificador: formDAPconTemp.verificador,
                    consultor: formDAPconTemp.consultor,
                    dapcons_codigo: formDAPconTemp.dapcons_codigo,
                    all_idiomas: formDAPconTemp.idiomas,
                    estadoDAPconsGlobal: formDAPconTemp.dapcons_estado_param_id,
                    estadoDAPcons: formDAPconTemp.idiomas?.find(item => item.idioma_id === idiomaDAPcon)?.dapcons_idioma_estado,
                    dv_nombre_producto: formDAPconTemp.dapcons_valores?.[0]?.dv_nombre_producto
                })

            } else {

                history.push('/DAPcons')
            }
        }

        if (fetchDAPconsValoresData.error) {
            history.push('/DAPcons')
        }
    }, [fetchDAPconsValoresData])

    const history = useHistory();

    // Parametros de estado para toda la app

    // const [fetchEstadosDAPcons, fetchEstadosDAPconsData] = useAxios('get', `/parametros/estados_dapcons?arbol=true&idioma=1`)

    // useEffect(() => {

    //     fetchEstadosDAPcons();

    // }, [])

    // const [listEstados, setListEstados] = useState([])

    // useEffect(() => {

    //     if (fetchEstadosDAPconsData.data?.status === 200) {
    //         const estados_dapcons = fetchEstadosDAPconsData?.data?.data?.data?.estados_dapcons?.parametros
    //         console.log(estados_dapcons)

    //         const listTemp = estados_dapcons.map(item => {
    //             return {
    //                 id: item.param_id,
    //                 nombre: `${item.param_textos?.[0]?.pt_texto}`
    //             }
    //         })
    //         setListEstados(listTemp.filter(item => item))
    //     }

    // }, [fetchEstadosDAPconsData])

    // Parametro ID

    const [fetchEstadosDAPcons, fetchEstadosDAPconsData] = GetParametroJSON('estados_dapcons')

    useEffect(() => {

        fetchEstadosDAPcons();

    }, [])
    

    return (
        <>
            {
                fetchDAPconsValoresData.loading || !fetchDAPconsValoresData.data?.data?.data || !datBasic ?
                    <div className="py-5">
                        <Loader />
                    </div> : <>
                    {
                        ( (isModuleAdministrador(user?.modulo_app) || datBasic?.consultor?.consultor_nif === user?.user?.usuario) && (datBasic?.estadoDAPcons === 'borrador' || datBasic?.estadoDAPcons === 'incidencias')) &&
                            <DAPconConsultor 
                                idDapcon={match.params?.id}
                                idiomaDAPcon={idiomaDAPcon}
                                setIdiomaDAPcon={setIdiomaDAPcon}
                                bloqueado={datBasic?.bloqueado}
                                fetchDAPconsValores={fetchDAPconsValores}
                                // listEstados={listEstados}
                                fetchEstadosDAPconsData={fetchEstadosDAPconsData}
                                setVistaConsultorAdmin={setVistaConsultorAdmin}
                            />
                    }
                    {
                        (datBasic?.verificador?.consultor_nif === user?.user?.usuario ||
                            (datBasic?.estadoDAPcons !== 'borrador' && datBasic?.estadoDAPcons !== 'incidencias')) &&
                            <DAPconVerificador 
                                idDapcon={match.params?.id}
                                setIdiomaDAPcon={setIdiomaDAPcon}
                                idiomaDAPcon={idiomaDAPcon}
                                bloqueado={datBasic?.bloqueado}
                                // listEstados={listEstados}
                                fetchEstadosDAPconsData={fetchEstadosDAPconsData}
                                setVistaConsultorAdmin={setVistaConsultorAdmin}
                            />
                    }
                    </>
            }
        </>
    )
}
