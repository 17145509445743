export function getLicense() {

    switch (process.env.REACT_APP_ENVIRONMENT) {

        case 'production':

            return {
                "format": 2,
                "allowedDomains": [
                    "*.apabcn.cat",
                    "*.enginyersbcn.cat",
                    "*.dapcons.com",
                    "*.libroedificiodigital.es",
                    "*.enginyersgp.cat",
                    "*.cetill.cat",
                    "*.arescoop.es",
                    "*.cateb.cat",
                    "*.enginyerslleida.cat"
                ],
                "homologDomains": [
                    "ip4:10.0.0.0/8",
                    "ip4:127.0.0.0/8",
                    "ip4:172.16.0.0/12",
                    "ip4:192.168.0.0/16"
                ],
                "productLevel": "Pro",
                "expiration": null,
                "signature": "yO/51htqj8XbE7EC4TD11uIIC3sMaNGQRksjIb9BPRckhlNPttKLOWmM9Y43P8U1wR9HQXGNK3XE65E296B6EGag8tsAxCcm3qfPmELd6/0aluogqKw+qHumbklCC+0owLnDnaZPZ5k5RCHqYK/clCRMCSZbrw8Aq4ifnhZyA/uQBaTR1wyjUfk9bh3rnsXJS/yRIITMA5vJYAPXsomMCYqsddDdc7APef/6oQMDzHyUKwzZu8CJaxiyKfiy+bwZovRfrKZaNObhdDZ5L7DJKdhRuxzKW4a7a+Po6aoPE8Uu2W5bjbN646hsJHseYm8sjc1aKKslWaCkLQnQ2f+cyA=="
            };
            
        default:

        return {
            "format": 2,
            "allowedDomains": [
                "*.apabcn.cat",
                "*.enginyersbcn.cat",
                "*.dapcons.com",
                "*.libroedificiodigital.es",
                "*.enginyersgp.cat",
                "*.cetill.cat",
                "*.arescoop.es",
                "*.cateb.cat",
                "*.enginyerslleida.cat"
            ],
            "homologDomains": [
                "ip4:10.0.0.0/8",
                "ip4:127.0.0.0/8",
                "ip4:172.16.0.0/12",
                "ip4:192.168.0.0/16"
            ],
            "productLevel": "Pro",
            "expiration": null,
            "signature": "yO/51htqj8XbE7EC4TD11uIIC3sMaNGQRksjIb9BPRckhlNPttKLOWmM9Y43P8U1wR9HQXGNK3XE65E296B6EGag8tsAxCcm3qfPmELd6/0aluogqKw+qHumbklCC+0owLnDnaZPZ5k5RCHqYK/clCRMCSZbrw8Aq4ifnhZyA/uQBaTR1wyjUfk9bh3rnsXJS/yRIITMA5vJYAPXsomMCYqsddDdc7APef/6oQMDzHyUKwzZu8CJaxiyKfiy+bwZovRfrKZaNObhdDZ5L7DJKdhRuxzKW4a7a+Po6aoPE8Uu2W5bjbN646hsJHseYm8sjc1aKKslWaCkLQnQ2f+cyA=="
        };

    }
}