import { faFile, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ListGroup } from 'react-bootstrap'

export const FileList = ({ eventClick, eventDelete, name, isLoading }) => {
    return (
        <div className='ListFileItem d-flex position-relative w-100'>
            <ListGroup.Item action onClick={eventClick}>
                <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faFile} className='me-2 faFile' />
                    <span>{name}</span>
                    {
                        isLoading && <div class="spinner-border spinner-border-sm ms-auto me-2" role="status"><span class="visually-hidden">Loading...</span></div>
                    }
                </div>
            </ListGroup.Item>

            {
                eventDelete && <FontAwesomeIcon onClick={eventDelete} icon={faTimes} className='cursor-pointer faTimes' />
            }
        </div>

    )
}
