import React, { useEffect } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { getIncidenciasAdapterSchema } from '../../adapters/incidencia.adapter'
import { useForm } from '../../hooks/useForm'

import { GetIncidenciasJSON } from '../../services/incidencia.service'
import { Loader } from '../Loader/Loader'
import { PaginationT } from '../PaginationT/PaginationT'
import { Incidencia } from './Incidencia'

export const ModalVerIncidencias = ({ showModalVerIncidencias, handleCloseModalVerIncidencias, idiomaDAPcon, 
    fetchIncidencias, fetchIncidenciasData, dapcons_id, perfil = '' }) => {

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        page: 1,
    });

    useEffect(() => {

        fetchIncidencias({
            params: {
                idioma_id: idiomaDAPcon,
                page: formValuesSearch.page,
                dapcons_id,
                esta_resuelta: false,
                perfil
            }
        })

    }, [formValuesSearch.page])

    useEffect(() => {
        if (showModalVerIncidencias) {
            fetchIncidencias({
                params: {
                    idioma_id: idiomaDAPcon,
                    page: formValuesSearch.page,
                    dapcons_id,
                    esta_resuelta: false,
                    perfil
                }
            })
        }
    }, [showModalVerIncidencias])

    return (
        <>
            <Modal show={showModalVerIncidencias} onHide={handleCloseModalVerIncidencias} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Incidencias</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-4'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <Table hover bordered responsive className='tableMain'>
                                    <thead>
                                        <tr>
                                            <th className='text-nowrap'>Descripción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fetchIncidenciasData.loading ?
                                                <tr>
                                                    <td className='text-center' colSpan={5}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                                :
                                                fetchIncidenciasData.data?.total > 0 ? <>
                                                    {
                                                        fetchIncidenciasData.data?.items.map(item => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <Incidencia item={item} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </> : <tr>
                                                    <td className='py-5 text-center' colSpan={5}>
                                                        <h5>No hay seguimiento de este DAPcons</h5>
                                                    </td>
                                                </tr>
                                        }


                                    </tbody>
                                </Table>
                                <PaginationT
                                    total={fetchIncidenciasData.data?.total}
                                    handleFormChange={handleFormChange}
                                    formValuesSearch={formValuesSearch}
                                    perPage={fetchIncidenciasData.data?.per_page}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
