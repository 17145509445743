import { useAxios2 } from "../hooks/useAxios2";
import SchemaBase from '../adapters/tarifa.adapter.js'

const GetAll = () => {
    return useAxios2('get', '/tarifas', 'json', {
        receiveAdapter: SchemaBase.adaterPaginate
    });
};

const Get = () => {
    return useAxios2('get', '/tarifas', 'json', SchemaBase.adaterGeneral, SchemaBase.schema);
};

const Create = () => {
    return useAxios2('post', '/tarifas', 'json', SchemaBase.adaterGeneral, SchemaBase.schema);
};

const Update = () => {
    return useAxios2('patch', `/tarifas`, 'json', {
        receiveAdapter: SchemaBase.adaterGeneral,
        sendAdapter: SchemaBase.schema
    });
};

const Remove = () => {
    return useAxios2('delete', `/tarifas`, 'json', {
        receiveAdapter: SchemaBase.adaterGeneral,
    });
};

const TarifaService = {
  GetAll,
  Get,
  Create,
  Update,
  Remove,
};
export default TarifaService;