import React, {useEffect, useState} from 'react'
import { Breadcrumb, ListGroup } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { useForm } from '../../hooks/useForm'
import { faHome, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TarifasFilter } from './TarifasFilter/TarifasFilter'
import { TarifasMain } from './TarifasMain/TarifasMain'
import queryString from 'query-string';
import { getURLQuery, getValuesParams } from '../../helpers/helpers'
import Service from '../../services/tarifa.service'

export const Tarifas = () => {
	const location = useLocation()
    const [fetchTarifas, fetchTarifasData] = Service.GetAll();

	const {
        magic: magic = '',
        page: page = 1,
        tipo: tipo = 'cuota_anual'
    } = queryString.parse(location.search);

    const [activeLink, setactiveLink] = useState(tipo)

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        magic: magic,
        page: page,
        tipo: tipo
    });

    const menuTarifa = [
        {
            name: '1. Cuota anual',
            id: 'cuota_anual',
            children: [
                {
                    id: 'empresas',
                    name: '1.1. Empresas',
                    children: [
                        {
                            id: 'ge_250',
                            name: '1344 - Gran empresa > 250 trabajadores'
                        },
                        {
                            id: 'emp_50_250',
                            name: 'Empresa de 50 a 250 trabajadores'
                        },
                        {
                            id: 'pme_50',
                            name: 'Pequeña y mediana empresa (50 trabajadores)'
                        }
                    ]
                },
                {
                    id: 'asociaciones',
                    name: '1.2. Asociaciones',
                    children: [
                        {
                            id: 'socios_5',
                            name: 'Hasta 5 socios'
                        },
                        {
                            id: 'socios_superior_5',
                            name: 'Superior a 5 socios e'
                        },
                    ]
                },
            ]
        },
        {
            name: '2. Dapcons',
            id: 'dapcons'
        },
        {
            name: '3. Renovación',
            id: 'dapcons_renovacion'
        },
        {
            name: '4. Suplemento Idioma',
            id: 'sup_dapcons_idioma'
        }
    ];

    const handleSearch = (e) => {
        if (e) {
            e.preventDefault();
        }
        const urlParams = getValuesParams({...formValuesSearch, tipo: activeLink})
        const query = getURLQuery(urlParams)
        window.history.replaceState(null, null, `?${query}`);

        if (formValuesSearch.page !== 1) {
            handleFormChange({
                ...formValuesSearch,
                page: 1
            });
        } else {
            let formValuesSearchTemp = { ...formValuesSearch }
            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);
            fetchTarifas({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    }

    useEffect(() => {
        const urlParams = getValuesParams({...formValuesSearch, tipo: activeLink})
        const query = getURLQuery(urlParams)
        window.history.replaceState(null, null, `?${query}`);
        fetchTarifas({
            params: {
                ...urlParams
            }
        })
    }, [activeLink, formValuesSearch.page, formValuesSearch.orderby])

    const [openItems, setOpenItems] = useState([]);

  const handleToggle = (index) => {
    setOpenItems((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpand = (itemId) => {
    setExpandedItems((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const renderItems = (items) => {
    return items.map((item) => (
      <div key={item.id}>
        <ListGroup.Item
          action
          onClick={() => {
            if (item.id) {
              setactiveLink(item.id);
              toggleExpand(item.id);
            }
          }}
          className={`${item.id === activeLink ? 'active' : ''}`}
          style={{ cursor: item.id ? 'pointer' : 'not-allowed' }}
        >
          {item.children && (
            expandedItems[item.id] ? <FontAwesomeIcon className="me-2 " icon={faChevronDown} /> :  <FontAwesomeIcon className="me-2" icon={faChevronRight} />
          )}   
          {item.name}
        </ListGroup.Item>
        {item.children && expandedItems[item.id] && (
          <ListGroup className="ps-4">
            {renderItems(item.children)}
          </ListGroup>
        )}
      </div>
    ));
  };

  return(
		<div className="container-inner row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                   Tarifas
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className='col-xl-3'>
                <div className='Column-small shadow-sm sticky-top listgroup-plantillas'>
                    <h5>Tipo de Tarifas</h5>
                    <ListGroup>{renderItems(menuTarifa)}</ListGroup>
                    {/*
                    <ListGroup variant="flush" className='mt-3'>
                        {menuTarifa.map((item, index) => {
                            return <ListGroup.Item key={index} className={`${item.id === activeLink ? 'active' : ''}`} onClick={() => setactiveLink(item.id)}>{index + 1}. {item.name}</ListGroup.Item>
                        })}
                    </ListGroup>
                    */}
                </div>
              <TarifasFilter
                handleSearch={handleSearch}
                handleInputChangeSearch={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}/>
            </div>
            <TarifasMain
                fetchTarifasData={fetchTarifasData}
                fetchTarifas={handleSearch}
                formValuesSearch={formValuesSearch}
                namePage={'Tarifas panel'}
                handleFormChange={handleFormChange}
                activeLink={activeLink}
            />
        </div>
	)
}