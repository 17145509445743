export const contenidosAdapterSchema = (item = {}) => {
    const data = {
        id: item.id || '',
        titulo: item.titulo || '',
        descripcion_corta: item.descripcion_corta || '',
        descripcion_larga: item.descripcion_larga || '',
        tipo: item.tipo || '',
        clasificacion: item.clasificacion || '',
        imagen: item.imagen || '',
        fecha_publicacion: item.fecha_publicacion || '',
        fecha_fin_publicacion: item.fecha_fin_publicacion || '',
        estado: item.estado || '',
        perfil: item.perfil  || [],
        ruta: item.ruta || '',
        tipo_noticia_texto: item.tipo_noticia_texto || '',
        total_archivos: item.total_archivos || 0,
    }  

    return {
        ...data,
    }
}

export const contenidosAdapter = (data) => {
    const dataTemp = data?.data.data
    const items = dataTemp?.data?.map(item => (contenidosAdapterSchema(item)))
    return {
        data: {
            total: dataTemp?.total,
            per_page: dataTemp?.per_page,
            items
        },
        status: data?.status
    }
}

export const postContenidoAdapterSchema = (item = {}) => {
    const data = {
        id: item.id || '',
        titulo: item.titulo || '',
        descripcion_corta: item.descripcion_corta || '',
        descripcion_larga: item.descripcion_larga || '',
        tipo: item.tipo || '',
        clasificacion: item.clasificacion || '',
        imagen: item.imagen || '',
        fecha_publicacion: item.fecha_publicacion || '',
        fecha_fin_publicacion: item.fecha_fin_publicacion || '',
        estado: item.estado || '',
        perfil: item.perfil  || []
    }
    return data
}

export const patchContenidoAdapterSchema = (item = {}) => {
    const data = {
        id: item.id || '',
        titulo: item.titulo || '',
        descripcion_corta: item.descripcion_corta || '',
        descripcion_larga: item.descripcion_larga || '',
        tipo: item.tipo || '',
        clasificacion: item.clasificacion || '',
        imagen: item.imagen || '',
        fecha_publicacion: item.fecha_publicacion || '',
        fecha_fin_publicacion: item.fecha_fin_publicacion || '',
        estado: item.estado || '',
        perfil: item.perfil  || [],
        _method: 'PATCH'
    }
    return data
}

export const getContenidoAdapter = (data) => {
    const dataTemp = data?.data?.data
    const item = contenidosAdapterSchema(dataTemp)
    return {
        data: item,
        status: data?.status
    }
}


export const postContenidoMultipar = (form) => {
    const formAdapter = postContenidoAdapterSchema(form)
    let formData = new FormData();
    for (const key in formAdapter) {
        formData.append(key, formAdapter[key])
    }
    return formData
}

export const patchContenidoMultipar = (form) => {
    const formAdapter = patchContenidoAdapterSchema(form)
    let formData = new FormData();
    for (const key in formAdapter) {
        formData.append(key, formAdapter[key])
    }
    return formData
}
