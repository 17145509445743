import React from 'react'

import { ListGroup } from 'react-bootstrap'
import './ListMenu.css'

export const ListMenu = ({ values, iDactive, setiDactive }) => {
    return (
        <ListGroup>
            {
                Object.keys(values).map((key) => {
                    return (
                        <ListGroup.Item className='cursor-pointer list-group-item' key={key} active={key === iDactive} onClick={() => setiDactive(key)}>{values[key].title}</ListGroup.Item>
                    )
                })
            }
        </ListGroup>
    )
}
