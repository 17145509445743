import React, { createRef, useRef, useState } from 'react'
import { faExchangeAlt, faInf, faInfoCircle, faInfoCircleoCirclefaInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Overlay, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap'
import './Tablas.css'
import { useEffect } from 'react'
import AutosizeInput from 'react-input-autosize';
import { breakExistA1A3, clipboardMatriz5y6 } from '../../helpers/helpers'
import { cloneDeep } from 'lodash'


export const Table5 = ({ matriz, setMatriz, handleClickMergeA1A3, exista1a3, impactoAmbiental, bloqueado }) => {

    // const [matriz, setMatriz] = useState(serie)

    const handleOnKeyUp = (e) => {

        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const handleOnKeyDown = async (e, fila, columna) => {
        if (e.key === 'Enter') {

            e.preventDefault();
        }

        if ((e.ctrlKey || e.metaKey) && e.keyCode === 86) {

            e.preventDefault();

            const clipboard = await navigator.clipboard.readText()
            console.log('clipboard', clipboard);

            if (clipboard && clipboard.length > 0) {

                let clipboardArray = []

                console.log(clipboard.split('\n'), clipboard.split('\t'))

                if ((clipboard.split('\n')).length === 1 && (clipboard.split('\t')).length === 1) {

                    clipboardArray[0] = [clipboard]
                    // console.log('clipboardString', clipboardArray)


                } else {
                    clipboardArray = clipboard.split('\n').map(function (ln) {
                        return ln.split('\t');
                    });
                    // console.log('clipboardArrayPre', clipboardArray)

                    clipboardArray.length = clipboardArray.length - 1
                }

                console.log('clipboardArray', clipboardArray)

                const matrizRes = clipboardMatriz5y6(clipboardArray, matriz, fila, columna, exista1a3)

                setMatriz(matrizRes)
            }
        }
    }

    const handleChange = (value, fila, columna) => {
        let matrizTemp = { ...matriz }
        matrizTemp[fila][columna] = value
        setMatriz(matrizTemp)
    };

    // useEffect(() => {

    //     console.log(matriz)

    // }, [matriz])

    let regex = /^[+-]?\d+(?:\.\d*(?:[eE][+-]?\d+)?)?$/


    return (
        <div>
            <div className='caption text-center d-block' htmlFor='prueba12'>Tabla Parámetros de impacto ambiental</div>
            <Table responsive id='Table5' className='DAPconTable w-auto mx-auto mb-3'>
                <thead className='text-center'>
                    <tr>
                        <th rowSpan="3">Parámetro</th>
                        <th rowSpan="3">Unidad</th>
                        <th colSpan={`${exista1a3 ? '14' : '16'}`}>Etapa del ciclo de vida</th>
                        <th rowSpan="3">Módulo D</th>
                    </tr>
                    <tr>
                        <th colSpan={`${exista1a3 ? '1' : '3'}`} className='position-relative'>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{exista1a3 ? 'Expandir A1 - A3' : 'Combinar A1 - A3'}</Tooltip>} placement='bottom'>
                                <Button variant="light" size='' className={`px-3 ms-3 d-inline-block ${exista1a3 ? 'Table5-btn-resizeActive' : ''}`}
                                    onClick={handleClickMergeA1A3}
                                    id='Table5-btn-resize'
                                    disabled={bloqueado}
                                >
                                    <FontAwesomeIcon icon={faExchangeAlt} className='' />

                                </Button>
                            </OverlayTrigger>
                            <span>Fabricación</span>
                        </th>
                        <th colSpan="2">Construcción</th>
                        <th colSpan="7">Uso</th>
                        <th colSpan="4">Fin de vida</th>
                    </tr>
                    <tr>
                        {
                            exista1a3 ? <>
                                <th>A1 -A3</th>
                            </> : <>
                                <th>A1</th>
                                <th>A2</th>
                                <th>A3</th>
                            </>
                        }

                        <th>A4</th>
                        <th>A5</th>
                        <th>B1</th>
                        <th>B2</th>
                        <th>B3</th>
                        <th>B4</th>
                        <th>B5</th>
                        <th>B6</th>
                        <th>B7</th>
                        <th>C1</th>
                        <th>C2</th>
                        <th>C3</th>
                        <th>C4</th>
                    </tr>
                </thead>

                {
                    Object.keys(matriz).length > 0 && impactoAmbiental &&
                    <tbody>
                        {
                            Object.keys(impactoAmbiental).map(key => {

                                // console.log(impactoAmbiental)

                                return (
                                    <>
                                        {
                                            Object.keys(impactoAmbiental)[Object.keys(impactoAmbiental).length - 1] === key &&
                                            <tr className='line-white'>
                                                <td></td>
                                            </tr>
                                        }
                                        <tr>
                                            <td valign='middle'>
                                                <strong>{impactoAmbiental[key]?.pt_texto}</strong>
                                            </td>
                                            <td valign='middle' className='text-center'>
                                                <span>{impactoAmbiental[key]?.pt_texto2}</span>
                                            </td>
                                            {
                                                Object.keys(matriz[key]).map(key2 => {
                                                    // console.log(key2)
                                                    if (breakExistA1A3(exista1a3, key2)) return null
                                                    return (
                                                        <>
                                                            {
                                                                (matriz[key][key2] === 'MND' || bloqueado) ?
                                                                    <td valign='middle' key={'1' + key2 + 1} className='disabledCursorInput'>
                                                                        <div className="container-form-control">
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="form-field-name"
                                                                                tabIndex={-1}
                                                                                value={matriz[key][key2]}
                                                                                onChange={() => null}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    :
                                                                    <td valign='middle' key={'1' + key2 + 1}>
                                                                        <AutosizeInput
                                                                            name="form-field-name"
                                                                            className={`container-form-control ${(regex.test(matriz[key][key2]) ||  !matriz[key][key2]) ? '' : 'container-input-danger'}`}
                                                                            value={matriz[key][key2]}
                                                                            onChange={
                                                                                (e) => {
                                                                                    if (e.target.value !== 'MND') {
                                                                                        handleChange(e.target.value, key, key2)
                                                                                    } else {
                                                                                        handleChange('', key, key2)
                                                                                    }
                                                                                }
                                                                            }
                                                                            onKeyUp={handleOnKeyUp}
                                                                            onKeyDown={
                                                                                (e) => {
                                                                                    handleOnKeyDown(e, key, key2)
                                                                                }
                                                                            }
                                                                        />
                                                                    </td>
                                                            }
                                                        </>

                                                    )
                                                })
                                            }
                                        </tr>
                                    </>

                                )
                            })
                        }

                    </tbody>
                }
            </Table>
        </div>
    )
}
