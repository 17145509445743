import React, { useContext, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faSort, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../components/Loader/Loader';
import { PaginationT } from '../../../components/PaginationT/PaginationT';

import { useLang } from '../../../language/index';
import { useHistory } from 'react-router-dom';
import ButtonOrderBy from '../../../components/ButtonOrderBy/ButtonOrderBy';
import { useAxios } from '../../../hooks/useAxios';
import { AuthContext } from '../../../auth/AuthContext';
import { isModuleOrganizacion } from '../../../helpers/helpers';
import { useModalData } from '../../../hooks/useModalData';
import { ModalCrearUsuario } from './ModalCrearUsuario';

export const UsuariosMain = ({ fetchUsuariosData, formValuesSearch, namePage = 'default', handleFormChange, 
modulo_app, estaVerificando, fetchUsuarios }) => {

    console.log('fetchUsuariosData', fetchUsuariosData)
    const history = useHistory();

    const Lang = useLang();

    // Crear Consultores basico

    const [dataModal_Usuario, handleOpenModal_Usuario, handleCloseModal_Usuario] = useModalData()

    const [showModalCrearUsuario, setShowModalCrearUsuario] = useState(false);

    const handleCloseModalCrearUsuario = () => setShowModalCrearUsuario(false)
    const handleShowModalCrearUsuario = () => setShowModalCrearUsuario(true);

    useEffect(() => {
        // fetchRCP()
        // fetchVerificadores()
        // fetchIdiomas()
    }, [])

    return (
        <>
            <div className="col">
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto">

                    <div className="d-flex justify-content-between mb-3 pb-2 flex-wrap">
                        <div>
                            <h5 className='mb-3 mb-lg-0'>{Lang('LISTA_DE')} {namePage}</h5>
                        </div>
                        <div className='d-flex justify-content-end ms-auto'>
                            <Button variant="primary" className="px-3 ms-3 d-flex align-items-center"
                                onClick={() => {
                                    handleOpenModal_Usuario({
                                        action: 'CREATE'
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faFile} className='me-2' /> Alta <span className=''>&nbsp;Usuario</span>
                            </Button>
                        </div>
                    </div>

                    {
                        fetchUsuariosData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    fetchUsuariosData?.data?.total ?

                                        <>
                                            <Table hover bordered responsive className='tableMain'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-nowrap'>NIF</th>
                                                        <th className='text-nowrap'>Nombre</th>
                                                        <th className='text-nowrap text-center'>Teléfono</th>
                                                        {/* <th className='text-nowrap text-center'>Es verificador</th> */}
                                                        {/* <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={Lang('FECHA_ESTADO')}
                                                                id='fecha_estado'
                                                            />
                                                        </th> */}
                                                        <th className='text-center'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fetchUsuariosData?.data?.items?.map((item) => {
                                                        // console.log(`/consultores/${item.id}`)
                                                        return (
                                                            <tr key={item.id}>
                                                                <td valign='middle'>{item.usuario}</td>
                                                                <td valign='middle'>{item.nombre} {item.apellidos}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{item.telefono}</td>
                                                                {/* <td valign='middle' className='text-nowrap text-center'>{item.es_verificador}</td> */}
                                                                <td valign='middle' className='text-center'>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <OverlayTrigger
                                                                                        key={item.id + '1'}
                                                                                        placement={'top'}
                                                                                        overlay={
                                                                                            <Tooltip id={`tooltip-${item.id}`}>
                                                                                                {'Editar usuario'}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <Button variant="transparent" size="sm" className='btn-modalVermas'
                                                                                            onClick={() =>{
                                                                                                 handleOpenModal_Usuario({
                                                                                                    action: 'UPDATE',
                                                                                                    id: item.id
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchUsuariosData?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchUsuariosData?.data?.per_page}
                                            />
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No se ha encontrado resultados</h5>
                                }
                            </>
                    }
                </div>
            </div>

            <ModalCrearUsuario
                data={dataModal_Usuario}
                handleCloseModal={handleCloseModal_Usuario}
                //showModalCrearUsuario={showModalCrearUsuario}
                //handleCloseModalCrearUsuario={handleCloseModalCrearUsuario}
                fetchUsuarios={fetchUsuarios}
            />
        </>
    )
}
