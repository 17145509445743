import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch, faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useRef } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table, InputGroup } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { Loader } from '../../../components/Loader/Loader';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputSelect, InputText, InputFile } from '../../../components/Inputs';
import { InputRadios } from '../../../components/Inputs/InputRadios';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { MsgInput } from '../../../components/MsgInput/MsgInput';
import { postDocumentoMultipar, postDocumentoAdapterSchema } from '../../../adapters/documento.adapter';
import { PostDocumentoJSON, GetDocumentoJson, PatchDocumentoJson, DeleteDocumentoJson } from '../../../services/documento.service';
import { useModalData } from '../../../hooks/useModalData';
import {ModalCategoria} from './ModalCategoria'

// import './ProcessosMain.css'

export const ModalDocumento = ({ data, handleCloseModal, fetchDocumentos }) => {

    const history = useHistory();

     const [dataModal_Categoria, handleOpenModal_Categoria, handleCloseModal_Categoria] = useModalData()

    const inputFile = useRef(null)

    const [fetchCrearDocumento, fetchCrearDocumentoData] = PostDocumentoJSON()

    const [fetchGetDocumento, fetchGetDocumentoData] = GetDocumentoJson()

    const [fetchPatchDocumento, fetchPatchDocumentoData] = PatchDocumentoJson()

    const [fetchDeleteDocumento, fetchDeleteDocumentoData] = DeleteDocumentoJson()

    
    const [fetchParameters, fetchParametersData] = useAxios('GET', `/parametros-select`)

    const [formDocumento, 
        handleInputChangeDocumento, 
        handleFormChangeDocumento, 
        handleResetDocumento] = useForm(postDocumentoAdapterSchema())

    const [valuesValidDocumento, handleVerifiedValuesDocumento, handleResetValuesDocumento] = useValidator({
        titulo: { isOk: true, msgError: [`Ingrese un titúlo`] },
        tipo: { isOk: true, msgError: [`Seleccione un típo.`] },
        descripcion: { isOk: true, msgError: [`Ingrese una descripción`] },
        tipo_documento: { isOk: true, msgError: [`Seleccione un típo de documento.`] },
        estado: { isOk: true, msgError: [`Seleccione un estado`] },
    })

    const handleSubmitDocumento = () => {
        if (handleVerifiedValuesDocumento(formDocumento)) {
            fetchCrearDocumento({
                body: {
                    ...formDocumento,
                    archivo: inputFile.current?.files?.[0] || ''
                }
            })
        }
    }

    const handleSubmitUpdateDocumento = () => {
        if (handleVerifiedValuesDocumento(formDocumento)) {
            fetchPatchDocumento({
                id: data?.id,
                body: {
                    ...formDocumento,
                    archivo: inputFile.current?.files?.[0] || '',
                    _method: 'PATCH'
                }
            })
        }
    }

    const handleDeleteDocumento = () => {
        if(window.confirm("Realmente desea borrar este documento ?")){
             fetchDeleteDocumento({
                id: data?.id,
            })
        }
    }

    useEffect(() => {
        if (fetchCrearDocumentoData?.status === 200) {
            fetchDocumentos()
            handleCloseModal()
        }

    }, [fetchCrearDocumentoData])

    useEffect(() => {
        if (fetchPatchDocumentoData?.status === 200) {
            fetchDocumentos()
            handleCloseModal()
        }

    }, [fetchPatchDocumentoData])

    useEffect(() => {
        if (fetchDeleteDocumentoData?.status === 200) {
            fetchDocumentos()
            handleCloseModal()
        }

    }, [fetchDeleteDocumentoData])

    useEffect(() => {
        if(data.value){
            fetchParameters({
                params: {
                    'idioma_id': 1,
                    'param_padre_id': 'clasificacion_documento',
                }
            });

            if (data?.id) {
                fetchGetDocumento({
                    id: data?.id
                })
            }

        }else{
            handleResetDocumento();
            handleResetValuesDocumento();
        }
    }, [data.value])

    const handleChange = (event) => {
        const { checked, value } = event.target;

        console.log(checked);

        if (checked) {
            handleFormChangeDocumento({
                ...formDocumento,
                perfil: [...formDocumento.perfil, parseInt(value)],
            });
        } else {
            const items = formDocumento.perfil.filter((item) => item !== parseInt(value));
            handleFormChangeDocumento({
                ...formDocumento,
                perfil: [...items],
            });
        }
    };

    useEffect(() => {
        if (fetchGetDocumentoData?.data) {
            const documento = fetchGetDocumentoData.data
            handleFormChangeDocumento({...documento})
        }
    }, [fetchGetDocumentoData.data])

    const handleChangeEsResaltado = (e) => {
        handleFormChangeDocumento({
            ...formDocumento,
            es_resaltado: e.target.checked ? true : false
        })
    }

    
    return (
        <>
        <Modal show={data.value} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>{data.action == 'CREATE' ? 'Nuevo Documento' : 'Editar Documento'}</Modal.Title>
            </Modal.Header>
            {fetchParametersData.loading || fetchGetDocumentoData.loading ? <Loader /> : <>
            <Modal.Body className='px-4 py-4'>
                <Form className='row'>

                    <Form.Group className="col-lg-6 mb-3">
                        <InputSelect
                            label='Tipo:'
                            name='tipo'
                            values={[
                                {
                                    id: 'documento', nombre: 'Documento'
                                },
                                {
                                    id: 'enlace', nombre: 'Enlace'
                                },
                            ]}
                            value={formDocumento.tipo}
                            onChange={handleInputChangeDocumento}
                            validation={valuesValidDocumento}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-lg-6">
                        <InputText
                            label='Título:'
                            className=''
                            name='titulo'
                            placeholder="Ingrese un título"
                            value={formDocumento.titulo}
                            onChange={handleInputChangeDocumento}
                            validation={valuesValidDocumento}
                            fetchData={fetchCrearDocumentoData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4 col-lg-12">
                        <InputText
                            label='Descripción:'
                            className=''
                            name='descripcion'
                            placeholder="Ingrese una descripción"
                            as="textarea" 
                            rows={3}
                            value={formDocumento.descripcion}
                            onChange={handleInputChangeDocumento}
                            validation={valuesValidDocumento}
                            fetchData={fetchCrearDocumentoData}
                        />
                    </Form.Group>

                    <Form.Group className="col-lg-6 mb-3" controlId="exampleForm.ControlInput2">
                    <Form.Label className='fw-500'>Tipo de documento:</Form.Label>
                        <InputGroup className="mb-3">
                        <InputSelect
                            label=''
                            name='tipo_documento'
                            values={
                                fetchParametersData.data?.data?.data?.map((item, index) => {
                                    return {
                                        id: item.pt_param_id,
                                        nombre: item.pt_texto
                                    }
                                })
                            }
                            value={formDocumento.tipo_documento}
                            onChange={handleInputChangeDocumento}
                            validation={valuesValidDocumento}
                        />
                        <Button 
                                variant="outline-success" 
                                id="button-addon1" 
                                onClick={
                                    () => handleOpenModal_Categoria({
                                        show: 'Create',
                                    })
                                }>
                              <FontAwesomeIcon icon={faPlusCircle} className='me-2' />
                            </Button>
                        </InputGroup>
                    </Form.Group>


                     <Form.Group className="col-lg-6 mb-3">
                        <InputSelect
                            label='Estado:'
                            name='estado'
                            values={[
                                {
                                    id: 'Publicado', nombre: 'Publicado'
                                },
                                {
                                    id: 'Despublicado', nombre: 'Despublicado'
                                },
                            ]}
                            value={formDocumento.estado}
                            onChange={handleInputChangeDocumento}
                            validation={valuesValidDocumento}
                        />
                    </Form.Group>

                    {formDocumento.tipo == 'documento' &&
                        <Form.Group className="mb-4 col-lg-12">
                            <InputFile
                                label='Archivo:'
                                className=''
                                referencia={inputFile}
                                name='archivo'
                                onChange={handleInputChangeDocumento}
                                fetchData={fetchCrearDocumentoData}
                            />
                        </Form.Group>
                    }

                    {formDocumento.tipo == 'enlace' &&
                        <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3"> 
                            <InputText
                                label='Enlace:'
                                className=''
                                name='link'
                                value={formDocumento.link}
                                onChange={handleInputChangeDocumento}
                                validation={valuesValidDocumento}
                                fetchData={fetchCrearDocumentoData}
                            />
                        </Form.Group>
                    }

                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3">
                        <Form.Label className="fw-500 form-label">Perfiles de usuario:</Form.Label>
                        <div className="col-12 mt-1">
                        {[
                            {
                                id: 3, nombre: 'Consultor'
                            },
                            {
                                id: 4, nombre: 'Verificador'
                            },
                            {
                                id: 5, nombre: 'Organización'
                            },
                        ].map((check, index) => ( 
                          <div className="d-inline-block me-4" key={index}> 
                              <Form.Check
                                    type="checkbox"
                                    id={`${check.id}`}
                                    label={check.nombre}
                                    value={check.id}
                                    checked={formDocumento.perfil.some((item) => item === check.id)}
                                    onChange={handleChange}
                                />
                            </div>
                         ))}    
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3 col-lg-6">
                        <Form.Label className='fw-500 mt-1 mb-2'>Resaltar Documento</Form.Label>
                        <Form.Check
                            type='checkbox'
                            id='recibir_notificacion'
                            label='En Inicio'
                            name='es_resaltado'
                            checked={formDocumento.es_resaltado}
                            onChange={handleChangeEsResaltado}
                        />
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                {data?.id ? 
                    <>
                    <ButtonSpinner
                        variant="warning" type="submit" className="ms-2"
                        handleSubmit={handleSubmitUpdateDocumento} fetchDataLoading={[fetchPatchDocumentoData.loading]}
                        value='Actualizar Documento' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                    />

                     <ButtonSpinner
                        variant="danger" type="submit" className="ms-2"
                        handleSubmit={handleDeleteDocumento} fetchDataLoading={[fetchDeleteDocumentoData.loading]}
                        value='Eliminar' icon={<FontAwesomeIcon icon={faTrash} className='me-1' />}
                    />
                    </>
                 : 
                    <ButtonSpinner
                        variant="info" type="submit" className="ms-2"
                        handleSubmit={handleSubmitDocumento} fetchDataLoading={[fetchCrearDocumentoData.loading]}
                        value='Crear Documento' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                    />
                }
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
            </>
         }
        </Modal>
        <ModalCategoria
        data={dataModal_Categoria}
        handleCloseModal={handleCloseModal_Categoria}
        formDocumento={formDocumento}
        handleFormChangeDocumento={handleFormChangeDocumento}
        fetchParameters={fetchParameters}
        />
        </>
    )
}
