import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'

import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { InputFile } from '../../../../components/Inputs';
import { useAxios } from '../../../../hooks/useAxios';
import { ModalStatusServer2 } from '../../../../components/ModalStatusServer/ModalStatusServer2';
import { isStatusSucess } from '../../../../helpers/helpers';
import IdiomasEcoplatform from '../../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { Loader } from '../../../../components/Loader/Loader';

// import './ProcessosMain.css'

export const ModalModificarIdiomas = ({ showModal__ModificarIdiomas, handleCloseModal__ModificarIdiomas, handleForm,
    form, id, fetchSuccess }) => {

    const [fetchIdiomas, fetchIdiomasData] = useAxios('get', `/idiomas`)

    // Preparando select de Idiomas

    const [listIdiomasECO, setListIdiomasECO] = useState([])

    useEffect(() => {

        if (fetchIdiomasData.data?.status === 200 && form.all_idiomas) {
            const listTemp = fetchIdiomasData?.data?.data?.data?.map(item => {
                return {
                    id: item.id,
                    nombre: `${item.idioma_nombre}`
                }
            })

            const idiomasECOTemp = listTemp.map(item => {
                return ({
                    id: item.id,
                    idioma: false,
                    nombre_idioma: item.nombre,
                    ecoplatform: false,
                })
            })

            for (let i = 0; i < idiomasECOTemp.length; i++) {

                const resTemp = form.all_idiomas?.find(item => item.id === idiomasECOTemp[i].id)

                if (resTemp) {
                    idiomasECOTemp[i].idioma = true
                    idiomasECOTemp[i].ecoplatform = resTemp.inscripcion_ecoplatform
                }

            }

            setListIdiomasECO(idiomasECOTemp)
        }

    }, [fetchIdiomasData, form.all_idiomas])

    const handleChangeCheckIdioma = (e, index) => {

        const listIdiomasECOTemp = [...listIdiomasECO]

        listIdiomasECOTemp[index]['idioma'] = e.target.checked ? true : false

        if (!e.target.checked) {
            listIdiomasECOTemp[index]['ecoplatform'] = false
        }

        setListIdiomasECO(listIdiomasECOTemp)
    }

    const handleChangeCheckECOplatform = (e, index) => {
        const listIdiomasECOTemp = [...listIdiomasECO]

        listIdiomasECOTemp[index]['ecoplatform'] = e.target.checked ? true : false

        setListIdiomasECO(listIdiomasECOTemp)
    }

    useEffect(() => {
        if (showModal__ModificarIdiomas.value) {

            fetchIdiomas()
        }
    }, [showModal__ModificarIdiomas.value]);

    useEffect(() => {

        console.log('form', form);

    }, [form]);

    useEffect(() => {

        console.log('listIdiomasECO', listIdiomasECO);

    }, [listIdiomasECO]);

    const [fetchDAPcons, fetchDAPconsData] = useAxios('patch', `/dapcons/${id}/update-by-admin`)

    const handleSubmitGuardarIdiomas = () => {

        let idiomasFinal = listIdiomasECO.filter(item => item.idioma === true)

        idiomasFinal = idiomasFinal.map(item => {
            return {
                id: String(item.id),
                ecoplatform: !!item.ecoplatform
            }
        })

        console.log('idiomasFinal', idiomasFinal);

        fetchDAPcons({
            body: {
                new_idiomas: idiomasFinal
            }
        })
    }

    useEffect(() => {
        console.log(fetchDAPconsData.data)
        if (isStatusSucess(fetchDAPconsData.data?.status)) {
            fetchSuccess()
            handleCloseModal__ModificarIdiomas()
        }

    }, [fetchDAPconsData])




    return (
        <>

            <Modal show={showModal__ModificarIdiomas.value} onHide={handleCloseModal__ModificarIdiomas}>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Modificar Idiomas</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-4'>

                    <Form.Group className="mb-3 col-12">
                        {
                            fetchIdiomasData.loading ?
                                <Loader /> :
                                <>
                                    <label className="form-label">Idiomas de publicación:</label>
                                    <IdiomasEcoplatform
                                        listIdiomasECO={listIdiomasECO}
                                        handleChangeCheckIdioma={handleChangeCheckIdioma}
                                        handleChangeCheckECOplatform={handleChangeCheckECOplatform}
                                    />
                                    <Alert variant='warning' className='mt-3 mb-0 py-2'>
                                        <small>
                                            Al desmarcar la inscripción ecoplatfom o el idioma, se eliminarán por completo del DAPcons
                                        </small>
                                    </Alert>
                                </>
                        }
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <Button variant="default" onClick={handleCloseModal__ModificarIdiomas}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleSubmitGuardarIdiomas} fetchDataLoading={[fetchDAPconsData.loading]}
                        value='Guardar'
                    />
                </Modal.Footer>
            </Modal>

            <ModalStatusServer2 fetchData={fetchDAPconsData} />

        </>
    )
}
