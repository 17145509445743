import React, { useContext, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faSort, faEdit, faDownload, faNewspaper, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { PaginationT } from '../../../components/PaginationT/PaginationT';
import { useLang } from '../../../language/index';
import Badge from 'react-bootstrap/Badge';
import { Loader } from '../../../components/Loader/Loader';
import { ModalArchivos } from './Archivos/ModalArchivos';
import {ModalEmail} from './Email/ModalEmail'
import { useModalData } from '../../../hooks/useModalData';

export const TableMain = ({fetchDocumentosData, formValuesSearch, handleFormChange, fetchDocumentos, setIndexFocus, setIsForm, setIdContenido}) => {
	 const Lang = useLang();
	 const [dataModal_Documento, handleOpenModal_Documento, handleCloseModal_Documento] = useModalData()
     const [dataModal_Archivo, handleOpenModal_Archivo, handleCloseModal_Archivo] = useModalData()
     const [dataModal_Email, handleOpenModal_Email, handleCloseModal_Email] = useModalData()
	 return (
        <>
        	<div className="d-flex justify-content-between mb-3 pb-2 flex-wrap">
                <div>
                    <h5 className='mb-3 mb-lg-0'>{Lang('LISTA_DE')} Contenidos</h5>
                </div>
                <div className='d-flex justify-content-end ms-auto'>
                    <Button variant="default" className="px-3 ms-3 d-flex align-items-center"
                        onClick={() => {
                            setIndexFocus(1)
                            setIsForm(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faNewspaper} className='me-2' /> <span className=''>&nbsp;Nuevo contenido</span>
                    </Button>
                </div>
            </div>
            {
                fetchDocumentosData?.loading ?
                    <Loader /> :
                    <>
                        {
                            fetchDocumentosData?.data?.total ?

                                <>
                                    <Table hover bordered responsive className='tableMain'>
                                        <thead>
                                            <tr className="text-center">
                                                <th className='text-nowrap'>Título</th>
                                                <th className='text-nowrap'>Tipo</th>
                                                <th className='text-nowrap text-center'>Estado</th>
                                                <th className=' text-nowrap text-center'>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fetchDocumentosData?.data?.items?.map((item) => {
                                                return (
                                                    <tr key={item.id} className="text-center">
                                                        <td valign='middle'>{item.titulo}</td>
                                                        {/*<td valign='middle'>{item.descripcion_corta}</td>*/}
                                                        <td valign='middle'>{item.tipo_noticia_texto}</td>
                                                        <td valign='middle' className='text-nowrap text-center'>
                                                        
                                                        <Badge bg={item.estado == 'Publicado' ? 'success' : 'warning'}>{item.estado}</Badge>
                                                        </td>
                
                                                        <td valign='middle' className='text-center'>

                                                             <Button variant="warning" size='sm' className='ms-3 my-0'
                                                                onClick={() => {
                                                                	setIndexFocus(1)
                            										setIsForm(true);
                            										setIdContenido(item.id);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Button>

                                                             <Button variant="success" size='sm' className='ms-3 my-0'
                                                                onClick={
                                                                    () => handleOpenModal_Archivo({
                                                                        id: item.id,
                                                                    })
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faFile} /> {item.total_archivos}
                                                            </Button>

                                                              <Button variant="info" size='sm' className='ms-3 my-0'
                                                                onClick={
                                                                    () => handleOpenModal_Email({
                                                                        id: item.id,
                                                                    })
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faEnvelope} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                    <PaginationT
                                        total={fetchDocumentosData?.data?.total}
                                        handleFormChange={handleFormChange}
                                        formValuesSearch={formValuesSearch}
                                        perPage={fetchDocumentosData?.data?.per_page}
                                    />

                                    <ModalArchivos
				                        data={dataModal_Archivo}
				                        handleCloseModal={handleCloseModal_Archivo}
				                        fetchDocumentos={fetchDocumentos}
				                    />

				                    <ModalEmail
				                        data={dataModal_Email}
				                        handleCloseModal={handleCloseModal_Email}
				                    />

                                </>
                                :
                                <h5 className='my-0 py-5 text-center'>No se ha encontrado resultados</h5>
                                }
                            </>
                    }
        </>
     )   
}