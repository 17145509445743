
// --------------- listado de Consultores

export const consultoresAdapterSchema = (item = {}) => {

    const data = {
        id: item.id || '',
        nif: item.consultor_nif || '',
        nombre: item.consultor_nombre || '',
        apellidos: item.consultor_apellidos || '',
        telefono: item.consultor_movil || '',
        es_verificador: item.es_verificador || false,
        fecha_baja: item?.usuario?.fecha_baja || '',
    }

    return {
        ...data,
        nombres_completo: `${data.nombre} ${data.apellidos}`,
        es_verificador: data.es_verificador ? 'Si' : 'No',
    }
}

export const consultoresAdapter = (data) => {

    const dataTemp = data?.data.data

    // console.log(dataTemp?.data)

    const items = dataTemp?.data?.map(item => (consultoresAdapterSchema(item)))

    return {
        data: {
            total: dataTemp?.total,
            per_page: dataTemp?.per_page,
            items
        },
        status: data?.status
    }
}

// ---------------- Objeto GET Consultor

export const getConsultorAdapterSchema = (item = {}) => {

    const data = {
        id: item.id || '',
        usuario: item.consultor_nif || '',
        nombre: item.consultor_nombre || '',
        apellidos: item.consultor_apellidos || '',
        empresa_nombre: item.consultor_empresa_nombre || '',
        direccion: item.consultor_direccion || '',
        codigo_postal: item.consultor_codigo_postal || '',
        poblacion: item.consultor_poblacion || '',
        provincia: item.consultor_provincia || '',
        logo: item.consultor_logo || '',
        movil: item.consultor_movil || '',
        email: item.consultor_email || '',
        es_verificador: item.es_verificador || false,
        fecha_alta: item.consultor_fecha_alta || '',
        fecha_modificacion: item.consultor_fecha_modificacion || '',
        pais: item.consultor_pais || '',
    }

    return {
        ...data,
        id: data.usuario
    }
}

export const getConsultorAdapter = (data) => {

    const dataTemp = data?.data?.data

    // console.log(dataTemp)

    // const items = dataTemp?.data?.map(item => (consultorAdapterSchema(item)))
    const item = getConsultorAdapterSchema(dataTemp)

    return {
        data: item,
        status: data?.status
    }
}


// --------------- Objeto POST Consultor

export const postConsultorAdapterSchema = (item = {}) => {

    const data = {
        id: item.id || '',
        usuario: item.usuario || '',
        consultor_nombre: item.nombre || '',
        consultor_apellidos: item.apellidos || '',
        empresa_nombre: item.empresa_nombre || '',
        consultor_direccion: item.direccion || '',
        consultor_codigo_postal: item.codigo_postal || '',
        consultor_poblacion: item.poblacion || '',
        consultor_provincia: item.provincia || '',
        logo: item.logo || '',
        consultor_movil: item.movil || '',
        email: item.email || '',
        es_verificador: item.es_verificador || false,
        es_comisionista: item.es_comisionista || false,
        consultor_pais: item.pais || '',
    }

    return data
}

export const postConsultorAdapter = (data) => {

    const item = postConsultorAdapterSchema(data)

    return {
        ...item
    }
}



// ----------------------- Mis Datos ------------------------

export const getConsultorAdapterSchemaMisDatos = (item = {}) => {

    const data = {
        id: item.id || '',
        logo: item.consultor_logo || '',
        nif: item.consultor_nif || '',
        nombre: item.consultor_nombre || '',
        apellidos: item.consultor_apellidos || '',
        empresa_nombre: item.consultor_empresa_nombre || '',
        direccion: item.consultor_direccion || '',
        codigo_postal: item.consultor_codigo_postal || '',
        movil: item.consultor_movil || '',
        email: item.consultor_email || '',
        poblacion: item.consultor_poblacion || '',
        provincia: item.consultor_provincia || '',
        es_verificador: item.es_verificador || false,
        usuario: item.usuario_id || '',
        verificador_casillas: item.verificador_casillas || [],
        pais: item.consultor_pais || '',
        estaActivo: item.usuario?.activo || false,
        consultor_web: item.consultor_web || '',
        es_comisionista: item.es_comisionista || false,
        fecha_baja: item?.usuario?.fecha_baja || '',
    }

    return {
        ...data
    }
}

export const getConsultorAdapterMisDatos = (data) => {

    const dataTemp = data?.data?.data
    
    const item = getConsultorAdapterSchemaMisDatos(dataTemp)

    return {
        data: item,
        status: data?.status
    }
}


export const postConsultorAdapterSchemaMisDatos = (item = {}) => {

    const data = {
        id: item.id || '',
        consultor_logo: item.logo || '',
        consultor_nif: item.nif || '',
        consultor_nombre: item.nombre || '',
        consultor_apellidos: item.apellidos || '',
        consultor_empresa_nombre: item.empresa_nombre || '',
        consultor_direccion: item.direccion || '',
        consultor_codigo_postal: item.codigo_postal || '',
        consultor_movil: item.movil || '',
        consultor_email: item.email || '',
        consultor_poblacion: item.poblacion || '',
        consultor_provincia: item.provincia || '',
        casillas: item.verificador_casillas || '',
        es_verificador: item.es_verificador || false,
        consultor_pais: item.pais || '',
        consultor_web: item.consultor_web || '',
        es_comisionista: item.es_comisionista || false,
    }

    return data
}


export const postConsultorAdapterMisDatos = (form) => {

    const formAdapter = postConsultorAdapterSchemaMisDatos(form)

    console.log(form, formAdapter)

    let formData = new FormData();

    for (const key in formAdapter) {
        formData.append(key, formAdapter[key])
        // if(key === 'casillas') {
             
        //     formData.append(key, JSON.stringify(formAdapter[key]))
        // } else {

        // }
    }

    return formData
}
