import { faArrowDown, faArrowRight, faFile, faFilePdf, faFileSignature, faPencilAlt, faSave, faSearch, faSignature } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'


import { Editor } from '@tinymce/tinymce-react';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { InputFile, InputSelect, InputText } from '../../../../components/Inputs';
import { useForm } from '../../../../hooks/useForm';
import { useAxios } from '../../../../hooks/useAxios';
import Wizard from '../../../../components/Wizard/Wizard';
import { MsgInputServer } from '../../../../components/MsgInput/MsgInputServer';
import { useValidator } from '../../../../hooks/useValidator';

// import './ProcessosMain.css'

export const ModalFirmarDAPcons = ({ showModalFirmarDAPcons, handleCloseModalFirmarDAPcons, certificates, pki, 
    idDapcon, idiomaDAPcon, setShowSuccessFirmarDapcons, fetchDAPconsDatReq }) => {

    const certificatesList = []

    if (certificates) {

        for (let i = 0; i < certificates.length; i++) {
            certificatesList.push({
                id: certificates[i].thumbprint,
                nombre: `${certificates[i].subjectName} ' (issued by '${certificates[i].issuerName}')`
            });
        }
    }

    // Prefirmar

    const [fetchPrefirmar, fetchPrefirmarData] = useAxios('post', `/dapcons`)
    const [fetchFirmar, fetchFirmarData] = useAxios('post', `/dapcons`)

    const [formCertificado, handleInputChangeCertificado, handleFormChangeCertificado, resetFormCertificado] = useForm({
        certificado: '',
    })

    const handlePrefirmar = (e) => {

        e.preventDefault()

        setLoadingFirmar(true)
        pki.readCertificate(formCertificado.certificado)
            .success(function (certificate) {
                fetchPrefirmar({
                    id: `${idDapcon}/prefirmar`,
                    body: {
                        idioma_id: idiomaDAPcon,
                        certificate,
                    }
                })
            }).fail(function (e) {
                console.error(e.responseJSON?.message);
            })
    }

    useEffect(() => {

        console.log(fetchPrefirmarData)
        if (fetchPrefirmarData.data) {
            pki.signHash({

                thumbprint: formCertificado.certificado,
                hash: fetchPrefirmarData.data?.data?.data?.hashDatabase64,
                digestAlgorithm: fetchPrefirmarData.data?.data?.data?.digestAlgorithmOid

            }).success(function (signature) {

                fetchFirmar({
                    id: `${idDapcon}/firmar`,
                    body: {
                        idioma_id: idiomaDAPcon,
                        signature
                    }
                })

            }).fail(function (e) {

                console.error(e.responseJSON?.message);
            })
        }

        if (fetchPrefirmarData.error?.response?.status === 400) {
            setLoadingFirmar(false)
        }

    }, [fetchPrefirmarData])

    useEffect(() => {

        if (fetchFirmarData.data) {

            console.log(fetchFirmarData.data)

            setLoadingFirmar(false)

            if (fetchFirmarData.data?.status === 200) {

                // setIndexFocusWizard(indexFocusWizard + 1)
                handleCloseModalFirmarDAPcons()
                setShowSuccessFirmarDapcons(true)
                fetchDAPconsDatReq({
                    params: {
                        idioma_id: idiomaDAPcon
                    }
                })

            }
        }
        if (fetchFirmarData.error?.response?.status === 400) {
            setLoadingFirmar(false)
        }

    }, [fetchFirmarData])

    // Loader All firmar

    const [loadingFirmar, setLoadingFirmar] = useState(false);

    useEffect(() => {

        if (!showModalFirmarDAPcons) {
            setLoadingFirmar(false)
            fetchPrefirmarData.data = null
            fetchFirmarData.data = null
        }
    }, [showModalFirmarDAPcons])


    return (
        <Modal show={showModalFirmarDAPcons} onHide={handleCloseModalFirmarDAPcons} size='lg' aria-labelledby="contained-modal-title-vcenter" centered backdrop='static'>
            <Modal.Header closeButton className=''>
                <Modal.Title className='h5'>Firmado del DAPcons</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>

                <section>
                    <div className="container animate__faster animate__animated animate__fadeInLeftModal">
                        <Form onSubmit={handlePrefirmar} className='row pt-5 pb-3 px-3 justify-content-center text-center'>
                            {/* <div className="col-12 mb-4">
                                <h5 className='text-center'>Firmar DAPcons</h5>
                            </div> */}
                            <p className='mb-2'>Seleccione el certificado con el que desee firmar...</p>
                            <Form.Group className="mb-4 col-sm-10 col-lg-10">
                                {
                                    console.log(certificates, certificatesList)
                                }
                                <InputSelect
                                    // label='Certificado *'
                                    className=''
                                    name='certificado'
                                    values={certificatesList}
                                    value={formCertificado.certificado}
                                    onChange={handleInputChangeCertificado}
                                    placeholder="certificado"
                                // validation={valuesValidEditarMuestra}
                                // fetchData={fetchEditarMuestraData}
                                />
                            </Form.Group>
                            <MsgInputServer fetchData={fetchPrefirmarData} />
                            <MsgInputServer fetchData={fetchFirmarData} />
                        </Form>
                    </div>
                </section>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={handleCloseModalFirmarDAPcons}>
                    Salir
                </Button>
                <ButtonSpinner
                    variant="primary" type="submit" className="ms-3 px-3"
                    handleSubmit={handlePrefirmar}
                    fetchDataLoading={[loadingFirmar]}
                    value='Firmar'
                    icon={<FontAwesomeIcon icon={faFileSignature} className='me-1' />}
                />
            </Modal.Footer>
        </Modal>
    )
}
