import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

export const TooltipWarning = ({ text, id, position }) => {
    return (
        <span className="">
            <OverlayTrigger
                key={id}
                placement={position}
                classname=''
                overlay={
                    <Popover id={id}>
                        <Popover.Body>
                            <span>{text}</span>
                        </Popover.Body>
                    </Popover>
                }
            >
                <div className='d-inline-block'>
                    {/* <FontAwesomeIcon icon={faInfoCircle} className='text-info cursor-pointer' /> */}
                    <FontAwesomeIcon icon={faExclamationCircle} className='me-1 text-warning' />
                </div>

            </OverlayTrigger>
        </span>
    )
}
