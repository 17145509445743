import { getObjectKeysMultilineUsed, getObjectKeysUsed } from "../helpers/helpers"

export const getFormAdapterErrors = (functionAdapter, err) => {
    
    console.log(err?.response?.data?.data)

    console.log(functionAdapter(err?.response?.data?.data))

    const res = getObjectKeysUsed(functionAdapter(err?.response?.data?.data))

    const res2 = getObjectKeysMultilineUsed(err?.response?.data?.data)

    return {
        response: {
            data: {
                data: {
                    ...res,
                    ...res2
                }
            }
        }
    }
}