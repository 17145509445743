import React, { useContext } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner'
import { AuthContext } from '../../../auth/AuthContext';
import { CondicionesContratacionEs } from '../../../pages/CondicionesContratacion/CondicionesContratacionEs';
//import { CondicionesContratacionCa } from 'pages/CondicionesContratacion/CondicionesContratacionCa';

export const ModalCondicionesContratacion = ({ showModal, handleCloseModal, setCheckedManifiesto, checkedManifiesto }) => {

    const { user: { token, lang }, dispatch } = useContext(AuthContext);

    const handleAccionManifiesto = (action) => {

        setCheckedManifiesto(() => {
            return {
                ...checkedManifiesto,
                [showModal.key]: action
            }
        })
        handleCloseModal()
    }

    return (
        <>
            <Modal show={showModal.value} onHide={handleCloseModal} size='lg' scrollable={false}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-4' style={{ height: 'auto', overflowY: 'auto' }}>
                    <CondicionesContratacionEs to={showModal.to} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={() => {
                        handleCloseModal()
                        }}>
                        Salir
                    </Button>
            </Modal.Footer>
            </Modal>
        </>
    )
}
