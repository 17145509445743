import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../../auth/AuthContext';
import { Usuario } from './Usuario/Usuario';
import { useLocation } from 'react-router-dom';
import { PagosContent } from './PagosContent/PagosContent';

export const Pagos = () => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const location = useLocation();
    console.log(location.pathname);

    return (
        <div className="container-inner row justify-content-between">
            <PagosContent namePage='Pagos' perfil={perfil} />
        </div>
    )
}
