import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'
import { AuthContext } from '../../../auth/AuthContext'
import { isModuleAdministrador } from '../../../helpers/helpers'
import { useLang } from '../../../language'
import { InputSelect } from '../../../components/Inputs';

export const TarifasFilter = ({ handleSearch, formValuesSearch, handleInputChangeSearch}) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const Lang = useLang();

    return (

        <div className='Column-small shadow-sm sticky-top mt-3'>
            <h5>{Lang('FILTRO')}</h5>
            <Form className='row' onSubmit={handleSearch}>
                <Form.Group className="col-12 col-sm-12 col-xl-12 mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Buscar</Form.Label>
                    <Form.Control type="text" autoComplete="off" name='magic' value={formValuesSearch.magic} onChange={handleInputChangeSearch} placeholder="Buscar por descripción, código contable" />
                </Form.Group>
                <div className="col-12 col-sm-12"></div>
                <div className='mt-2'>
                    <button className="px-3 btn btn-primary float-end">
                        <FontAwesomeIcon icon={faSearch} /> {Lang('BUSCAR')}
                    </button>
                </div>
            </Form>
        </div>

    )
}
