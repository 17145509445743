import React from 'react'
import { Form } from 'react-bootstrap'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers';

export const InputRadios = ({ className = '', name, values = [], onChange, validation = {},
    fetchData = {}, value, disabled, onClick}) => {

    const handleOnchange = (e) => {

        if (validation[name])
            validation[name].required.isOk = true

        if (fetchData.data)
            fetchData.data.status = 'Success'

        onChange(e)
    }

    return (
        <>
            {
                values.map(item => {
                    console.log(value, item.id)
                    return (
                        <div className="d-inline-block me-4" key={name + '_' + item.nombre}>
                            <Form.Check
                                type='radio'
                                className={`${className} ${(validation ? getMsgInputColor(validation, name) : false) || getMsgInputServerColor(validation, name) ? 'is-invalid' : ''}`}
                                id={name + '_' + item.nombre}
                                label={item.nombre}
                                name={name}
                                value={item.id}
                                onChange={handleOnchange}
                                autoComplete='one-time-code'
                                defaultChecked={item.id === value}
                                checked={item.id === value}
                                disabled={disabled}
                                onClick={onClick}
                            />
                        </div>
                    )
                })
            }
            {
                validation && <MsgInput obj={validation} name={name} />
            }
            <MsgInputServer obj={fetchData?.data} name={name} />

        </>
    )
}
