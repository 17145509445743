import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useLang } from '../../language/';
import { AuthContext } from '../../auth/AuthContext';
import { useLocation } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios'
import queryString from 'query-string';
import { useForm } from '../../hooks/useForm'
import { getURLQuery, getValuesParams, isModuleOrganizacion } from '../../helpers/helpers'
import { GetContenidosJSON } from '../../services/contenido.service'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContenidosFilter } from './ContenidosFilter/ContenidosFilter'
import { ContenidosMain } from './ContenidosMain/ContenidosMain'

export const Contenidos = () => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const location = useLocation()

    const [isForm, setIsForm] = useState('');

    const [fetchParameters, fetchParametersData] = useAxios('POST', `/parametros_generales`)

    useEffect(() => {
        fetchParameters({
                body: [
                    {
                        "param": "tipo_noticias",
                        "locale": 1,
                        "active": true
                    },
                    {
                        param: "clasificacion_noticias",
                        locale: 1,
                        active: true,
                    },
                ]
            });
    }, [])
    
    const [fetchContenidos, fetchContenidosData] = GetContenidosJSON()

    const {
        magic: magic = '',
        estado: estado = '',
        tipo_noticia: tipo_noticia = '',
        tipo_clasificacion: tipo_clasificacion = '',
        page: page = 1
    } = queryString.parse(location.search);

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        magic: magic,
        estado: estado,
        tipo_noticia: tipo_noticia,
        tipo_clasificacion: tipo_clasificacion,
        page: page
    });

    const handleSearch = (e) => {

        if (e) {
            e.preventDefault();
        }

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        // history.push(`?${query}`);
        window.history.replaceState(null, null, `?${query}`);

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchContenidos({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    }

    useEffect(() => {

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        window.history.replaceState(null, null, `?${query}`);

        fetchContenidos({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.orderby])

    return (
        <div className="container-inner row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Administración Contenidos
                </Breadcrumb.Item>
            </Breadcrumb>
            {!isForm &&
                 <ContenidosFilter
                    handleSearch={handleSearch}
                    handleInputChangeSearch={handleInputChangeSearch}
                    formValuesSearch={formValuesSearch}
                    fetchParametersData={fetchParametersData}
                />
            }
            <ContenidosMain
                fetchDocumentosData={fetchContenidosData}
                fetchDocumentos={fetchContenidos}
                formValuesSearch={formValuesSearch}
                handleFormChange={handleFormChange}
                fetchParametersData={fetchParametersData}
                setIsForm={setIsForm}
            />
        </div>
    )
}
