import { faCheckCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Form, ListGroup, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { Table5 } from '../../../../components/DAPconTables/Table5';
import { Table6 } from '../../../../components/DAPconTables/Table6';
import { Table7 } from '../../../../components/DAPconTables/Table7';
import { InputSelect, InputText } from '../../../../components/Inputs';
import { Loader } from '../../../../components/Loader/Loader';
import { ModalPrompt } from '../../../../components/ModalStatusServer/ModalPrompt';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';
import { ModalStatusError } from '../../../../components/ModalStatusServer/ModalStatusError';
import { useAxios } from '../../../../hooks/useAxios';
import { useForm } from '../../../../hooks/useForm';
import { GetDAPconAnalisisCicloVidaJSON } from '../../../../services/dapcon.service';
import _ from 'lodash';
import { TooltipLock } from '../../../../components/TooltipLock/TooltipLock';
import { TooltipWarning } from '../../../../components/TooltipWarning/TooltipWarning';
import { TextInformationSaltoPagina } from '../../../../components/TextInformation/TextInformationSaltoPagina';

export const DAPconConsultorSeccion5 = ({ idDapcon, idiomaDAPcon, datBasic, bloqueado, fetchCheckForms }) => {

    const [fetchAnalisisCicloVida, fetchAnalisisCicloVidaData] = GetDAPconAnalisisCicloVidaJSON(idDapcon)

    const [fetchTabla5, fetchTabla5Data] = useAxios('get', `/dapcons/${idDapcon}/tabla5`)

    const [fetchTabla6, fetchTabla6Data] = useAxios('get', `/dapcons/${idDapcon}/tabla6`)

    const [impactoAmbiental, setImpactoAmbiental] = useState({})

    const [parametrosUso, setParametrosUso] = useState({})

    const [modeloTable5, setmodeloTable5] = useState(datBasic?.modelos_series[0] || {})

    const [modeloTable6, setmodeloTable6] = useState(datBasic?.modelos_series[0] || {})

    const [fetchTabla5Patch, fetchTabla5PatchData] = useAxios('patch', `/dapcons/${idDapcon}/tabla5`)

    const [fetchTabla6Patch, fetchTabla6PatchData] = useAxios('patch', `/dapcons/${idDapcon}/tabla6`)

    useEffect(() => {

        fetchAnalisisCicloVida({
            params: {
                dapcons_id: idDapcon,
                idioma_id: idiomaDAPcon
            }
        })

    }, [])

    useEffect(() => {

        if (modeloTable5?.modelo_id) {
            fetchTabla5({
                params: {
                    modelo_id: modeloTable5?.modelo_id
                }
            })
        } else {
            fetchTabla5()
        }

    }, [])

    useEffect(() => {

        if (modeloTable6?.modelo_id) {
            fetchTabla6({
                params: {
                    modelo_id: modeloTable6?.modelo_id
                }
            })
        } else {
            fetchTabla6()
        }

    }, [])

    useEffect(() => {

        if (fetchTabla5Data.data) {
            console.log(fetchTabla5Data.data?.data?.data?.table5?.[0])
            const newData = { ...fetchTabla5Data.data?.data?.data?.table5?.[0] } || {}
            setMatriz(_.cloneDeep(newData))
            setMatrizTempCopy(_.cloneDeep(newData))
        }

    }, [fetchTabla5Data])

    useEffect(() => {

        if (fetchTabla6Data.data) {
            const newData = { ...fetchTabla6Data.data?.data?.data?.table6?.[0] } || {}
            setMatrizTabla6(_.cloneDeep(newData))
            setMatrizTabla6Temp(_.cloneDeep(newData))
        }

    }, [fetchTabla6Data])

    useEffect(() => {

        if (fetchAnalisisCicloVidaData.data && Object.keys(impactoAmbiental).length > 0 && Object.keys(parametrosUso).length > 0) {

            console.log(fetchAnalisisCicloVidaData)

            const fetchAnalisisCicloVidaDataTemp = fetchAnalisisCicloVidaData.data
            handleFormChangeAnalisisCicloVida({
                ...fetchAnalisisCicloVidaDataTemp,
                form_errors: fetchAnalisisCicloVidaDataTemp.form_completed?.form_errors?.[0] || {}
            })
            setisEditForm(isEditf(fetchAnalisisCicloVidaDataTemp))
        }

    }, [fetchAnalisisCicloVidaData, impactoAmbiental, parametrosUso])

    const [formAnalisisCicloVida, handleInputChangeAnalisisCicloVida,
        handleFormChangeAnalisisCicloVida, handleResetAnalisisCicloVida] = useForm({
            dv_idioma_id: idiomaDAPcon,
            dv_analisis_ciclo_vida: '',
            dapcons_unidad_param_id: '',
            propiedad_tecnica_id: '',
            propiedad_tecnica_cantidad: '',
            dv_unidad_texto: '',
            dv_rsl: '',
            dv_unidad_comentarios: '',
            dv_recomendaciones: '',
            dv_reglas_corte: '',
            dv_explicacion_medioambiental_adicional: '',
            dv_otros_datos: '',
            table5: [],
            table6: [],
            table7: [],
            form_completed: {},
            form_errors: {},
            a1a3: false
        })

    useEffect(() => {
        console.log(formAnalisisCicloVida)
    }, [formAnalisisCicloVida])


    const modelos = datBasic?.modelos_series || []

    // Parametros tabla 5

    const [fetchImpactoAmbiental, fetchImpactoAmbientalData] = useAxios('get', `/parametros-select?&idioma_id=1`)

    useEffect(() => {

        fetchImpactoAmbiental({
            params: {
                idioma_id: 1,
                param_padre_id: 'parametros_impacto_ambiental',
            }
        })

    }, [])


    useEffect(() => {
        if (fetchImpactoAmbientalData.data) {

            const impactoAmbientalTemp = [...fetchImpactoAmbientalData.data?.data?.data]

            let impactoAmbientalObj = {}

            for (let i = 0; i < impactoAmbientalTemp.length; i++) {
                impactoAmbientalObj[impactoAmbientalTemp[i].pt_param_id] = impactoAmbientalTemp[i]
            }

            setImpactoAmbiental({
                ...impactoAmbientalObj
            })
        }

    }, [fetchImpactoAmbientalData])


    // Parametros tabla 6

    const [fetchParametrosUso, fetchParametrosUsoData] = useAxios('get', `/parametros-select?&idioma_id=1`)

    useEffect(() => {

        fetchParametrosUso({
            params: {
                idioma_id: 1,
                param_padre_id: 'parametros_uso',
            }
        })

    }, [])


    useEffect(() => {
        if (fetchParametrosUsoData.data) {

            const parametrosUsoTemp = [...fetchParametrosUsoData.data?.data?.data]

            let impactoAmbientalObj = {}

            for (let i = 0; i < parametrosUsoTemp.length; i++) {
                impactoAmbientalObj[parametrosUsoTemp[i].pt_param_id] = parametrosUsoTemp[i]
            }

            setParametrosUso({
                ...impactoAmbientalObj
            })
        }

    }, [fetchParametrosUsoData])

    // Parametros tabla 7

    const [fetchCarbonoBiogenico, fetchCarbonoBiogenicoData] = useAxios('get', `/parametros-select`)

    useEffect(() => {

        fetchCarbonoBiogenico({
            params: {
                idioma_id: 1,
                param_padre_id: 'tabla_7_kg_carbono_biogenico',
            }
        })

    }, [])

    const [carbonoBiogenico, setCarbonoBiogenico] = useState({})

    useEffect(() => {
        if (fetchCarbonoBiogenicoData.data) {

            const carbonoBiogenicoTemp = [...fetchCarbonoBiogenicoData.data?.data?.data]

            let carbonoBiogenicoObj = {}

            for (let i = 0; i < carbonoBiogenicoTemp.length; i++) {
                carbonoBiogenicoObj[carbonoBiogenicoTemp[i].pt_param_id] = carbonoBiogenicoTemp[i]
            }

            setCarbonoBiogenico({
                ...carbonoBiogenicoObj
            })
        }

    }, [fetchCarbonoBiogenicoData])


    const [exista1a3, setExista1a3] = useState(datBasic?.dapcons_tabla5_tiene_a1a3)

    const [exista1a3Table6, setExista1a3Table6] = useState(datBasic?.dapcons_tabla6_tiene_a1a3)

    const handleClickMergeA1A3 = () => {

        setExista1a3(!exista1a3)
    }

    const handleClickMergeA1A3Table6 = () => {

        setExista1a3Table6(!exista1a3Table6)
    }

    const [matriz, setMatriz] = useState({})
    const [matrizTempCopy, setMatrizTempCopy] = useState({})

    const [matrizTabla6, setMatrizTabla6] = useState([])
    const [matrizTabla6Temp, setMatrizTabla6Temp] = useState([])

    const [fetchAnalisisCicloVidaSubmit, fetchAnalisisCicloVidaSubmitData] = useAxios('patch', `/dapcons/${idDapcon}/form/analisis-ciclo-vida`)

    const handleSubmitAnalisisCicloVida = (e) => {

        e.preventDefault()

        if (!bloqueado) {

            handleSubmitTable5(e)
            setmodeloTable5Temp(modeloTable5)

            handleSubmitTable6(e)
            setmodeloTable6Temp(modeloTable6)

        } else {

            fetchAnalisisCicloVidaSubmit({
                body: {
                    ...formAnalisisCicloVida,
                }
            })
        }

    }

    const handleSubmitTable5 = (e, newModeloTable5) => {
        e.preventDefault()

        if (modeloTable5?.modelo_id) {
            fetchTabla5Patch({
                body: {
                    modelo_id: modeloTable5?.modelo_id,
                    table5: Object.keys(matriz).map(item => {
                        return {
                            [item]: { ...matriz[item] }
                        }
                    }),
                    dapcons_tabla5_tiene_a1a3: exista1a3
                }
            })
        } else {
            fetchTabla5Patch({
                body: {
                    table5: Object.keys(matriz).map(item => {
                        return {
                            [item]: { ...matriz[item] }
                        }
                    }),
                    dapcons_tabla5_tiene_a1a3: exista1a3
                }
            })
        }

        if (newModeloTable5) {
            setmodeloTable5Temp(newModeloTable5)
        }
    }

    const handleSubmitTable6 = (e, newModeloTable6) => {
        e.preventDefault()

        if (modeloTable6?.modelo_id) {
            fetchTabla6Patch({
                body: {
                    modelo_id: modeloTable6?.modelo_id,
                    table6: Object.keys(matrizTabla6).map(item => {
                        return {
                            [item]: { ...matrizTabla6[item] }
                        }
                    }),
                    dapcons_tabla6_tiene_a1a3: exista1a3Table6
                }
            })
        } else {
            fetchTabla6Patch({
                body: {
                    table6: Object.keys(matrizTabla6).map(item => {
                        return {
                            [item]: { ...matrizTabla6[item] }
                        }
                    }),
                    dapcons_tabla6_tiene_a1a3: exista1a3Table6
                }
            })
        }

        if (newModeloTable6) {
            setmodeloTable6Temp(newModeloTable6)
        }
    }

    const handleNoSubmitTable5 = (item) => {
        setShowConfirmacionT5({
            value: false,
            index: -1
        })
        setmodeloTable5(item)
        fetchTabla5({
            params: {
                modelo_id: item?.modelo_id
            }
        })
    }

    const handleNoSubmitTable6 = (item) => {
        setShowConfirmacionT6({
            value: false,
            index: -1
        })
        setmodeloTable6(item)
        fetchTabla6({
            params: {
                modelo_id: item?.modelo_id
            }
        })
    }

    const handleInputChangeTable7 = (e, key) => {
        handleFormChangeAnalisisCicloVida({
            ...formAnalisisCicloVida,
            table7: {
                ...formAnalisisCicloVida.table7,
                [key]: e.target.value
            }
        })
    }

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        console.log(fetchAnalisisCicloVidaSubmitData.data)
        if (fetchAnalisisCicloVidaSubmitData.data?.status === 201 &&
            fetchTabla5PatchData.data?.status === 201 &&
            fetchTabla6PatchData.data?.status === 201 && !bloqueado) {
            setShowSuccessEdit(true)
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchAnalisisCicloVidaSubmitData, fetchTabla5PatchData, fetchTabla6PatchData])

    // Guardado con bloqueo

    useEffect(() => {
        console.log(fetchAnalisisCicloVidaSubmitData.data, 'bloqueado')
        if (fetchAnalisisCicloVidaSubmitData.data?.status === 201 && bloqueado) {
            setShowSuccessEdit(true)
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchAnalisisCicloVidaSubmitData])

    const [modeloTable5Temp, setmodeloTable5Temp] = useState(datBasic?.modelos_series[0] || {})

    const [modeloTable6Temp, setmodeloTable6Temp] = useState(datBasic?.modelos_series[0] || {})

    useEffect(() => {
        // console.log(fetchAnalisisCicloVidaSubmitData.data)
        if (fetchTabla5PatchData.data?.status === 201) {
            setShowConfirmacionT5({ value: false, index: -1 })
            fetchTabla5({
                params: {
                    modelo_id: modeloTable5Temp?.modelo_id
                }
            })
            setmodeloTable5(modeloTable5Temp)
        }

    }, [fetchTabla5PatchData])

    useEffect(() => {
        // console.log(fetchAnalisisCicloVidaSubmitData.data)
        if (fetchTabla6PatchData.data?.status === 201) {
            setShowConfirmacionT6({ value: false, index: -1 })
            fetchTabla6({
                params: {
                    modelo_id: modeloTable6Temp?.modelo_id
                }
            })
            setmodeloTable6(modeloTable6Temp)
        }

    }, [fetchTabla6PatchData])

    // Fetch despues de las tablas, el general

    useEffect(() => {
        // console.log(fetchAnalisisCicloVidaSubmitData.data)
        if (fetchTabla5PatchData.data?.status === 201 && fetchTabla6PatchData.data?.status === 201) {
            fetchAnalisisCicloVidaSubmit({
                body: {
                    ...formAnalisisCicloVida,
                }
            })
        }

    }, [fetchTabla5PatchData, fetchTabla6PatchData])

    // Select unidad funcional

    const [fetchUnidadFuncional, fetchUnidadFuncionalData] = useAxios('get', `/parametros-select`)
    useEffect(() => {
        fetchUnidadFuncional({
            params: {
                idioma_id: 1,
                param_padre_id: 'tipo_unidad_funcional',
            }
        })
    }, [])

    const [listUnidadFuncional, setListUnidadFuncional] = useState([])

    useEffect(() => {

        console.log(fetchUnidadFuncionalData)

        if (fetchUnidadFuncionalData.data?.status === 200) {
            const listTemp = fetchUnidadFuncionalData?.data?.data?.data?.map(item => {

                return {
                    id: item.pt_param_id,
                    nombre: item.pt_texto
                }
            })
            setListUnidadFuncional(listTemp)
        }

    }, [fetchUnidadFuncionalData])

    // Confirmar eliminar 

    const [showConfirmacionT5, setShowConfirmacionT5] = useState({
        value: false,
        index: -1
    })

    const [showConfirmacionT6, setShowConfirmacionT6] = useState({
        value: false,
        index: -1
    })

    const handleChangeTable5 = (item) => {

        const isMatrizEqual = _.isEqual(matriz, matrizTempCopy)

        if (!isMatrizEqual) {
            setShowConfirmacionT5({ value: true, index: item })
        } else {
            handleNoSubmitTable5(item)
        }
    }

    const handleChangeTable6 = (item) => {

        const isMatrizEqual = _.isEqual(matrizTabla6, matrizTabla6Temp)

        if (!isMatrizEqual) {
            setShowConfirmacionT6({ value: true, index: item })
        } else {
            handleNoSubmitTable6(item)
        }
    }

    const isEditf = (form) => {

        const formTemp = _.cloneDeep(form)

        delete formTemp['idioma_id']
        delete formTemp['form_completed']
        delete formTemp['form_errors']
        delete formTemp['table5']
        delete formTemp['table6']
        delete formTemp['table7']
        delete formTemp['a1a3']

        for (const key in formTemp) {
            if (formTemp[key]) {
                return true
            }
        }

        return false
    }

    const [isEditForm, setisEditForm] = useState(false)

    // Integrando Propiedades tecnicas

    const [fetchPropiedadesTecnicas, fetchPropiedadesTecnicasData] = useAxios('get', `/propiedades-tecnicas?idioma_id=1&limit=200`)

    useEffect(() => {

        fetchPropiedadesTecnicas()

    }, [])

    const [listPropiedadesTecnicas, setlistPropiedadesTecnicas] = useState([])

    useEffect(() => {
        if (fetchPropiedadesTecnicasData.data) {
            setlistPropiedadesTecnicas(fetchPropiedadesTecnicasData?.data?.data?.data?.data || [])
        }
    }, [fetchPropiedadesTecnicasData])



    return (
        <>
            <div className='MainInner shadow-sm animate-width-disabled'>
                {
                    (fetchAnalisisCicloVidaData.loading || fetchImpactoAmbientalData.loading ||
                        fetchParametrosUsoData.loading || fetchCarbonoBiogenicoData.loading) ? (
                        <div className="py-5">
                            <Loader />
                        </div>
                    ) : (
                        <>

                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <h5>3. Análisis del ciclo de vida</h5>
                                </div>
                            </div>

                            <Form className='row'>

                                <Form.Group className="col-12 mb-4">
                                    <InputText
                                        className=''
                                        as="textarea"
                                        rows={5}
                                        name='dv_analisis_ciclo_vida'
                                        onChange={handleInputChangeAnalisisCicloVida}
                                        value={formAnalisisCicloVida.dv_analisis_ciclo_vida}
                                    />
                                    <TextInformationSaltoPagina />

                                    {
                                        isEditForm && !formAnalisisCicloVida['dv_analisis_ciclo_vida'] && formAnalisisCicloVida.form_errors?.['dv_analisis_ciclo_vida'] &&
                                        <Form.Text className='text-danger d-block'>{formAnalisisCicloVida.form_errors?.['dv_analisis_ciclo_vida']}</Form.Text>
                                    }
                                </Form.Group>

                            </Form>

                            <h5 className='d-flex flex-wrap mb-0'>
                                <small className='d-flex align-items-center mb-2'>3.1 Unidad
                                    {
                                        !bloqueado ? <Form.Group className="ms-2">
                                            <InputSelect
                                                className=''
                                                name='dapcons_unidad_param_id'
                                                values={listUnidadFuncional}
                                                value={formAnalisisCicloVida.dapcons_unidad_param_id}
                                                onChange={handleInputChangeAnalisisCicloVida}
                                            // fetchData={fetchDAPconsDatReqEditData}
                                            // validation={valuesValidationDAPcon}
                                            />

                                        </Form.Group> : (
                                            <span className='ms-2'>{listUnidadFuncional.find(item => item.id === formAnalisisCicloVida.dapcons_unidad_param_id)?.nombre}</span>
                                        )
                                    }
                                    {
                                        bloqueado && <TooltipLock id='dapcons_unidad_param_id' position='right' className='ms-2' />
                                    }

                                </small>

                                <small className='d-flex align-items-center ms-3 ps-2 mb-2'>Unidad en:
                                    {
                                        !bloqueado ? <Form.Group className="ms-2">
                                            <InputSelect
                                                className=''
                                                name='propiedad_tecnica_id'
                                                values={listPropiedadesTecnicas?.map(item => {
                                                    // console.log(item);
                                                    return {
                                                        id: item.id,
                                                        nombre: `${item.unidad} - ${item.propiedad_textos?.[0]?.propiedad}`
                                                    }
                                                }) || []}
                                                value={formAnalisisCicloVida.propiedad_tecnica_id}
                                                onChange={handleInputChangeAnalisisCicloVida}
                                            // fetchData={fetchDAPconsDatReqEditData}
                                            // validation={valuesValidationDAPcon}
                                            />

                                        </Form.Group> : (
                                            <span className='ms-2 fw-normal'>
                                                {listPropiedadesTecnicas?.find(item => item.id === formAnalisisCicloVida.propiedad_tecnica_id)?.unidad} - {listPropiedadesTecnicas?.find(item => item.id === formAnalisisCicloVida.propiedad_tecnica_id)?.propiedad_textos?.[0]?.propiedad}
                                            </span>
                                        )
                                    }
                                    {
                                        bloqueado && <TooltipLock id='propiedad_tecnica_id' position='right' className='ms-2' />
                                    }

                                </small>

                                <small className='d-flex align-items-center ms-3 ps-2 mb-2'>Cantidad:
                                    {
                                        !bloqueado ? <Form.Group className="ms-2">
                                            <InputText
                                                style={{ 'width': '120px' }}
                                                type='number'
                                                className=''
                                                name='propiedad_tecnica_cantidad'
                                                onChange={handleInputChangeAnalisisCicloVida}
                                                value={formAnalisisCicloVida.propiedad_tecnica_cantidad}
                                            />

                                        </Form.Group> : (
                                            <span className='ms-2 fw-normal'>{formAnalisisCicloVida.propiedad_tecnica_cantidad}</span>
                                        )
                                    }
                                    {
                                        bloqueado && <TooltipLock id='propiedad_tecnica_cantidad' position='right' className='ms-2' />
                                    }

                                </small>

                            </h5>

                            {
                                isEditForm && !formAnalisisCicloVida['dapcons_unidad_param_id'] && formAnalisisCicloVida.form_errors?.['dapcons_unidad_param_id'] &&
                                <Form.Text className='text-danger d-block'>{formAnalisisCicloVida.form_errors?.['dapcons_unidad_param_id']}</Form.Text>
                            }

                            {
                                isEditForm && !formAnalisisCicloVida['propiedad_tecnica_id'] && formAnalisisCicloVida.form_errors?.['propiedad_tecnica_id'] &&
                                <Form.Text className='text-danger d-block'>{formAnalisisCicloVida.form_errors?.['propiedad_tecnica_id']}</Form.Text>
                            }

                            {
                                isEditForm && !formAnalisisCicloVida['propiedad_tecnica_cantidad'] && formAnalisisCicloVida.form_errors?.['propiedad_tecnica_cantidad'] &&
                                <Form.Text className='text-danger d-block'>{formAnalisisCicloVida.form_errors?.['propiedad_tecnica_cantidad']}</Form.Text>
                            }
                            <Form className='row'>

                                <Form.Group className="col-12 mb-4 mt-3">
                                    <Form.Label>Descripción de Unidad en Documento DAPcons</Form.Label>

                                    <InputText
                                        className=''
                                        as="textarea"
                                        rows={5}
                                        name='dv_unidad_texto'
                                        onChange={handleInputChangeAnalisisCicloVida}
                                        value={formAnalisisCicloVida.dv_unidad_texto}
                                    />
                                    <TextInformationSaltoPagina />

                                    {
                                        isEditForm && !formAnalisisCicloVida['dv_unidad_texto'] && formAnalisisCicloVida.form_errors?.['dv_unidad_texto'] &&
                                        <Form.Text className='text-danger d-block'>{formAnalisisCicloVida.form_errors?.['dv_unidad_texto']}</Form.Text>
                                    }
                                </Form.Group>

                                {/* <Form.Group className="col-12 mb-4">
                                    <Form.Label>Vida útil referencia</Form.Label>

                                    <InputText
                                        className=''
                                        as="textarea"
                                        rows={5}
                                        name='dv_rsl'
                                        onChange={handleInputChangeAnalisisCicloVida}
                                        value={formAnalisisCicloVida.dv_rsl}
                                    />
                                    {
                                        isEditForm && !formAnalisisCicloVida['dv_rsl'] && formAnalisisCicloVida.form_errors?.['dv_rsl'] &&
                                        <Form.Text className='text-danger d-block'>{formAnalisisCicloVida.form_errors?.['dv_rsl']}</Form.Text>
                                    }
                                </Form.Group> */}

                                <Form.Group className="col-12 mb-4">
                                    <Form.Label>Comentarios adicionales</Form.Label>
                                    <InputText
                                        className=''
                                        as="textarea"
                                        rows={4}
                                        name='dv_unidad_comentarios'
                                        onChange={handleInputChangeAnalisisCicloVida}
                                        value={formAnalisisCicloVida.dv_unidad_comentarios}
                                    />
                                    <TextInformationSaltoPagina />

                                    {
                                        isEditForm && !formAnalisisCicloVida['dv_unidad_comentarios'] && formAnalisisCicloVida.form_errors?.['dv_unidad_comentarios'] &&
                                        <Form.Text className='text-danger d-block'>{formAnalisisCicloVida.form_errors?.['dv_unidad_comentarios']}</Form.Text>
                                    }
                                </Form.Group>

                            </Form>

                            {/* <h5 className='mb-3'>
                    <small>3.2 Límites del sistema</small>
                </h5> */}

                            <h5 className='mb-3'>
                                <small>3.3 Datos del análisis del ciclo de vida
                                    {
                                        bloqueado && <TooltipLock id='dapcons_verificador_id' position='right' className='ms-2' />
                                    }
                                </small>
                            </h5>

                            {
                                modelos.length > 0 && <ListGroup horizontal className='mb-2 mt-4'>

                                    {
                                        modelos.map((item, index) => {
                                            return (
                                                <ListGroup.Item
                                                    className='cursor-pointer'
                                                    key={index}
                                                    active={modeloTable5?.modelo_id === item.modelo_id}
                                                    onClick={() => handleChangeTable5(item)}
                                                // onClick={() => setShowConfirmacionT5({ value: true, index: item })}
                                                >
                                                    {
                                                        !formAnalisisCicloVida.form_completed?.table5?.[index]?.isCompleted &&
                                                        <TooltipWarning position={'top'} text='Los campos de la tabla son requeridos' />
                                                    }
                                                    {item.modelo_nombre}
                                                </ListGroup.Item>
                                            )
                                        })
                                    }
                                </ListGroup>
                            }

                            {
                                isEditForm && !formAnalisisCicloVida.form_completed?.['Table5isCompleted'] &&
                                <Form.Text className='text-danger d-block'>Los campos de la tabla son obligatorios</Form.Text>
                            }

                            {
                                fetchTabla5Data.loading ? (
                                    <div className="py-5">
                                        <Loader />
                                    </div>
                                ) : (
                                    <Table5
                                        handleClickMergeA1A3={handleClickMergeA1A3}
                                        exista1a3={exista1a3}
                                        matriz={matriz}
                                        setMatriz={setMatriz}
                                        impactoAmbiental={impactoAmbiental}
                                        bloqueado={bloqueado}
                                    />
                                )
                            }

                            {
                                datBasic?.dapcons_incluir_tabla_6 && (
                                    <>
                                        <div className="my-4"></div>

                                        {
                                            modelos.length > 0 && <ListGroup horizontal className='mb-2 mt-5'>

                                                {
                                                    modelos.map((item, index) => {
                                                        return (
                                                            <ListGroup.Item
                                                                className='cursor-pointer'
                                                                key={index + "2"}
                                                                active={modeloTable6?.modelo_id === item.modelo_id}
                                                                // onClick={() => setShowConfirmacionT6({ value: true, index: item })}
                                                                onClick={() => handleChangeTable6(item)}
                                                            >
                                                                {
                                                                    !formAnalisisCicloVida.form_completed?.table6?.[index]?.isCompleted &&
                                                                    <TooltipWarning position={'top'} text='Los campos de la tabla son requeridos' />
                                                                }
                                                                {item.modelo_nombre}
                                                            </ListGroup.Item>
                                                        )
                                                    })
                                                }
                                            </ListGroup>
                                        }

                                        {
                                            isEditForm && !formAnalisisCicloVida.form_completed?.['Table6isCompleted'] &&
                                            <Form.Text className='text-danger d-block'>Los campos de la tabla son obligatorios</Form.Text>
                                        }

                                        {
                                            fetchTabla6Data.loading ? (
                                                <div className="py-5">
                                                    <Loader />
                                                </div>
                                            ) : (
                                                <Table6
                                                    handleClickMergeA1A3={handleClickMergeA1A3Table6}
                                                    exista1a3={exista1a3Table6}
                                                    matriz={matrizTabla6}
                                                    setMatriz={setMatrizTabla6}
                                                    parametrosUso={parametrosUso}
                                                    bloqueado={bloqueado}
                                                />
                                            )
                                        }
                                    </>
                                )
                            }


                            <div className="py-3"></div>

                            <Table7
                                formAnalisisCicloVida={formAnalisisCicloVida}
                                carbonoBiogenico={carbonoBiogenico}
                                handleInputChangeTable7={handleInputChangeTable7}
                            />

                            {
                                isEditForm && !formAnalisisCicloVida.form_completed?.['Table7isCompleted'] &&
                                <Form.Text className='text-danger d-block text-center'>Los campos de la tabla son obligatorios</Form.Text>
                            }

                            <div className="py-3"></div>


                            <h5 className='mb-3'>
                                <small>3.4 Recomendaciones de esta DAP</small>
                            </h5>

                            <Form className='row'>

                                <Form.Group className="col-12 mb-4">
                                    <InputText
                                        className=''
                                        as="textarea"
                                        rows={5}
                                        name='dv_recomendaciones'
                                        onChange={handleInputChangeAnalisisCicloVida}
                                        value={formAnalisisCicloVida.dv_recomendaciones}
                                    />
                                    <TextInformationSaltoPagina />

                                    {
                                        isEditForm && !formAnalisisCicloVida['dv_recomendaciones'] && formAnalisisCicloVida.form_errors?.['dv_recomendaciones'] &&
                                        <Form.Text className='text-danger d-block'>{formAnalisisCicloVida.form_errors?.['dv_recomendaciones']}</Form.Text>
                                    }
                                </Form.Group>

                            </Form>

                            <h5 className='mb-3'>
                                <small>3.5 Reglas de corte</small>
                            </h5>

                            <Form className='row'>

                                <Form.Group className="col-12 mb-4">
                                    <InputText
                                        className=''
                                        as="textarea"
                                        rows={5}
                                        name='dv_reglas_corte'
                                        onChange={handleInputChangeAnalisisCicloVida}
                                        value={formAnalisisCicloVida.dv_reglas_corte}
                                    />
                                    <TextInformationSaltoPagina />

                                    {
                                        isEditForm && !formAnalisisCicloVida['dv_reglas_corte'] && formAnalisisCicloVida.form_errors?.['dv_reglas_corte'] &&
                                        <Form.Text className='text-danger d-block'>{formAnalisisCicloVida.form_errors?.['dv_reglas_corte']}</Form.Text>
                                    }
                                </Form.Group>

                            </Form>

                            <h5 className='mb-3'>
                                <small>3.6 Información medioambiental adicional</small>
                            </h5>

                            <Form className='row'>

                                <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput7">
                                    <InputText
                                        className=''
                                        as="textarea"
                                        rows={5}
                                        name='dv_explicacion_medioambiental_adicional'
                                        onChange={handleInputChangeAnalisisCicloVida}
                                        value={formAnalisisCicloVida.dv_explicacion_medioambiental_adicional}
                                    />
                                    <TextInformationSaltoPagina />

                                    {
                                        isEditForm && !formAnalisisCicloVida['dv_explicacion_medioambiental_adicional'] && formAnalisisCicloVida.form_errors?.['dv_explicacion_medioambiental_adicional'] &&
                                        <Form.Text className='text-danger d-block'>{formAnalisisCicloVida.form_errors?.['dv_explicacion_medioambiental_adicional']}</Form.Text>
                                    }
                                </Form.Group>

                            </Form>

                            <h5 className='mb-3'>
                                <small>3.7 Otros datos</small>
                            </h5>

                            <Form className='row'>

                                <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput7">
                                    <InputText
                                        className=''
                                        as="textarea"
                                        rows={5}
                                        name='dv_otros_datos'
                                        onChange={handleInputChangeAnalisisCicloVida}
                                        value={formAnalisisCicloVida.dv_otros_datos}
                                    />
                                    <TextInformationSaltoPagina />

                                    {
                                        isEditForm && !formAnalisisCicloVida['dv_otros_datos'] && formAnalisisCicloVida.form_errors?.['dv_otros_datos'] &&
                                        <Form.Text className='text-danger d-block'>{formAnalisisCicloVida.form_errors?.['dv_otros_datos']}</Form.Text>
                                    }
                                </Form.Group>

                            </Form>
                            <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                                <ButtonSpinner
                                    variant="info" type="submit" className="me-2"
                                    handleSubmit={handleSubmitAnalisisCicloVida} fetchDataLoading={[fetchAnalisisCicloVidaSubmitData.loading || fetchTabla5PatchData.loading || fetchTabla6PatchData.loading]}
                                    value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                />
                            </div>
                        </>
                    )
                }

            </div>
            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatusError fetchData={fetchAnalisisCicloVidaSubmitData} />

            <ModalStatusError fetchData={fetchTabla5PatchData} />

            <ModalStatusError fetchData={fetchTabla6PatchData} />

            <ModalPrompt setShowStatus={setShowConfirmacionT5} showStatus={showConfirmacionT5} fixed>
                <Modal.Body>
                    <div className="py-0 text-center">
                        <h5 className='mt-2 mb-2 fw-normal'>
                            ¿Guardar datos de la tabla?
                        </h5>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={() => handleNoSubmitTable5(showConfirmacionT5.index)}>
                        No
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={(e) => handleSubmitTable5(e, showConfirmacionT5.index)} fetchDataLoading={[fetchTabla5PatchData.loading]}
                        value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                    />
                </Modal.Footer>
            </ModalPrompt>

            <ModalPrompt setShowStatus={setShowConfirmacionT6} showStatus={showConfirmacionT6} fixed>
                <Modal.Body>
                    <div className="py-0 text-center">
                        <h5 className='mt-2 mb-2 fw-normal'>
                            ¿Guardar datos de la tabla?
                        </h5>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={() => handleNoSubmitTable6(showConfirmacionT6.index)}>
                        No
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={(e) => handleSubmitTable6(e, showConfirmacionT6.index)} fetchDataLoading={[fetchTabla6PatchData.loading]}
                        value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                    />
                </Modal.Footer>
            </ModalPrompt>

        </>
    )
}
