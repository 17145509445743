import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useEffect} from 'react'
import {GetArchivoJson } from '../../../../../services/contenidoarchivo.service'
import { ButtonSpinner } from '../../../../../components/ButtonSpinner/ButtonSpinner';

export const BtnEditar = ({data, addDataForm}) => {
	const [fetchGetArchivo, fetchGetArchivoData] = GetArchivoJson()
	const handleShowArchivo = () => {
        fetchGetArchivo({
            id: data,
        })
    }
   
    useEffect(() => {
        if (fetchGetArchivoData?.status === 200) {
           addDataForm(fetchGetArchivoData.data);
        }
    }, [fetchGetArchivoData])

	return (
        <>
        	 <ButtonSpinner
                variant="warning" type="submit" size="sm" 
                handleSubmit={handleShowArchivo} fetchDataLoading={[fetchGetArchivoData.loading]}
                icon={<FontAwesomeIcon icon={faEdit} className='me-1' />}
            />
        </>
     )    
}