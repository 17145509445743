import React, { useContext, useEffect } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useLang } from '../../language/';
import { AuthContext } from '../../auth/AuthContext';
import { useLocation } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios'
import queryString from 'query-string';
import { useForm } from '../../hooks/useForm'
import { getURLQuery, getValuesParams, isModuleOrganizacion } from '../../helpers/helpers'
import { GetDocumentosJSON } from '../../services/documento.service'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DocumentosFilter } from './DocuementosFilter/DocumentosFilter'
import { DocumentosMain } from './DocumentosMain/DocumentosMain'

export const Documentos = () => {


    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const location = useLocation()

    const [fetchParameters, fetchParametersData] = useAxios('GET', `/parametros-select`)

    useEffect(() => {
        fetchParameters({
            params: {
                'idioma_id': 1,
                'param_padre_id': 'clasificacion_documento',
            }
        });
    }, [])
    
    const [fetchDocumentos, fetchDocumentosData] = GetDocumentosJSON()

    const {
        magic: magic = '',
        tipo_documento: tipo_documento = '',
        estado: estado = '',
        page: page = 1,
        portada: portada = '',
    } = queryString.parse(location.search);

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        magic: magic,
        tipo_documento: tipo_documento,
        estado: estado,
        page: page,
        portada: portada
    });

    const handleSearch = (e) => {

        if (e) {
            e.preventDefault();
        }

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        // history.push(`?${query}`);
        window.history.replaceState(null, null, `?${query}`);

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchDocumentos({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    }

    useEffect(() => {

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        window.history.replaceState(null, null, `?${query}`);

        fetchDocumentos({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.orderby])

    return (
        <div className="container-inner row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Biblioteca documentos
                </Breadcrumb.Item>
            </Breadcrumb>
             <DocumentosFilter
                handleSearch={handleSearch}
                handleInputChangeSearch={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
                fetchParametersData={fetchParametersData}
                handleFormChange={handleFormChange}
            />
            <DocumentosMain
                fetchDocumentosData={fetchDocumentosData}
                fetchDocumentos={fetchDocumentos}
                formValuesSearch={formValuesSearch}
                namePage={'Biblioteca documentos'}
                handleFormChange={handleFormChange}
                fetchParametersData={fetchParametersData}
            />
        </div>
    )
}
