
export const getParametroAdapterSchema = (item = {}) => {

    const data = {
        id: item.param_id || '',
        nombre: item.param_textos?.[0]?.pt_texto || '',
    }

    return {
        ...data
    }
}

export const getParametroAdapter = (data) => {

    const dataTemp = {...data?.data?.data}

    delete dataTemp['pt_texto']
    delete dataTemp['pt_texto2']

    console.log(dataTemp)

    const items = Object.keys(dataTemp)?.map(key => {
        
        return dataTemp[key]?.parametros?.map( item => getParametroAdapterSchema(item))
    })

    return {
        data: [...items[0]]
    }
}