import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ListGroup, OverlayTrigger, Popover } from 'react-bootstrap'

const DAPconLinkMenu = ({ activeNavDAPcon, setActiveNavDAPcon, hideNav, label, id, status, invisible }) => {

    let icon = ''
    let classStatus = ''

    if (status === 'completo') {

        icon = <FontAwesomeIcon icon={faCheckCircle} className={`${hideNav ? '' : 'me-2'} listgroup-iconcheck ${invisible ? 'invisible' : ''}`} />
        classStatus = 'listgroup-complete'

    } else if (status === 'hidden') {

        icon = ''

    } else {

        icon = <FontAwesomeIcon icon={faCircle} className={`${hideNav ? '' : 'me-2'} listgroup-iconUncheck ${invisible ? 'invisible' : ''}`} />
    }

    return (
        <ListGroup.Item className={`${activeNavDAPcon === id ? 'active' : ''} ${classStatus}`}
            onClick={() => {
                setActiveNavDAPcon(id)
            }} >
            {
                !hideNav ? <>
                    {icon}
                    <span>{label}</span>
                </> : <>
                    <OverlayTrigger
                        key={id}
                        placement='right'
                        overlay={
                            <Popover id={id} className='tooltip-dapcon'>
                                <Popover.Body>
                                    <span>{label}</span>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <div className='btn-tooltip-dapcon'>
                            {icon}
                        </div>
                    </OverlayTrigger>
                </>
            }
        </ListGroup.Item>
    )
}

export default DAPconLinkMenu