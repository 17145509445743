import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../hooks/useAxios';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';

export const ModalEliminarDapcons = ({data, handleCloseModal, fetchDAPcons, formValuesSearch}) => {
     const [fetchEliminar, fetchEliminarData] = useAxios('DELETE', `/dapcons`); 
     
     const handleSubmitDelete = (e) => {
            fetchEliminar({
                id: data?.id+'/eliminar'
            })
    }
    
    useEffect(() => {
        if (fetchEliminarData?.data?.status === 200) {
            handleCloseModal();
            fetchDAPcons({
                params: formValuesSearch
            })
        }
    }, [fetchEliminarData.data])

	 return (
        <Modal show={data?.value} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>¿Realmente desea eliminar este Dapcons {data?.id} ?</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                 <p className="text-dark">Esta acción eliminará todos los registros relacionados (documentos, imágenes, idiomas, valores, modelos y módulos) de forma permanente. Esta operación no se puede deshacer.</p>
                 <div className="col-12 text-center">
                    <MsgInputServer fetchData={fetchEliminarData} className='mt-2' />
                 </div>  
            </Modal.Body>
            <Modal.Footer>
                <ButtonSpinner
                    variant="danger" type="submit" className="ms-2"
                    handleSubmit={handleSubmitDelete} fetchDataLoading={[fetchEliminarData.loading]}
                    value='Confirmar' icon={<FontAwesomeIcon icon={faTrash} className='me-1' />}
                />   
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
     )   
}