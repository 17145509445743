import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../../hooks/useAxios';
import { Loader } from '../../../../components/Loader/Loader';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';
import { InputText} from '../../../../components/Inputs';
import { InputRadios } from '../../../../components/Inputs/InputRadios';
import InputImage from '../../../../components/Inputs/InputImage';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import {ArchivosMain} from './ArchivosMain/ArchivosMain'
import {ArchivosForm} from './ArchivosForm'
import queryString from 'query-string';
import { postArchivoMultipar, postArchivoAdapterSchema } from '../../../../adapters/contenidoarchivo.adapter';
import { GetArchivosJSON, PostArchivoJSON, PatchArchivoJson} from '../../../../services/contenidoarchivo.service'
import { getURLQuery, getValuesParams, isModuleOrganizacion } from '../../../../helpers/helpers'


export const ModalArchivos = ({ data, handleCloseModal, fetchDocumentos}) => {
    const location = useLocation()
    const history = useHistory();

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        magic: '',
        page: 1,
    });

    const [fetchArchivos, fetchArchivosData] = GetArchivosJSON();

    const [fetchPatchArchivo, fetchPatchArchivoData] = PatchArchivoJson();


    const reloadTable = () =>{
        setTabPanel(1);
        fetchArchivos({
            params: {
                ...formValuesSearch,
                contenido_web_id: data?.id,
                page:1
            }
        })
    }

    useEffect(() => {
        if(data.value){
            fetchArchivos({
                params: {
                    ...formValuesSearch,
                    contenido_web_id: data?.id
                }
            })
        }else{
            setTabPanel(1)
            handleResetArchivo();
        }
    }, [data.value, formValuesSearch.page, formValuesSearch.orderby])

    const [tabPanel, setTabPanel] = useState(1)


    const inputFile = useRef(null)

    const [fetchCrearArchivo, fetchCrearArchivoData] = PostArchivoJSON()

    const [formArchivo, 
        handleInputChangeArchivo, 
        handleFormChangeArchivo, 
        handleResetArchivo] = useForm(postArchivoAdapterSchema())

    const [valuesValidDocumento, handleVerifiedValuesDocumento, handleResetValuesDocumento] = useValidator({
        titulo: { isOk: true, msgError: [`Ingrese un titúlo`] },
    })

    const handleSubmitArchivo = () => {
        if (handleVerifiedValuesDocumento(formArchivo)) {
            fetchCrearArchivo({
                body: {
                    ...formArchivo,
                    archivo: inputFile.current?.files?.[0] || '',
                    contenido_web_id: data?.id,
                }
            })
        }
    }

    const handleSubmitEditArchivo = () => {
        if (handleVerifiedValuesDocumento(formArchivo)) {
            fetchPatchArchivo({
                id: formArchivo?.id,
                body: {
                    ...formArchivo,
                    archivo: inputFile.current?.files?.[0] || '',
                    contenido_web_id: data?.id,
                    _method: 'PATCH'
                }
            })
        }
    }

    const addDataForm = (data) =>{
        handleFormChangeArchivo(data);
        setTabPanel(2);
    }

    useEffect(() => {
        if (fetchCrearArchivoData?.status === 200) {
            handleResetArchivo()
            reloadTable()
            fetchDocumentos()
        }

    }, [fetchCrearArchivoData])

     useEffect(() => {
        if (fetchPatchArchivoData?.status === 200) {
            handleResetArchivo()
            reloadTable()
            fetchDocumentos()
        }

    }, [fetchPatchArchivoData])

    return (
        <Modal show={data.value} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Listado de archivos</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>

                {tabPanel == 1 &&
                    <>
                        <div className='d-flex justify-content-end ms-auto mb-3'>
                            <Button variant="primary" size="sm" className="px-3 ms-3 d-flex align-items-center"
                                onClick={() => {
                                    setTabPanel(2);
                                    handleResetArchivo();
                                }}
                            >
                               <span className=''>&nbsp;Nuevo</span>
                            </Button>
                        </div>
                        <ArchivosMain
                        fetchArchivosData={fetchArchivosData}
                        fetchArchivos={fetchArchivos}
                        formValuesSearch={formValuesSearch}
                        handleFormChange={handleFormChange}
                        reloadTable={reloadTable}
                        addDataForm={addDataForm}
                        />
                    </>
                }
                {tabPanel == 2 &&
                     <>
                        <div className='d-flex justify-content-end ms-auto mb-3'>
                            <Button variant="warning" size="sm" className="px-3 ms-3 d-flex align-items-center"
                                onClick={() => {
                                    reloadTable(1);
                                    handleResetArchivo();
                                }}
                            >
                            <span className=''>&nbsp;Regresar</span>
                            </Button>
                        </div>
                       <ArchivosForm
                       reloadTable={reloadTable}
                       formArchivo={formArchivo}
                       valuesValidDocumento={valuesValidDocumento}
                       handleSubmitArchivo={handleSubmitArchivo}
                       handleSubmitEditArchivo={handleSubmitEditArchivo}
                       handleInputChangeArchivo={handleInputChangeArchivo}
                       fetchCrearArchivoData={fetchCrearArchivoData}
                       fetchPatchArchivoData={fetchPatchArchivoData}
                       inputFile={inputFile}

                       />
                     </>   
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
           
        </Modal>
    )
}
