import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useEffect } from 'react'
import { Button, Form, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import ButtonOrderBy from '../../../../../components/ButtonOrderBy/ButtonOrderBy'
import { InputSelect, InputText } from '../../../../../components/Inputs'
import { GetConsultoresJSON } from '../../../../../services/consultor.service'
import { Loader } from '../../../../../components/Loader/Loader'
import { PaginationT } from '../../../../../components/PaginationT/PaginationT'
import { getURLQuery, getValuesParams } from '../../../../../helpers/helpers'
import { useAxios } from '../../../../../hooks/useAxios'
import { useForm } from '../../../../../hooks/useForm'
import { useLang } from '../../../../../language'

export const ModalTableConsultores = ({ show, handleClose, setConsultorSelect }) => {


    // const [fetchParameters, fetchParametersData] = useAxios('GET', `/parametros`)

    // useEffect(() => {

    //     fetchParameters({
    //         params: {
    //             'colegios': 1,
    //             'perfiles_usuario': 1,
    //             'estados_colegiacion': 1,
    //         }
    //     });

    // }, [])

    const [fetchConsultores, fetchConsultoresData] = GetConsultoresJSON()

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        estado_id: '',
        filter_nif: '',
        num_colegiado: '',
        filter_name: '',
        filter_verificador: '',
        page: 1,
        order_by: '',
    });

    const handleSearch = (e) => {

        if (e) {
            e.preventDefault();
        }

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchConsultores({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    }

    useEffect(() => {

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        fetchConsultores({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order_by])

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Buscador de Consultores</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <FilterConsultor
                    fetchConsultoresData={fetchConsultoresData}
                    handleSearch={handleSearch}
                    handleInputChangeSearch={handleInputChangeSearch}
                    formValuesSearch={formValuesSearch}
                    handleFormChange={handleFormChange}
                    setConsultorSelect={setConsultorSelect}
                    handleClose={handleClose}
                />

            </Modal.Body>
        </Modal>
    )
}

const FilterConsultor = ({ handleSearch, handleInputChangeSearch, formValuesSearch,
    fetchConsultoresData, handleFormChange, setConsultorSelect, handleClose }) => {

    const handleSelect = (id, nombre) => {

        setConsultorSelect({
            id,
            nombre
        })

        handleClose()
    }

    const Lang = useLang();

    return (
        <div className='row'>
            <div className='col-12 mb-3'>
                <div className='Column-small shadow-sm'>


                    {/* <h5>{Lang('FILTRO')}</h5> */}
                    <Form className='row' onSubmit={handleSearch}>

                        <Form.Group className="col-6 col-sm-4 col-xl-4 mb-3">
                            <InputText
                                label='Nombre o apellidos:'
                                className=''
                                name='filter_name'
                                value={formValuesSearch.filter_name}
                                onChange={handleInputChangeSearch}
                            />
                        </Form.Group>

                        <Form.Group className="col-6 col-sm-4 col-xl-4 mb-3">
                            <InputText
                                label='NIF:'
                                className=''
                                name='filter_nif'
                                value={formValuesSearch.filter_nif}
                                onChange={handleInputChangeSearch}
                            />
                        </Form.Group>

                        <Form.Group className="col-12 col-sm-4 col-xl-4 mb-3">
                            <InputSelect
                                label='Es verificador:'
                                name='filter_verificador'
                                values={
                                    [
                                        { id: true, nombre: 'Si' },
                                        { id: false, nombre: 'No' },
                                    ]
                                }
                                value={formValuesSearch.filter_verificador}
                                onChange={handleInputChangeSearch}
                            />
                        </Form.Group>

                        <div className='mt-2'>
                            <button className="px-3 btn btn-primary float-end">
                                <FontAwesomeIcon icon={faSearch} /> {Lang('BUSCAR')}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>

            <div className="col-12">

                <>
                    {
                        fetchConsultoresData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    fetchConsultoresData?.data?.total ?

                                        <>
                                            <Table hover bordered responsive className='tableMain'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-nowrap'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`NIF`}
                                                                id='nif'
                                                            />
                                                        </th>
                                                        <th className='text-nowrap'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`Nombre`}
                                                                id='nombre'
                                                            />
                                                        </th>
                                                        <th className='text-nowrap text-center'>Teléfono</th>
                                                        <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`Es verificador`}
                                                                className='text-center'
                                                                id='es_verificador'
                                                            />
                                                        </th>
                                                        {/* <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={Lang('FECHA_ESTADO')}
                                                                id='fecha_estado'
                                                            />
                                                        </th> */}
                                                        <th className='text-center'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fetchConsultoresData?.data?.items?.map((item) => {
                                                        // console.log(`/consultores/${item.id}`)
                                                        return (
                                                            <tr key={item.id}>
                                                                <td valign='middle'>{item.nif}</td>
                                                                <td valign='middle'>{item.nombres_completo}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{item.telefono}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{item.es_verificador}</td>
                                                                <td valign='middle' className='text-center'>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <Button variant="primary" size="sm" className=''
                                                                                        onClick={() => handleSelect(item.nif, item.nombres_completo)}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchConsultoresData?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchConsultoresData?.data?.per_page}
                                            />
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No es van trobar resultats</h5>
                                }
                            </>
                    }
                </>
            </div>

        </div>
    )
}
