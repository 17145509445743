import React, { useContext } from 'react'
import { AuthContext } from '../../auth/AuthContext';
import { isModuleAdministrador, isModuleConsultor, isModuleOrganizacion } from '../../helpers/helpers';
import { MisDatosAdmin } from './MisDatosAdmin';
import { MisDatosConsultor } from './MisDatosConsultor';
import { MisDatosOrganizacion } from './MisDatosOrganizacion';

export const MisDatos = () => {

    const { user: { modulo_app } } = useContext(AuthContext);

    return (

        <>
            {
                isModuleOrganizacion(modulo_app) && <MisDatosOrganizacion /> 
            }
            {
                isModuleConsultor(modulo_app) && <MisDatosConsultor />
            }
            {
                isModuleAdministrador(modulo_app) && <MisDatosAdmin />
            }
        </>
    )
}
