import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, Form, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faSort, faEdit, faDownload, faNewspaper, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputText} from '../../../components/Inputs';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../hooks/useAxios';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';

export const ModalCategoria = ({data, handleCloseModal, handleFormChangeDocumento, formDocumento, fetchParameters}) => {
     const [fetchCrearCategoria, fetchCrearCategoriaData] = useAxios('POST', `/parametros/guardar_categoria`);   
     const [formCategoria, 
        handleInputChangeCategoria, 
        handleFormChangeCategoria, 
        handleResetCategoria] = useForm({titulo: ''})

     const [valuesValidCategoria, handleVerifiedValuesCategoria, handleResetValuesCategoria] = useValidator({
        titulo: { isOk: true, msgError: [`Ingrese un valor`] },
     })

     const handleSubmitCategoria = (e) => {
        if (handleVerifiedValuesCategoria(formCategoria)) {
            fetchCrearCategoria({
                body: {
                    ...formCategoria,
                    param_padre_id: 'clasificacion_documento',
                    modulo: 'tipo_documento',
                }
            })
        }
    }

    useEffect(() => {
        if (fetchCrearCategoriaData?.data?.status === 201) {
           const data = fetchCrearCategoriaData.data?.data?.data; 
           handleCloseModal()
           handleFormChangeDocumento({...formDocumento, tipo_documento: data.param_id})
           handleResetCategoria();
           fetchParameters({
                params: {
                    'idioma_id': 1,
                    'param_padre_id': 'clasificacion_documento',
                }
            });
        }
    }, [fetchCrearCategoriaData.data])

	 return (
        <Modal show={data?.value} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Nueva clasificación</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                <Form.Group className="mb-4 col-lg-12">
                     <InputText
                            label='Clasificación:'
                            className=''
                            name='titulo'
                            placeholder="Ingrese un valor"
                            value={formCategoria.titulo}
                            onChange={handleInputChangeCategoria}
                            validation={valuesValidCategoria}
                            fetchData={fetchCrearCategoriaData}
                        />
                 </Form.Group>

                 <div className="col-12 text-center">
                    <MsgInputServer fetchData={fetchCrearCategoriaData} className='mt-2' />
                 </div>  
            </Modal.Body>
            <Modal.Footer>
                <ButtonSpinner
                    variant="success" type="submit" className="ms-2"
                    handleSubmit={handleSubmitCategoria} fetchDataLoading={[fetchCrearCategoriaData.loading]}
                    value='Guardar' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                />   
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
     )   
}