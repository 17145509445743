import React, { useEffect, useRef, useState } from 'react'

import { Button, Modal, Form } from 'react-bootstrap'

import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner'
import { InputFile, InputSelect, InputText } from '../../components/Inputs'
import { MsgInput } from '../../components/MsgInput/MsgInput'
import { MsgInputServer } from '../../components/MsgInput/MsgInputServer'
import { useFetch } from '../../hooks/useFetch'
import { useForm } from '../../hooks/useForm'
import { useValidator } from '../../hooks/useValidator'
import Wizard from '../../components/Wizard/Wizard'
import { useAxios } from '../../hooks/useAxios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import InputImage from '../../components/Inputs/InputImage'
import { ModalCropImage } from '../../components/ModalCropImage/ModalCropImage'
import { getPoblacionesJSON } from '../../services/poblacion.service'
import { GetPaisJSON } from '../../services/pais.service'
import { ModalStatus } from '../../components/ModalStatusServer/ModalStatus'
import { ModalStatusError } from '../../components/ModalStatusServer/ModalStatusError'
import { ModalStatusServer2 } from '../../components/ModalStatusServer/ModalStatusServer2'

const ModalCrearEmpresa = ({ showModalRegistroEmpresa, handleCloseModalRegistroEmpresa, handleShowModalTerminosCondiciones,
    org_confrm_acepta_terminos_condiciones, setOrg_confrm_acepta_terminos_condiciones, org_confrm_acepta_prot_datos_pers,
    handleShowModalProteccionDatos, setOrg_confrm_acepta_prot_datos_pers }) => {

    const listWizard = [
        'Datos generales',
        'Acreditación',
    ]

    const [indexFocusWizard, setIndexFocusWizard] = useState(0);

    const [showStatusVerify2, setShowStatusVerify2] = useState(false);

    const [fetchRegistroEmpresa, fetchRegistroEmpresaData] = useAxios('post', `/auth/registroOrganizacion`)

    useEffect(() => {
        console.log(fetchRegistroEmpresaData)

        if (fetchRegistroEmpresaData?.data?.status === 201) {
            setIndexFocusWizard(next => next + 1)
        }

    }, [fetchRegistroEmpresaData])

    const [formRegisterEmpresa, handleInputChangeRegisterEmpresa, handleFormChangeRegisterEmpresa, handleResetRegisterEmpresa] = useForm({
        usuario: '',
        org_nombre: '',
        org_direccion: '',
        org_codigo_postal: '',
        org_poblacion: '',
        org_provincia: '',
        org_pais: '',
        email: '',
        org_telefono: '',
        org_web: '',

        org_persona_contacto_nombre: '',
        org_persona_contacto_cargo: '',
        org_persona_contacto_telefono: '',
        org_persona_contacto_email: '',

        org_contacto_facturacion_nombre: '',
        org_contacto_facturacion_email: '',
        org_contacto_facturacion_telefono: '',

        password: '',
        password_confirmation: '',

        org_confrm_veracidad_datos: '',
        org_confrm_acepta_terminos_condiciones: '',
        org_confrm_acepta_prot_datos_pers: '',
        org_tipo: '',
        org_tamanio: '',
    })

    const [valuesValidRegistroEmpresa, handleVerifiedValuesRegisterEmpresa, handleResetValuesRegisterEmpresa] = useValidator({
        usuario: { isOk: true, msgError: [`El documento es obligatorio.`] },
        org_nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        org_direccion: { isOk: true, msgError: [`La dirección es obligatoria.`] },
        org_codigo_postal: { isOk: true, msgError: [`El código postal es obligatorio.`] },
        org_poblacion: { isOk: true, msgError: [`La población es obligatoria.`] },
        org_provincia: { isOk: true, msgError: [`La província es obligatoria.`] },
        org_pais: { isOk: true, msgError: [`El país es obligatorio.`] },
        email: { isOk: true, msgError: [`El correo es obligatorio.`] },
        org_telefono: { isOk: true, msgError: [`El teléfono es obligatorio.`] },

        org_persona_contacto_nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        org_persona_contacto_cargo: { isOk: true, msgError: [`El cargo es obligatorio.`] },
        org_persona_contacto_telefono: { isOk: true, msgError: [`El teléfono es obligatorio.`] },
        org_persona_contacto_email: { isOk: true, msgError: [`El email es obligatorio.`] },

        org_contacto_facturacion_nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        org_contacto_facturacion_email: { isOk: true, msgError: [`El email es obligatorio.`] },
        org_contacto_facturacion_telefono: { isOk: true, msgError: [`El teléfono es obligatorio.`] },

        password: { isOk: true, msgError: [`La contraseña es obligatoria.`] },
        password_confirmation: { isOk: true, msgError: [`La confirmación de contraseña es obligatoria.`] },

        org_confrm_veracidad_datos: { isOk: true, msgError: [`Ha de aceptar la veracidad de los datos`] },
        org_confrm_acepta_terminos_condiciones: { isOk: true, msgError: [`Ha de aceptar los términos y condiciones`] },
        org_confrm_acepta_prot_datos_pers: { isOk: true, msgError: [`Ha de aceptar la protección de datos`] },
        
        org_tipo: { isOk: true, msgError: [`El tipo de empresa es obligatorio.`] },
        org_tamanio: { isOk: true, msgError: [`El tamaño es obligatorio.`] },
    })


    useEffect(() => {

        handleFormChangeRegisterEmpresa({
            ...formRegisterEmpresa,
            org_confrm_acepta_terminos_condiciones: org_confrm_acepta_terminos_condiciones ? org_confrm_acepta_terminos_condiciones : ''
        })
    }, [org_confrm_acepta_terminos_condiciones])


    useEffect(() => {

        handleFormChangeRegisterEmpresa({
            ...formRegisterEmpresa,
            org_confrm_acepta_prot_datos_pers: org_confrm_acepta_prot_datos_pers ? org_confrm_acepta_prot_datos_pers : ''
        })
    }, [org_confrm_acepta_prot_datos_pers])

    const handleChangeVeracidad = (e) => {

        handleFormChangeRegisterEmpresa({
            ...formRegisterEmpresa,
            org_confrm_veracidad_datos: e.target.checked ? true : ''
        })
    }

    useEffect(() => {
        console.log(formRegisterEmpresa)

    }, [formRegisterEmpresa])


    // Checks para adjuntar documentacio nde acreditacion

    const [valuesValidISO, handleVerifiedValuesISO, handleResetValuesISO] = useValidator({
        file_iso14001: { isOk: true, msgError: [`Es necesario adjuntar el documento acreditativo`] },
    })

    const [valuesValidEmacs, handleVerifiedValuesEmacs, handleResetValuesEmacs] = useValidator({
        file_emacs: { isOk: true, msgError: [`Es necesario adjuntar el documento acreditativo`] },
    })

    const [showInputFileISO, setshowInputFileISO] = useState(false)

    const handleChangeISO = (e) => {

        handleFormChangeRegisterEmpresaFiles({
            ...formRegisterEmpresaFiles,
            file_iso14001: e.target.checked ? true : ''
        })
        setshowInputFileISO(e.target.checked ? true : false)
    }

    const [showInputFileEMACS, setshowInputFileEMACS] = useState(false)

    const handleChangeEMACS = (e) => {
        handleFormChangeRegisterEmpresaFiles({
            ...formRegisterEmpresaFiles,
            file_emacs: e.target.checked ? true : ''
        })
        setshowInputFileEMACS(e.target.checked ? true : false)
    }

    // Registro subir archivos

    const [fetchRegistroEmpresaFileISO, fetchRegistroEmpresaFileISOData] = useAxios('post', `/auth/registroOrganizacion/documentos`)
    const [fetchRegistroEmpresaFileEMACS, fetchRegistroEmpresaFileEMACSData] = useAxios('post', `/auth/registroOrganizacion/documentos`)

    const [formRegisterEmpresaFiles, handleInputChangeRegisterEmpresaFiles,
        handleFormChangeRegisterEmpresaFiles, handleResetRegisterEmpresaFiles] = useForm({
            file_iso14001: '',
            file_emacs: '',
        })

    const inputISO = useRef(null)
    const inputEmacs = useRef(null)

    const handleSubmitNextStep2 = (e) => {

        e.preventDefault()

        let formDataISO = new FormData();

        if (inputISO.current?.files[0]) {
            formDataISO.append("documento", inputISO.current.files[0])
            formDataISO.append("org_cif", fetchRegistroEmpresaData.data?.data?.data?.[0]?.organizaciones?.[0]?.org_cif)
            formDataISO.append("token_docs", fetchRegistroEmpresaData.data?.data?.data?.[0]?.organizaciones?.[0]?.org_token_docs_sist_gestion)
            formDataISO.append("type", 'iso14001')
        }

        let formDataEMACS = new FormData();

        if (inputEmacs.current?.files[0]) {
            formDataEMACS.append("documento", inputEmacs.current.files[0])
            formDataEMACS.append("org_cif", fetchRegistroEmpresaData.data?.data?.data?.[0]?.organizaciones?.[0]?.org_cif)
            formDataEMACS.append("token_docs", fetchRegistroEmpresaData.data?.data?.data?.[0]?.organizaciones?.[0]?.org_token_docs_sist_gestion)
            formDataEMACS.append("type", 'emacs')
        }

        console.log(formRegisterEmpresaFiles)

        if (formRegisterEmpresaFiles.file_iso14001 && !formRegisterEmpresaFiles.file_emacs) {

            if (handleVerifiedValuesISO({ file_iso14001: inputISO.current.files[0] })) {

                fetchRegistroEmpresaFileISO({ body: formDataISO })
            }

        } else if (!formRegisterEmpresaFiles.file_iso14001 && formRegisterEmpresaFiles.file_emacs) {

            if (handleVerifiedValuesEmacs({ file_emacs: inputEmacs.current.files[0] })) {

                fetchRegistroEmpresaFileEMACS({ body: formDataEMACS })
            }

        } else if (formRegisterEmpresaFiles.file_iso14001 && formRegisterEmpresaFiles.file_emacs) {
            if (handleVerifiedValuesISO({ file_iso14001: inputISO.current.files[0] }) &&
                handleVerifiedValuesEmacs({ file_emacs: inputEmacs.current.files[0] })) {

                fetchRegistroEmpresaFileISO({ body: formDataISO })
                fetchRegistroEmpresaFileEMACS({ body: formDataEMACS })
            }
        } else {
            handleCloseModalRegistroEmpresa()
        }

    }

    useEffect(() => {

        console.log('formRegisterEmpresaFiles', formRegisterEmpresaFiles)

        if (formRegisterEmpresaFiles.file_iso14001 && !formRegisterEmpresaFiles.file_emacs) {

            if (fetchRegistroEmpresaFileISOData?.data?.status === 201) {
                handleCloseModalRegistroEmpresa()
            }

        } else if (!formRegisterEmpresaFiles.file_iso14001 && formRegisterEmpresaFiles.file_emacs) {

            if (fetchRegistroEmpresaFileEMACSData?.data?.status === 201) {
                handleCloseModalRegistroEmpresa()
            }

        } else if (fetchRegistroEmpresaFileISOData?.data?.status === 201 &&
            fetchRegistroEmpresaFileEMACSData?.data?.status === 201) {
            handleCloseModalRegistroEmpresa()
        }


    }, [fetchRegistroEmpresaFileISOData, fetchRegistroEmpresaFileEMACSData, formRegisterEmpresaFiles])

    useEffect(() => {

        if (!showModalRegistroEmpresa) {
            if (fetchRegistroEmpresaData?.data?.status === 201) {
                setShowStatusVerify2(true)
            }
        }
    }, [showModalRegistroEmpresa])

    // Reseteando datos

    useEffect(() => {
        if (!showModalRegistroEmpresa) {
            handleResetRegisterEmpresa()
            handleResetValuesRegisterEmpresa()
            setOrg_confrm_acepta_terminos_condiciones(false)
            setOrg_confrm_acepta_prot_datos_pers(false)
            handleResetValuesISO()
            handleResetValuesEmacs()
            setIndexFocusWizard(0)
            handleResetRegisterEmpresaFiles()
            setshowInputFileISO(false)
            setshowInputFileEMACS(false)
        }
    }, [showModalRegistroEmpresa])

    // Registro submit empresa

    const handleSubmitFormRegisterEmpresa = (e) => {

        console.log('formRegisterEmpresa', formRegisterEmpresa)

        e.preventDefault()

        if (handleVerifiedValuesRegisterEmpresa(formRegisterEmpresa)) {

            let formDataCrearEmpresa = new FormData();

            for (const key in formRegisterEmpresa) {
                formDataCrearEmpresa.append(key, formRegisterEmpresa[key])
            }

            if (InputFiles.length > 0) {
                formDataCrearEmpresa.append("logo", InputFiles[0])
            }

            fetchRegistroEmpresa({ body: formDataCrearEmpresa })

        }
    }

    // const inputFileRef = useRef();


    // Recortar imagen

    const [InputFiles, setInputFiles] = useState([])

    // Modal

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        file: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        file: null
    })
    const handleShowModalCropImage = (file) => setShowModalCropImage({
        value: true,
        file
    });

    const handleChangeInputImage = (e) => {
        if (e.target.files.length > 0) {
            handleShowModalCropImage(e.target.files[0])
        }
    }

    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useAxios('get', `/poblacion`)
    // const [fetchPoblaciones, fetchPoblacionesData] = getPoblacionesJSON()()

    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (activeEspana) {
            if (formRegisterEmpresa.org_codigo_postal?.length === 5) {

                fetchPoblaciones({
                    params: {
                        cp: formRegisterEmpresa.org_codigo_postal
                    }
                })
            } else {
                setPoblaciones([])
                handleFormChangeRegisterEmpresa({
                    ...formRegisterEmpresa,
                    org_provincia: '',
                    org_poblacion: '',
                    // pais: '',
                })
            }
        }

    }, [formRegisterEmpresa.org_codigo_postal])

    useEffect(() => {

        console.log(fetchPoblacionesData)

        // if (fetchPoblacionesData.data) {

        if (fetchPoblacionesData.data?.status === 201) {

            const poblaciones = fetchPoblacionesData.data?.data?.data

            let poblacionesTemp = poblaciones.map(item => {
                return ({
                    id: item.poblacion,
                    nombre: item.poblacion
                })
            })

            setPoblaciones(poblacionesTemp)

        } else {
            handleFormChangeRegisterEmpresa({
                ...formRegisterEmpresa,
                org_provincia: '',
                org_poblacion: '',
                // pais: '',
            })
        }
        // }

    }, [fetchPoblacionesData])

    useEffect(() => {

        if (formRegisterEmpresa.org_poblacion && fetchPoblacionesData.data && activeEspana) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data.data]

            console.log(poblacionesTemp)

            const resp = poblacionesTemp.find(item => item.poblacion === formRegisterEmpresa.org_poblacion)

            handleFormChangeRegisterEmpresa({
                ...formRegisterEmpresa,
                org_provincia: resp?.provincia,
                // pais: resp?.pais,
            })
        }

    }, [formRegisterEmpresa.org_poblacion, fetchPoblacionesData.data])

    // funcionalidad de pais

    const [fetchPaises, fetchPaisesData] = GetPaisJSON()

    useEffect(() => {
        fetchPaises()
    }, [])


    const [activeEspana, setactiveEspana] = useState(false)

    useEffect(() => {

        if (formRegisterEmpresa.org_pais === '1' || formRegisterEmpresa.org_pais === 1) {

            setactiveEspana(true)
            fetchPoblaciones({
                params: {
                    cp: formRegisterEmpresa.org_codigo_postal
                }
            })
        } else {
            setactiveEspana(false)
            handleFormChangeRegisterEmpresa({
                ...formRegisterEmpresa,
            })
        }

    }, [formRegisterEmpresa.org_pais])


    //parametros
    //tamanio_empresa
    const [fetchParameters, fetchParametersData] = useAxios('POST', `/parametros_generales`)
    const [fetchParametersType, fetchParametersTypeData] = useAxios('POST', `/parametros_generales`)
    useEffect(() => {
        fetchParameters({
                body: [
                    {
                        "param": "tipo_empresa",
                        "locale": 1,
                        "active": true
                    },
                ]
            });
    }, [])

    useEffect(() => {
        if(formRegisterEmpresa.org_tipo === 'asociacion'){
            fetchParametersType({
                body: [
                    {
                        "param": "tamanio_asociacion",
                        "locale": 1,
                        "active": true
                    },
                ]
            });
        }else if(formRegisterEmpresa.org_tipo === 'empresa'){
             fetchParametersType({
                body: [
                    {
                        "param": "tamanio_empresa",
                        "locale": 1,
                        "active": true
                    },
                ]
            });
        }
    }, [formRegisterEmpresa.org_tipo])

    return (
        <>
            <Modal show={showModalRegistroEmpresa} onHide={handleCloseModalRegistroEmpresa} size='lg' id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h5 mx-auto'>Registro de empresa</Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModalRegistroEmpresa}></button>
                </Modal.Header>
                <Modal.Body className='p-0'>

                    <Wizard>
                        <Wizard.Header
                            listWizard={listWizard}
                            indexFocusWizard={indexFocusWizard}
                        // setIndexFocusWizard={setIndexFocusWizard}
                        />
                        <Wizard.Body indexFocusWizard={indexFocusWizard}>
                            <section style={{ 'backgroundColor': '#f2f4f6' }}>
                                <div className="container animate__faster animate__animated animate__fadeInLeftModal">
                                    <Form className="row pt-3 pb-4 px-3" autoComplete="off">
                                        <div className="col-12">
                                            <h5 className='mb-3 mt-2'>Datos de la empresa</h5>
                                        </div>

                                        <Form.Group className="mb-3 col-sm-7 col-lg-3">
                                            <InputText
                                                label='CIF *'
                                                className=''
                                                name='usuario'
                                                value={formRegisterEmpresa.usuario}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-lg-9">
                                            <InputText
                                                label='Razón social *'
                                                className=''
                                                name='org_nombre'
                                                value={formRegisterEmpresa.org_nombre}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-sm-7 col-lg-3">
                                            <InputSelect
                                                label='Tipo de Empresa:'
                                                name='org_tipo'
                                                values={
                                                    fetchParametersData.data?.data?.data?.tipo_empresa?.map((item, index) => {
                                                        return {
                                                           id: item.param_id,
                                                           nombre: item.pt_texto
                                                        }
                                                    })
                                                  }
                                                value={formRegisterEmpresa.org_tipo}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-lg-9">
                                           <InputSelect
                                                label={formRegisterEmpresa.org_tipo === 'asociacion' ? 'Tamaño Asociación' : 'Tamaño Empresa' }
                                                name='org_tamanio'
                                                values={formRegisterEmpresa.org_tipo === 'asociacion' 
                                                    ? 
                                                        fetchParametersTypeData.data?.data?.data?.tamanio_asociacion?.map((item, index) => {
                                                            return {
                                                               id: item.param_id,
                                                               nombre: item.pt_texto
                                                            }
                                                        })
                                                    :
                                                        fetchParametersTypeData.data?.data?.data?.tamanio_empresa?.map((item, index) => {
                                                            return {
                                                               id: item.param_id,
                                                               nombre: item.pt_texto
                                                            }
                                                        })
                                                }
                                                value={formRegisterEmpresa.org_tamanio}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>


                                        <div className="col-12"></div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-8">

                                                    <div className="row">
                                                        <Form.Group className="mb-3 col-lg-12">
                                                            <InputText
                                                                label='Dirección *'
                                                                className=''
                                                                name='org_direccion'
                                                                value={formRegisterEmpresa.org_direccion}
                                                                onChange={handleInputChangeRegisterEmpresa}
                                                                validation={valuesValidRegistroEmpresa}
                                                                fetchData={fetchRegistroEmpresaData}
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="mb-3 col-sm-6 col-lg-5">
                                                            <InputSelect
                                                                label='País *'
                                                                className=''
                                                                name='org_pais'
                                                                // values={[
                                                                //     { id: 'es', nombre: 'España' },
                                                                //     { id: 'it', nombre: 'Italia' },
                                                                // ]}
                                                                values={fetchPaisesData.data || []}
                                                                value={formRegisterEmpresa.org_pais}
                                                                onChange={handleInputChangeRegisterEmpresa}
                                                                validation={valuesValidRegistroEmpresa}
                                                                fetchData={fetchRegistroEmpresaData}
                                                            // disabled={!fetchPoblacionesData.data || formRegisterEmpresa.org_codigo_postal?.length !== 5}
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="mb-3 col-sm-6 col-lg-4">
                                                            <InputText
                                                                label='Código Postal *'
                                                                className=''
                                                                name='org_codigo_postal'
                                                                value={formRegisterEmpresa.org_codigo_postal}
                                                                onChange={handleInputChangeRegisterEmpresa}
                                                                validation={valuesValidRegistroEmpresa}
                                                                fetchData={fetchRegistroEmpresaData}
                                                            />
                                                        </Form.Group>
                                                        {
                                                            activeEspana ? <>
                                                                <Form.Group className="mb-3 col-sm-6 col-lg-8">
                                                                    <InputSelect
                                                                        label='Población *'
                                                                        className=''
                                                                        name='org_poblacion'
                                                                        // values={[]}
                                                                        values={poblaciones}
                                                                        value={formRegisterEmpresa.org_poblacion}
                                                                        onChange={handleInputChangeRegisterEmpresa}
                                                                        validation={valuesValidRegistroEmpresa}
                                                                        fetchData={fetchRegistroEmpresaData}
                                                                        disabled={!fetchPoblacionesData.data || formRegisterEmpresa.org_codigo_postal?.length !== 5}
                                                                    />

                                                                </Form.Group>
                                                            </> : <>
                                                                <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                                    <InputText
                                                                        label='Región *'
                                                                        className=''
                                                                        name='org_poblacion'
                                                                        value={formRegisterEmpresa.org_poblacion}
                                                                        onChange={handleInputChangeRegisterEmpresa}
                                                                        validation={valuesValidRegistroEmpresa}
                                                                        fetchData={fetchRegistroEmpresaData}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                                    <InputText
                                                                        label='Ciudad *'
                                                                        className=''
                                                                        name='org_provincia'
                                                                        value={formRegisterEmpresa.org_provincia}
                                                                        onChange={handleInputChangeRegisterEmpresa}
                                                                        validation={valuesValidRegistroEmpresa}
                                                                        fetchData={fetchRegistroEmpresaData}
                                                                    />
                                                                </Form.Group>
                                                            </>
                                                        }

                                                        <Form.Group className="mb-3 col-sm-6 col-lg-5">
                                                            <InputText
                                                                label='Teléfono *'
                                                                className=''
                                                                name='org_telefono'
                                                                value={formRegisterEmpresa.org_telefono}
                                                                onChange={handleInputChangeRegisterEmpresa}
                                                                validation={valuesValidRegistroEmpresa}
                                                                fetchData={fetchRegistroEmpresaData}
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="mb-3 col-lg-12">
                                                            <InputText
                                                                label='Web'
                                                                className=''
                                                                name='org_web'
                                                                value={formRegisterEmpresa.org_web}
                                                                onChange={handleInputChangeRegisterEmpresa}
                                                                fetchData={fetchRegistroEmpresaData}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-sm-9 col-lg-12">
                                                            <InputImage
                                                                label='Logotipo'
                                                                width='100%'
                                                                height='13rem'
                                                                onChange={handleChangeInputImage}
                                                                InputFiles={InputFiles}
                                                                setInputFiles={setInputFiles}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-12">
                                            <h5 className='mb-3 mt-2'>Datos de acceso</h5>
                                        </div>


                                        <Form.Group className="mb-3 col-lg-7">
                                            <InputText
                                                label='Dirección electrónica *'
                                                className=''
                                                name='email'
                                                value={formRegisterEmpresa.email}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                            <small className='d-block mt-2'>
                                                Éste email será utilizado para las comunicaciones.
                                            </small>
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-lg-6">
                                            <InputText
                                                label='Contraseña *'
                                                className=''
                                                name='password'
                                                type='password'
                                                value={formRegisterEmpresa.password}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-lg-6">
                                            <InputText
                                                label='Repetir contraseña *'
                                                className=''
                                                type='password'
                                                name='password_confirmation'
                                                value={formRegisterEmpresa.password_confirmation}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <div className="col-12">
                                            <h5 className='mb-3 mt-2'>Datos de contacto</h5>
                                        </div>

                                        <Form.Group className="mb-3 col-sm-7 col-lg-6">
                                            <InputText
                                                label='Persona de contacto *'
                                                className=''
                                                name='org_persona_contacto_nombre'
                                                value={formRegisterEmpresa.org_persona_contacto_nombre}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-sm-7 col-lg-5">
                                            <InputText
                                                label='Cargo *'
                                                className=''
                                                name='org_persona_contacto_cargo'
                                                value={formRegisterEmpresa.org_persona_contacto_cargo}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <div className="col-12"></div>

                                        <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                            <InputText
                                                label='Teléfono *'
                                                className=''
                                                name='org_persona_contacto_telefono'
                                                value={formRegisterEmpresa.org_persona_contacto_telefono}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-lg-6">
                                            <InputText
                                                label='Dirección electrónica *'
                                                className=''
                                                name='org_persona_contacto_email'
                                                value={formRegisterEmpresa.org_persona_contacto_email}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <div className="col-12">
                                            <h5 className='mb-3 mt-2'>Contacto para facturación</h5>
                                        </div>

                                        <Form.Group className="mb-3 col-sm-7 col-lg-6">
                                            <InputText
                                                label='Persona de contacto para facturación *'
                                                className=''
                                                name='org_contacto_facturacion_nombre'
                                                value={formRegisterEmpresa.org_contacto_facturacion_nombre}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                            <InputText
                                                label='Teléfono *'
                                                className=''
                                                name='org_contacto_facturacion_telefono'
                                                value={formRegisterEmpresa.org_contacto_facturacion_telefono}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 col-lg-6">
                                            <InputText
                                                label='Dirección electrónica *'
                                                className=''
                                                name='org_contacto_facturacion_email'
                                                value={formRegisterEmpresa.org_contacto_facturacion_email}
                                                onChange={handleInputChangeRegisterEmpresa}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchRegistroEmpresaData}
                                            />
                                        </Form.Group>

                                        <div className="col-12 mt-2 pb-2" key='org_confrm_veracidad_datos'>
                                            <Form.Check
                                                type='checkbox'
                                                name='org_confrm_veracidad_datos'
                                                value={formRegisterEmpresa.org_confrm_veracidad_datos}
                                                onChange={handleChangeVeracidad}
                                                id='org_confrm_veracidad_datos'

                                                label='La empresa confirma la veracidad de los datos que comunica en la presente solicitud.'
                                            />
                                            <MsgInput obj={valuesValidRegistroEmpresa} name='org_confrm_veracidad_datos' />

                                        </div>

                                        <div className="col-12 pb-2" key='org_confrm_acepta_terminos_condiciones'>
                                            <Form.Check
                                                type='checkbox'
                                                name='org_confrm_acepta_terminos_condiciones'
                                                checked={formRegisterEmpresa.org_confrm_acepta_terminos_condiciones}
                                                onChange={(e) => {
                                                    if (e.target.checked === true) {
                                                        e.preventDefault()
                                                        handleShowModalTerminosCondiciones()
                                                    }
                                                    else {
                                                        setOrg_confrm_acepta_terminos_condiciones(false)
                                                    }
                                                }}
                                                id='org_confrm_acepta_terminos_condiciones'
                                                label={
                                                    <div className="cursor-pointer">
                                                        La empresa ha leído y acepta los <span className="text-decoration-underline">Términos y condiciones del Programa DAPconstrucción® para empresas.</span>
                                                    </div>
                                                }
                                            />
                                            <MsgInput obj={valuesValidRegistroEmpresa} name='org_confrm_acepta_terminos_condiciones' />

                                        </div>


                                        <div className="col-12" key='org_confrm_acepta_prot_datos_pers'>
                                            <Form.Check
                                                type='checkbox'
                                                name='org_confrm_acepta_prot_datos_pers'
                                                checked={formRegisterEmpresa.org_confrm_acepta_prot_datos_pers}
                                                id='org_confrm_acepta_prot_datos_pers'
                                                onChange={(e) => {
                                                    if (e.target.checked === true) {
                                                        e.preventDefault()
                                                        handleShowModalProteccionDatos()
                                                    }
                                                    else {
                                                        setOrg_confrm_acepta_prot_datos_pers(false)
                                                    }
                                                }}
                                                label={
                                                    <div className="cursor-pointer">
                                                        La empresa ha leído y acepta la <span className="text-decoration-underline">Información relativa a la protección de datos de carácter personal.</span>
                                                    </div>
                                                }
                                            />
                                            <MsgInput obj={valuesValidRegistroEmpresa} name='org_confrm_acepta_prot_datos_pers' />

                                        </div>


                                        <div className="col-12 mt-2 text-center">
                                            {/* <MsgInputServer fetchData={fetchRegistroEmpresaData} className='mt-4' /> */}
                                        </div>

                                    </Form>
                                </div>
                                <Modal.Footer style={{ 'backgroundColor': '#fff' }}>
                                    <Button variant="default" onClick={handleCloseModalRegistroEmpresa} className='px-3'>
                                        Salir
                                    </Button>
                                    <ButtonSpinner
                                        variant="primary" type="submit" className="ms-3 px-3"
                                        handleSubmit={handleSubmitFormRegisterEmpresa} fetchDataLoading={[fetchRegistroEmpresaData?.loading]}
                                        value='Guardar y continuar'
                                    />
                                </Modal.Footer>
                            </section>
                            <section style={{ 'backgroundColor': '#f2f4f6' }}>
                                <div className="container animate__faster animate__animated animate__fadeInLeftModal">
                                    <div className="row pt-3 pb-4 px-3">
                                        <div className="col-12">
                                            <h5 className='mb-3 mt-2'>Sistemas de gestión medioambiental de la empresa</h5>
                                        </div>

                                        <div className="col-12 mt-2 pb-2" key='iso14001'>
                                            <Form.Check
                                                type='checkbox'
                                                name='iso14001'
                                                checked={formRegisterEmpresaFiles.file_iso14001}
                                                onChange={handleChangeISO}
                                                id='iso14001'
                                                label={
                                                    <>
                                                        <div>La empresa dispone de la acreditación Sistema de gestión Medioambiental ISO 14001.</div>
                                                        <div>(adjuntar documento acreditativo)</div>
                                                    </>
                                                }
                                            />
                                            <MsgInput obj={valuesValidRegistroEmpresa} name='iso14001' />

                                        </div>

                                        {
                                            showInputFileISO && <Form.Group controlId="formFile" className="mb-3 ps-4 ms-1">
                                                <InputFile
                                                    name='file_iso14001'
                                                    onChange={handleInputChangeRegisterEmpresaFiles}
                                                    referencia={inputISO}
                                                    validation={valuesValidISO}
                                                />
                                            </Form.Group>
                                        }

                                        <div className="col-12 mt-2 pb-2" key='emacs'>
                                            <Form.Check
                                                type='checkbox'
                                                name='emacs'
                                                value={formRegisterEmpresaFiles.file_emacs}
                                                onChange={handleChangeEMACS}
                                                id='emacs'

                                                label={
                                                    <>
                                                        <div>La empresa dispone de la acreditación Sistema de gestión Medioambiental EMAS.</div>
                                                        <div>(adjuntar documento acreditativo)</div>
                                                    </>
                                                }
                                            />
                                            <MsgInput obj={valuesValidRegistroEmpresa} name='emacs' />

                                        </div>

                                        {
                                            showInputFileEMACS && <Form.Group controlId="formFile" className="mb-3 ps-4 ms-1">
                                                <InputFile
                                                    name='file_emacs'
                                                    onChange={handleInputChangeRegisterEmpresaFiles}
                                                    referencia={inputEmacs}
                                                    validation={valuesValidEmacs}
                                                />
                                            </Form.Group>
                                        }

                                        <div className="col-12 mt-2 text-center">
                                            {/* <MsgInputServer fetchData={fetchRegistroEmpresaData} className='mt-4' /> */}
                                        </div>

                                        {/* <div className="col-12">
                                            <small>
                                                Si no posee acreditaciones, deje las casillas en blanco
                                            </small>
                                        </div> */}

                                    </div>
                                </div>
                                <Modal.Footer style={{ 'backgroundColor': '#fff' }}>
                                    <ButtonSpinner
                                        variant="primary" type="submit" className="ms-3 px-3"
                                        handleSubmit={handleSubmitNextStep2} fetchDataLoading={[fetchRegistroEmpresaFileISOData.loading || fetchRegistroEmpresaFileEMACSData.loading]}
                                        value='Finalizar'
                                    />
                                </Modal.Footer>
                            </section>
                        </Wizard.Body>
                    </Wizard>

                </Modal.Body>
            </Modal>
            <ModalCropImage
                show={showModalCropImage}
                handleClose={handleCloseModalCropImage}
                height='500px'
                setInputFiles={setInputFiles}
                escalas={[
                    { x: 1, y: 1, label: 'Cuadrado' },
                    { x: 4, y: 2, label: 'Rectángulo' }
                ]}
            />

            <ModalStatus setShowStatus={setShowStatusVerify2} showStatus={showStatusVerify2}>
                <div className="py-0 text-center">
                    {/* <h5 className='mb-4 fw-normal'>Confirmar registro</h5> */}
                    <p className='mb-0'>
                        {fetchRegistroEmpresaData?.data?.data?.message}
                        {/* Hemos enviado un enlace de confirmación a tu correo electrónico. */}
                        {/* <br />
                        Si no has recibido el enlace ponte en contacto con nosotros en el telf. 932 40 20 60. Antes, revisa tu bandeja de spam */}
                    </p>
                </div>
            </ModalStatus>

            <ModalStatusServer2 fetchData={fetchRegistroEmpresaData} onlyError />


        </>
    )
}

export default ModalCrearEmpresa