import { faCheckCircle, faDownload, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import { Form, ListGroup, Table } from 'react-bootstrap'
import { Prompt } from 'react-router-dom';
import { useAxios } from '../../../../hooks/useAxios';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';
import { InputSelect, InputText } from '../../../../components/Inputs';
import { Loader } from '../../../../components/Loader/Loader';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';
import { AuthContext } from '../../../../auth/AuthContext';
import { images } from '../../../../helpers/images';
import { FileList } from '../../../../components/FileList/FileList';
import { TooltipLock } from '../../../../components/TooltipLock/TooltipLock';
import { ModalStatusError } from '../../../../components/ModalStatusServer/ModalStatusError';
import { getDataFile, isStatusSucess } from '../../../../helpers/helpers';
import { ModalStatusServer2 } from '../../../../components/ModalStatusServer/ModalStatusServer2';

export const DAPconSectionEmpresa0 = ({ id, fetchFichaResumenData, dapcons_codigo, idiomaDAPcon, formDAPcon,
    handleInputChangeDAPcon, fetchFichaResumen }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const [fetchRCP, fetchRCPData] = useAxios('get', `/rcp`)
    const [fetchVerificadores, fetchVerificadoresData] = useAxios('get', `/verificadores`)
    // const [fetchParametros, fetchParametrosData] = useAxios('get', '/parametros')

    const [database, setdatabase] = useState([]);

    useEffect(() => {
        fetchRCP()
        fetchVerificadores()
        // fetchParametros({
        //     id: 'database_dapcons',
        //     params: {
        //         arbol: false
        //     }
        // })
    }, [])


    // useEffect(() => {

    //     if (fetchParametrosData.data?.status === 200) {

    //         const arrayParams = fetchParametrosData.data?.data?.data['database_dapcons'].parametros?.map(item => {
    //             return {
    //                 id: item.param_id || '',
    //                 nombre: item.param_textos?.[0]?.pt_texto || '',
    //             }
    //         })

    //         setdatabase(arrayParams)
    //     }

    // }, [fetchParametrosData]);

    const [listRCP, setListRCP] = useState([])

    useEffect(() => {

        if (fetchRCPData.data?.status === 200) {
            const listTemp = fetchRCPData?.data?.data?.data?.map(item => {

                const item2 = item.rcp_idiomas?.find(item2 => item2.idioma_id === idiomaDAPcon) || {}

                return {
                    id: Number(item.rcp_id),
                    nombre: `${item.rcp_equivalencia} ${item2.rcp_nombre || ''}`
                }
            })
            setListRCP(listTemp)
        }

    }, [fetchRCPData])

    // Preparando select de Verificadores

    const [listVerificadores, setListVerificadores] = useState([])

    useEffect(() => {

        if (fetchVerificadoresData.data?.status === 200) {
            const listTemp = fetchVerificadoresData?.data?.data?.data?.map(item => {
                return {
                    id: item.consultor_nif,
                    nombre: `${item.consultor_nombre} ${item.consultor_apellidos}`
                }
            })
            setListVerificadores(listTemp)
        }

    }, [fetchVerificadoresData])

    const [valuesValidationDAPcon, handleValidationDAPcon, handleResetValidationDAPcon] = useValidator({
        dv_nombre_producto: { isOk: true, msgError: [`El nombre del producto es obligatorio.`] },
        dv_descripcion_producto: { isOk: true, msgError: [`La descripción del producto es obligatoria.`] },
        verificador_nif: { isOk: true, msgError: [`El verificador es obligatorio.`] },
        dapcons_rcp_id: { isOk: true, msgError: [`El RCP de referencia es obligatorio.`] },
    })

    const [valuesValidJustificacion, handleVerifiedValuesJustificacion, handleResetValuesJustificacion] = useValidator({
        justificacion_rcp: { isOk: true, msgError: [`La justificación es obligatoria.`] },
    })

    useEffect(() => {
        console.log(formDAPcon)
    }, [formDAPcon])

    const [isBlocking, setIsBlocking] = useState(false);

    const [fetchFichaResumenEdit, fetchFichaResumenEditData] = useAxios('patch', `/dapcons/${id}/update-by-org`)

    const handleSubmitDAPconDatReqEdit = (e) => {

        e.preventDefault()

        if (showJustificacion ?
            handleVerifiedValuesJustificacion(formDAPcon) && handleValidationDAPcon(formDAPcon)
            : handleValidationDAPcon(formDAPcon)) {

            fetchFichaResumenEdit({
                body: {
                    ...formDAPcon,
                    nif: formDAPcon.consultor.consultor_nif,
                    idioma_id: idiomaDAPcon
                }
            })
        }
    }

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        console.log(fetchFichaResumenEditData.data)
        if (fetchFichaResumenEditData.data?.status === 201) {
            fetchFichaResumen({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
            setShowSuccessEdit(true)
        }

    }, [fetchFichaResumenEditData])

    const [fetchDAPconHtml, fetchDAPconHtmlData] = useAxios('get', `/dapcons-pdf/${id}`, '', 'blob')


    const handleFetchDAPcons = () => {
        // const arrayLang = ['es', 'en']

        // console.log(arrayLang[idiomaDAPcon])
        fetchDAPconHtml({
            params: {
                idioma_id: idiomaDAPcon
            }
        })
    }

    useEffect(() => {
        if (fetchDAPconHtmlData.data) {
            // 2. Create blob link to download
            const instanceBlob = new Blob([fetchDAPconHtmlData.data?.data])

            const namePDF = `${formDAPcon.dapcons_codigo || 'DAPcons-' + id}.${instanceBlob.type || 'pdf'}`

            const date = new Date()

            const url = window.URL.createObjectURL(instanceBlob);
            const link = document.createElement('a');

            link.href = url;
            // link.setAttribute('download', `DAPcons_${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}.${instanceBlob.type || 'pdf'}`);
            link.setAttribute('download', namePDF);
            // 3. Append to html page
            document.body.appendChild(link);

            // 4. Force download
            link.click();

            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);

            fetchDAPconHtmlData.setState({
                loading: false,
                error: null,
                data: null,
            })

        }
    }, [fetchDAPconHtmlData])


    // Descargar Documentos adjuntos obligatorios

    const [fetchDocAdjOblig, fetchDocAdjObligData, resetfetchDocAdjObligData] = useAxios('get', `/dapcons/${id}/form/doc-adjuntos-obligatorios/obtener-documento`, '', 'blob')

    const handleFetchDocAdjOblig = (token, type) => {

        fetchDocAdjOblig({
            params: {
                token_doc: token,
                doc_type: type,
            }
        })
    }

    useEffect(() => {

        if (fetchDocAdjObligData.data) {
            getDataFile(fetchDocAdjObligData, resetfetchDocAdjObligData)
        }
    }, [fetchDocAdjObligData])


    const [activeClick, setActiveClick] = useState()


    const [fetchDocInfoAdic, fetchDocInfoAdicData, resetfetchDocInfoAdicData] = useAxios('get', `/dapcons/${id}/form/informacion-adicional/obtener-documento`, '', 'blob')

    const handleFetchDocInfoAdic = (token, type) => {

        fetchDocInfoAdic({
            params: {
                token_doc: token,
                doc_type: type,
            }
        })
    }

    useEffect(() => {

        if (fetchDocInfoAdicData.data) {
            getDataFile(fetchDocInfoAdicData, resetfetchDocInfoAdicData)
        }
    }, [fetchDocInfoAdicData])


    const [fetchDocInfoVeri, fetchDocInfoVeriData, resetfetchDocInfoVeriData] = useAxios('get', `/dapcons/${id}/informe-verificacion-dapcons/obtener-documento`, '', 'blob')

    const handleFetchDocInfoVeri = (token, type) => {

        fetchDocInfoVeri({
            params: {
                token_doc: token,
                doc_type: type,
            }
        })
    }

    useEffect(() => {

        if (fetchDocInfoVeriData.data) {
            getDataFile(fetchDocInfoVeriData, resetfetchDocInfoVeriData)
        }
    }, [fetchDocInfoVeriData])


    const [showLoadingDownload, setShowLoadingDownload] = useState(false);

    useEffect(() => {
        setShowLoadingDownload(fetchDAPconHtmlData.loading)
    }, [fetchDAPconHtmlData.loading])

    // useState para mostrar la justificacion

    const [showJustificacion, setshowJustificacion] = useState(false);

    useEffect(() => {

        if (fetchRCPData.data?.status === 200) {

            const isRCP100 = fetchRCPData?.data?.data?.data?.find((item) => item.rcp_id === +formDAPcon.dapcons_rcp_id)?.codigo_para_codificacion_dapcons === '100'

            setshowJustificacion(isRCP100)
        }

    }, [fetchRCPData, formDAPcon.dapcons_rcp_id]);


    return (
        <>
            <Prompt when={isBlocking} message="Are you sure you want to leave?" />

            <div className='MainInner shadow-sm animate-width-disabled'>
                {(fetchRCPData.loading || fetchVerificadoresData.loading || fetchFichaResumenData.loading) ?
                    <div className="py-5">
                        <Loader />
                    </div>
                    : <>

                        <div className="d-flex justify-content-center mb-2">
                            <div>
                                <h5 className='mt-2 mb-4'>Ficha general {dapcons_codigo}</h5>

                            </div>
                            {/* <Button variant="warning" size='' className="px-3 ms-3 d-flex align-items-center"
                                onClick={() => null}
                            >
                                <FontAwesomeIcon icon={faExchangeAlt} className='me-2' /> Crear en Inglés
                            </Button> */}
                        </div>

                        <Form className='row'>

                            <div className="col-lg-8">
                                <div className="row">

                                    <Form.Group className="mb-3 col-12">
                                        {
                                            (formDAPcon.estadoDAPcons === 'borrador' && !formDAPcon.bloqueado) ?
                                                <InputText
                                                    label='Nombre del Producto: *'
                                                    className=''
                                                    name='dv_nombre_producto'
                                                    value={formDAPcon.dv_nombre_producto}
                                                    onChange={handleInputChangeDAPcon}
                                                // validation={valuesValidationDAPcon}
                                                // fetchData={fetchFichaResumenEditData}
                                                /> : <>
                                                    <Form.Label className='fw-500'>
                                                        <span className='pe-2'>Nombre del Producto: </span>
                                                        <TooltipLock id='dv_nombre_producto' position='top' />
                                                    </Form.Label>
                                                    <p className='mt-0 mb-0'>{formDAPcon.dv_nombre_producto}</p>
                                                </>
                                        }

                                    </Form.Group>

                                    <Form.Group className="col-12 mb-4">
                                        {
                                            (formDAPcon.estadoDAPcons === 'borrador' && !formDAPcon.bloqueado) ?
                                                <InputText
                                                    label='Descripción del Producto: *'
                                                    as="textarea"
                                                    rows={3}
                                                    className=''
                                                    name='dv_descripcion_producto'
                                                    value={formDAPcon.dv_descripcion_producto}
                                                    onChange={handleInputChangeDAPcon}
                                                    validation={valuesValidationDAPcon}
                                                // fetchData={fetchFichaResumenEditData}
                                                /> : <>
                                                    <Form.Label className='fw-500'>
                                                        <span className='pe-2'>Descripción del Producto: </span>
                                                        <TooltipLock id='dv_nombre_producto' position='top' />
                                                    </Form.Label>
                                                    <p className='mt-0 mb-0'>{formDAPcon.dv_descripcion_producto}</p>
                                                </>
                                        }

                                    </Form.Group>

                                    <div className="col-12"></div>


                                    <Form.Group className="mb-3 col-sm-12 col-lg-12">

                                        {
                                            (formDAPcon.estadoDAPcons === 'borrador' && !formDAPcon.bloqueado) ?
                                                <InputSelect
                                                    label='RCP de referencia: *'
                                                    className=''
                                                    name='dapcons_rcp_id'
                                                    values={listRCP}
                                                    value={formDAPcon.dapcons_rcp_id}
                                                    onChange={handleInputChangeDAPcon}
                                                    fetchData={fetchFichaResumenEditData}
                                                    validation={valuesValidationDAPcon}
                                                /> : <>
                                                    <Form.Label className='fw-500'>
                                                        <span className='pe-2'>RCP de referencia: </span>
                                                        <TooltipLock id='dv_nombre_producto' position='top' />
                                                    </Form.Label>
                                                    <p className='mt-0 mb-0'>
                                                        {listRCP.find(item => item.id === formDAPcon.dapcons_rcp_id)?.nombre}
                                                    </p>
                                                </>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-sm-12 col-lg-12">

                                        {
                                            showJustificacion &&
                                            <>
                                                {
                                                    (formDAPcon.estadoDAPcons === 'borrador' && !formDAPcon.fecha_verificacion_rcp && !formDAPcon.bloqueado) ?
                                                        <Form.Group className="col-12 mb-4">
                                                            <Form.Label className='fw-500'>Justificación del RCP 100: *</Form.Label>
                                                            <InputText
                                                                className=''
                                                                as="textarea"
                                                                rows={3}
                                                                name='justificacion_rcp'
                                                                onChange={handleInputChangeDAPcon}
                                                                value={formDAPcon.justificacion_rcp}
                                                                validation={valuesValidJustificacion}
                                                            />
                                                        </Form.Group>
                                                        : <>
                                                            <Form.Label className='fw-500'>
                                                                <span className='pe-2'>Justificación del RCP 100: *</span>
                                                                <TooltipLock id='dv_nombre_producto' position='top' />
                                                            </Form.Label>
                                                            <p className='mt-0 mb-0 text-justify'>
                                                                {formDAPcon.justificacion_rcp}
                                                            </p>
                                                        </>
                                                }
                                            </>
                                        }
                                    </Form.Group>

                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 text-center d-flex flex-column align-items-center">
                                <div className="px-4 w-100">

                                    <img src={images('./preview.png')} className="DAPcon__imagen_portada" alt="logo" />
                                </div>
                                <ButtonSpinner
                                    variant="primary" type="submit" className="mt-3"
                                    handleSubmit={handleFetchDAPcons} fetchDataLoading={[fetchDAPconHtmlData.loading]}
                                    value='Descargar' icon={<FontAwesomeIcon icon={faDownload} className='me-1' />}
                                />
                            </div>

                            <div className="col-12"></div>

                            <Form.Group className="col-lg-4 mb-4" controlId="exampleForm.ControlInput2">
                                <Form.Label className='fw-500'>Consultor:</Form.Label>
                                <p className='mt-0 mb-0'>{formDAPcon.consultor?.consultor_nombre} {formDAPcon.consultor?.consultor_apellidos}</p>
                            </Form.Group>

                            <Form.Group className="col-lg-4 mb-4">
                                {
                                    (formDAPcon.estadoDAPcons === 'borrador' && !formDAPcon.bloqueado) ?
                                        <InputSelect
                                            label='Verificador:'
                                            className=''
                                            name='verificador_nif'
                                            values={listVerificadores}
                                            value={formDAPcon.verificador_nif}
                                            onChange={handleInputChangeDAPcon}
                                            fetchData={fetchFichaResumenEditData}
                                            validation={valuesValidationDAPcon}
                                        /> : <>
                                            <Form.Label className='fw-500'>
                                                <span className='pe-2'>Verificador: </span>
                                                <TooltipLock id='dv_nombre_producto' position='top' />
                                            </Form.Label>
                                            <p className='mt-0 mb-0'>
                                                {listVerificadores.find(item => item.id === formDAPcon.verificador_nif)?.nombre}
                                            </p>
                                        </>
                                }

                            </Form.Group>
                            {
                                (formDAPcon.estadoDAPcons === 'borrador' && !formDAPcon.bloqueado) &&
                                <div className="col-lg-8 mb-4">
                                    <div className='mt-0 py-3 d-flex justify-content-end border-top'>
                                        <ButtonSpinner
                                            variant="info" type="submit" className=""
                                            handleSubmit={handleSubmitDAPconDatReqEdit} fetchDataLoading={[fetchFichaResumenEditData.loading]}
                                            value='Guardar' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                                        />
                                    </div>
                                </div>
                            }


                        </Form>

                        {/* <div className="row">
                            <div className="col-12 mb-4">
                                <div className="border-top"></div>
                            </div>
                        </div> */}

                        <div className="row mb-3">
                            <Form.Group className="col-lg-4 mb-4" controlId="exampleForm.ControlInput2">
                                <Form.Label className='fw-500'>Fecha Verificación:</Form.Label>
                                {
                                    formDAPcon.fecha_informe_verificacion_producto ?
                                        (
                                            <p className='mt-0 mb-0'>{formDAPcon.fecha_informe_verificacion_producto?.split('-')?.reverse()?.join('/')}</p>
                                        )
                                        : (
                                            <p>No hay fecha de verificación</p>
                                        )
                                }

                            </Form.Group>

                            <div className="col-lg-6">
                                <h5 className='h6 fw-500 mb-3 d-flex justify-content-between'>
                                    Informe de verificación del producto (IV)
                                </h5>
                                {
                                    formDAPcon.doc_inf_verificacion_dapcons?.length > 0 ?
                                        <div className='d-flex align-items-center Files__container'>
                                            <ListGroup className='col-12 col-lg-6 d-flex align-items-center Files__container'>
                                                <FileList
                                                    eventClick={() => {
                                                        handleFetchDocInfoVeri(formDAPcon.doc_inf_verificacion_dapcons?.[0]?.token_doc, 'documento_informe_verificacion_dapcons')
                                                        setActiveClick('documento_informe_verificacion_dapcons')
                                                    }}
                                                    name='Informe de verificación'
                                                    isLoading={(fetchDocInfoVeriData.loading) && activeClick === 'documento_informe_verificacion_dapcons'}

                                                />
                                            </ListGroup>
                                        </div> : (
                                            <p>No hay informe de verificación</p>
                                        )
                                }

                            </div>

                            {/* <div className="col-lg0">
                                <Form.Label className='fw-500'>
                                    <span className='pe-2'>Base de datos: </span>
                                </Form.Label>
                                <p className='mt-0 mb-0'>
                                    {database.find(item => item.id === formDAPcon.dapcons_data_base)?.nombre || '-'}
                                </p>
                            </div> */}
                        </div>

                        {
                            (formDAPcon.documento_inf_analisis_ciclo_vida || formDAPcon.documento_ficha_producto) &&
                            (

                                <div className="row">
                                    {
                                        formDAPcon.documento_inf_analisis_ciclo_vida &&
                                        <div className="col-12 col-lg-6 mb-4">
                                            <h5 className='fs-105 mb-3 d-flex justify-content-between'>
                                                Documento de Informe de análisis de ciclo de vida
                                            </h5>

                                            <div className='d-flex align-items-center Files__container'>
                                                <ListGroup className='col-12 d-flex align-items-center Files__container'>
                                                    <FileList
                                                        eventClick={() => {
                                                            handleFetchDocAdjOblig(formDAPcon.documento_inf_analisis_ciclo_vida.token_doc, 'documento_inf_analisis_ciclo_vida')
                                                            setActiveClick('documento_inf_analisis_ciclo_vida')
                                                        }}
                                                        name='Informe de análisis de ciclo de vida'
                                                        isLoading={(fetchDocAdjObligData.loading) && activeClick === 'documento_inf_analisis_ciclo_vida'}

                                                    />
                                                </ListGroup>

                                            </div>
                                        </div>
                                    }
                                    {
                                        formDAPcon.documento_ficha_producto &&
                                        <div className="col-12 col-lg-6 mb-4">
                                            <h5 className='fs-105 mb-3 d-flex justify-content-between'>
                                                Documento de Ficha de producto
                                            </h5>

                                            <div className='d-flex align-items-center Files__container'>
                                                <ListGroup className='col-12 d-flex align-items-center Files__container'>
                                                    <FileList
                                                        eventClick={() => {
                                                            handleFetchDocAdjOblig(formDAPcon.documento_ficha_producto.token_doc, 'ficha_de_producto')
                                                            setActiveClick('ficha_de_producto')
                                                        }}
                                                        name='Ficha de producto'
                                                        isLoading={(fetchDocAdjObligData.loading) && activeClick === 'ficha_de_producto'}

                                                    />
                                                </ListGroup>

                                            </div>
                                        </div>
                                    }

                                    <div className="col-12 mb-4">
                                        <div className="border-top"></div>
                                    </div>

                                </div>
                            )
                        }
                        {
                            (formDAPcon.doc_opcionales_verificables.length > 0 || formDAPcon.doc_opcionales_no_verificables.length > 0) &&
                            (

                                <div className="row">
                                    {
                                        formDAPcon.doc_opcionales_verificables.length > 0 &&
                                        <div className="col-12 col-lg-6 mb-4">
                                            <h5 className='fs-105 mb-3 d-flex justify-content-between'>
                                                Documentos opcionales verificables
                                            </h5>

                                            <div className='d-flex align-items-center Files__container'>
                                                <ListGroup className='col-12 d-flex align-items-center Files__container'>
                                                    {
                                                        formDAPcon.doc_opcionales_verificables.map(item => {
                                                            return (
                                                                <FileList
                                                                    eventClick={() => {
                                                                        handleFetchDocInfoAdic(item.token_doc, 'documentos_opcionales_verificables')
                                                                        setActiveClick(`documentos_opcionales_verificables_${item.doc_descripcion}_${item.doc_id}`)
                                                                    }}
                                                                    name={item.doc_descripcion}
                                                                    isLoading={(fetchDocInfoAdicData.loading) && activeClick === `documentos_opcionales_verificables_${item.doc_descripcion}_${item.doc_id}`}

                                                                />
                                                            )
                                                        })
                                                    }
                                                </ListGroup>

                                            </div>
                                        </div>
                                    }
                                    {
                                        formDAPcon.doc_opcionales_no_verificables.length > 0 &&

                                        <div className="col-12 col-lg-6 mb-4">
                                            <h5 className='fs-105 mb-3 d-flex justify-content-between'>
                                                Documentos opcionales no verificables
                                            </h5>

                                            <div className='d-flex align-items-center Files__container'>
                                                <ListGroup className='col-12 d-flex align-items-center Files__container'>
                                                    {
                                                        formDAPcon.doc_opcionales_no_verificables.map(item => {
                                                            return (
                                                                <FileList
                                                                    eventClick={() => {
                                                                        handleFetchDocInfoAdic(item.token_doc, 'documentos_opcionales_no_verificables')
                                                                        setActiveClick(`documentos_opcionales_no_verificables_${item.doc_descripcion}_${item.doc_id}`)
                                                                    }}
                                                                    name={item.doc_descripcion}
                                                                    isLoading={(fetchDocInfoAdicData.loading) && activeClick === `documentos_opcionales_no_verificables_${item.doc_descripcion}_${item.doc_id}`}

                                                                />
                                                            )
                                                        })
                                                    }

                                                </ListGroup>

                                            </div>
                                        </div>
                                    }
                                    <div className="col-12 mb-4">
                                        <div className="border-top"></div>
                                    </div>
                                </div>

                            )
                        }

                    </>
                }
            </div>

            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatus setShowStatus={setShowLoadingDownload} showStatus={showLoadingDownload}>
                <div className="py-0 text-center">
                    <Loader />
                    <p className='text-center'>Este proceso puede durar hasta 1 minuto...</p>
                </div>
            </ModalStatus>

            <ModalStatusError fetchData={fetchDAPconHtmlData} />

            <ModalStatusError fetchData={fetchDocInfoVeriData} />
            <ModalStatusError fetchData={fetchDocAdjObligData} />
            <ModalStatusError fetchData={fetchDocInfoAdicData} />

            <ModalStatusServer2 fetchData={fetchFichaResumenEditData} onlyError />
        </>
    )
}
