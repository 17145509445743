import { getAdminAdapterMisDatos, getAdminAdapterSchemaMisDatos, postAdminAdapterMisDatos } from "../adapters/admin.adapter";
import { useAxios2 } from "../hooks/useAxios2";


export const GetAdminJSON = () => {
    return useAxios2('get', '/usuario-admin/mis-datos/mostrar', 'json', {
        receiveAdapter: getAdminAdapterMisDatos
    });
};

export const PostAdminJSON = () => {
    return useAxios2('post', '/usuario-admin/mis-datos/actualizar?_method=PATCH', 'json', {
        receiveSchemaAdapter: getAdminAdapterSchemaMisDatos,
        sendAdapter: postAdminAdapterMisDatos
    });
};