import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import { ButtonSpinner } from '../ButtonSpinner/ButtonSpinner';

const ModalTerminosCondiciones = ({ showModalTerminosCondiciones, setShowModalTerminosCondiciones, handleCloseModalTerminosCondiciones,
    handleSubmit, fixed, fetchDataLoading }) => {

    return (
        <Modal show={showModalTerminosCondiciones} onHide={handleCloseModalTerminosCondiciones} size='lg' scrollable>

            <Modal.Header className='border-bottom-0 py-4' closeButton></Modal.Header>
            <Modal.Body className='px-md-5 py-md-4 pt-0'>
                <h5 className='text-center mb-4'>TÉRMINOS Y CONDICIONES DEL PROGRAMA DAPconstrucción®</h5>
                <p><strong>Condiciones generales:</strong></p>
                <ul>
                    <li>
                        La empresa deberá solicitar su incorporación y ser aprobada para formar parte del Programa.
                    </li>
                    <li>
                        La empresa deberá cumplir con todos los requisitos legales vigentes, así como las obligaciones en
                        materia de calidad y medio ambiente.
                    </li>
                    <li>
                        La empresa nombrará a un representante como intermediario con el Administrador del
                        Programa.
                    </li>
                    <li>
                        La empresa deberá hacer un uso adecuado de la marca registrada DAPcons® y su logo, y siempre
                        bajo autorización del Administrador del Programa.

                    </li>
                    <li>
                        La empresa cumplirá con cualquier obligación que se desprenda de las prescripciones del
                        Programa DAPconstrucción® y su funcionamiento.
                    </li>
                </ul>
                <p><strong>Condiciones de procedimientos</strong></p>

                <ul>
                    <li>
                        La empresa que desarrolle un Análisis de Ciclo de Vida de un producto objeto, lo realizará
                        siguiendo las Reglas de Categoría del producto. Asimismo, asumirá la veracidad de los datos
                        facilitados.
                    </li>
                    <li>
                        La empresa podrá proponer razonadamente todas las iniciativas que estimen beneficiosas para el
                        Programa DAPconstrucción® y sus afiliados.
                    </li>
                </ul>

                <p><strong>Condiciones de procedimientos</strong></p>

                <ul>
                    <li>
                        La empresa formará parte de la base de datos de empresas miembros del Programa.
                    </li>
                    <li>
                        La empresa justificará adecuadamente la declaración o certificación ambiental del producto a
                        incluir para incorporar productos en la base de datos
                    </li>
                    <li>
                        La empresa podrá hacer pública su pertenencia al Programa y el proceso de obtención de sus
                        declaraciones ambientales.
                    </li>
                </ul>

                <p><strong>Servicios del Programa</strong></p>

                <ul>
                    <li>
                        La empresa dispondrá de asesoramiento sobre los procedimientos del Programa en la obtención
                        de acreditaciones ambientales.
                    </li>
                    <li>
                        La empresa dispondrá de acceso a la Intranet con información específica para los miembros del
                        Programa.
                    </li>
                    <li>
                        La empresa recibirá el boletín electrónico con información relacionada con el Programa y con el
                        sector del medio ambiente.
                    </li>
                    <li>
                        La empresa formará parte de la lista de distribución para recibir puntualmente los correos
                        electrónicos que el Programa genere para sus miembros.
                    </li>
                    <li>
                        La empresa podrá participar preferentemente en aquellas actividades que organice el Programa.
                        En caso de tener un coste, las empresas miembros disfrutarán de condiciones especiales.
                    </li>
                </ul>

                <p><strong>Difusión del Programa</strong></p>

                <ul>
                    <li>
                        El administrador del Programa dará a conocer el Programa entre todos los agentes del sector
                        (empresas, técnicos y profesionales, administraciones y usuarios) mediante una campaña de
                        comunicación con acciones previstas de:
                        <ul>
                            <li>
                                Divulgación de la existencia y funcionamiento del Programa en los medios de comunicación
                                técnico (prensa y web).
                            </li>
                            <li>
                                Difusión a los técnicos y profesionales del sector mediante los colegios y gremios de las
                                profesiones relacionadas con el sector: (Arquitectos, Aparejadores, Constructores,
                                Promotores, Instaladores, Ingenieros Técnicos, Ingenieros de Caminos, Ingenieros de Obras
                                públicas, Decoradores, Administradores de Fincas, etc.).
                            </li>
                            <li>
                                Información a los organismos e instituciones públicas relacionadas con el sector.
                            </li>
                            <li>
                                Participación en Jornadas y conferencias de presentación del Programa.
                            </li>
                        </ul>
                    </li>
                </ul>

                <small className='mt-3 pt-2 border-top d-block'>Administrador del Programa: CAATEEB. Bon Pastor 5 08021 Barcelona. www.csostenible.net -932402060- sostenible@apabcn.cat</small>
            </Modal.Body>
            <Modal.Footer className=''>
                <Button variant="default" onClick={handleCloseModalTerminosCondiciones} className='px-3'>
                        Salir
                    </Button>
                <ButtonSpinner
                    variant="primary" type="submit" className="ms-3 px-3"
                    handleSubmit={handleSubmit} fetchDataLoading={[fetchDataLoading ? fetchDataLoading.loading : false]}
                    value='Aceptar'
                />
            </Modal.Footer>
        </Modal>
    )
}

export default ModalTerminosCondiciones