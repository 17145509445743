import { faCheckCircle, faPlusCircle, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { Table5 } from '../../../../components/DAPconTables/Table5';
import { Table7 } from '../../../../components/DAPconTables/Table7';
import InputImage from '../../../../components/Inputs/InputImage';
import { Loader } from '../../../../components/Loader/Loader';
import { ModalCropImage } from '../../../../components/ModalCropImage/ModalCropImage';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';
import { ModalStatusError } from '../../../../components/ModalStatusServer/ModalStatusError';
import { dataURLtoFile } from '../../../../helpers/helpers';
import { useAxios } from '../../../../hooks/useAxios';

export const DAPconConsultorSeccion2 = ({ id, idiomaDAPcon, fetchCheckForms }) => {

    const inputFileRef = useRef();

    const [fetchEditarDisenoDoc, fetchEditarDisenoDocData] = useAxios('post', `/dapcons/${id}/form/diseno-documento?_method=PATCH`)

    const [fetchDisenoDoc, fetchDisenoDocData] = useAxios('get', `/dapcons/${id}/form/diseno-documento`)

    const [fetchGetImage, fetchGetImageData] = useAxios('get', `/dapcons/${id}/form/image`, '', 'blob')

    const [urlImage, setUrlImage] = useState('')

    useEffect(() => {

        fetchDisenoDoc({
            params: {
                idioma_id: idiomaDAPcon
            }
        })

    }, [])

    useEffect(() => {

        console.log(fetchDisenoDocData)

        const img_id = fetchDisenoDocData.data?.data?.data?.[0]?.img_id

        if (fetchDisenoDocData.data?.status === 200 && img_id) {

            fetchGetImage({
                id: img_id
            })
        }

    }, [fetchDisenoDocData])

    useEffect(() => {

        if (fetchGetImageData.data) {

            console.log(fetchGetImageData.data.data)
            setInputFiles([{
                path: URL.createObjectURL(fetchGetImageData.data?.data)
            }])
        }

    }, [fetchGetImageData])

    function _imageEncode(arrayBuffer) {
        let u8 = new Uint8Array(arrayBuffer)
        let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), function (p, c) { return p + String.fromCharCode(c) }, ''))
        let mimetype = "image/jpeg"
        return "data:" + mimetype + ";base64," + b64encoded
    }

    // const handleSubmitDisenoDoc = (e) => {

    //     e.preventDefault()

    //     let formPortada = new FormData();

    //     // if (InputFiles[0]) {
    //     formPortada.append("portada_imagen", InputFiles[0])
    //     // formPortada.append("cabecera_imagen", InputFiles[0])
    //     // formPortada.append("logo_empresa_imagen", InputFiles[0])
    //     // formPortada.append("logo_consultor_imagen", InputFiles[0])

    //     fetchEditarDisenoDoc({ body: formPortada })
    //     // }
    // }

    const handleSubirImagen = (file) => {
        let formDataImagen = new FormData();
        formDataImagen.append("portada_imagen", file)
        formDataImagen.append("idioma_id", idiomaDAPcon)
        console.log(file)
        fetchEditarDisenoDoc({
            body: formDataImagen
        })
    }

    const handleEliminarImagen = () => {
        let formDataImagen = new FormData();
        formDataImagen.append("portada_imagen", '')
        formDataImagen.append("idioma_id", idiomaDAPcon)
        fetchEditarDisenoDoc({
            body: formDataImagen
        })
    }

    const [InputFiles, setInputFiles] = useState([])

    // Modal

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        file: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        file: null
    })
    const handleShowModalCropImage = (file) => setShowModalCropImage({
        value: true,
        file
    });

    const handleChangeInputImage = (e) => {
        if (e.target.files.length > 0) {
            handleShowModalCropImage(e.target.files[0])
        }
    }


    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        console.log(fetchEditarDisenoDocData.data)
        if (fetchEditarDisenoDocData.data?.status === 201) {
            setShowSuccessEdit(true)

            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchEditarDisenoDocData])

    const [showModalHtml, setShowModalHtml] = useState(false);

    const handleCloseModalHtml = () => setShowModalHtml(false)
    const handleShowModalHtml = () => setShowModalHtml(true);


    // Visualizar DAPcon

    const [fetchDAPconHtml, fetchDAPconHtmlData] = useAxios('get', `/dapcons-portada/${id}/pdf`, '', 'blob')

    const handleClickVisualizarPortada = () => {

        // const arrayLang = ['es', 'en']

        // console.log(arrayLang[idiomaDAPcon])
        fetchDAPconHtml({
            params: {
                idioma_id: idiomaDAPcon
            }
        })
        handleShowModalHtml()
    }

    useEffect(() => {
        if (fetchDAPconHtmlData.data) {
            console.log(fetchDAPconHtmlData)
            // window.open(URL.createObjectURL(fetchDAPconHtmlData.data?.data));

        }
    }, [fetchDAPconHtmlData])

    return (
        <>
            <div className='MainInner shadow-sm animate-width-disabled'>
                {
                    fetchDisenoDocData.loading ?
                        (
                            <div className="py-5">
                                <Loader />
                            </div>
                        )
                        :
                        <>
                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <h5>Diseño del documento</h5>
                                </div>
                            </div>

                            <Form className='row'>

                                <div className="col-12">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-lg-8">
                                            <Form.Group controlId="formFile" className="d-flex justify-content-between align-items-end mb-3">
                                                <Form.Label className='mb-0'>Portada corporativa del documento</Form.Label>
                                                <Button variant="primary" size="sm" onClick={handleClickVisualizarPortada} disabled={InputFiles.length === 0} >
                                                    Previsualizar en documento
                                                </Button>
                                            </Form.Group>

                                            <div>
                                                {
                                                    fetchGetImageData.loading ? (
                                                        <div className='container-files-input d-flex align-items-center justify-content-center w-100'>
                                                            <div className="py-5">
                                                                <Loader />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <InputImage
                                                            width='100%'
                                                            height='40rem'
                                                            onChange={handleChangeInputImage}
                                                            InputFiles={InputFiles}
                                                            setInputFiles={setInputFiles}
                                                            urlImage={urlImage}
                                                            setUrlImage={setUrlImage}
                                                            fetchAccionesData={fetchEditarDisenoDocData}
                                                            // handleSubirImagen={handleSubirImagen}
                                                            handleEliminarImagen={handleEliminarImagen}
                                                        />
                                                    )
                                                }
                                                
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </Form>
                            {/* <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                                <ButtonSpinner
                                    variant="primary" type="submit" className="me-2"
                                    handleSubmit={handleSubmitDisenoDoc} fetchDataLoading={[fetchEditarDisenoDocData?.loading]}
                                    value='Guardar' icon={<FontAwesomeIcon icon={faSave} />}
                                />
                            </div> */}
                        </>
                }
            </div>

            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalCropImage
                show={showModalCropImage}
                handleClose={handleCloseModalCropImage}
                height='500px'
                setInputFiles={setInputFiles}
                handleSubirImagen={handleSubirImagen}
                escalas={[
                    { x: 9, y: 10, label: 'Cuadrado' }
                ]}
            />

            <Modal show={showModalHtml} onHide={handleCloseModalHtml} size='lg' scrollable className='visor-plantilla-pdf'>
                {/* <Modal.Header closeButton>
                    <Modal.Title className='h5'>Previsualizar DAPcons</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className='px-4 py-4 p-0'>
                    <div className="">
                        <div className="col-12">
                            {
                                fetchDAPconHtmlData.loading ? (
                                    <div className="position-absolute w-100 h-100">
                                        <div className="d-flex justify-content-center align-content-center py-5 h-100">
                                            <Loader />
                                            {/* <h4>Por favor espere. Este proceso puede demorar algunos segundos...</h4> */}
                                        </div>
                                    </div>

                                ) : (
                                    // <div dangerouslySetInnerHTML={{ __html: fetchDAPconHtmlData.data?.data }}></div>
                                    fetchDAPconHtmlData.data?.data &&
                                    <embed
                                        src={URL.createObjectURL(fetchDAPconHtmlData.data?.data)}
                                        width="100%" height="100%"
                                        className='position-absolute h-100'
                                    ></embed>

                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="default" >
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        // handleSubmit={handleSubmitAltaDAPcons} fetchDataLoading={[fetchCrearDAPconsData.loading]}
                        value='Descargar' disabled
                    />
                </Modal.Footer> */}
            </Modal>

            <ModalStatusError fetchData={fetchEditarDisenoDocData} />

        </>
    )
}
