import { faCheckCircle, faFile, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Breadcrumb, Form, Tab, Tabs } from "react-bootstrap";
import { getConsultorAdapterSchemaMisDatos } from "../../../adapters/consultor.adapter";
import { AuthContext } from "../../../auth/AuthContext";
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { InputSelect, InputText } from '../../../components/Inputs';
import { Loader } from '../../../components/Loader/Loader';
import { ModalCropImage } from "../../../components/ModalCropImage/ModalCropImage";
import { ModalHelpDownFirma } from "../../../components/Modals/ModalsHelp/ModalHelpDownFirma";
import { ModalHelpUpFirma } from "../../../components/Modals/ModalsHelp/ModalHelpUpFirma";
import { ModalStatus } from "../../../components/ModalStatusServer/ModalStatus";
import { useAxios } from "../../../hooks/useAxios";
import { useForm } from "../../../hooks/useForm";
import { useValidator } from "../../../hooks/useValidator";
import { GetConsultorJSON, PostConsultorJSON } from "../../../services/consultor.service";
import { useHistory } from 'react-router-dom';
import { GetUsuarioJSON } from '../../../services/usuario.service';

export const Usuario = ({ match }) => {

    const history = useHistory();

    const { user } = useContext(AuthContext);

    const inputFileRef = useRef();

    const [fetchConsultor, fetchConsultorData, resetFetchConsultor] = GetUsuarioJSON(match.params.id)

    const [fetchIdiomas, fetchIdiomasData] = useAxios('get', `/idiomas`)


    useEffect(() => {

        fetchConsultor({
            params: {
                consultor_nif: match.params.id
            }
        })
        fetchIdiomas()

    }, [])

    useEffect(() => {

        if (fetchConsultorData.status === 200) {

            handleFormChangeConsultor(fetchConsultorData.data)
        }

    }, [fetchConsultorData])

    const [fetchUpdateUsuario, fetchUpdateUsuarioData] = useAxios('patch', `/usuario-admin/caateeb/${match.params.id}/update`)

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {

        console.log(fetchUpdateUsuarioData)

        if (fetchUpdateUsuarioData.data?.status === 200) {
            // handleCloseModalRegistroConsultor()
            setShowSuccessEdit(true)
            // setShowStatusVerify2(true)
        }

    }, [fetchUpdateUsuarioData])

    const [
        formUsuario,
        handleInputChangeConsultor,
        handleFormChangeConsultor,
        handleResetConsultor
    ] = useForm(getConsultorAdapterSchemaMisDatos())


    const [listIdiomasFirmas, setListIdiomasFirmas] = useState([])

    useEffect(() => {

        if (fetchIdiomasData.data && formUsuario.id) {

            if (fetchIdiomasData.data?.status === 200) {
                const listTemp = fetchIdiomasData?.data?.data?.data?.map(item => {
                    return {
                        idioma_id: item.id,
                        nombre: `${item.idioma_nombre}`
                    }
                })

                for (let i = 0; i < listTemp.length; i++) {

                    const itemExtraido = formUsuario.verificador_casillas?.find(item => item.idioma_id === listTemp[i].idioma_id) || {}
                    console.log('itemExtraido', itemExtraido)

                    listTemp[i]['casilla_pagina_firma'] = itemExtraido.casilla_pagina_firma || ''
                    listTemp[i]['casilla_ultima_pagina'] = itemExtraido.casilla_ultima_pagina || ''
                }
                console.log('itemExtraido', listTemp)
                setListIdiomasFirmas(listTemp)
            }
        }

    }, [fetchIdiomasData, formUsuario.id])

    const [valuesValidUsuario, handleVerifiedValuesUsuario, handleResetValuesRegisterExterno] = useValidator({
        usuario: { isOk: true, msgError: [`El NIF es obligatorio.`] },
        nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        apellidos: { isOk: true, msgError: [`Los apellidos son obligatorios.`] },
        telefono: { isOk: true, msgError: [`La teléfono es obligatoria.`] },
        email: { isOk: true, msgError: [`El correo es obligatorio.`] },
    })

    const handleSubmitFormUsuario = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesUsuario(formUsuario)) {

            fetchUpdateUsuario({ 
                body: formUsuario
            })
        }
    }

    const [fetchGetImage, fetchGetImageData] = useAxios('get', `/consultor-logo-img`, '', 'blob')

    useEffect(() => {

        if (formUsuario.logo && typeof formUsuario.logo === 'string') {
            console.log(formUsuario)

            fetchGetImage({
                id: `${formUsuario.id}/img?token_img=${formUsuario.logo}`
            })
        }

    }, [formUsuario.logo])

    useEffect(() => {

        if (fetchGetImageData.data) {

            console.log(fetchGetImageData.data.data)
            setInputFiles([new File([fetchGetImageData.data?.data], 'logo.png')])

        }

    }, [fetchGetImageData])

    useEffect(() => {
        console.log(formUsuario)

    }, [formUsuario])

    // Recortar imagen

    const [InputFiles, setInputFiles] = useState([])

    useEffect(() => {

        handleFormChangeConsultor({
            ...formUsuario,
            logo: InputFiles?.[0],
        })

    }, [InputFiles])

    // Modal

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        file: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        file: null
    })
    const handleShowModalCropImage = (file) => setShowModalCropImage({
        value: true,
        file
    });


    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useAxios('get', `/poblacion`)

    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (formUsuario.codigo_postal?.length === 5) {

            fetchPoblaciones({
                params: {
                    cp: formUsuario.codigo_postal
                }
            })
        } else {
            setPoblaciones([])
            handleFormChangeConsultor({
                ...formUsuario,
                provincia: '',
                poblacion: '',
                // pais: '',
            })
        }

    }, [formUsuario.codigo_postal])

    useEffect(() => {

        console.log(fetchPoblacionesData)

        if (fetchPoblacionesData.data) {

            if (fetchPoblacionesData.data?.status === 201) {

                const poblaciones = fetchPoblacionesData.data?.data?.data

                let poblacionesTemp = poblaciones.map(item => {
                    return ({
                        id: item.poblacion,
                        nombre: item.poblacion
                    })
                })

                setPoblaciones(poblacionesTemp)

            } else {
                handleFormChangeConsultor({
                    ...formUsuario,
                    provincia: '',
                    poblacion: '',
                    // pais: '',
                })
            }
        }

    }, [fetchPoblacionesData])

    useEffect(() => {

        if (formUsuario.poblacion && fetchPoblacionesData.data) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data.data]

            console.log(poblacionesTemp)

            const resp = poblacionesTemp.find(item => item.poblacion === formUsuario.poblacion)

            handleFormChangeConsultor({
                ...formUsuario,
                provincia: resp?.provincia,
                // pais: resp?.pais,
            })
        }

    }, [formUsuario.poblacion, fetchPoblacionesData.data])

    const [showModalHelpUpFirma, setShowModalHelpUpFirma] = useState(false);
    const [showModalHelpDownFirma, setShowModalHelpDownFirma] = useState(false);


    useEffect(() => {
        console.log('listIdiomasFirmas', listIdiomasFirmas)
    }, [listIdiomasFirmas])


    // Eliminar usuario
    const [fetchEliminarUsuario, fetchEliminarUsuarioData] = useAxios('delete', `/usuario-admin/${match.params.id}/destroy`)

    const handleSubmitEliminar = (e) => {

        e.preventDefault()

        fetchEliminarUsuario()
    }

    useEffect(() => {

        console.log(fetchEliminarUsuarioData)

        if (fetchEliminarUsuarioData.data?.status === 201) {

            history.push(`/usuarios`)
            
        }

    }, [fetchEliminarUsuarioData])

    return (
        <>

            <div className="container-inner row justify-content-between">
                <Breadcrumb className="Breadcrumb-inner">
                    <Breadcrumb.Item href="#">
                        <FontAwesomeIcon icon={faHome} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Usuario
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="d-flex justify-content-center">
                    <div className="col-12 col-xl-9">
                        <div className="MainInner shadow-sm animate__animated animate__fadeInUp">
                            {
                                fetchConsultorData.loading ?
                                    <div className="py-5">
                                        <Loader />
                                    </div> : <>

                                        <div className="row pt-3 pb-4 px-3">
                                            <div className="col-12">
                                                <h5 className='mb-4'>Datos personales</h5>
                                            </div>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-3">
                                                <InputText
                                                    label='NIF *'
                                                    className=''
                                                    name='usuario'
                                                    value={formUsuario.usuario}
                                                    onChange={handleInputChangeConsultor}
                                                    validation={valuesValidUsuario}
                                                    fetchData={fetchUpdateUsuarioData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-4">
                                                <InputText
                                                    label='Nombre *'
                                                    className=''
                                                    name='nombre'
                                                    value={formUsuario.nombre}
                                                    onChange={handleInputChangeConsultor}
                                                    validation={valuesValidUsuario}
                                                    fetchData={fetchUpdateUsuarioData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-5">
                                                <InputText
                                                    label='Apellidos *'
                                                    className=''
                                                    name='apellidos'
                                                    value={formUsuario.apellidos}
                                                    onChange={handleInputChangeConsultor}
                                                    validation={valuesValidUsuario}
                                                    fetchData={fetchUpdateUsuarioData}
                                                />
                                            </Form.Group>

                                            <div className="col-12">
                                                <h5 className='mb-3 mt-2'>Datos de contacto</h5>
                                            </div>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                                <InputText
                                                    label='Teléfono *'
                                                    className=''
                                                    name='telefono'
                                                    value={formUsuario.telefono}
                                                    onChange={handleInputChangeConsultor}
                                                    validation={valuesValidUsuario}
                                                    fetchData={fetchUpdateUsuarioData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-6">
                                                <InputText
                                                    label='Dirección electrónica *'
                                                    className=''
                                                    name='email'
                                                    value={formUsuario.email}
                                                    onChange={handleInputChangeConsultor}
                                                    validation={valuesValidUsuario}
                                                    fetchData={fetchUpdateUsuarioData}
                                                />
                                            </Form.Group>

                                        </div>
                                        <div className=' pt-3 d-flex justify-content-between border-top'>
                                            <ButtonSpinner
                                                variant="danger" type="submit" className="me-2"
                                                handleSubmit={handleSubmitEliminar} fetchDataLoading={[fetchEliminarUsuarioData.loading]}
                                                value='Eliminar' icon={<FontAwesomeIcon icon={faTrash} className='me-1' />}
                                            />
                                            <ButtonSpinner
                                                variant="info" type="submit" className="me-2"
                                                handleSubmit={handleSubmitFormUsuario} fetchDataLoading={[fetchUpdateUsuarioData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                                            />
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
                </div>

            </div>
            <ModalCropImage
                show={showModalCropImage}
                handleClose={handleCloseModalCropImage}
                height='500px'
                setInputFiles={setInputFiles}
                escalas={[
                    { x: 1, y: 1, label: 'Cuadrado' },
                    { x: 4, y: 2, label: 'Rectángulo' }
                ]}
            />
            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalHelpUpFirma setShowModalHelp={setShowModalHelpUpFirma} showModalHelp={showModalHelpUpFirma} />

            <ModalHelpDownFirma setShowModalHelp={setShowModalHelpDownFirma} showModalHelp={showModalHelpDownFirma} />
        </>

    )
}
