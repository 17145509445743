import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useRef, useContext } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { Loader } from '../../../components/Loader/Loader';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputSelect, InputText, InputFile } from '../../../components/Inputs';
import { InputRadios } from '../../../components/Inputs/InputRadios';
import InputImage from '../../../components/Inputs/InputImage';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { MsgInput } from '../../../components/MsgInput/MsgInput';
import AdapterBase from '../../../adapters/tarifa.adapter';
import Service from '../../../services/tarifa.service';
import { AuthContext } from '../../../auth/AuthContext';
import { isAdmin } from '../../../helpers/helpers';

// import './ProcessosMain.css'

export const ModalTarifa = ({ data, handleCloseModal, fetchTarifas, activeLink, handleFormChange }) => {
    
    const { user: { perfil } } = useContext(AuthContext);

    const history = useHistory();

    const inputFile = useRef(null)

    const [fetchCrearTarifa, fetchCrearTarifaData] = Service.Create()

    const [fetchGetTarifa, fetchGetTarifaData] = Service.Get()

    const [fetchPatchTarifa, fetchPatchTarifaData] = Service.Update()

    const [fetchDeleteTarifa, fetchDeleteTarifaData] = Service.Remove()

    const [formTarifa, 
        handleInputChangeTarifa, 
        handleFormChangeTarifa, 
        handleResetTarifa] = useForm(AdapterBase.schema())

    const [valuesValidTarifa, handleVerifiedValuesTarifa, handleResetValuesTarifa] = useValidator({
        codigo: { isOk: true, msgError: [`Ingrese un código`] },
        descripcion: { isOk: true, msgError: [`Ingrese una descripción`] },
        codigo_contable: { isOk: true, msgError: [`Ingrese una código contable`] },
        precio: { isOk: true, msgError: [`Ingrese un precio.`] },
        estado: { isOk: true, msgError: [`Seleccione un estado`] },
    })

    const handleSubmitTarifa = () => {
        if (handleVerifiedValuesTarifa(formTarifa)) {
            fetchCrearTarifa({
                body: {
                    ...formTarifa,
                    tipo: activeLink
                }
            })
        }
    }

    const handleSubmitUpdateTarifa = () => {
        if (handleVerifiedValuesTarifa(formTarifa)) {
            fetchPatchTarifa({
                id: data?.id,
                body: {
                    ...formTarifa,
                    _method: 'PATCH',
                    tipo: activeLink
                }
            })
        }
    }

    const handleDeleteTarifa = () => {
        if(window.confirm("Realmente desea borrar esta tarifa ?")){
             fetchDeleteTarifa({
                id: data?.id,
            })
        }
    }

    useEffect(() => {
        if (fetchCrearTarifaData?.status === 200) {
            handleFormChange()
            handleCloseModal()
        }

    }, [fetchCrearTarifaData])

    useEffect(() => {
        if (fetchPatchTarifaData?.status === 200) {
            handleFormChange()
            handleCloseModal()
        }

    }, [fetchPatchTarifaData])

    useEffect(() => {
        if (fetchDeleteTarifaData?.status === 200) {
            handleFormChange()
            handleCloseModal()
        }

    }, [fetchDeleteTarifaData])

    useEffect(() => {
        if(data.value){
            if (data?.id) {
                fetchGetTarifa({
                    id: data?.id
                })
            }
        }else{
            handleResetTarifa();
            handleResetValuesTarifa();
        }
    }, [data.value])

     useEffect(() => {
        if (fetchGetTarifaData?.data) {
            const tarifa = fetchGetTarifaData.data?.data;
            handleFormChangeTarifa({...tarifa})
        }
    }, [fetchGetTarifaData.data])


    return (
        <Modal show={data.value} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>{data.action == 'CREATE' ? 'Nueva Tarifa' : 'Editar Tarifa'}</Modal.Title>
            </Modal.Header>
            {fetchGetTarifaData.loading ? <Loader /> : <>
            <Modal.Body className='px-4 py-4'>
                <Form className='row'>
                    <Form.Group className="mb-3 col-lg-6">
                        <InputText
                            label='Descripción:'
                            className=''
                            name='descripcion'
                            placeholder="Ingrese una descripción"
                            value={formTarifa.descripcion}
                            onChange={handleInputChangeTarifa}
                            validation={valuesValidTarifa}
                            fetchData={fetchCrearTarifaData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-lg-6">
                        <InputText
                            label='Código contable:'
                            className=''
                            name='codigo_contable'
                            placeholder="Ingrese un código"
                            value={formTarifa.codigo_contable}
                            onChange={handleInputChangeTarifa}
                            validation={valuesValidTarifa}
                            fetchData={fetchCrearTarifaData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-lg-4">
                        <InputText
                            label='Precio:'
                            type="number"
                            className=''
                            name='precio'
                            placeholder="Ingrese un precio"
                            value={formTarifa.precio}
                            onChange={handleInputChangeTarifa}
                            validation={valuesValidTarifa}
                            fetchData={fetchCrearTarifaData}
                        />
                    </Form.Group>
                    { isAdmin(perfil) &&
                        <Form.Group className="mb-3 col-lg-4">
                            <InputText
                                label='Código interno:'
                                className=''
                                name='codigo'
                                placeholder="Ingrese un código"
                                value={formTarifa.codigo}
                                onChange={handleInputChangeTarifa}
                                validation={valuesValidTarifa}
                                fetchData={fetchCrearTarifaData}
                            />
                        </Form.Group>
                    }

                    <Form.Group className="col-lg-4 mb-3">
                        <InputSelect
                            label='Estado:'
                            name='estado'
                            values={[
                                {
                                    id: true, nombre: 'Activo'
                                },
                                {
                                    id: false, nombre: 'Inactivo'
                                },
                            ]}
                            value={formTarifa.estado}
                            onChange={handleInputChangeTarifa}
                            validation={valuesValidTarifa}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {data?.id ? 
                    <>
                        <ButtonSpinner
                            variant="warning" type="submit" className="ms-2"
                            handleSubmit={handleSubmitUpdateTarifa} fetchDataLoading={[fetchPatchTarifaData.loading]}
                            value='Actualizar Tarifa' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                        />

                         <ButtonSpinner
                            variant="danger" type="submit" className="ms-2"
                            handleSubmit={handleDeleteTarifa} fetchDataLoading={[fetchDeleteTarifaData.loading]}
                            value='Eliminar' icon={<FontAwesomeIcon icon={faTrash} className='me-1' />}
                        />
                    </>
                 : 
                    <ButtonSpinner
                        variant="info" type="submit" className="ms-2"
                        handleSubmit={handleSubmitTarifa} fetchDataLoading={[fetchCrearTarifaData.loading]}
                        value='Crear Tarifa' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                    />
                }
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
            </>
         }
        </Modal>
    )
}
