import React, { useReducer, useEffect } from 'react'

import { AppRouter } from './routers/AppRouter'
import { AuthContext } from './auth/AuthContext'
import { authReducer } from './auth/authReducer'

import 'bootstrap/dist/css/bootstrap.min.css';
import './Dapcons.css';
import jwt from 'jwt-decode';
import { types } from './types/types';
import { Redirect } from 'react-router-dom';
import AppContent from './AppContent';


const init = () => {

    return JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE)) || { logged: false, lang: process.env.REACT_APP_LANGUAGE_DEFAULT };
}

export const Dapcons = () => {

    const [user, dispatch] = useReducer(authReducer, {}, init);

    useEffect(() => {

        localStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE, JSON.stringify(user));

        if (user.token) {
            try {
                const exp = jwt(user.token).exp
                const hoy = Date.now()

                console.log(new Date(hoy), "--", new Date(exp * 1000))

                if (hoy >= exp * 1000) {
                    console.log('token expirado')
                    dispatch({
                        type: types.logout,
                        lang: user.lang
                    });
                    return <Redirect to='/login' />
                }
            } catch (error) {
                console.log('err')
                    dispatch({
                        type: types.logout,
                        lang: user.lang
                    });
                return <Redirect to='/login' />
            }
        }

    }, [user])

    return (
        <AuthContext.Provider value={{ user, dispatch }}>
            <AppContent />
        </AuthContext.Provider>
    )
}
