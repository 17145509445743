import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

export const ModalStatusError = ({ fetchData }) => {

    const [showStatus, setShowStatus] = useState(false);

    const [status, setStatus] = useState({});

    const handleCloseStatus = () => {
        setShowStatus(false)
    };

    const handleShowStatus = () => setShowStatus(true);

    useEffect(() => {

        console.log(fetchData)
        if ((fetchData.data?.status && fetchData.data?.message)) {
            setStatus({
                status: fetchData.data.status,
                message: fetchData.data.message,
                description: ''
            })
            handleShowStatus()
        } else if (fetchData?.error?.response?.data?.status === 'Error' &&
            fetchData?.error?.response?.data?.message) {
            setStatus({
                status: 'Error',
                message: fetchData?.error?.response?.data?.message,
                description: JSON.stringify(fetchData?.error?.response?.data?.data || '', null, 2) || ''
            })
            handleShowStatus()
        } else if (fetchData?.error?.response?.data?.data) {
            setStatus({
                status: 'Error',
                message: 'Error',
                description: JSON.stringify(fetchData?.error?.response?.data?.data || '', null, 2) || ''
            })
            handleShowStatus()
        } else if (fetchData?.error?.response?.data?.size) {

            const file = new Blob([fetchData?.error?.response?.data], { type: 'application/json' })

            file.text()
                .then(value => {
                    const obj = JSON.parse(value)
                    setStatus({
                        status: 'Error',
                        message: obj?.status,
                        description: obj?.message
                    })
                    handleShowStatus()
                })
                .catch(error => {
                    console.log("Something went wrong" + error);
                });
            
        }

    }, [fetchData])

    return (
        <div>
            <Modal show={showStatus} onHide={handleCloseStatus}>
                <Modal.Header closeButton className='border-0 pt-4'>
                    <Modal.Title className='h5'>{status.status}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {status.message}
                    <br />
                    {status.description}
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={handleCloseStatus}>
                        Salir
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}