
export const getIncidenciasAdapterSchema = (item = {}) => {

    const data = {
        dapcons_id: item.dapcons_id || '',
        idioma_id: item.idioma_id || '',
        descripcion: item.dapcons_incidencias_descripcion || '',
        titulo: item.dapcons_incidencias_titulo || '',
        created_at: item.created_at || '',
        dapcons_codigo: item.dapcons_codigo || '',
    }

    return {
        ...data
    }
}

export const getIncidenciasAdapter = (data) => {

    const dataTemp = data?.data?.data

    const items = dataTemp?.data?.map(item => (getIncidenciasAdapterSchema(item)))

    return {
        data: {
            total: dataTemp?.total,
            per_page: dataTemp?.per_page,
            items
        }
    }
}


export const postIncidenciaAdapterSchema = (item = {}) => {

    const data = {
        dapcons_id: item.dapcons_id || '',
        idioma_id: item.idioma_id || '',
        dapcons_incidencias_descripcion: item.descripcion || '',
        dapcons_incidencias_titulo: item.titulo || '',
    }

    return data
}


export const postIncidenciaAdapter = (form) => {

    const formAdapter = postIncidenciaAdapterSchema(form)

    return formAdapter
}
