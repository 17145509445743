import React, {useContext, useState, useEffect} from 'react';
import { AuthContext } from './auth/AuthContext'
import usePaymentMiddleware from './middleware/usePaymentMiddleware';
import {PaymentModal} from './pages/Dashboard/Pago/PaymentModal';
import { AppRouter } from './routers/AppRouter'
import { useAxios } from './hooks/useAxios';

const AppContent = () => {
  const { user } = useContext(AuthContext);
  return (
    <>
        <AppRouter />
        {user?.token && user?.perfil === 'organizacion' &&
          <PaymentModal isOpen={user?.estado_pago} />
        }
    </>
  );
};

export default AppContent;
