import React, { useContext, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faSort, faEdit, faDownload, faNewspaper, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { useLang } from '../../../language/index';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../auth/AuthContext';
import { FormContenido } from './FormContenido';
import { useModalData } from '../../../hooks/useModalData';
import {TableMain} from './TableMain'

export const ContenidosMain = ({ fetchDocumentosData, formValuesSearch, namePage = 'default', handleFormChange, fetchDocumentos, setIsForm }) => {
    const history = useHistory();
    const Lang = useLang();
    const [indexFocus, setIndexFocus] = useState(0);
    const [idContenido, setIdContenido] = useState(0);
    return (
        <>
            <div className="col">
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto">
                    {indexFocus == 0 &&
                        <TableMain
                            fetchDocumentosData={fetchDocumentosData}
                            formValuesSearch={formValuesSearch}
                            handleFormChange={handleFormChange}
                            fetchDocumentos={fetchDocumentos}
                            setIndexFocus={setIndexFocus}
                            setIsForm={setIsForm}
                            setIdContenido={setIdContenido}
                        />
                    }

                    {indexFocus == 1 &&
                        <FormContenido
                            idContenido={idContenido}
                            fetchDocumentos={fetchDocumentos}
                            setIndexFocus={setIndexFocus}
                            setIsForm={setIsForm}
                            setIdContenido={setIdContenido}
                        />
                    }
                </div>
            </div>
        </>
    )
}
