import React, { useEffect, useState } from 'react'
import { Button, Form, ListGroup, Modal } from 'react-bootstrap'
import { InputText, InputSelect } from '../../../components/Inputs';
import { useAxios } from '../../../hooks/useAxios';
import { useForm } from '../../../hooks/useForm'
import { useValidator } from '../../../hooks/useValidator'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner'
import { GetPaisJSON } from '../../../services/pais.service'
import { Loader } from '../../../components/Loader/Loader';
import ValidateSpanishID from '../../../helpers/validateSpanishID';

export const ModalRegistroPagador = ({data, handleCloseModal, documento, fetchTarifa, setPagador, dapconsId}) => {

    const [fetchPaises, fetchPaisesData] = GetPaisJSON()
    const [fetchCambiarPagador, fetchCambiarPagadorData] = useAxios('POST', `/facturacion/registrar_pagador`)
    const [fetchPoblaciones, fetchPoblacionesData] = useAxios('get', `/poblacion`)

    const [formPagador, handleInputChangePagador, handleFormChangePagador] = useForm({
        documento: '',
        tipo_documento: '',
        nombre: '',
        apellido: '',
        direccion: '',
        pais: '',
        codigo_postal: '',
        poblacion: '',
        ciudad: '',
        email: '',
        telefono: '',
    })

    const [valuesValidPagador, handleVerifiedValuesPagador, handleResetValuesPagador] = useValidator({
        documento: { isOk: true, msgError: [`Ingrese un CIF/NIF`] },
        tipo_documento: { isOk: true, msgError: [`Ingrese un Típo de documento`] },
        nombre: { isOk: true, msgError: [`Ingrese un CIF/NIF`] },
        poblacion: { isOk: true, msgError: [`Ingrese una Población`] },
        codigo_postal: { isOk: true, msgError: [`Ingrese un Código postal`] },
        pais: { isOk: true, msgError: [`Ingrese un País`] },
        email: { isOk: true, msgError: [`Ingrese un Email`] },
        direccion: { isOk: true, msgError: [`Ingrese una dirección`] },
        telefono: { isOk: true, msgError: [`Ingrese un Teléfono`] },
    })

    useEffect(() => {
        if (fetchCambiarPagadorData.data?.status == 200) {
            const data = fetchCambiarPagadorData.data?.data?.data;
            fetchTarifa({
                params: {
                    facturacion_id: data?.facturacion_id,
                    dapcons_id: dapconsId
                }
            })
            setPagador(data?.facturacion_id); 
            handleCloseModal();
        }

    }, [fetchCambiarPagadorData])


    const handleNuevoPagador = () => {
        if (handleVerifiedValuesPagador(formPagador)) {
            fetchCambiarPagador({
                body: formPagador
            })
        }
    }

    useEffect(() => {
        if (data.value) {
            fetchPaises();
            const validationResult = ValidateSpanishID(documento);
            handleFormChangePagador({
                ...formPagador,
                documento,
                tipo_documento: validationResult.type === 'cif' ? 'CIF' :'NIF'
            })
        }
    }, [data.value])

    // Poblaciones
    useEffect(() => {
        if (formPagador.pais == 1) {
            if (formPagador.codigo_postal?.length === 5) {
                fetchPoblaciones({
                    params: {
                        cp: formPagador.codigo_postal
                    }
                })
            } else {
                handleFormChangePagador({
                    ...formPagador,
                    ciudad: '',
                    poblacion: '',
                })
            }
        }
    }, [formPagador.codigo_postal])

    return (
        <>
            <Modal show={data.value} onHide={handleCloseModal} size='lg' backdrop="static" keyboard={false}>
                <Modal.Header closeButton className='border-0 pb-0'>
                    <Modal.Title className='h5'>Datos de facturación</Modal.Title>
                </Modal.Header>
                {fetchPaisesData.loading ? <Loader /> : <>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <Form.Group className="mb-3 col-sm-6 col-lg-6">
                                <InputSelect
                                    label='Típo de documento *'
                                    className=''
                                    name='tipo_documento'
                                    values={[
                                       { id: 'NIF', nombre: 'NIF' },
                                       { id: 'CIF', nombre: 'CIF' },
                                    ]}
                                    value={formPagador.tipo_documento}
                                    onChange={handleInputChangePagador}
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}

                                />
                            </Form.Group>
                            <Form.Group className="mb-4 col-lg-6">
                                <InputText
                                    label={'Documento'}
                                    className=''
                                    name='documento'
                                    value={formPagador.documento}
                                    onChange={handleInputChangePagador}
                                    placeholder="Documento"
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12">
                                <InputText
                                    label={formPagador.tipo_documento === 'CIF' ? 'Razón social' : 'Nombre completo'}
                                    className=''
                                    name='nombre'
                                    value={formPagador.nombre}
                                    onChange={handleInputChangePagador}
                                    placeholder={formPagador.tipo_documento === 'CIF' ? 'Ingrese una Razón social' : 'Ingrese un nombre'}
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group>
                            {formPagador.tipo_documento === 'NIF' &&
                                <Form.Group className="mb-3 col-lg-12">
                                    <InputText
                                        label={'Apellidos'}
                                        className=''
                                        name='apellido'
                                        value={formPagador.apellido}
                                        onChange={handleInputChangePagador}
                                        placeholder="Apellidos"
                                        validation={valuesValidPagador}
                                        fetchData={fetchCambiarPagadorData}
                                    />
                                </Form.Group>
                            }

                            <Form.Group className="mb-3 col-lg-12">
                                <InputText
                                    label={'Dirección'}
                                    className=''
                                    as="textarea"
                                    rows={3}
                                    name='direccion'
                                    value={formPagador.calle}
                                    onChange={handleInputChangePagador}
                                    placeholder="Dirección"
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group>

                            <div className="col-12"></div>
                            <Form.Group className="mb-3 col-sm-6 col-lg-6">
                                <InputSelect
                                    label='País *'
                                    className=''
                                    name='pais'
                                    values={fetchPaisesData.data || []}
                                    value={formPagador.pais}
                                    onChange={handleInputChangePagador}
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                />
                            </Form.Group>

                            <Form.Group className="mb-4 col-sm-6 col-lg-6">
                                <InputText
                                    label={'Código Postal'}
                                    className=''
                                    name='codigo_postal'
                                    value={formPagador.codigo_postal}
                                    onChange={handleInputChangePagador}
                                    placeholder="Código postal"
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                />

                            </Form.Group>

                            {
                                formPagador.pais == 1 ? <>
                                    <Form.Group className="mb-3 col-sm-6 col-lg-12">
                                        <InputSelect
                                            label='Población *'
                                            className=''
                                            name='poblacion'
                                            values={
                                                fetchPoblacionesData.data?.data?.data?.map((item, index) => {
                                                    return {
                                                        id: item.poblacion,
                                                        nombre: item.poblacion
                                                    }
                                                })
                                            }
                                            value={formPagador.poblacion}
                                            onChange={handleInputChangePagador}
                                            validation={valuesValidPagador}
                                            fetchData={fetchCambiarPagadorData}
                                            disabled={!fetchPoblacionesData.data || formPagador.codigo_postal?.length !== 5}
                                        />

                                    </Form.Group>
                                </> : <>
                                    <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                        <InputText
                                            label='Región *'
                                            className=''
                                            name='poblacion'
                                            value={formPagador.poblacion}
                                            onChange={handleInputChangePagador}
                                            validation={valuesValidPagador}
                                            fetchData={fetchCambiarPagadorData}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-sm-6 col-lg-6">
                                        <InputText
                                            label='Ciudad *'
                                            className=''
                                            name='ciudad'
                                            value={formPagador.ciudad}
                                            onChange={handleInputChangePagador}
                                            validation={valuesValidPagador}
                                            fetchData={fetchCambiarPagadorData}
                                        />
                                    </Form.Group>
                                </>
                            }

                            <Form.Group className="mb-0 col-lg-6">
                                <InputText
                                    label={'Email'}
                                    className=''
                                    name='email'
                                    value={formPagador.email}
                                    onChange={handleInputChangePagador}
                                    placeholder="Email"
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                />

                            </Form.Group>

                            <Form.Group className="mb-0 col-lg-6">
                                <InputText
                                    label={'Teléfono'}
                                    className=''
                                    name='telefono'
                                    value={formPagador.telefono}
                                    onChange={handleInputChangePagador}
                                    placeholder="Teléfono"
                                    validation={valuesValidPagador}
                                    fetchData={fetchCambiarPagadorData}
                                />

                            </Form.Group>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={handleCloseModal}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="px-4 ms-2"
                        handleSubmit={handleNuevoPagador} fetchDataLoading={[fetchCambiarPagadorData.loading]}
                        value={'Registrar'}
                    />
                </Modal.Footer>
              </>
            }
            </Modal>
        </>
    )
}
