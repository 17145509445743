import { faCheckCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Form, ListGroup, Modal } from 'react-bootstrap'
import { AuthContext } from '../../../../auth/AuthContext';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { Table5 } from '../../../../components/DAPconTables/Table5';
import { Table7 } from '../../../../components/DAPconTables/Table7';
import { FileList } from '../../../../components/FileList/FileList';
import { InputFile, InputText } from '../../../../components/Inputs';
import { Loader } from '../../../../components/Loader/Loader';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';
import { ModalStatusError } from '../../../../components/ModalStatusServer/ModalStatusError';
import { getDataFile, isStatusSucess } from '../../../../helpers/helpers';
import { useAxios } from '../../../../hooks/useAxios';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';

export const DAPconConsultorSeccion7 = ({ idDapcon, idiomaDAPcon, fetchCheckForms, bloqueado }) => {

    const [fetchDocsAdjObligatorios, fetchDocsAdjObligatoriosData, resetFetchDocsAdjObligatorios] = useAxios('get', `/dapcons/${idDapcon}/form/doc-adjuntos-obligatorios`)

    useEffect(() => {

        fetchDocsAdjObligatorios({
            params: {
                idioma_id: idiomaDAPcon
            }
        })

    }, [])

    useEffect(() => {
        if (fetchDocsAdjObligatoriosData.data) {

            console.log(fetchDocsAdjObligatoriosData.data?.data?.data)

            // const formTemp1 = fetchDocsAdjObligatoriosData.data?.data?.data[0] || {}
            // const formTemp2 = fetchDocsAdjObligatoriosData.data?.data?.data[1] || {}

            // const formTemp = [formTemp1, formTemp2]
            const formTemp = fetchDocsAdjObligatoriosData.data?.data?.data
            console.log(formTemp)

            handleFormChangeDocInformeCicloVida({
                ...formTemp['documento_inf_analisis_ciclo_vida']
            })

            handleFormChangeDocFichaProd({
                ...formTemp['ficha_de_producto']
            })

            handleFormChangeDocsAdicionales({
                doc_verificables: formTemp.doc_verificables,
                doc_no_verificables: formTemp.doc_no_verificables,
                form_errors: formTemp.form_errors,
                form_completed: formTemp.form_completed,
            })

        }
    }, [fetchDocsAdjObligatoriosData])

    const [formDocInformeCicloVida, handleInputChangeDocInformeCicloVida, handleFormChangeDocInformeCicloVida, handleResetDocInformeCicloVida] = useForm({
        doc_descripcion: '',
        doc_id: '',
        token_doc: '',
    })

    const [formDocFichaProd, handleInputChangeDocFichaProd, handleFormChangeDocFichaProd, handleResetDocFichaProd] = useForm({
        doc_descripcion: '',
        doc_id: '',
        token_doc: '',
    })

    const [valuesValidationInforme, handleValidationInforme, handleResetValidationInforme] = useValidator({
        doc_descripcion: { isOk: true, msgError: [`El nombre del documento es obligatorio.`] },
    })

    const [valuesValidationFicha, handleValidationFicha, handleResetValidationFicha] = useValidator({
        doc_descripcion: { isOk: true, msgError: [`El nombre del documento es obligatorio.`] },
    })


    const ref_informe_ciclo_vida = useRef(null)
    const ref_ficha_producto = useRef(null)

    // Docs opcionales

    const [formDocsAdicionales, handleInputChangeDocsAdicionales, handleFormChangeDocsAdicionales, handleResetDocsAdicionales] = useForm({
        doc_verificables: [],
        doc_no_verificables: [],
        doc_descripcion: '',
        form_errors: {},
        form_completed: {},
    })

    const [fetchDocumento, fetchDocumentoData, resetfetchDocumentoData] = useAxios('get', `/dapcons/${idDapcon}/form/doc-adjuntos-obligatorios/obtener-documento`, '', 'blob')

    const handleFetchDocumento = (name, type) => {

        fetchDocumento({
            params: {
                token_doc: name,
                doc_type: type,
            }
        })
    }

    const [fetchDocumentoOp, fetchDocumentoDataOp, resetfetchDocumentoDataOp] = useAxios('get', `/dapcons/${idDapcon}/form/informacion-adicional/obtener-documento`, '', 'blob')

    const handleFetchDocumentoOp = (name, type) => {

        fetchDocumentoOp({
            params: {
                token_doc: name,
                doc_type: type,
            }
        })
    }

    const [activeClick, setActiveClick] = useState()

    const [fetchRegistroDocsAdjObligatorios, fetchRegistroDocsAdjObligatoriosData] = useAxios('post', `/dapcons/${idDapcon}/form/doc-adjuntos-obligatorios?_method=PATCH`)

    const handleSubmitDocuments = (e) => {

        e.preventDefault()

        let formData = new FormData();

        // console.log(ref_informe_ciclo_vida.current?.files?.[0], handleValidationInforme(formDocInformeCicloVida))
        // console.log(ref_ficha_producto.current?.files?.[0], handleValidationFicha(formDocFichaProd))

        if (ref_informe_ciclo_vida.current?.files?.[0] && !handleValidationInforme(formDocInformeCicloVida))
            return

        if (ref_ficha_producto.current?.files?.[0] && !handleValidationFicha(formDocFichaProd))
            return


        if (ref_informe_ciclo_vida.current?.files?.[0]) {
            formData.append("doc_id_informe_analisis_ciclo_vida", formDocInformeCicloVida.doc_id || '')
            formData.append("documento_inf_analisis_ciclo_vida", formDocInformeCicloVida.token_doc || ref_informe_ciclo_vida.current?.files?.[0])
            formData.append("descripcion_doc_informe_analisis_ciclo_vida", formDocInformeCicloVida.doc_descripcion)

        }

        if (ref_ficha_producto.current?.files?.[0]) {
            formData.append("doc_id_ficha_producto", formDocFichaProd.doc_id || '')
            formData.append("ficha_de_producto", formDocFichaProd.token_doc || ref_ficha_producto.current?.files?.[0])
            formData.append("descripcion_doc_ficha_producto", formDocFichaProd.doc_descripcion)
        }

        formData.append("idioma_id", idiomaDAPcon)

        fetchRegistroDocsAdjObligatorios({
            body: formData
        })
    }

    useEffect(() => {
        console.log(formDocInformeCicloVida)
    }, [formDocInformeCicloVida])


    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {

        if (fetchRegistroDocsAdjObligatoriosData?.data?.status === 201) {
            setShowSuccessEdit(true)
            // handleResetOrganizacion()
            resetFetchDocsAdjObligatorios()

            fetchDocsAdjObligatorios({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchRegistroDocsAdjObligatoriosData])

    useEffect(() => {

        console.log(fetchDocumentoData);
        if (fetchDocumentoData.data) {
            getDataFile(fetchDocumentoData, resetfetchDocumentoData)
        }
    }, [fetchDocumentoData])

    useEffect(() => {

        if (fetchDocumentoDataOp.data) {
            getDataFile(fetchDocumentoDataOp, resetfetchDocumentoDataOp)
        }
    }, [fetchDocumentoDataOp])


    // Eliminar Documentos adjuntos olbigatorios

    const [fetchEliminarDoc, fetchEliminarDocData] = useAxios('delete', `/dapcons/${idDapcon}/form/doc-adjuntos-obligatorios/eliminar-documento`)

    const handleFetchEliminarDoc = (token_doc) => {

        fetchEliminarDoc({
            params: {
                token_doc,
                idioma_id: idiomaDAPcon
            }
        })
    }

    useEffect(() => {

        if (fetchEliminarDocData?.data?.status === 200) {
            setShowSuccessEdit(true)

            fetchDocsAdjObligatorios({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchEliminarDocData])

    // Documentos opcionales 

    const [showDocs, setShowDocs] = useState({
        value: false,
        title: '',
        type: ''
    });

    const handleCloseDocs = () => setShowDocs({
        value: false,
        title: '',
        type: ''
    });

    const handleShowDocs = (title, type) => setShowDocs({
        value: true,
        title,
        type
    });

    // Subir Documentos

    const [valuesValidationDoc, handleValidationDoc, handleResetValidationDoc] = useValidator({
        doc_descripcion: { isOk: true, msgError: [`El nombre del documento es obligatorio.`] },
    })

    const [fetchSubirDoc, fetchSubirDocData] = useAxios('post', `/dapcons/${idDapcon}/form/informacion-adicional/documento?_method=PATCH`)

    const handleSubmitDoc = (e) => {

        e.preventDefault()

        let formData = new FormData();
        if (!handleValidationDoc(formDocsAdicionales) || !ref_doc.current?.files?.[0])
            return

        formData.append("doc_descripcion", formDocsAdicionales.doc_descripcion)
        formData.append("doc_tipo", showDocs.type)
        formData.append("doc_file", ref_doc.current?.files?.[0])

        formData.append("idioma_id", idiomaDAPcon)

        fetchSubirDoc({
            body: formData
        })
    }

    const ref_doc = useRef(null)


    useEffect(() => {

        if (fetchSubirDocData?.data?.status === 201) {
            setShowSuccessEdit(true)
            handleCloseDocs()
            // handleResetOrganizacion()
            resetFetchDocsAdjObligatorios()

            fetchDocsAdjObligatorios({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchSubirDocData])

    // const { user: { token, lang }, dispatch } = useContext(AuthContext);

    // const handleClickTemp = async (token_doc, doc_type) => {
    //     const response = await fetch(`https://dapcons.arescoop.es/api/dapcons/${idDapcon}/form/doc-adjuntos-obligatorios/obtener-documento?token_doc=${token_doc}&doc_type=${doc_type}`, {
    //         headers: {
    //             'Authorization': 'Bearer ' + token
    //         },
    //         method: 'GET'
    //       });
    //     // waits until the request completes...
    //     console.log(response);
    // }

    return (
        <>
            <div className='MainInner shadow-sm animate-width-disabled'>
                {
                    fetchDocsAdjObligatoriosData.loading ?
                        <div className="py-5">
                            <Loader />
                        </div> :

                        <>
                            <div className="d-flex justify-content-between mb-2">
                                <div>
                                    <h5>Documentos adjuntos obligatorios</h5>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <p>Todos los documentos obligatorios que se suben en este bloque, están protegidos y no son publicables.</p>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <h5 class="mb-3"><small>Documento de Informe de análisis de ciclo de vida</small></h5>
                                    </div>
                                </div>
                                {
                                    formDocInformeCicloVida.token_doc ?
                                        <div className='d-flex align-items-center Files__container col-12'>
                                            <ListGroup className='w-50 d-flex align-items-center Files__container'>
                                                <FileList
                                                    eventClick={() => {
                                                        handleFetchDocumento(formDocInformeCicloVida.token_doc, 'documento_inf_analisis_ciclo_vida')
                                                        setActiveClick('documento_inf_analisis_ciclo_vida')
                                                    }}
                                                    eventDelete={bloqueado ? false : () => {
                                                        handleFetchEliminarDoc(formDocInformeCicloVida.token_doc)
                                                        setActiveClick('documento_inf_analisis_ciclo_vida')
                                                    }}
                                                    name={formDocInformeCicloVida.doc_descripcion}
                                                    isLoading={(fetchDocumentoData.loading || fetchEliminarDocData.loading) && activeClick === 'documento_inf_analisis_ciclo_vida'}
                                                />
                                            </ListGroup>

                                        </div> : <>

                                            <Form.Group className="mb-3 col-lg-6">
                                                <InputText
                                                    label='Título del documento: *'
                                                    className=''
                                                    name='doc_descripcion'
                                                    value={formDocInformeCicloVida.doc_descripcion}
                                                    onChange={handleInputChangeDocInformeCicloVida}
                                                    validation={valuesValidationInforme}
                                                // fetchData={fetchSendOrganizacionData}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formFile" className="col-lg-6 mb-3">
                                                <label class="fw-500 form-label">Documento:</label>

                                                <InputFile
                                                    name='informe_ciclo_vida'
                                                    // onChange={handleInputChangeDocsAdjObligatorios}
                                                    referencia={ref_informe_ciclo_vida}
                                                // validation={valuesValidISO}
                                                />
                                            </Form.Group>
                                            {
                                                formDocsAdicionales.form_completed?.form_errors?.['documento_inf_analisis_ciclo_vida'] &&
                                                <Form.Text className='text-danger d-block mb-2'>{formDocsAdicionales.form_completed?.form_errors?.['documento_inf_analisis_ciclo_vida']}</Form.Text>
                                            }
                                        </>
                                }
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <h5 class="mb-3"><small>Documento de Ficha de producto</small></h5>
                                </div>

                                {
                                    formDocFichaProd.token_doc ?
                                        <div className='d-flex align-items-center Files__container col-12'>
                                            <ListGroup className='w-50 d-flex align-items-center Files__container'>
                                                <FileList
                                                    eventClick={() => {
                                                        handleFetchDocumento(formDocFichaProd.token_doc, 'ficha_de_producto')
                                                        setActiveClick('ficha_de_producto')
                                                        // handleClickTemp(formDocFichaProd.token_doc, 'ficha_de_producto')
                                                    }}
                                                    eventDelete={bloqueado ? false : () => {
                                                        handleFetchEliminarDoc(formDocFichaProd.token_doc)
                                                        setActiveClick('ficha_de_producto')
                                                    }}
                                                    name={formDocFichaProd.doc_descripcion}
                                                    isLoading={(fetchDocumentoData.loading || fetchEliminarDocData.loading) && activeClick === 'ficha_de_producto'}
                                                />
                                            </ListGroup>

                                        </div> : <>
                                            <Form.Group className="mb-3 col-lg-6">
                                                <InputText
                                                    label='Título del documento: *'
                                                    className=''
                                                    name='doc_descripcion'
                                                    value={formDocFichaProd.doc_descripcion}
                                                    onChange={handleInputChangeDocFichaProd}
                                                    validation={valuesValidationFicha}
                                                // fetchData={fetchSendOrganizacionData}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formFile" className="col-lg-6 mb-3">
                                                <label class="fw-500 form-label">Documento:</label>
                                                <InputFile
                                                    name='ficha_producto'
                                                    // onChange={handleInputChangeDocsAdjObligatorios}
                                                    referencia={ref_ficha_producto}
                                                // validation={valuesValidEmacs}
                                                />
                                            </Form.Group>
                                            {
                                                formDocsAdicionales.form_completed?.form_errors?.['ficha_de_producto'] &&
                                                <Form.Text className='text-danger d-block mb-2'>{formDocsAdicionales.form_completed?.form_errors?.['ficha_de_producto']}</Form.Text>
                                            }
                                        </>
                                }

                            </div>

                            <div className=' pt-3 d-flex justify-content-end border-top'>
                                <ButtonSpinner
                                    variant="info" type="submit" className="me-2"
                                    handleSubmit={handleSubmitDocuments} fetchDataLoading={[fetchRegistroDocsAdjObligatoriosData.loading]}
                                    value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                />
                            </div>


                            <div className="row mt-5">
                                <div className="col-12 mb-4">
                                    <h5 className='mb-4 d-flex justify-content-between'>
                                        <span>Documentos opcionales verificables</span>
                                        <Button className='ms-4' variant="primary" size="sm" onClick={() => handleShowDocs('Documentos opcionales verificables', 'documentos_opcionales_verificables')}>
                                            Adjuntar documento
                                        </Button>
                                    </h5>
                                    <p><small>En el apartado documentos opcionales verificables sólo se podran añadir las tablas de parámetros de impacto ambiental y de uso de recursos, residuos y flujos materiales de salida y para el caso específico de multiproducto.  Estos anexos no estarán numerados y no formaran parte de la DAP, se incorporarán al final del documento. </small></p>
                                    <div className='d-flex align-items-center Files__container'>
                                        {
                                            formDocsAdicionales.doc_verificables?.length > 0 ? (
                                                <ListGroup className='col-12 col-lg-6 d-flex align-items-center Files__container'>
                                                    {
                                                        formDocsAdicionales.doc_verificables.map(item => {
                                                            return (
                                                                <FileList
                                                                    eventClick={() => {
                                                                        handleFetchDocumentoOp(item.token_doc, 'documentos_opcionales_verificables')
                                                                        setActiveClick(`documentos_opcionales_verificables_${item.doc_descripcion}_${item.doc_id}`)
                                                                    }}
                                                                    eventDelete={() => {
                                                                        handleFetchEliminarDoc(item.token_doc)
                                                                        setActiveClick(`documentos_opcionales_verificables_${item.doc_descripcion}_${item.doc_id}`)
                                                                    }}
                                                                    name={item.doc_descripcion}
                                                                    isLoading={(fetchDocumentoDataOp.loading || fetchEliminarDocData.loading) && activeClick === `documentos_opcionales_verificables_${item.doc_descripcion}_${item.doc_id}`}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </ListGroup>
                                            ) : (
                                                <div className='text-center py-5 w-100'>
                                                    No hay Documentos opcionales verificables
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>

                                <div className="col-12 mb-4 mt-3">
                                    <h5 className='mb-4 d-flex justify-content-between'>
                                        <span>Documentos opcionales no verificables</span>
                                        <Button className='ms-4' variant="primary" size="sm" onClick={() => handleShowDocs('Documentos opcionales no verificables', 'documentos_opcionales_no_verificables')}>
                                            Adjuntar documento
                                        </Button>
                                    </h5>

                                    <div className='d-flex align-items-center Files__container'>
                                        {
                                            formDocsAdicionales.doc_no_verificables?.length > 0 ? (
                                                <ListGroup className='col-12 col-lg-6 d-flex align-items-center Files__container'>
                                                    {
                                                        formDocsAdicionales.doc_no_verificables.map(item => {
                                                            return (
                                                                <FileList
                                                                    eventClick={() => {
                                                                        handleFetchDocumentoOp(item.token_doc, 'documentos_opcionales_no_verificables')
                                                                        setActiveClick(`documentos_opcionales_no_verificables_${item.doc_descripcion}_${item.doc_id}`)
                                                                    }}
                                                                    eventDelete={() => {
                                                                        handleFetchEliminarDoc(item.token_doc)
                                                                        setActiveClick(`documentos_opcionales_no_verificables_${item.doc_descripcion}_${item.doc_id}`)
                                                                    }}
                                                                    name={item.doc_descripcion}
                                                                    isLoading={(fetchDocumentoDataOp.loading || fetchEliminarDocData.loading) && activeClick === `documentos_opcionales_no_verificables_${item.doc_descripcion}_${item.doc_id}`}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </ListGroup>
                                            ) : (
                                                <div className='text-center py-5 w-100'>
                                                    No hay Documentos opcionales verificables
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>

                        </>
                }

            </div>
            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatusError fetchData={fetchRegistroDocsAdjObligatoriosData} />

            <Modal
                show={showDocs.value}
                onHide={handleCloseDocs}
                // backdrop="static"
                keyboard={false}
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>{showDocs.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row my-4">

                            <Form.Group className="mb-3 col-12 col-lg-6">
                                <InputText
                                    label='Título del documento: *'
                                    className=''
                                    name='doc_descripcion'
                                    value={formDocsAdicionales.doc_descripcion}
                                    onChange={handleInputChangeDocsAdicionales}
                                    validation={valuesValidationDoc}
                                // fetchData={fetchSendOrganizacionData}
                                />
                            </Form.Group>

                            <Form.Group controlId="formFile" className="col-12 col-lg-6 mb-3">
                                <label class="fw-500 form-label">Documento:</label>
                                <InputFile
                                    name='ficha_producto'
                                    // onChange={handleInputChangeDocsAdjObligatorios}
                                    referencia={ref_doc}
                                // validation={valuesValidEmacs}
                                />
                            </Form.Group>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDocs}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="info" type="submit" className="me-2"
                        handleSubmit={handleSubmitDoc} fetchDataLoading={[fetchSubirDocData.loading]}
                        value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                    />
                    {/* <Button variant="primary" onClick={handleSubmitDoc}>Guardar</Button> */}
                </Modal.Footer>
            </Modal>

            <ModalStatusError fetchData={fetchDocumentoData} />
            
            <ModalStatusError fetchData={fetchDocumentoDataOp} />

        </>
    )
}
