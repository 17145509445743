import { useAxiosFake } from "../hooks/useAxiosFake";
import { useAxios2 } from "../hooks/useAxios2";
import { 
    getUsuarioAdapter, 
    getUsuarioAdapterSchema, 
    postUsuarioAdapter, 
    postUsuarioAdapterSchema, 
    usuariosAdapter 
} from "../adapters/usuario.adapter";

export const GetUsuariosJSON = () => {
    return useAxios2('get', '/usuarios-admin/lista', 'json', {
        receiveAdapter: usuariosAdapter
    });
};

export const GetUsuarioJSON = (user_id) => {
    return useAxios2('get', `/usuarios-admin/${user_id}/show`, 'json', {
        receiveAdapter: getUsuarioAdapter
    });
};

export const PostUsuarioJSON = () => {
    return useAxios2('post', '/usuario-admin/caateeb/registro', 'json', {
        receiveSchemaAdapter: getUsuarioAdapterSchema,
        sendAdapter: postUsuarioAdapter
    });
};

// export const PostUpdateUsuarioJSON = (user_id) => {
//     return useAxios2('post', `/usuario-admin/caateeb/${user_id}/update`, 'json', {
//         receiveSchemaAdapter: getUsuarioAdapterSchema,
//         sendAdapter: postUsuarioAdapter
//     });
// };