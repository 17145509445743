import React from 'react';
import './Wizard.css'

const Wizard = ({children}) => {

    return (
        <>
            <section className='Wizard'>
                { children }
            </section>
        </>
    )
};

const HeaderWizard = ({listWizard, indexFocusWizard, setIndexFocusWizard}) => {

    return (
        <>
            <section className={`HeaderWizard ${!setIndexFocusWizard ? 'disabledCursor' : ''}`}>
                {
                    listWizard?.map((item, index) => {
                        return (
                            <div key={index} className={`HeaderWizard-item ${indexFocusWizard === index ? 'HeaderWizard-item_focus' : ''} ${indexFocusWizard > index ? 'HeaderWizard-item_complete' : ''}`}>
                                <div className={`HeaderWizard-item-info ${indexFocusWizard < index ? 'disabledCursor' : ''}`} onClick={() => setIndexFocusWizard ? setIndexFocusWizard(index) : null} >
                                    <div className='HeaderWizard-item-number'>{index + 1}</div>
                                    <div className='HeaderWizard-item-name'>{item}</div>
                                </div>
                                <div className="HeaderWizard-item-line"></div>
                            </div>
                        )
                    })
                }
            </section>
        </>
    )
};

const BodyWizard = ({children, indexFocusWizard, setIndexFocusWizard}) => {

    return (
        <>
            <section className='BodyWizard'>
                {
                    children?.map((item, index) => {
                        if (index !== indexFocusWizard) {
                            return null
                        }
                        return (
                            <div key={index}>
                                {item}
                            </div>
                        )
                    })
                }
            </section>
        </>
    )
};

Wizard.Header = HeaderWizard
Wizard.Body = BodyWizard

export default Wizard;

// <div key={index} className={index === indexFocusWizard ? 'd-block' : 'd-none'}>
                            //     {item}
                            // </div>