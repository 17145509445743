import { faAngleLeft, faAngleRight, faCheckCircle, faChevronLeft, faChevronRight, faFile, faPlusCircle, faSave, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner'
import { InputText } from '../../../../components/Inputs'
import InputImages from '../../../../components/Inputs/InputImages'
import { Loader } from '../../../../components/Loader/Loader'
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus'
import { isOrderPrev_ } from '../../../../helpers/helpers'
import { useAxios } from '../../../../hooks/useAxios'
import { useAxiosMultiple } from '../../../../hooks/useAxiosMultiple'
import { useForm } from '../../../../hooks/useForm'
import { TextInformationSaltoPagina } from '../../../../components/TextInformation/TextInformationSaltoPagina'

export const DAPconConsultorSeccion3 = ({ id, idiomaDAPcon, fetchCheckForms }) => {

    // 1. Descripción del producto y su uso

    const [fetchDescProd, fetchDescProdData] = useAxios('get', `/dapcons/${id}/form/desc-prod-uso`)

    const [fetchEditarDescProd, fetchEditarDescProdData] = useAxios('post', `/dapcons/${id}/form/desc-prod-uso/desc?_method=PATCH`)

    const [fetchGetImages, fetchGetImagesData] = useAxiosMultiple('get', `/dapcons/${id}/form/image`, '', 'blob')

    const [formDescProd, handleInputChangeDescProd, handleFormChangeDescProd, handleResetDescProd] = useForm({
        descripcion: '',
        packaging_materials: '',
        product_components: '',
    })

    useEffect(() => {
        fetchDescProd({
            params: {
                idioma_id: idiomaDAPcon
            }
        })
    }, [])


    useEffect(() => {
        if (fetchDescProdData.data) {

            console.log(fetchDescProdData.data?.data?.data)
            const formDescProd = fetchDescProdData.data?.data?.data
            handleFormChangeDescProd({
                descripcion: formDescProd.descripcion_prod_uso,
                packaging_materials: formDescProd.packaging_materials,
                product_components: formDescProd.product_components,
            })

            const payload = formDescProd.imagenes?.map(item => {
                return {
                    id: item.img_id,
                    order_by_section: item.order_by_section
                }
            })

            fetchGetImages(payload)
        }
    }, [fetchDescProdData])

    useEffect(() => {

        console.log(fetchGetImagesData)

        if (fetchGetImagesData.data) {
            const arrayImages = fetchGetImagesData.data?.map((item, index) => {
                console.log(item.data)
                return {
                    path: URL.createObjectURL(item.data),
                    id: fetchDescProdData.data?.data?.data?.imagenes?.[index]?.img_id,
                    order_by_section: fetchDescProdData.data?.data?.data?.imagenes?.[index]?.order_by_section
                }
            })

            setInputFiles(arrayImages)
        }

    }, [fetchGetImagesData])


    const handleSubmitDAPconDatReqEdit = (e) => {

        e.preventDefault()

        fetchEditarDescProd({
            params: {
                idioma_id: idiomaDAPcon
            },
            body: formDescProd
        })
    }

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        console.log(fetchEditarDescProdData.data)
        if (fetchEditarDescProdData.data?.status === 201) {
            setShowSuccessEdit(true)
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchEditarDescProdData])


    const [InputFiles, setInputFiles] = useState([])

    // Subir una imagen

    const [fetchSubirImagen, fetchSubirImagenData] = useAxios('post', `/dapcons/${id}/form/desc-prod-uso/image?_method=PATCH`)

    const handleSubirImagen = (file) => {
        let formDataImagen = new FormData();
        formDataImagen.append("imagen", file)
        formDataImagen.append("idioma_id", idiomaDAPcon)
        console.log(file)
        fetchSubirImagen({
            body: formDataImagen
        })
    }

    // Error al subir imagen

    const [showErrorUpload, setShowErrorUpload] = useState(false);

    useEffect(() => {
        console.log(fetchSubirImagenData.error?.response?.status === 400)

        if (fetchSubirImagenData.error?.response?.status === 400) {

            const InputFilesTemp = [...InputFiles]

            InputFilesTemp.pop()

            setInputFiles(InputFilesTemp)
            setShowErrorUpload(true)
        }

    }, [fetchSubirImagenData])

    // Asignar id a la imagen subida, luego de cargarla

    useEffect(() => {
        if (fetchSubirImagenData.data?.status === 201) {
            console.log(fetchSubirImagenData.data?.data?.data?.img_id)

            const InputFilesTemp = [...InputFiles]

            InputFilesTemp[InputFilesTemp.length - 1].id = fetchSubirImagenData.data?.data?.data?.img_id
            InputFilesTemp[InputFilesTemp.length - 1].order_by_section = fetchSubirImagenData.data?.data?.data?.order_by_section

            setInputFiles(InputFilesTemp)
        }
    }, [fetchSubirImagenData])


    // Eliminar una imagen

    const [fetchEliminarImagen, fetchEliminarImagenData] = useAxios('delete', `/dapcons/${id}/form/image`)

    const handleEliminarImagen = (file) => {
        // const arrayLang = ['es', 'en']
        fetchEliminarImagen({
            // params: {
            //     idioma_id: 'es'
            // },
            body: {
                img_id: file.id
            }
        })
    }

    // Ordenar imagenes

    const [fetchOrdenarImagenes, fetchOrdenarImagenesData] = useAxios('post', `/dapcons/${id}/form/desc-prod-uso/image-ord?_method=PATCH`)

    const handleOrdenarImagenes = (imagenes) => {
        // const arrayLang = ['es', 'en']

        fetchOrdenarImagenes({
            // id: arrayLang[idiomaDAPcon - 1],
            body: {
                imagenes
            }
        })
    }


    return (
        <>
            <div className='MainInner shadow-sm animate-width-disabled'>
                {
                    fetchDescProdData.loading ? (
                        <div className="py-5">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <h5> 1. Descripción del producto y su uso</h5>
                                </div>
                            </div>
                            <Form className='row'>

                                <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput7">
                                    <InputText
                                        label='Descripción del Producto'
                                        className=''
                                        as="textarea"
                                        rows={5}
                                        name='descripcion'
                                        value={formDescProd.descripcion}
                                        onChange={handleInputChangeDescProd}
                                        fetchData={fetchEditarDescProdData}
                                    // validation={valuesValidationDAPcon}
                                    />
                                    <TextInformationSaltoPagina />

                                </Form.Group>

                                <h5 className='mb-3'>Información de contenido</h5>

                                <Form.Group className="col-12 mb-2" controlId="exampleForm.ControlInput7">
                                    <InputText
                                        label='Componentes del producto'
                                        className=''
                                        as="textarea"
                                        rows={5}
                                        name='product_components'
                                        value={formDescProd.product_components}
                                        onChange={handleInputChangeDescProd}
                                        fetchData={fetchEditarDescProdData}
                                    // validation={valuesValidationDAPcon}
                                    />
                                    {/* <TextInformationSaltoPagina /> */}

                                </Form.Group>

                                <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput7">
                                    <InputText
                                        label='Materiales de embalaje'
                                        className=''
                                        as="textarea"
                                        rows={5}
                                        name='packaging_materials'
                                        value={formDescProd.packaging_materials}
                                        onChange={handleInputChangeDescProd}
                                        fetchData={fetchEditarDescProdData}
                                    // validation={valuesValidationDAPcon}
                                    />
                                    <TextInformationSaltoPagina />

                                </Form.Group>



                                <div className='mt-0 pt-3 d-flex justify-content-end border-top'>
                                    <ButtonSpinner
                                        variant="info" type="submit" className="me-2"
                                        handleSubmit={handleSubmitDAPconDatReqEdit} fetchDataLoading={[fetchEditarDescProdData.loading]}
                                        value='Guardar' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                                    />
                                </div>
                                <div className="col-12">
                                    <Form.Group className="col-12 col-sm-6">
                                        <Form.Label>Adjuntar imágenes</Form.Label>
                                    </Form.Group>

                                    <div className="d-flex flex-wrap">
                                        {
                                            fetchGetImagesData.loading ? (
                                                <div className='container-files-input d-flex align-items-center justify-content-center w-100'>
                                                    <div className="py-5">
                                                        <Loader />
                                                    </div>
                                                </div>

                                            ) : (
                                                <InputImages
                                                    width='17rem'
                                                    height='25rem'
                                                    InputFiles={InputFiles}
                                                    setInputFiles={setInputFiles}
                                                    handleSubirImagen={handleSubirImagen}
                                                    fetchSubirImagenData={fetchSubirImagenData}
                                                    handleEliminarImagen={handleEliminarImagen}
                                                    fetchEliminarImagenData={fetchEliminarImagenData}
                                                    handleOrdenarImagenes={handleOrdenarImagenes}
                                                    fetchOrdenarImagenesData={fetchOrdenarImagenesData}
                                                    multipleImages={false}
                                                />
                                            )
                                        }

                                    </div>

                                </div>
                            </Form>

                        </>
                    )
                }
            </div>
            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatus setShowStatus={setShowErrorUpload} showStatus={showErrorUpload}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faTimesCircle} className='me-2 fa-2x text-danger' />
                    </h5>
                    <p className='mb-0'>No se ha podido cargar la imagen
                    </p>
                </div>
            </ModalStatus>
        </>
    )
}
