import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useEffect } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import ButtonOrderBy from '../../../../components/ButtonOrderBy/ButtonOrderBy'
import { InputSelect, InputText } from '../../../../components/Inputs'
import { Loader } from '../../../../components/Loader/Loader'
import { PaginationT } from '../../../../components/PaginationT/PaginationT'
import { getURLQuery, getValuesParams } from '../../../../helpers/helpers'
import { useAxios } from '../../../../hooks/useAxios'
import { useForm } from '../../../../hooks/useForm'
import { useLang } from '../../../../language'
import { GetOrganizacionesJSON } from '../../../../services/organizacion.service'
import { OrganizacionesFilter } from '../../../Organizaciones/OrganizacionesFilter/OrganizacionesFilter'

export const ModalTableOrganizacion = ({ show, handleClose, setOrganizacionSelect }) => {

    const [fetchOrganizaciones, fetchOrganizacionesData] = GetOrganizacionesJSON()

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        filter_cif: '',
        num_colegiado: '',
        filter_name: '',
        colegio_id: '',
        page: 1,
        order_by: '',
        estado_id: '',
        filter_estado_alta: 'alta',

    });

    const handleSearch = (e) => {

        if (e) {
            e.preventDefault();
        }

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchOrganizaciones({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    }

    useEffect(() => {

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        fetchOrganizaciones({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order_by])

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Buscador de Organizaciones</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <FilterOrganizacion
                    fetchOrganizacionesData={fetchOrganizacionesData}
                    handleSearch={handleSearch}
                    handleInputChangeSearch={handleInputChangeSearch}
                    formValuesSearch={formValuesSearch}
                    handleFormChange={handleFormChange}
                    setOrganizacionSelect={setOrganizacionSelect}
                    handleClose={handleClose}
                />

            </Modal.Body>
        </Modal>
    )
}

const FilterOrganizacion = ({ handleSearch, handleInputChangeSearch, formValuesSearch,
    fetchOrganizacionesData, handleFormChange, setOrganizacionSelect, handleClose }) => {

    const handleSelect = (id, nombre) => {

        setOrganizacionSelect({
            id,
            nombre
        })

        handleClose()
    }

    const Lang = useLang();

    return (
        <div className='row'>
            <div className='col-12 mb-3'>
                <div className='Column-small shadow-sm'>


                    {/* <h5>{Lang('FILTRO')}</h5> */}
                    <Form className='row' onSubmit={handleSearch}>

                        <Form.Group className="col-6 col-sm-4 col-xl-4 mb-3">
                            <InputText
                                label='Nombre:'
                                className=''
                                name='filter_name'
                                value={formValuesSearch.filter_name}
                                onChange={handleInputChangeSearch}
                            />
                        </Form.Group>

                        <Form.Group className="col-6 col-sm-4 col-xl-4 mb-3">
                            <InputText
                                label='CIF:'
                                className=''
                                name='filter_cif'
                                value={formValuesSearch.filter_cif}
                                onChange={handleInputChangeSearch}
                            />
                        </Form.Group>

                        <Form.Group className="col-6 col-sm-4 col-xl-4 mb-3">

                            <InputSelect
                                label='Estado:'
                                name='filter_estado_alta'
                                values={
                                    [
                                        { id: 'alta', nombre: 'Alta' },
                                        { id: 'baja', nombre: 'baja' },
                                        { id: 'todos', nombre: 'todos' },
                                    ]
                                }
                                value={formValuesSearch.filter_estado_alta}
                                onChange={handleInputChangeSearch}
                            />
                        </Form.Group>


                        <div className='mt-2'>
                            <button className="px-3 btn btn-primary float-end">
                                <FontAwesomeIcon icon={faSearch} /> {Lang('BUSCAR')}
                            </button>
                        </div>
                    </Form>

                </div>
            </div>

            <div className="col-12">

                <>
                    {
                        fetchOrganizacionesData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    fetchOrganizacionesData?.data?.total ?

                                        <>
                                            <Table hover bordered responsive className='tableMain'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-nowrap'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`CIF`}
                                                                id='cif'
                                                            />
                                                        </th>
                                                        <th className='text-nowrap'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`Nombre`}
                                                                id='nombre'
                                                            />
                                                        </th>
                                                        <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                className='text-center'
                                                                label={`Fecha alta`}
                                                                id='fecha_creacion'
                                                            />
                                                        </th>
                                                        <th className='text-nowrap text-center'>Teléfono</th>
                                                        {/* <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={Lang('FECHA_ESTADO')}
                                                                id='fecha_estado'
                                                            />
                                                        </th> */}
                                                        <th className='shrink-td text-center'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fetchOrganizacionesData?.data?.items?.map((item) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td valign='middle'>{item.cif}</td>
                                                                <td valign='middle'>{item.nombre}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{item.fecha_alta}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{item.telefono}</td>
                                                                <td valign='middle' className='text-center'>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <Button variant="primary" size="sm" className=''
                                                                                        onClick={() => handleSelect(item.org_id, item.nombre)}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchOrganizacionesData?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchOrganizacionesData?.data?.per_page}
                                            />
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No es van trobar resultats</h5>
                                }
                            </>
                    }
                </>
            </div>

        </div>
    )
}
