import React, { useContext, useEffect } from 'react'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useAxios } from '../../../hooks/useAxios'
import { useForm } from '../../../hooks/useForm'
import { RCPsFilter } from '../RCPsFilter/RCPsFilter'
import { RCPsMain } from '../RCPsMain/RCPsMain'
import queryString from 'query-string';
import { AuthContext } from '../../../auth/AuthContext'
import { GetRCPsJSON } from '../../../services/rcp.service'

export const RCPsContent = ({ namePage, estaVerificando }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const location = useLocation()

    const [fetchParameters, fetchParametersData] = useAxios('GET', `/parametros`)

    useEffect(() => {

        fetchParameters({
            params: {
                'colegios': 1,
                'perfiles_usuario': 1,
                'estados_colegiacion': 1,
            }
        });
        fetchRCPs()

    }, [])

    const [fetchRCPs, fetchRCPsData] = GetRCPsJSON()

    return (
        <div className="container-inner row justify-content-between">

            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {namePage}
                </Breadcrumb.Item>
            </Breadcrumb>

            {/* <RCPsFilter
                handleSearch={handleSearch}
                handleInputChangeSearch={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
                fetchParametersData={fetchParametersData}
            /> */}

            <RCPsMain
                fetchRCPsData={fetchRCPsData}
                fetchRCPs={fetchRCPs}
                // formValuesSearch={formValuesSearch}
                namePage={namePage}
                // handleFormChange={handleFormChange}
                fetchParametersData={fetchParametersData}
                modulo_app={modulo_app}
                estaVerificando={estaVerificando}
            />
        </div>

    )
}
