import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, InputGroup, Modal, Spinner, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputSelect, InputText } from '../../../components/Inputs';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { MsgInput } from '../../../components/MsgInput/MsgInput';
import { postUsuarioAdapterSchema } from '../../../adapters/usuario.adapter';
import { PostConsultorJSON } from '../../../services/consultor.service';
import { PostUsuarioJSON } from '../../../services/usuario.service';
import { ModalStatusServer2 } from '../../../components/ModalStatusServer/ModalStatusServer2';
import { getValuesParams, isStatusSucess } from '../../../helpers/helpers';
import { ModalTableOrganizacion } from '../../DAPcons/DAPconsFilter/ModalTableOrganizacion';

// import './ProcessosMain.css'

export const ModalPagoCuota = ({ showModalPagoCuota, setshowModalPagoCuota, handleCloseModalPagoCuota, fetchRCPData,
    fetchVerificadoresData, fetchIdiomasData, fetchSuccess, formValuesSearch }) => {

    const history = useHistory();

    const [fetchPagoPOST, fetchPagoPOSTData] = useAxios('POST', '/cuotas/registro-pago')

    const [formPago, handleInputChangePago, handleFormChangeCrearDAPcons, handleResetCrearDAPcons]
        = useForm({})

    const [valuesValidCrearDAPcons, handleVerifiedValuesCrearDAPcons, handleResetValuesCrearDAPcons] = useValidator({
        org_id: { isOk: true, msgError: [`El campo es obligatorio.`] },
        year: { isOk: true, msgError: [`El campo es obligatorio.`] },
        pagado: { isOk: true, msgError: [`El campo es obligatorio.`] },
    })

    const handleSubmitAltaDAPcons = () => {

        if (handleVerifiedValuesCrearDAPcons(formPago)) {
            fetchPagoPOST({
                body: {
                    cuotas: [
                        {
                            ...formPago,
                            org_id: Number(formPago.org_id || 0)
                        }
                    ]
                }
            })
        }
    }

    useEffect(() => {
        console.log(fetchPagoPOSTData)

        if (isStatusSucess(fetchPagoPOSTData?.data?.status)) {
            fetchSuccess({
                params: {
                    ...getValuesParams(formValuesSearch)
                }
            })
            handleCloseModalPagoCuota()
        }

    }, [fetchPagoPOSTData])

    // ------------ DELETE pago cuota

    const [fetchPagoDELETE, fetchPagoDELETEData] = useAxios('DELETE', '/cuotas/borrado-pago')

    const handleSubmitDeletePago = () => {
        fetchPagoDELETE({
            body: {
                cuotas: [
                    {
                        ...formPago,
                        org_id: Number(formPago.org_id || 0)
                    }
                ]
            }
        })
    }

    useEffect(() => {
        console.log(fetchPagoDELETEData)

        if (isStatusSucess(fetchPagoDELETEData?.data?.status)) {
            fetchSuccess({
                params: {
                    ...getValuesParams(formValuesSearch)
                }
            })
            handleCloseModalPagoCuota()
        }

    }, [fetchPagoDELETEData])

    // --------- Seleccion de organizacion

    const [organizacionSelect, setOrganizacionSelect] = useState({
        id: '',
        nombre: ''
    })

    // Modal

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        handleInputChangePago({
            target: {
                name: 'org_id',
                value: organizacionSelect.id
            }
        })
    }, [organizacionSelect])

    useEffect(() => {

        if (showModalPagoCuota.value) {

            if (showModalPagoCuota?.payload?.item) {

                handleFormChangeCrearDAPcons({
                    ...showModalPagoCuota?.payload?.item
                })
            }

        } else {
            
            setOrganizacionSelect({
                id: '',
                nombre: ''
            })
            handleResetCrearDAPcons()
            handleResetValuesCrearDAPcons()
        }

    }, [showModalPagoCuota.value]);



    return (
        <>

            <Modal show={showModalPagoCuota.value} onHide={handleCloseModalPagoCuota}>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Ficha de registro de cuotas</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-4'>

                    <Form className='row'>

                        {/* <Form.Group className="">
                            <InputText
                                label='NIF:'
                                className=''
                                name='org_id'
                                value={formPago.org_id}
                                onChange={handleInputChangePago}
                                validation={valuesValidCrearDAPcons}
                                fetchData={fetchPagoPOSTData}
                            />
                        </Form.Group> */}

                        <Form.Group className="mb-4 col-12">
                            <Form.Label className='fw-500'>Organización:</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    value={organizacionSelect.nombre}
                                    onChange={() => setOrganizacionSelect({ id: '', nombre: '' })}
                                />
                                <InputGroup.Text className='btn btn-sm btn-primary d-flex align-items-center' onClick={handleShow}>
                                    <FontAwesomeIcon icon={faWarehouse} className='' />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-4 col-lg-6">
                            {
                                showModalPagoCuota.payload?.action === 'CREATE' ?
                                    <InputText
                                        label='Año cuota:'
                                        className=''
                                        name='year'
                                        value={formPago.year}
                                        onChange={handleInputChangePago}
                                        validation={valuesValidCrearDAPcons}
                                        fetchData={fetchPagoPOSTData}
                                    />
                                    :
                                    <>
                                        <Form.Label className='fw-500'>
                                            <span className='pe-2'>Año cuota:</span>
                                        </Form.Label>
                                        <p className='mt-0 mb-0'>{formPago.year}</p>
                                    </>
                            }
                        </Form.Group>

                        <Form.Group className="mb-4 col-lg-6">
                            {
                                showModalPagoCuota.payload?.action === 'CREATE' ?
                                    <InputText
                                        label='Fecha de pago:'
                                        className=''
                                        type='date'
                                        name='pagado'
                                        value={formPago.pagado}
                                        onChange={handleInputChangePago}
                                        validation={valuesValidCrearDAPcons}
                                        fetchData={fetchPagoPOSTData}
                                    />
                                    :
                                    <>
                                        <Form.Label className='fw-500'>
                                            <span className='pe-2'>Fecha de pago:</span>
                                        </Form.Label>
                                        <p className='mt-0 mb-0'>{(new Date(formPago.pagado)?.toLocaleDateString())}</p>
                                    </>
                            }

                        </Form.Group>

                        <Form.Group className="col-12">
                            {
                                showModalPagoCuota.payload?.action === 'CREATE' ?
                                    <InputText
                                        label='Observaciones:'
                                        className=''
                                        as='textarea'
                                        rows={2}
                                        name='observaciones'
                                        value={formPago.observaciones}
                                        onChange={handleInputChangePago}
                                        validation={valuesValidCrearDAPcons}
                                        fetchData={fetchPagoPOSTData}
                                    />
                                    :
                                    <>
                                        <Form.Label className='fw-500'>
                                            <span className='pe-2'>Observaciones:</span>
                                        </Form.Label>
                                        <p className='mt-0 mb-0'>{formPago.observaciones}</p>
                                    </>
                            }

                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    {
                        showModalPagoCuota.payload?.action === 'CREATE' &&
                        <>
                            <Button variant="default" >
                                Salir
                            </Button>
                            <ButtonSpinner
                                variant="info" type="submit" className="ms-2"
                                handleSubmit={handleSubmitAltaDAPcons} fetchDataLoading={[fetchPagoPOSTData.loading]}
                                value='Crear Pago' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                            />
                        </>
                    }
                    {
                        showModalPagoCuota.payload?.action === 'EDIT' &&
                        <>
                            <ButtonSpinner
                                variant="danger" type="submit" className="ms-2"
                                handleSubmit={handleSubmitDeletePago} fetchDataLoading={[fetchPagoDELETEData.loading]}
                                value='Eliminar Pago' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                            />
                            <div></div>
                        </>
                    }

                </Modal.Footer>
            </Modal>

            <ModalStatusServer2 fetchData={fetchPagoPOSTData} />
            <ModalStatusServer2 fetchData={fetchPagoDELETEData} />

            <ModalTableOrganizacion show={show} handleClose={handleClose} setOrganizacionSelect={setOrganizacionSelect} />

        </>
    )
}
