import React from 'react'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import './Tablas.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'

export const Table3 = ({ destinos, formEtapasCiclo, handleInputChangeTable3, tablaEscenariosTransporte,
    handleShowModalCrearTransporte, handleDeleteTransporte, bloqueado }) => {

    // console.log('formEtapasCiclo', formEtapasCiclo)

    const [totalPercent, settotalPercent] = useState(0);

    useEffect(() => {

        if (!isEmpty(formEtapasCiclo.table3)) {

            const score2 = Object.values(formEtapasCiclo.table3)?.reduce((a, b) => [...a, ...b]).reduce((total, itemCurrent) => {
                console.log(itemCurrent);
                return total + Number(itemCurrent.porcentaje || 0)
            }, 0)

            settotalPercent(score2)
        }


    }, [formEtapasCiclo.table3]);


    return (
        <>
            {
                tablaEscenariosTransporte && destinos && formEtapasCiclo &&
                <div>
                    {/* <div className="d-flex justify-content-end align-content-center mt-1 pt-3 border-top">
                        {
                            !bloqueado && <Button onClick={handleShowModalCrearTransporte} size='sm'>
                                Agregar Tipo Transporte
                            </Button>
                        }

                    </div> */}
                    <caption className='text-center d-block'>Tabla Escenarios aplicados para el transporte del producto hasta el lugar de instalación</caption>
                    <Table responsive id='Table3' className='DAPconTable w-auto mx-auto'>
                        <thead>
                            <tr>
                                <th>Destino</th>
                                {
                                    Object.keys(tablaEscenariosTransporte).map(key => (
                                        <th>
                                            {key === 'tipo_transporte' && !bloqueado ?
                                                <div className='text-nowrap'>
                                                    {tablaEscenariosTransporte[key].pt_texto}
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Agregar transporte</Tooltip>} placement='bottom'>
                                                        <Button variant="light" size='' className={`px-3 ms-3 d-inline-block btn_3`}
                                                            onClick={handleShowModalCrearTransporte}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} className='' />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                                :
                                                tablaEscenariosTransporte[key].pt_texto
                                            }
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(destinos).map(key => {
                                    return (
                                        <>
                                            {
                                                formEtapasCiclo.table3[key]?.map((item, index) => {

                                                    return (
                                                        <>
                                                            <tr>
                                                                {
                                                                    index === 0 &&
                                                                    <td valign='middle' rowSpan={formEtapasCiclo.table3[key]?.length}>
                                                                        <strong>{destinos[key]?.pt_texto}</strong>
                                                                    </td>
                                                                }

                                                                {
                                                                    Object.keys(tablaEscenariosTransporte).map(key2 => {
                                                                        return (
                                                                            <td>
                                                                                <Form.Control
                                                                                    type={key2 === 'porcentaje' || key2 === 'km_medios' ? 'number' : 'text'}
                                                                                    autoComplete="one-time-code"
                                                                                    value={formEtapasCiclo.table3[key]?.[index]?.[key2]}
                                                                                    onChange={(e) => handleInputChangeTable3(e, key, key2, index)}
                                                                                />
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    index !== 0 && !bloqueado &&
                                                                    <td>
                                                                        <Button className={index} variant='danger' size='sm' onClick={() => handleDeleteTransporte(key, index)}>
                                                                            <FontAwesomeIcon icon={faTrash} className='' />
                                                                        </Button>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                })
                            }

                            <tr>
                                <td className='border-0'></td>
                                {
                                    Object.keys(tablaEscenariosTransporte).map(key2 => {

                                        if (key2 !== 'porcentaje')
                                            return <td className='border-0'></td>

                                        return <td className='text-center'>
                                            <span className={totalPercent === 100 ? 'text-success' : 'text-danger'}>
                                                Total {totalPercent}%
                                            </span>
                                        </td>

                                    })
                                }
                            </tr>
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}
