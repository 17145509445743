import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

const ButtonOrderBy = ({formValuesSearch, handleFormChange, label, id, className, disabledNoWrapText}) => {
    return (
        <button
            className={`bg-transparent border-0 text-start w-100 fw-bold ${disabledNoWrapText ? '' : 'text-nowrap'} ${className ? className : ''} ${formValuesSearch?.order_by && formValuesSearch?.order_by?.indexOf(id) !== -1 ? 'table-orderby-active' : ''}`}
            onClick={() => {
                if (formValuesSearch.order_by === `${id} asc`) {
                    handleFormChange({
                        ...formValuesSearch,
                        order_by: `${id} desc`
                    });
                } else {
                    handleFormChange({
                        ...formValuesSearch,
                        order_by: `${id} asc`
                    });
                }
            }}
        >
            <FontAwesomeIcon icon={faSort} className='me-2' /> {label}
        </button>
    )
}

export default ButtonOrderBy