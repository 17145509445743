import { useAxiosFake } from "../hooks/useAxiosFake";
import { useAxios2 } from "../hooks/useAxios2";
import { 
    getOrganizacionAdapter, 
    getOrganizacionAdapterMisDatos, 
    getOrganizacionAdapterSchema, 
    getOrganizacionAdapterSchemaMisDatos, 
    postOrganizacionAdapterMisDatos,
    organizacionesAdapter, 
    organizacionesAdapterSchema 
} from "../adapters/organizacion.adapter";

export const GetOrganizacionesJSON = () => {
    return useAxios2('get', '/organizaciones', 'json', {
        receiveAdapter: organizacionesAdapter
    });
};

export const getOrganizacionJSON = () => {
    return () => useAxios2('get', '/organizaciones', 'json', getOrganizacionAdapter, getOrganizacionAdapterSchema);
};

export const GetOrganizacionJSON = () => {
    return useAxios2('get', '/organizaciones/mis-datos/mostrar', 'json', {
        receiveAdapter: getOrganizacionAdapterMisDatos
    });
};

export const PostOrganizacionJSON = () => {
    return useAxios2('post', '/organizaciones/mis-datos/actualizar?_method=PATCH', 'json', {
        receiveSchemaAdapter: getOrganizacionAdapterSchemaMisDatos,
        sendAdapter: postOrganizacionAdapterMisDatos
    });
};
