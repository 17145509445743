import React, { useContext, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import { DAPconsContent } from './DAPconsContent/DAPconsContent';
import './DAPcons.css'
import { useLang } from '../../language/';
import { AuthContext } from '../../auth/AuthContext';
import { useAxios } from '../../hooks/useAxios';
import { isAdmin, isModuleAdministrador, isModuleConsultor, isModuleOrganizacion, isOrganizacion } from '../../helpers/helpers';
import { DAPconEmpresa } from './DAPcon/DAPconEmpresa/DAPconEmpresa';
import { DAPconConsultorVerificador } from './DAPcon/DAPconConsultorVerificador/DAPconConsultorVerificador';
import { DAPconAdmin } from './DAPcon/DAPconAdmin/DAPconAdmin';

export const DAPcons = () => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    // console.log('useContext', colegio, perfil)
    // const colegio = {id: 1, nombre: 'EBCN'} 
    // const perfil = {id: 'administrador', nombre: 'Administrador'}
    const Lang = useLang();

    const [vistaConsultorAdmin, setVistaConsultorAdmin] = useState('admin')


    return (
        <div className="container-inner row justify-content-between">

            <Route
                // exact
                path="/DAPcons/:id/:idioma_id"
                component={(props) => {

                    if (isModuleOrganizacion(modulo_app))
                        return <DAPconEmpresa 
                            {...props} 
                            namePage='DAPcon' 
                            perfil={perfil} 
                            nameProcess='' />

                    if (isModuleConsultor(modulo_app) || (isModuleAdministrador(modulo_app) && vistaConsultorAdmin === 'consultor'))
                        return <DAPconConsultorVerificador 
                            {...props} 
                            namePage='DAPcon' 
                            perfil={perfil} 
                            setVistaConsultorAdmin={setVistaConsultorAdmin}
                            nameProcess='' />

                    if (isModuleAdministrador(modulo_app) || (isAdmin(perfil) && vistaConsultorAdmin === 'admin'))
                        return <DAPconAdmin 
                            {...props} 
                            namePage='DAPcon' 
                            perfil={perfil} 
                            setVistaConsultorAdmin={setVistaConsultorAdmin}
                            nameProcess='' />

                }}
            />

            <Route
                exact
                path="/DAPcons/:id"
                component={() => <Redirect to="/DAPcons" />}
            />

            <Route
                exact
                path="/DAPcons"
                component={() => <DAPconsContent namePage='DAPcons' perfil={perfil} />}
            />

            <Route
                exact
                path="/verificar-DAPcons"
                component={() => <DAPconsContent namePage='DAPcons' perfil={perfil} estaVerificando={true} />}
            />


            {/* <Redirect to="/DAPcons" /> */}

        </div>
    )
}
