import { faCheckCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, ListGroup, Modal, Table } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { Table5 } from '../../../../components/DAPconTables/Table5';
import { Table7 } from '../../../../components/DAPconTables/Table7';
import { FileList } from '../../../../components/FileList/FileList';
import { InputFile, InputText } from '../../../../components/Inputs';
import { Loader } from '../../../../components/Loader/Loader';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';
import { ModalStatusError } from '../../../../components/ModalStatusServer/ModalStatusError';
import { getDataFile, isStatusSucess } from '../../../../helpers/helpers';
import { useAxios } from '../../../../hooks/useAxios';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';
import { TextInformationSaltoPagina } from '../../../../components/TextInformation/TextInformationSaltoPagina';

export const DAPconConsultorSeccion8 = ({ idDapcon, idiomaDAPcon, fetchCheckForms }) => {

    const [showDocs, setShowDocs] = useState({
        value: false,
        title: '',
        type: ''
    });

    const handleCloseDocs = () => setShowDocs({
        value: false,
        title: '',
        type: ''
    });
    const handleShowDocs = (title, type) => setShowDocs({
        value: true,
        title,
        type
    });

    const handleEliminarDocumento = (name) => {
        // handleFormChangeOrganizacion({
        //     ...formOrganizacion,
        //     [name]: '',
        // })
    }

    const [fetchInfoAdicional, fetchInfoAdicionalData, resetFetchInfoAdicional] = useAxios('get', `/dapcons/${idDapcon}/form/informacion-adicional`)

    useEffect(() => {

        fetchInfoAdicional({
            params: {
                idioma_id: idiomaDAPcon
            }
        })

    }, [])

    const [formInfoAdicional, handleInputChangeInfoAdicional, handleFormChangeInfoAdicional, handleResetInfoAdicional] = useForm({
        dv_informacion_adicional: '',
        form_errors: {},
        form_completed: {},
    })

    useEffect(() => {

        if (fetchInfoAdicionalData.data) {

            const formTemp1 = fetchInfoAdicionalData.data?.data?.data

            handleFormChangeInfoAdicional({
                ...formTemp1,
                dv_informacion_adicional: formTemp1.dv_informacion_adicional || '',
                form_errors: formTemp1.form_completed?.form_errors?.[0] || {}
            })

            setisEditForm(isEditf(formTemp1))

        }
    }, [fetchInfoAdicionalData])

    // Post data informacion adicional

    const [fetchPatchInfoAdicional, fetchPatchInfoAdicionalData] = useAxios('post', `/dapcons/${idDapcon}/form/informacion-adicional?_method=PATCH`)

    const handleSubmitInfoAdicional = () => {

        fetchPatchInfoAdicional({
            params: {
                dv_informacion_adicional: formInfoAdicional.dv_informacion_adicional,
                idioma_id: idiomaDAPcon
            }
        })
    }


    const [fetchDocumento, fetchDocumentoData, resetfetchDocumentoData] = useAxios('get', `/dapcons/${idDapcon}/form/informacion-adicional/obtener-documento`, '', 'blob')

    const handleFetchDocumento = (name, type) => {

        fetchDocumento({
            params: {
                token_doc: name,
                doc_type: type,
            }
        })
    }

    // Eliminar Documentos adjuntos olbigatorios

    const [fetchEliminarDoc, fetchEliminarDocData] = useAxios('delete', `/dapcons/${idDapcon}/form/informacion-adicional/eliminar-documento`)

    const handleFetchEliminarDoc = (token_doc) => {

        fetchEliminarDoc({
            params: {
                token_doc
            }
        })
    }

    const [activeClick, setActiveClick] = useState()

    // Subir archivo

    const [formDocInfoAdicional, handleInputChangeDocInfoAdicional, handleFormChangeDocInfoAdicional, handleResetDocInfoAdicional] = useForm({
        doc_descripcion: '',
    })

    const [valuesValidationDoc, handleValidationDoc, handleResetValidationDoc] = useValidator({
        doc_descripcion: { isOk: true, msgError: [`El nombre del documento es obligatorio.`] },
    })

    // Subir Documentos

    const [fetchSubirDoc, fetchSubirDocData] = useAxios('post', `/dapcons/${idDapcon}/form/informacion-adicional/documento?_method=PATCH`)

    const handleSubmitDoc = (e) => {

        e.preventDefault()

        let formData = new FormData();
        if (ref_doc.current?.files?.[0] && !handleValidationDoc(formDocInfoAdicional))
            return

        formData.append("doc_descripcion", formDocInfoAdicional.doc_descripcion)
        formData.append("doc_tipo", showDocs.type)
        formData.append("doc_file", ref_doc.current?.files?.[0])

        formData.append("idioma_id", idiomaDAPcon)

        fetchSubirDoc({
            body: formData
        })
    }

    const ref_doc = useRef(null)



    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {

        if (fetchSubirDocData?.data?.status === 201) {
            setShowSuccessEdit(true)
            handleCloseDocs()
            // handleResetOrganizacion()
            resetFetchInfoAdicional()

            fetchInfoAdicional({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchSubirDocData])

    useEffect(() => {

        if (fetchPatchInfoAdicionalData?.data?.status === 201) {
            setShowSuccessEdit(true)
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchPatchInfoAdicionalData])

    useEffect(() => {

        if (fetchEliminarDocData?.data?.status === 200) {
            setShowSuccessEdit(true)

            fetchInfoAdicional({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchEliminarDocData])

    useEffect(() => {

        if (fetchDocumentoData.data) {
            getDataFile(fetchDocumentoData, resetfetchDocumentoData)
        }
    }, [fetchDocumentoData])

    const isEditf = (form) => {

        const formTemp = _.cloneDeep(form)

        delete formTemp['doc_no_verificables']
        delete formTemp['doc_verificables']
        delete formTemp['form_errors']
        delete formTemp['form_completed']
        delete formTemp['dv_idioma_id']

        for (const key in formTemp) {
            if (formTemp[key]) {
                return true
            }
        }

        return false
    }

    const [isEditForm, setisEditForm] = useState(false)



    return (
        <>
            <div className='MainInner shadow-sm animate-width-disabled'>
                {
                    fetchInfoAdicionalData.loading ?
                        <div className="py-5">
                            <Loader />
                        </div> : <>

                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <h5>5. Información adicional</h5>
                                </div>
                            </div>

                            <Form className='row'>

                                <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput7">
                                    <InputText
                                        className=''
                                        as="textarea"
                                        rows={10}
                                        name='dv_informacion_adicional'
                                        onChange={handleInputChangeInfoAdicional}
                                        value={formInfoAdicional.dv_informacion_adicional}
                                    />
                                    <TextInformationSaltoPagina />

                                    {
                                        isEditForm && !formInfoAdicional.dv_informacion_adicional && formInfoAdicional.form_errors?.['dv_informacion_adicional'] &&
                                        <Form.Text className='text-danger d-block'>{formInfoAdicional.form_errors?.['dv_informacion_adicional']}</Form.Text>
                                    }
                                </Form.Group>
                                <div className='col-12 pt-3 pb-5 d-flex justify-content-end border-top'>
                                    <ButtonSpinner
                                        variant="info" type="submit" className="me-2"
                                        handleSubmit={handleSubmitInfoAdicional} fetchDataLoading={[fetchPatchInfoAdicionalData.loading]}
                                        value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                    />
                                </div>
                            </Form>

                        </>
                }
            </div>


            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatusError fetchData={fetchDocumentoData} />

        </>
    )
}
