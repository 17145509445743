import { faCheckCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { Table5 } from '../../../../components/DAPconTables/Table5';
import { Table7 } from '../../../../components/DAPconTables/Table7';
import { InputText } from '../../../../components/Inputs';
import { Loader } from '../../../../components/Loader/Loader';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';
import { useAxios } from '../../../../hooks/useAxios';
import { useForm } from '../../../../hooks/useForm';
import { TextInformationSaltoPagina } from '../../../../components/TextInformation/TextInformationSaltoPagina';

export const DAPconConsultorSeccion9 = ({ idDapcon, idiomaDAPcon, fetchCheckForms, bloqueado }) => {

    const [fetchRCPVer, fetchRCPVerData, resetFetchRCPVer] = useAxios('get', `/dapcons/${idDapcon}/form/rcp-verificacion`)

    useEffect(() => {

        fetchRCPVer({
            params: {
                idioma_id: idiomaDAPcon
            }
        })

    }, [])

    useEffect(() => {
        if (fetchRCPVerData.data) {

            console.log(fetchRCPVerData)

            const formTemp = fetchRCPVerData.data?.data?.data

            handleFormChangeRCPVer({
                ...formTemp,
                form_errors: formTemp.form_completed?.form_errors?.[0] || {},
                dapcons_verificacion_independiente_param_id: 'externa'
            })

            setisEditForm(isEditf(formTemp))

        }
    }, [fetchRCPVerData])

    const [formRCPVer, handleInputChangeRCPVer, handleFormChangeRCPVer, handleResetRCPVer] = useForm({
        dv_documento_base_declaracion: '',
        dv_referencias: '',
        dapcons_verificacion_independiente_param_id: '',
        text_verif_independiente_declaracion: '',
    })

    const [fetchPatchRCPVer, fetchPatchRCPVerData, resetFetchPatchRCPVer] = useAxios('patch', `/dapcons/${idDapcon}/form/rcp-verificacion?_method=PATCH`)

    const handleSubmitRCPVer = (e) => {

        e.preventDefault()

        fetchPatchRCPVer({
            body: {
                ...formRCPVer,
                idioma_id: idiomaDAPcon
            }
        })
    }

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {

        if (fetchPatchRCPVerData?.data?.status === 201) {
            setShowSuccessEdit(true)
            fetchCheckForms({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchPatchRCPVerData])



    const isEditf = (form) => {

        const formTemp = _.cloneDeep(form)

        console.log(formTemp)

        delete formTemp['form_completed']
        delete formTemp['form_errors']
        delete formTemp['dv_idioma_id']

        for (const key in formTemp) {
            if (formTemp[key]) {
                return true
            }
        }

        return false
    }

    const [isEditForm, setisEditForm] = useState(false)


    return (
        <>
            <div className='MainInner shadow-sm animate-width-disabled'>
                {
                    fetchRCPVerData.loading ?
                        <div className="py-5">
                            <Loader />
                        </div> : <>

                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <h5>6. RCP y verificación</h5>
                                </div>
                            </div>

                            <Form className='row'>

                                <Form.Group className="col-12 mb-4">
                                    <Form.Label className='fw-500'>Esta declaración se basa en el Documento</Form.Label>
                                    <p className='mt-0 mb-0'>{formRCPVer.dv_documento_base_declaracion}</p>
                                </Form.Group>

                                <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput7">
                                    <Form.Label>{formRCPVer.text_verif_independiente_declaracion}</Form.Label>
                                    <div className="d-flex">
                                        {/* <Form.Check
                                            type={'radio'}
                                            id={`default-${'radio'}`}
                                            label={`Interna`}
                                            name='id'
                                            onClick={() => {
                                                handleFormChangeRCPVer({
                                                    ...formRCPVer,
                                                    dapcons_verificacion_independiente_param_id: 'interna'
                                                })
                                            }}
                                            checked={formRCPVer.dapcons_verificacion_independiente_param_id === 'interna'}
                                            disabled={bloqueado}
                                        /> */}

                                        <Form.Check
                                            type={'radio'}
                                            id={`default-${'radio'}2`}
                                            label={`Externa`}
                                            name='id'
                                            className=''
                                            onClick={() => {
                                                handleFormChangeRCPVer({
                                                    ...formRCPVer,
                                                    dapcons_verificacion_independiente_param_id: 'externa'
                                                })
                                            }}
                                            checked={formRCPVer.dapcons_verificacion_independiente_param_id === 'externa'}
                                            disabled
                                        />
                                    </div>
                                    {
                                        isEditForm && !formRCPVer.dapcons_verificacion_independiente_param_id && formRCPVer.form_errors?.['dapcons_verificacion_independiente_param_id'] &&
                                        <Form.Text className='text-danger d-block'>{formRCPVer.form_errors?.['dapcons_verificacion_independiente_param_id']}</Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group className="col-12 mb-4">
                                    <InputText
                                        label='Referencias'
                                        className=''
                                        name='dv_referencias'
                                        value={formRCPVer.dv_referencias}
                                        onChange={handleInputChangeRCPVer}
                                        as="textarea"
                                        rows={5}
                                    // validation={valuesValidationInforme}
                                    // fetchData={fetchSendOrganizacionData}
                                    />
                                    <TextInformationSaltoPagina />

                                    {
                                        isEditForm && !formRCPVer.dv_referencias && formRCPVer.form_errors?.['dv_referencias'] &&
                                        <Form.Text className='text-danger d-block'>{formRCPVer.form_errors?.['dv_referencias']}</Form.Text>
                                    }
                                </Form.Group>

                            </Form>
                            <div className='mt-4 pt-3 d-flex justify-content-end border-top'>
                                <ButtonSpinner
                                    variant="info" type="submit" className="me-2"
                                    handleSubmit={handleSubmitRCPVer} fetchDataLoading={[fetchPatchRCPVerData.loading]}
                                    value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                />
                            </div>
                        </>
                }
            </div>
            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>
        </>
    )
}
