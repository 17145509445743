import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useEffect} from 'react'
import {DeleteArchivoJson } from '../../../../../services/contenidoarchivo.service'
import { ButtonSpinner } from '../../../../../components/ButtonSpinner/ButtonSpinner';

export const BtnDelete = ({data, reloadTable}) => {
	const [fetchDeleteArchivo, fetchDeleteArchivoData] = DeleteArchivoJson()
	const handleDeleteArchivo = () => {
        if(window.confirm("Realmente desea borrar este archivo?")){
             fetchDeleteArchivo({
                id: data,
            })
        }
    }

    useEffect(() => {
        if (fetchDeleteArchivoData?.status === 200) {
            reloadTable()
        }
    }, [fetchDeleteArchivoData])

	return (
        <>
        	 <ButtonSpinner
                variant="danger" type="submit" size="sm" 
                handleSubmit={handleDeleteArchivo} fetchDataLoading={[fetchDeleteArchivoData.loading]}
                icon={<FontAwesomeIcon icon={faTrash} className='me-1' />}
            />
        </>
     )    
}