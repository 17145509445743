import React, { useEffect } from 'react'
import { Button, Modal, Table, InputGroup, FormControl } from 'react-bootstrap'
import { useAxios } from '../../../hooks/useAxios'
import { useForm } from '../../../hooks/useForm'
import { PaginationT } from '../../../components/PaginationT/PaginationT';
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loader } from '../../../components/Loader/Loader'

export const DocumentosResaltadosModal = ({ data, handleCloseModal}) => {
    const [fetchDocumentos, fetchDocumentosData] = useAxios('get', '/biblioteca-documentos-resaltados')


    const [formValuesSearch, handleInputChangeSearch, handleFormChange, handleFormChangeReset] = useForm({
        magic: '',
        page: 1,
    });

     const handleSearch = (event) => {
        event.preventDefault();
        fetchDocumentos({
            params: {
                ...formValuesSearch,
                page: 1,
            }
        });
    };

    useEffect(() => {
        if(data?.action){
            fetchDocumentos({ 
                params: {
                ...formValuesSearch,
            }});
        }
    },[data.value, formValuesSearch.page, formValuesSearch.orderby])
    return (
        <Modal show={data.value} onHide={handleCloseModal} size='lg' backdrop="static" keyboard={false}>
             <Modal.Header closeButton className='border-0'>
                <Modal.Title className='h5'>Lista de Documentos</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                    <div className="row d-flex justify-content-end"> 
                        <div className="col-12 col-md-5 mb-4">
                            <form onSubmit={handleSearch}>
                                <InputGroup>
                                  <FormControl
                                    placeholder="Buscar título, descripción"
                                    aria-label="Buscar"
                                    aria-describedby="basic-addon2"
                                    name="magic"
                                    value={formValuesSearch.magic} 
                                    onChange={handleInputChangeSearch}
                                  />
                                  <Button variant="primary" type="submit">Buscar</Button>
                                </InputGroup>
                              </form>
                          </div>  
                  </div> 
                {fetchDocumentosData.loading ?
                    <div className="py-5">
                        <Loader />
                    </div> : 
                    <>
                    {
                        fetchDocumentosData?.data?.data?.data?.total ?
                        <> 
                            <Table hover bordered responsive className='tableMain table-sm table-striped'>
                                <thead>
                                    <tr className="text-center">
                                        <th className='text-nowrap'>Título</th>
                                        <th className='text-nowrap'>Descripción</th>
                                        <th className='text-center'>Documento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {fetchDocumentosData?.data?.data?.data?.data?.map((documento, index) => (
                                     <tr key={index} className="text-center">
                                        <td valign='middle'>{documento.titulo}</td>
                                        <td valign='middle'>{documento.descripcion}</td>
                                        <td valign='middle'>
                                            {documento.tipo === 'documento' &&
                                                <Button href={documento.ruta} target="_blank" download variant="success" size='sm' className='ms-3 my-0'>
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </Button>
                                            }

                                            {documento.tipo === 'enlace' &&
                                                <Button href={documento.link} target="_blank" variant="success" size='sm' className='ms-3 my-0'>
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </Button>
                                            }
                                        </td>
                                     </tr>      
                                 ))
                                }
                                 </tbody>
                               </Table>        

                               <PaginationT
                                    total={fetchDocumentosData?.data?.data?.data?.total}
                                    handleFormChange={handleFormChange}
                                    formValuesSearch={formValuesSearch}
                                    perPage={fetchDocumentosData?.data?.data?.data?.per_page}
                                />
                            </>
                            :
                            <h5 className='my-0 py-5 text-center'>No se ha encontrado resultados</h5>
                          }  
                       </>  
                }              

            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button variant="default" onClick={() => {
                    handleCloseModal()
                }}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
