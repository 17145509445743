// ----------------------- DAPcon ------------------------

export const getDAPconAnalisisCicloVidaAdapterSchema = (item = {}) => {

    const data = {
        dv_analisis_ciclo_vida: item.dv_analisis_ciclo_vida || '',
        dv_explicacion_medioambiental_adicional: item.dv_explicacion_medioambiental_adicional || '',
        idioma_id: item.dv_idioma_id || 1,
        dv_otros_datos: item.dv_otros_datos || '',
        dv_recomendaciones: item.dv_recomendaciones || '',
        dv_reglas_corte: item.dv_reglas_corte || '',
        dv_unidad_comentarios: item.dv_unidad_comentarios || '',
        dapcons_unidad_param_id: item.dapcons_unidad_param_id || '',
        propiedad_tecnica_id: item.propiedad_tecnica_id || '',
        propiedad_tecnica_cantidad: item.propiedad_tecnica_cantidad || '',
        dv_unidad_texto: item.dv_unidad_texto || '',
        dv_rsl: item.dv_rsl || '',
        table5: item.table5 || [],
        table6: item.table6 || [],
        table7: item.table7 || [],
        form_completed: item.form_completed || {},
        form_errors: {},
        a1a3: false
    }

    return {
        ...data
    }
}

export const getDAPconAnalisisCicloVidaAdapter = (data) => {

    const dataTemp = data?.data?.data
    
    const item = getDAPconAnalisisCicloVidaAdapterSchema(dataTemp)

    return {
        data: item,
    }
}

// ----------------------- DAPcon ------------------------

export const getDAPconTable5AdapterSchema = (item = {}, impactoAmbiental) => {

    const data = {
        a1: item.a1 || '',
        a2: item.a2 || '',
        a3: item.a3 || '',
        a1a3: item.a1a3 || '',
        a4: item.a4 || '',
        a5: item.a5 || '',
        b1: item.b1 || '',
        b2: item.b2 || '',
        b3: item.b3 || '',
        b4: item.b4 || '',
        b5: item.b5 || '',
        b6: item.b6 || '',
        b7: item.b7 || '',
        c1: item.c1 || '',
        c2: item.c2 || '',
        c3: item.c3 || '',
        d: item.d || '',
    }

    const data2 = Object.keys(impactoAmbiental).map( key => {
        return {
            [key]: {...data}
        }
    })

    return {
        ...data2
    }
}