
export const getSeguimientosAdapterSchema = (item = {}) => {

    const data = {
        dapcons_seguimiento_tipo: item.dapcons_seguimiento_tipo || '',
        dapcons_seguimiento_descripcion: item.dapcons_seguimiento_descripcion || '',
        estado: item.dapcons_estado || '',
        fecha_creacion: item.fecha_creacion || '',
        nombre: item.usuario_nombre || '',
        apellidos: item.usuario_apellidos || '',
        dapcons_id: item.dapcons_id || '',
        idioma_id: item.idioma_id || '',
    }

    return {
        ...data
    }
}

export const getSeguimientosAdapter = (data) => {

    const dataTemp = data?.data?.data

    const items = dataTemp?.data?.map(item => (getSeguimientosAdapterSchema(item)))

    return {
        data: {
            total: dataTemp?.total,
            per_page: dataTemp?.per_page,
            items
        }
    }
}