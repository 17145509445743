import React, { useEffect, useState } from 'react'
import { NavStartInner } from '../NavStartInner/NavStartInner'
import { NavtopInner } from '../NavtopInner/NavtopInner'
import { useLocation } from 'react-router-dom';

export const LayoutNavs = () => {

    const prueba = localStorage.getItem( process.env.REACT_APP_MAIN_VARIABLE + '_ACTIVE_MENU' )
    console.log('logalstorage close: ', prueba)
    
    const [close, setClose] = useState(false)
    
    useEffect(() => {
        console.log('close: ', close)
        localStorage.setItem( process.env.REACT_APP_MAIN_VARIABLE + '_ACTIVE_MENU', close );
        console.log('close: ', close)

    }, [close])

    const location = useLocation();
    useEffect(() => {
        setClose(false);
    }, [location.pathname]);

    return (
        <>
            <NavtopInner setClose={setClose} close={close}/>
            <NavStartInner setClose={setClose} close={close}/>
            {close && <div className="sidebar-overlay" onClick={() => setClose(!close)}></div>}
        </>
    )
}
