import { useAxiosFake } from "../hooks/useAxiosFake";
import { useAxios2 } from "../hooks/useAxios2";
import {documentosAdapter, documentosAdapterSchema, postDocumentoAdapterSchema, getDocumentoAdapter, postDocumentoMultipar, patchDocumentoAdapterSchema, patchDocumentoMultipar} from '../adapters/documento.adapter'

export const GetDocumentosJSON = () => {
    return useAxios2('get', '/biblioteca-documentos', 'json', {
        receiveAdapter: documentosAdapter
    });
};

export const PostDocumentoJSON = () => {
    return useAxios2('post', '/biblioteca-documentos', 'json', {
        receiveSchemaAdapter: documentosAdapterSchema,
        sendAdapter: postDocumentoMultipar
    });
};

export const GetDocumentoJson = (id) => {
    return useAxios2('get', `/biblioteca-documentos`, 'json', {
        receiveAdapter: getDocumentoAdapter
    });
};

export const PatchDocumentoJson = () => {
    return useAxios2('post', `/biblioteca-documentos`, 'json', {
        receiveAdapter: getDocumentoAdapter,
        sendAdapter: patchDocumentoMultipar
    });
};

export const DeleteDocumentoJson = () => {
    return useAxios2('delete', `/biblioteca-documentos`, 'json', {
        receiveAdapter: getDocumentoAdapter
    });
};
