import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export const ModalStatus = ({setShowStatus, showStatus, title, children}) => {

    // Modal de success

    // const [showSuccess, setShowSuccess] = useState(true);

    const handleCloseSuccess = () => {
        setShowStatus(false)
    };

    // const handleShowSuccess = () => setShowSuccess(true);

    return (
        <div>
            <Modal show={showStatus} onHide={handleCloseSuccess}>
                <Modal.Header closeButton className='border-0 pt-4'>
                    <Modal.Title className='h5'>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { children }
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={handleCloseSuccess}>
                        Salir
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}