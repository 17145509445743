import React, { useEffect, useState } from 'react'
import { faArrowLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { useAxios } from '../../../hooks/useAxios';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useLang } from '../../../language';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import { InputText } from '../../../components/Inputs';

export const ModalInfoPagador = ({ data, handleCloseModal, handleChangePagador, handleChangeDocumento, 
    handleOpenModalRegistroPagador, documento, fetchTarifa, setPagador,  dapconsId }) => {
    const locale = useLang();
    const [fetchCambiarPagador, fetchCambiarPagadorData] = useAxios('POST', `/facturacion/cambiar_pagador`)
    const handleCambiarPagador = () => {
        fetchCambiarPagador({
            body: {
                documento: documento,
            }
        })
    }
    useEffect(() => {
        if (fetchCambiarPagadorData.data?.status === 200) {
             const data = fetchCambiarPagadorData.data?.data?.data;
             if(data?.facturacion_id){
                 handleCloseModal()
                 fetchTarifa({
                    params: {
                        facturacion_id: data?.facturacion_id,
                        dapcons_id: dapconsId
                    }
                 });
                setPagador(data?.facturacion_id); 
                return false;
             }
             handleCloseModal()
             handleOpenModalRegistroPagador({
                action: 'CREATE'
            }); 
        }
    }, [fetchCambiarPagadorData])

    return (
        <>
            <Modal show={data.value} onHide={handleCloseModal} size='sm' backdrop="static" keyboard={false}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'>Cambiar pagador</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-4'>
                    <div className="container">
                        <div className="row justify-content-center flex-column align-items-center">
                            <Form.Group className="col-12 mb-4">
                                <Form.Label>Consultar CIF / NIF *</Form.Label>
                                <InputText
                                    className=''
                                    name='documento'
                                    onChange={handleChangeDocumento}
                                    fetchData={fetchCambiarPagadorData} 
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={() => {
                        handleCloseModal()
                    }}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleCambiarPagador} fetchDataLoading={[fetchCambiarPagadorData.loading]}
                        value='Cambiar pagador' icon={<FontAwesomeIcon icon={faSearch} />}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
