import React from "react";
import { useAxios } from "../../../hooks/useAxios";
import { useEffect } from "react";
import { isStatusSucess } from "../../../helpers/helpers";
import { Button, Form, Table, Accordion  } from "react-bootstrap";
import { Loader } from "../../../components/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faTrash, faFilter, faSignal } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ModalPagoCuota } from "./ModalPagoCuota";
import { useForm } from "../../../hooks/useForm";
import { ModalStatusServer2 } from "../../../components/ModalStatusServer/ModalStatusServer2";
import { ButtonSpinner } from "../../../components/ButtonSpinner/ButtonSpinner";
import { PaginationT } from "../../../components/PaginationT/PaginationT";
import { ModalPagoDAPcons } from "./ModalPagoDAPcons";
import './PagosDAPcons.css'
import { imagesFlags } from "../../../helpers/imagesFlags";
import { images } from "../../../helpers/images";
import { useHistory } from 'react-router-dom';
import { useModalData } from '../../../hooks/useModalData';
import {ModalFilter} from './ModalFilter'
import {ModalResumen} from './ModalResumen'
import { GetParametroJSON } from "../../../services/parametro.service";
import {ResumenDapcons} from '../../../components/ResumenDapcons/ResumenDpacons'

export const PagosDAPcons = ({ id, fetchOrganizacionData }) => {

    const [fetchDAPconsOrg, fetchDAPconsOrgData, resetFetchDAPconsOrg] = useAxios('get', `/dapcons`)
    const [fetchResumen, fetchResumenData] = useAxios('get', `/dapcons/resumen`)

    const [dataModal_Filter, handleOpenModal_Filter, handleCloseModal_Filter] = useModalData()
    const [dataModal_Resumen, handleOpenModal_Resumen, handleCloseModal_Resumen] = useModalData()

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        page: 1,
        organizacion_id: '',
        pendiente_pago: ''
    });

    useEffect(() => {

        if (formValuesSearch.organizacion_id) {

            fetchDAPconsOrg({
                params: {
                    ...formValuesSearch,
                    organizacion_id: formValuesSearch.organizacion_id,
                    page: formValuesSearch.page,
                }
            })
        }

    }, [formValuesSearch.page, formValuesSearch.organizacion_id])

    useEffect(() => {

        if (isStatusSucess(fetchOrganizacionData.status)) {
            handleFormChange({
                ...formValuesSearch,
                organizacion_id: fetchOrganizacionData.data?.id,
            })

            fetchResumen({
                id: fetchOrganizacionData.data?.id
            })
        }
    }, [fetchOrganizacionData]);

    const [showModalPagoDAPcons, setShowModalPagoDAPcons] = useState({});

    const handleCloseModalPagoDAPcons = () => setShowModalPagoDAPcons({
        value: false,
        payload: {}
    })
    const handleShowModalPagoDAPcons = (payload = {}) => setShowModalPagoDAPcons({
        value: true,
        payload
    });


    // ------------ DELETE pago cuota

    const [idDapcons, setidDapcons] = useState('');


    // const [fetchPagoDELETE, fetchPagoDELETEData] = useAxios('DELETE', '/cuotas/borrado-pago')
    const [fetchPagarDapcons, fetchPagarDapconsData, resetFetchPagarDapcons] = useAxios('patch', `/dapcons/registro-pagos`)

    const handleSubmitDeletePago = (item) => {
        fetchPagarDapcons({
            body: {
                dapcons_id: item.id,
                pagado: ''
            }
        })
        setidDapcons(item.id)
    }

    useEffect(() => {

        if (isStatusSucess(fetchPagarDapconsData?.data?.status)) {
            fetchDAPconsOrg({
                params: {
                    ...formValuesSearch,
                    organizacion_id: formValuesSearch.organizacion_id,
                    page: formValuesSearch.page,
                }
            })
        }

    }, [fetchPagarDapconsData])

    const history = useHistory();

    // Parametro ID

    const [fetchEstadosDAPcons, fetchEstadosDAPconsData] = GetParametroJSON('estados_dapcons')

    useEffect(() => {

        fetchEstadosDAPcons();

    }, [])

    // Preparando select de Estados

    // const [listEstados, setListEstados] = useState([])

    // useEffect(() => {

    //     if (fetchEstadosDAPconsData.data) {
    //         const estados_dapcons = fetchEstadosDAPconsData?.data
    //         console.log(estados_dapcons)

    //         const listTemp = estados_dapcons.map(item => {

    //             if (estaVerificando && item.id === 'borrador') {
    //                 return null
    //             }
    //             return item
    //         })
    //         setListEstados(listTemp.filter(item => item))
    //     }

    // }, [fetchEstadosDAPconsData])

    const handleSearch = () => {
        handleCloseModal_Filter();

        fetchResumen({
            id: fetchOrganizacionData.data?.id,
            params:{
                pendiente_pago: formValuesSearch.pendiente_pago
            }
        })

        fetchDAPconsOrg({
            params: {
                ...formValuesSearch,
                organizacion_id: formValuesSearch.organizacion_id,
                page: 1,
            }
        })
    }

    const [pieData, setPieData] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalRenovados, setTotalRenovados] = useState(0);
    const [totalPublicados, setTotalPublicados] = useState(0);
    useEffect(() => {
        if(fetchResumenData?.data?.data?.data){
            const data = [
                  { label: 'Español', value: fetchResumenData?.data?.data?.data?.total_es},
                  { label: 'Inglés', value: fetchResumenData?.data?.data?.data?.total_en},
             ];
             setPieData(data);
             setTotal(fetchResumenData?.data?.data?.data?.total);
             setTotalRenovados(fetchResumenData?.data?.data?.data?.renovado);
             setTotalPublicados(fetchResumenData?.data?.data?.data?.publicados);
        }
    },[fetchResumenData.data])


    return (
        <>
            <div className="MainInner shadow-sm animate__animated animate__fadeInUp">

                <div className="d-flex justify-content-between mb-3">
                    <div>
                      <h5 className='mt-0'>Pagos DAPcons</h5>
                    </div>
                     <div className='d-flex justify-content-end ms-auto'>
                        <Button variant="outline-primary" className="px-3 ms-3 d-flex align-items-center" onClick={
                            () => handleOpenModal_Filter({
                                show: 'FILTRO',
                            })
                            }
                        >
                            <FontAwesomeIcon icon={faFilter}/>
                        </Button>
                        
                        <Button disabled={pieData.length == 0 ? true : false} variant="outline-dark" className="px-3 ms-3 d-flex align-items-center" onClick={
                            () => handleOpenModal_Resumen({
                                show: 'Resumen',
                            })
                            }
                        >
                            <FontAwesomeIcon icon={faSignal}/>
                        </Button>
                

                    </div> 
                </div>
                {
                    fetchOrganizacionData?.loading || fetchResumenData.loading ?
                        <div className="py-5 d-flex justify-content-center">
                            <Loader />
                        </div>
                        :
                        <>  
                            {/*
                            <Accordion defaultActiveKey={null} className="mb-3">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>Resumen de DAPcons</Accordion.Header>
                                <Accordion.Body>
                                  <Table striped className="tableMain">
                                      <tbody>
                                        <tr>
                                            <td style={{width: '30%'}}><strong>Dapcons total</strong></td>
                                            <td>{fetchResumenData?.data?.data?.data?.total || 0} ( {fetchResumenData?.data?.data?.data?.total - fetchResumenData?.data?.data?.data?.renovado} altas y {fetchResumenData?.data?.data?.data?.renovado} renovaciones {fetchResumenData?.data?.data?.data?.caducados || 0} caducadas) </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Dapcons publicados</strong></td>
                                            <td>{fetchResumenData?.data?.data?.data?.publicados || 0} ( Español: {fetchResumenData?.data?.data?.data?.total_es || 0} Ingles: {fetchResumenData?.data?.data?.data?.total_en || 0} )</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Dapcons en proceso</strong></td>
                                            <td>{fetchResumenData?.data?.data?.data?.proceso || 0}</td>
                                        </tr>
                                      </tbody>
                                  </Table>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            */}

                            <ResumenDapcons
                                fetchResumenData={fetchResumenData}
                            />

                            <Table hover bordered responsive className='tableMain TableStripeRowSpan'>
                                <thead>
                                    <tr>
                                        <th className='text-nowrap'>Codigo DAPcons</th>
                                        <th className='text-center'>Fecha creación</th>
                                        <th className='text-center'>Idioma</th>
                                        <th className='text-nowrap'>Estado</th>

                                        <th className='text-nowrap text-center'>
                                            <img src={images('./ECO_Logo.png')} className="" alt="logo" width='40px' />

                                        </th>
                                        <th className='text-nowrap text-center'>Importe</th>
                                        <th className='text-nowrap text-center'>Fecha de pago</th>
                                        <th className='text-nowrap text-center'>Secuencia</th>
                                        <th className='text-nowrap'></th>
                                        {/* <th className="shrink-td"></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fetchDAPconsOrgData.loading ?
                                            <tr>
                                                <td className='text-center' colSpan={11}>
                                                    <Loader />
                                                </td>
                                            </tr>
                                            :
                                            fetchDAPconsOrgData.data?.data?.data?.data?.length > 0 ? <>
                                                {
                                                    fetchDAPconsOrgData.data?.data?.data?.data?.map((item, index) => {
                                                        return (
                                                            <>
                                                                {
                                                                    item.dapcons_valores?.map((item2, index2) => {
                                                                        return (
                                                                            <tr className={index % 2 === 0 ? 'tr_par' : ''}>
                                                                                {
                                                                                    index2 === 0 &&
                                                                                    <td rowspan={item.dapcons_valores?.length}>
                                                                                        <span className="btn-link" onClick={() => history.push({
                                                                                            pathname: `/DAPcons/${item.id}/${item['idiomas'][index2]['idioma_id']}`
                                                                                        })}>
                                                                                            {item.dapcons_codigo ? item.dapcons_codigo : `DAPcons ${item.id}`}

                                                                                        </span>
                                                                                        {
                                                                                            item.id_padre &&
                                                                                            <>
                                                                                             <br/><span className="mt-2 text-success-dapcons">Renovado a partir de N° {item.id_padre}</span>
                                                                                             </>
                                                                                        }
                                                                                    </td>
                                                                                }

                                                                                {
                                                                                    index2 === 0 &&
                                                                                    <td rowspan={item.dapcons_valores?.length} className="text-center">
                                                                                        {item.dapcons_fecha_creacion || ''}
                                                                                    </td>
                                                                                }

                                                                                <td valign='middle' className='text-nowrap text-center icon-flag'>
                                                                                    <img src={imagesFlags(`./${item['idiomas'][index2]['idioma_id']}.png`)} alt="" />
                                                                                </td>
                                                                                <td>
                                                                                    {fetchEstadosDAPconsData.data?.find(item2 => item2.id === item['idiomas']?.[index2]?.dapcons_idioma_estado)?.nombre}
                                                                                </td>
                                                                                {/* <td valign='middle' className='text-nowrap text-center text-success'>
                                                                                    {item2['dv_fecha_creacion'] ? (new Date(item2['dv_fecha_creacion'])?.toLocaleDateString()) : ''}
                                                                                </td> */}
                                                                                <td valign='middle' className='text-nowrap text-center text-success'>
                                                                                    {item['idiomas'][index2]['inscripcion_ecoplatform'] ? 'Si' : ''}
                                                                                </td>

                                                                                <td valign='middle' className='text-nowrap text-center text-success'>
                                                                                    {item.pago?.importe ? '€ '+ item.pago?.importe : ''}
                                                                                </td>

                                                                                {
                                                                                    index2 === 0 &&
                                                                                    <td rowspan={item.dapcons_valores?.length} valign="middle" className="text-center">
                                                                                        {item.pagado ? (new Date(item.pagado)?.toLocaleDateString()) : ''}
                                                                                    </td>
                                                                                }

                                                                                <td valign='middle' className='text-nowrap text-center text-success'>
                                                                                    {item.secuencia}
                                                                                </td>

                                                                                {
                                                                                    index2 === 0 &&
                                                                                    <td className="shrink-td" rowspan={item.dapcons_valores?.length} valign="middle">
                                                                                        {item.pagado ?
                                                                                            <>
                                                                                                <Button variant="warning" size="sm" className=''
                                                                                                    onClick={() => handleShowModalPagoDAPcons({
                                                                                                        action: 'CREATE',
                                                                                                        item: {
                                                                                                            ...item,
                                                                                                            dapcons_id: item.id
                                                                                                        },
                                                                                                    })}
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faMoneyBill} className="me-2" /> Actualizar pago
                                                                                                </Button>
                                                                                            </> :
                                                                                            <>
                                                                                                <Button variant="primary" size="sm" className=''
                                                                                                    onClick={() => handleShowModalPagoDAPcons({
                                                                                                        action: 'CREATE',
                                                                                                        item: {
                                                                                                            ...item,
                                                                                                            dapcons_id: item.id
                                                                                                        },
                                                                                                    })}
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faMoneyBill} className="me-2" /> Registrar pago
                                                                                                </Button>
                                                                                            </>
                                                                                        }
                                                                                    </td>
                                                                                }

                                                                            </tr>
                                                                        )
                                                                    })
                                                                }

                                                            </>

                                                        )
                                                    })
                                                }
                                            </> : <tr>
                                                <td className='py-5 text-center' colSpan={11}>
                                                    <h5>No hay datos a mostrar</h5>
                                                </td>
                                            </tr>
                                    }


                                </tbody>
                            </Table>
                            <PaginationT
                                total={fetchDAPconsOrgData?.data?.data?.data?.total}
                                handleFormChange={handleFormChange}
                                formValuesSearch={formValuesSearch}
                                perPage={fetchDAPconsOrgData?.data?.data?.data?.per_page}
                            />

                        </>
                }

            </div>

            {/* <ModalPagoCuota
                showModalPagoCuota={showModalPagoCuota}
                handleCloseModalPagoCuota={handleCloseModalPagoCuota}
                fetchSuccess={() => fetchCuotasPendientes({
                    id: fetchOrganizacionData.data?.id + '/cuotas-pendientes'
                })}
            /> */}


            <ModalPagoDAPcons
                showModalPagoDAPcons={showModalPagoDAPcons}
                handleCloseModalPagoDAPcons={handleCloseModalPagoDAPcons}
                fetchSuccess={() => fetchDAPconsOrg({
                    params: {
                        organizacion_id: formValuesSearch.organizacion_id,
                        page: formValuesSearch.page,
                    }
                })}
            // formValuesSearch={formValuesSearch}
            />

             <ModalFilter
                data={dataModal_Filter}
                handleCloseModal={handleCloseModal_Filter}
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChangeSearch={handleInputChangeSearch}
                handleSearch={handleSearch}
            />

            <ModalResumen
                data={dataModal_Resumen}
                handleCloseModal={handleCloseModal_Resumen}
                dataChart={pieData}
                totalChart={total}
                totalRenovado={totalRenovados}
                totalPublicado={totalPublicados}
            />

        </>
    );
};
