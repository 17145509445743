import { useAxiosFake } from "../hooks/useAxiosFake";
import { useAxios2 } from "../hooks/useAxios2";
import {contenidosAdapter, contenidosAdapterSchema, postContenidoAdapterSchema, getContenidoAdapter, postContenidoMultipar, patchContenidoAdapterSchema, patchContenidoMultipar} from '../adapters/contenido.adapter'

export const GetContenidosJSON = () => {
    return useAxios2('get', 'contenidos-web', 'json', {
        receiveAdapter: contenidosAdapter
    });
};

export const PostContenidoJSON = () => {
    return useAxios2('post', '/contenidos-web', 'json', {
        receiveSchemaAdapter: contenidosAdapterSchema,
        sendAdapter: postContenidoMultipar
    });
};

export const GetContenidoJson = (id) => {
    return useAxios2('get', `/contenidos-web`, 'json', {
        receiveAdapter: getContenidoAdapter
    });
};

export const PatchContenidoJson = () => {
    return useAxios2('post', `/contenidos-web`, 'json', {
        receiveAdapter: getContenidoAdapter,
        sendAdapter: patchContenidoMultipar
    });
};

export const DeleteContenidoJson = () => {
    return useAxios2('delete', `/contenidos-web`, 'json', {
        receiveAdapter: getContenidoAdapter
    });
};
