import { faCheckCircle, faFile, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Breadcrumb, Form, ListGroup, Tab, Tabs } from 'react-bootstrap'
import { getAdminAdapterSchemaMisDatos } from '../../adapters/admin.adapter'
import { AuthContext } from '../../auth/AuthContext'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner'
import { InputFile, InputSelect, InputText } from '../../components/Inputs'
import InputImage from '../../components/Inputs/InputImage'
import { Loader } from '../../components/Loader/Loader'
import { ModalCropImage } from '../../components/ModalCropImage/ModalCropImage'
import { ModalStatus } from '../../components/ModalStatusServer/ModalStatus'
import { MsgInput } from '../../components/MsgInput/MsgInput'
import { MsgInputServer } from '../../components/MsgInput/MsgInputServer'
import { isAdmin, isModuleOrganizacion, isVerificador } from '../../helpers/helpers'
import { useAxios } from '../../hooks/useAxios'
import { useForm } from '../../hooks/useForm'
import { useValidator } from '../../hooks/useValidator'
import { GetAdminJSON, PostAdminJSON } from '../../services/admin.service'

export const MisDatosAdmin = () => {

    const { user } = useContext(AuthContext);

    const [fetchAdmin, fetchAdminData] = GetAdminJSON()

    useEffect(() => {

        fetchAdmin()
    }, [])

    useEffect(() => {

        console.log(fetchAdminData)

        if (fetchAdminData.status === 200) {

            handleFormChangeRegisterExterno({
                ...fetchAdminData.data
            })
        }

    }, [fetchAdminData])


    const [fetchRegistroAdmin, fetchRegistroAdminData] = PostAdminJSON()

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {

        console.log(fetchRegistroAdminData)

        if (fetchRegistroAdminData.status === 201) {
            // handleCloseModalRegistroAdmin()
            setShowSuccessEdit(true)
            // setShowStatusVerify2(true)
        }

    }, [fetchRegistroAdminData])

    const [
        formRegisterExterno, 
        handleInputChangeRegisterExterno, 
        handleFormChangeRegisterExterno, 
        handleResetRegisterExterno
    ] = useForm(getAdminAdapterSchemaMisDatos())

    const [valuesValidRegistroAdmin, handleVerifiedValuesRegisterExterno, handleResetValuesRegisterExterno] = useValidator({
        nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        apellidos: { isOk: true, msgError: [`Los apellidos son obligatorios.`] },
        telefono: { isOk: true, msgError: [`El telefono es obligatorio.`] },
        email: { isOk: true, msgError: [`El correo es obligatorio.`] },
    })

    const handleSubmitFormRegisterExterno = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesRegisterExterno(formRegisterExterno)) {
            fetchRegistroAdmin({
                body: formRegisterExterno
            })
        }
    }


    useEffect(() => {
        console.log(formRegisterExterno)

    }, [formRegisterExterno])

    return (
        <>

            <div className="container-inner row justify-content-between">
                <Breadcrumb className="Breadcrumb-inner">
                    <Breadcrumb.Item href="#">
                        <FontAwesomeIcon icon={faHome} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Mis datos
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="d-flex justify-content-center">
                    <div className="col-12 col-xl-9">
                        <div className="MainInner shadow-sm animate__animated animate__fadeInUp">

                            {
                                fetchAdminData.loading ?
                                    <div className="py-5">
                                        <Loader />
                                    </div> : <>
                                        <div className="row pt-3 pb-4 px-3">
                                            <div className="col-12">
                                                <h5 className='mb-4'>Datos personales</h5>
                                            </div>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-3">
                                                <Form.Label className='fw-500'>NIF:</Form.Label>
                                                <p className='mt-1 mb-0'>{formRegisterExterno.usuario}</p>
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-4">
                                                <InputText
                                                    label='Nombre *'
                                                    className=''
                                                    name='nombre'
                                                    value={formRegisterExterno.nombre}
                                                    onChange={handleInputChangeRegisterExterno}
                                                    validation={valuesValidRegistroAdmin}
                                                    fetchData={fetchRegistroAdminData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-5">
                                                <InputText
                                                    label='Apellidos *'
                                                    className=''
                                                    name='apellidos'
                                                    value={formRegisterExterno.apellidos}
                                                    onChange={handleInputChangeRegisterExterno}
                                                    validation={valuesValidRegistroAdmin}
                                                    fetchData={fetchRegistroAdminData}
                                                />
                                            </Form.Group>

                                            <div className="col-12">
                                                <h5 className='mb-3 mt-2'>Datos de contacto</h5>
                                            </div>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                                <InputText
                                                    label='Teléfono *'
                                                    className=''
                                                    name='telefono'
                                                    value={formRegisterExterno.telefono}
                                                    onChange={handleInputChangeRegisterExterno}
                                                    validation={valuesValidRegistroAdmin}
                                                    fetchData={fetchRegistroAdminData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-6">
                                                <InputText
                                                    label='Dirección electrónica *'
                                                    className=''
                                                    name='email'
                                                    value={formRegisterExterno.email}
                                                    onChange={handleInputChangeRegisterExterno}
                                                    validation={valuesValidRegistroAdmin}
                                                    fetchData={fetchRegistroAdminData}
                                                />
                                            </Form.Group>

                                            <div className="col-12 mt-2 text-center">
                                                <MsgInputServer fetchData={fetchRegistroAdminData} className='mt-4' />
                                            </div>

                                        </div>
                                        <div className=' pt-3 d-flex justify-content-end border-top'>
                                            <ButtonSpinner
                                                variant="info" type="submit" className="me-2"
                                                handleSubmit={handleSubmitFormRegisterExterno} fetchDataLoading={[fetchRegistroAdminData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                                            />
                                        </div>
                                    </>
                            }


                        </div>
                    </div>
                </div>

            </div>
            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>
        </>

    )
}
