import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'

import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { InputFile } from '../../../../components/Inputs';
import { useAxios } from '../../../../hooks/useAxios';
import { ModalStatusServer2 } from '../../../../components/ModalStatusServer/ModalStatusServer2';
import { isStatusSucess } from '../../../../helpers/helpers';

// import './ProcessosMain.css'

export const ModalReemplazarPDF = ({ fetchCrearIncidencia, fetchCrearIncidenciaData, showModalReemplazarPDF, 
    handleCloseModalReemplazarPDF, id, idiomaDAPcon, fetchDAPconsDatReq }) => {

    const doc_file = useRef(null)

    const [fetchReemplazarPDF, fetchReemplazarPDFData] = useAxios('post', `/dapcons/${id}/form/replaced-dapcon`)

    const handleSubmitReemplazarPDF = () => {

        let formData = new FormData();

        formData.append("doc_file", doc_file.current?.files?.[0])
        formData.append("idioma_id", idiomaDAPcon)

        fetchReemplazarPDF({
            body: formData
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchReemplazarPDFData.data?.status)) {
            handleCloseModalReemplazarPDF()
        }

    }, [fetchReemplazarPDFData]);


    return (
        <>

            <Modal show={showModalReemplazarPDF.value} onHide={handleCloseModalReemplazarPDF}>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Reemplazar PDF DAPcons</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-4'>

                    <Form className='row'>

                        <Form.Group controlId="formFile" className="col-12 mb-3">
                            <label class="fw-500 form-label">Documento:</label>

                            <InputFile
                                name='doc_file'
                                // onChange={handleInputChangeDocsAdjObligatorios}
                                referencia={doc_file}
                            // validation={valuesValidISO}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <Button variant="default" onClick={handleCloseModalReemplazarPDF}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleSubmitReemplazarPDF} fetchDataLoading={[fetchReemplazarPDFData.loading]}
                        value='Reemplazar PDF'
                    />
                </Modal.Footer>
            </Modal>

            <ModalStatusServer2 fetchData={fetchReemplazarPDFData} />

        </>
    )
}
