import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal, Table, Form } from 'react-bootstrap'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../hooks/useAxios'
import { MsgInputServer } from '../../components/MsgInput/MsgInputServer';
import { InputText, InputSelect } from '../../components/Inputs';
import { useForm } from '../../hooks/useForm';
import { useValidator } from '../../hooks/useValidator';
import {generateYears} from '../../helpers/helpers'
import { Loader } from '../../components/Loader/Loader';
import { GetOrganizacionJSON } from "../../services/organizacion.service";

export const ModalRenovacion = ({ data, handleCloseModal, fetchConsolidado}) => {

    const currentYear = new Date().getFullYear();
    const startYear = 2000;

    const years = generateYears(startYear, currentYear);

    const [fetchOrganizacion, fetchOrganizacionData, resetFetchOrganizacion] = GetOrganizacionJSON()
    const [fetchRenovar, fetchRenovarData] = useAxios('PATCH', '/panel/renovar_organizacion')

    const [formRenovacion, 
        handleInputChangeRenovacion, 
        handleFormChangeRenovacion, 
        handleResetRenovacion] = useForm({fecha_renovacion: '', importe: '', year: currentYear, observaciones: '', org_tipo: '', org_tamanio: ''})

     const [valuesValidRenovacion, handleVerifiedValuesRenovacion, handleResetValuesRenovacion] = useValidator({
        //fecha_renovacion: { isOk: true, msgError: [`Ingrese una fecha`] },
        importe: { isOk: true, msgError: [`Ingrese un importe`] },
        year: { isOk: true, msgError: [`Ingrese una año`] },
        org_tipo: { isOk: true, msgError: [`Seleccione un tipo de empresa`] },
        org_tamanio: { isOk: true, msgError: [`Seleccione un tamaño de empresa`] },
        //observaciones: { isOk: true, msgError: [`Ingrese una observación`] },
     })

    const handleSubmitActualizar = (e) => {
        e.preventDefault();
        if (handleVerifiedValuesRenovacion(formRenovacion)) {
            fetchRenovar({
                id: data?.id,
                body: formRenovacion
            })
        }
    }

    useEffect(() => {
        if(!data.value){
            handleResetRenovacion();
            handleResetValuesRenovacion();
        }
    }, [data.value])

    useEffect(() => {
      if(fetchRenovarData?.data?.status == 200){
            handleCloseModal();
            fetchConsolidado();
      }  
    },[fetchRenovarData.data])

    const [fetchParameters, fetchParametersData] = useAxios('POST', `/parametros_generales`)
    const [fetchParametersType, fetchParametersTypeData] = useAxios('POST', `/parametros_generales`)
    const [fetchTarifa, fetchTarifaData] = useAxios('GET', `/parametros/tarifa_asignada`)

    useEffect(() => {
        if(data?.value){
            fetchParameters({
                body: [
                    {
                        "param": "empresa_convenios",
                        "locale": 1,
                        "active": true
                    },
                    {
                        "param": "tipo_empresa",
                        "locale": 1,
                        "active": true
                    },
                ]
            });

            fetchOrganizacion({
                params: {
                    org_cif: data?.org_cif,
                }
            })
        } 
    }, [data?.value])

    useEffect(() => {
        handleFormChangeRenovacion({...formRenovacion, importe: ''})
        if(formRenovacion.org_tipo === 'asociacion'){
            fetchParametersType({
                body: [
                    {
                        "param": "tamanio_asociacion",
                        "locale": 1,
                        "active": true
                    },
                ]
            });
        }else if(formRenovacion.org_tipo === 'empresa'){
             fetchParametersType({
                body: [
                    {
                        "param": "tamanio_empresa",
                        "locale": 1,
                        "active": true
                    },
                ]
            });
        }
    }, [formRenovacion.org_tipo])

    useEffect(() => {
        if(formRenovacion.org_tamanio){
            handleFormChangeRenovacion({...formRenovacion, importe: ''})
            fetchTarifa({
                id: formRenovacion.org_tamanio
            });
        }
    }, [formRenovacion.org_tamanio])

    useEffect(() =>{
       if(fetchTarifaData.data?.data){
         const data = fetchTarifaData.data?.data;
         handleFormChangeRenovacion({...formRenovacion, importe: data.precio})
       }
    }, [fetchTarifaData.data])

    useEffect(() => {
        if(fetchOrganizacionData.data){
            const data = fetchOrganizacionData.data;
            handleFormChangeRenovacion({...formRenovacion, org_tipo: data.org_tipo, org_tamanio: data.org_tamanio})
        }

    }, [fetchOrganizacionData.data])

    return (
        <Modal show={data.value} onHide={handleCloseModal} size='' backdrop="static" keyboard={false}>
             <Modal.Header closeButton className='border-0'>
                <Modal.Title className='h5'>Renovación de empresa</Modal.Title>
            </Modal.Header>
            {fetchParametersData.loading || fetchOrganizacionData.loading ? <Loader /> : <>
            <Modal.Body className='px-4 py-4'>
                <Form className="row">
                 <Form.Group className="mb-3 col-lg-6">
                    <InputSelect
                        label='Tipo de Empresa:'
                        name='org_tipo'
                        values={
                            fetchParametersData.data?.data?.data?.tipo_empresa?.map((item, index) => {
                                return {
                                   id: item.param_id,
                                   nombre: item.pt_texto
                                }
                            })
                        }
                        value={formRenovacion.org_tipo}
                        onChange={handleInputChangeRenovacion}
                        validation={valuesValidRenovacion}
                        fetchData={fetchRenovarData}
                    />
                </Form.Group>

                <Form.Group className="mb-3 col-lg-6">
                   <InputSelect
                        label={formRenovacion.org_tipo === 'asociacion' ? 'Tamaño Asociación' : 'Tamaño Empresa' }
                        name='org_tamanio'
                        values={formRenovacion.org_tipo === 'asociacion' 
                            ? 
                                fetchParametersTypeData.data?.data?.data?.tamanio_asociacion?.map((item, index) => {
                                    return {
                                       id: item.param_id,
                                       nombre: item.pt_texto
                                    }
                                })
                            :
                                fetchParametersTypeData.data?.data?.data?.tamanio_empresa?.map((item, index) => {
                                    return {
                                       id: item.param_id,
                                       nombre: item.pt_texto
                                    }
                                })
                        }
                        value={formRenovacion.org_tamanio}
                        onChange={handleInputChangeRenovacion}
                        validation={valuesValidRenovacion}
                        fetchData={fetchRenovarData}
                    />
                </Form.Group>

                <Form.Group className="mb-4 col-lg-6">
                     <InputSelect
                            disabled={true}
                            label='Año:'
                            name='year'
                            values={years}
                            value={formRenovacion.year}
                            onChange={handleInputChangeRenovacion}
                            validation={valuesValidRenovacion}
                            fetchData={fetchRenovarData}
                        />
                </Form.Group>

                {/*
                <Form.Group className="mb-4 col-lg-12">
                     <InputText
                            label='Fecha de pago:'
                            type="datetime-local"
                            className=''
                            name='fecha_renovacion'
                            placeholder="Ingrese una fecha"
                            value={formRenovacion.fecha_renovacion}
                            onChange={handleInputChangeRenovacion}
                            validation={valuesValidRenovacion}
                            fetchData={fetchRenovarData}
                        />
                </Form.Group>
                */}
                <Form.Group className="mb-4 col-lg-6">
                 <InputText
                        label='Importe:'
                        type="number"
                        className=''
                        name='importe'
                        placeholder="Ingrese un valor"
                        value={formRenovacion.importe}
                        onChange={handleInputChangeRenovacion}
                        validation={valuesValidRenovacion}
                        fetchData={fetchRenovarData}
                    />
             </Form.Group>
             <Form.Group className="mb-4 col-lg-12">
             <InputText
                    label='Observaciones:'
                    className=''
                    as='textarea'
                    rows={2}
                    name='observaciones'
                    placeholder="Ingrese una observación"
                    value={formRenovacion.observaciones}
                    onChange={handleInputChangeRenovacion}
                    validation={valuesValidRenovacion}
                    fetchData={fetchRenovarData}
                />
            </Form.Group>
            </Form>
            <MsgInputServer fetchData={fetchRenovarData} className='mt-2' />
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button variant="default" onClick={() => {
                    handleCloseModal()
                }}>
                    Cancelar
                </Button>
                <ButtonSpinner
                    variant="success" type="submit" className="ms-2 mt-2"
                    handleSubmit={handleSubmitActualizar} fetchDataLoading={[fetchRenovarData.loading]}
                    value='Renovar' icon={<FontAwesomeIcon icon={faArrowRight} className='me-1' />}
                />
            </Modal.Footer>
           </>
        }
        </Modal>
    )
}
