import React, { useState } from 'react'

import './incidencia.css'

export const Incidencia = ({ item }) => {

    const [showAll, setShowAll] = useState(false)

    return (
        <div>
            <h6>{item.titulo}</h6>
            <div className={`${showAll ? 'd-block' : 'd-none'}`} dangerouslySetInnerHTML={{ __html: item.descripcion }}></div>
            <div className="color-primary cursor-pointer text-end pt-2" onClick={() => setShowAll(!showAll)}>
                {showAll ? 'Mostrar menos' : 'Mostrar más'}
            </div>
        </div>

    )
}
