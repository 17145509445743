import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner'

export const ButtonSolicitarVerificacion = ({ estadoDAPcons, handleClickSolicitarVerificacion, fetchDAPconsSoliVeriData, isCompleteForm }) => {

    console.log(estadoDAPcons)

    let messageTooltip = ''
    let disabled = true

    if (isCompleteForm) {

        messageTooltip = ''
        disabled = false

        if (estadoDAPcons === 'incidencias') {

            messageTooltip = 'Solicite la verificación cuando haya corregido las incidencias'
            disabled = false
        }

    } else {

        messageTooltip = 'Debe completar todos los formularios para poder solicitar la verificación'
        disabled = true
    }

    return (
        <>
            {
                isCompleteForm && estadoDAPcons !== 'incidencias' ?
                    <>
                        <ButtonSpinner
                            variant="info" type="submit" className="px-3 ms-3 d-flex align-items-center"
                            handleSubmit={handleClickSolicitarVerificacion} fetchDataLoading={[fetchDAPconsSoliVeriData.loading]}
                            value='Solicitar verificación' icon={<FontAwesomeIcon icon={faCheck} className='me-2' />}
                            disabled={disabled}
                        />
                    </> : (
                        <>
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-disabled">{messageTooltip}</Tooltip>}
                                placement='bottom'
                                key='bottom'
                            >
                                <span className="d-inline-block">
                                    <ButtonSpinner
                                        variant="info" type="submit" className="px-3 ms-3 d-flex align-items-center"
                                        handleSubmit={handleClickSolicitarVerificacion} fetchDataLoading={[fetchDAPconsSoliVeriData.loading]}
                                        value='Solicitar verificación' icon={<FontAwesomeIcon icon={faCheck} className='me-2' />}
                                        disabled={disabled}
                                    />
                                </span>
                            </OverlayTrigger>
                        </>
                    )
            }
        </>

    )
}
