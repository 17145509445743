import React, { useEffect } from 'react'
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../../hooks/useAxios';
import { MsgInputServer } from '../../../../components/MsgInput/MsgInputServer';
import { InputText } from '../../../../components/Inputs';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';
import { Loader } from '../../../../components/Loader/Loader';
import { formatFecha } from '../../../../helpers/helpers';

export const ModalEditPayment = ({data, handleCloseModal, total=0, selectedRows, reloadComisiones, setTotalCommission}) => {
     const [fetchUpdatePago, fetchUpdatePagoData] = useAxios('patch', `/comisiones`); 
    const [fetchGETComision, fetchGETComisionData] = useAxios('GET', `/comisiones`); 
     const [formPago, handleInputChangePago, handleFormChangeCrearDAPcons, handleResetCrearDAPcons]
        = useForm({
            pagado: '', 
            observaciones: '',
        })

     const handleSubmitPagar = (e) => {
        fetchUpdatePago({
            id: data?.id+'/update',
            body: formPago
        })
    }
    
    useEffect(() => {
        if(!data.value){
            handleResetCrearDAPcons();
        }else{
            fetchGETComision({
                id: data?.id+'/show'
            })
        }
    },[data.value])

    useEffect(() => {
        if (fetchUpdatePagoData?.data?.status === 200) {
            reloadComisiones();
            setTotalCommission(0);
            handleCloseModal();
        }
    }, [fetchUpdatePagoData.data])

    useEffect(() => {

       if(fetchGETComisionData.data?.data?.data){
         const data = fetchGETComisionData.data?.data?.data;
         const pagado = data.fecha_pago ? formatFecha(data.fecha_pago) : '';
         handleFormChangeCrearDAPcons({...formPago, pagado, observaciones: data.observaciones || ''})
       }

    },[fetchGETComisionData.data])

	 return (
        <Modal show={data?.value} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Actualizar comisión</Modal.Title>
            </Modal.Header>
            {fetchGETComisionData.loading ? <Loader /> : <>
            <Modal.Body className='px-4 py-4'>
                 <div className="col-12">
                     <Form.Group className="mb-4 col-lg-12">
                         <InputText
                                label='Fecha de pago:'
                                type="date"
                                className=''
                                name='pagado'
                                placeholder="Ingrese una fecha"
                                value={formPago.pagado}
                                onChange={handleInputChangePago}
                                fetchData={fetchUpdatePagoData}
                            />
                    </Form.Group>

                    <Form.Group className="mb-4 col-lg-12">
                         <InputText
                            label='Observaciones:'
                            className=''
                            as='textarea'
                            rows={2}
                            name='observaciones'
                            placeholder="Ingrese una observación"
                            value={formPago.observaciones}
                            onChange={handleInputChangePago}
                            fetchData={fetchUpdatePagoData}
                        />
                    </Form.Group>

                 </div>
                 <div className="col-12 text-center">
                    <MsgInputServer fetchData={fetchUpdatePagoData} className='mt-2' />
                 </div>  
            </Modal.Body>
            <Modal.Footer>
                <ButtonSpinner
                    variant="success" type="submit" className="ms-2"
                    handleSubmit={handleSubmitPagar} fetchDataLoading={[fetchUpdatePagoData.loading]}
                    value='Actualizar' icon={<FontAwesomeIcon icon={faCreditCard} className='me-1' />}
                />   
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
            </>
         }
        </Modal>
     )   
}