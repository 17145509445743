import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { images } from '../../../helpers/images';
import { imagesHelps } from '../../../helpers/imagesHelps';

import '../ModalHelp.css'

export const ModalHelpDownFirma = ({ setShowModalHelp, showModalHelp }) => {

    const handleCloseSuccess = () => {
        setShowModalHelp(false)
    };

    const src = imagesHelps('./HelpDownFirma.png')
    console.log(src)

    return (
        <Modal show={showModalHelp} onHide={handleCloseSuccess} size='lg' className='ModalHelp'>
            <Modal.Header closeButton className='border-0 pt-4'>
                <Modal.Title className='h5 d-flex align-items-center'>
                    <FontAwesomeIcon icon={faQuestionCircle} className='me-2 text-info' />
                    <span>Casilla Última página verificador de parte</span>
                </Modal.Title>

            </Modal.Header>
            <Modal.Body className='px-lg-5 py-0'>

                <p>
                    Este campo es parte del DAPcons y se verá reflejado en la última página de Verificador de tercera parte,
                    es importante que complete este campo en los diferentes idiomas,
                    el sistema seleccionará este campo de acuerdo al idioma del DAPcons que está verificando.
                </p>
                <p>
                    {
                        src && <div className='border p-3 shadow'>
                            <img src={src} className="mx-auto d-block w-100" alt="help" />
                        </div>
                    }

                </p>

            </Modal.Body>
            <Modal.Footer className='border-0'>
                <Button variant="default" onClick={handleCloseSuccess}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
