import React, { useEffect, useState, useContext } from 'react'
import { faCreditCard, faArrowRight, faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, Table, Badge } from 'react-bootstrap'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../hooks/useAxios';
import { types } from '../../../types/types';

export const DocumentoSepa = ({fetchTarifaData, formTarifa, handleInputChangeTarifa, valuesValidTarifa, fetchPagarData, setIndexFocusWizard}) => {

	 return (
        <div className="row d-flex mt-3 justify-content-center align-items-center">
          <div className="col-8">
                <Table hover responsive>
                  <tbody>
                    <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                           Descargue la plantilla del documento SEPA, complete los datos y en el siguiente paso suba el documento.<br/>
                           <span className="text-danger">Nota: Asegúrate de que coincida el número de cuenta con la entidad</span>
                        </td>
                        <td>
                           <Button variant="success" className="mt-2 mb-1" size="sm" href="/SEPA.pdf" download><FontAwesomeIcon icon={faDownload} className='me-1'/>Descargar SEPA</Button>
                        </td>
                      </tr>
                  </tbody>
                </Table>   
            </div>   
            <div className="text-center">
                <Button variant="outline-secondary" className="mt-2 mb-1" size="" onClick={() => setIndexFocusWizard(0)}><FontAwesomeIcon icon={faArrowLeft} className='me-1'/>Regresar</Button>
                <Button variant="outline-primary" className="ms-2 mt-2 mb-1" size="" onClick={() => setIndexFocusWizard(2)}><FontAwesomeIcon icon={faArrowRight} className='me-1'/>Siguiente</Button>
            </div>
        </div>  
    )
}