import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { faBars, faCheck, faCheckCircle, faCheckDouble, faExclamationTriangle, faFilePdf, faGlobe, faHome, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Breadcrumb, Button, DropdownButton, Dropdown, ListGroup, Modal, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'

import DAPconLinkMenu from '../DAPconLinkMenu';
import { AuthContext } from '../../../../auth/AuthContext';
import { useAxios } from '../../../../hooks/useAxios';
import { Loader } from '../../../../components/Loader/Loader';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import ModalPlugin from '../../../../components/ModalPlugin/ModalPlugin';
import LacunaWebPKI from 'web-pki';
import { PatchDAPconsSolicitarVerificacionJSON } from '../../../../services/dapcons.service';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';

import { DAPconConsultorSeccion0 } from './DAPconConsultorSeccion0';
import { DAPconConsultorSeccion2 } from './DAPconConsultorSeccion2';
import { DAPconConsultorSeccion3 } from './DAPconConsultorSeccion3';
import { DAPconConsultorSeccion4 } from './DAPconConsultorSeccion4';
import { DAPconConsultorSeccion5 } from './DAPconConsultorSeccion5';
import { DAPconConsultorSeccion6 } from './DAPconConsultorSeccion6';
import { DAPconConsultorSeccion7 } from './DAPconConsultorSeccion7';
import { DAPconConsultorSeccion8 } from './DAPconConsultorSeccion8';
import { DAPconConsultorSeccion9 } from './DAPconConsultorSeccion9';
import { DAPconSeccionSeguimiento } from '../DAPconSeccionSeguimiento';
import { DAPconVerificadorSeccion0 } from './DAPconVerificadorSeccion0';

import { ModalCrearIncidencia } from './ModalCrearIncidencia';
import { ModalFirmarDAPcons } from './ModalFirmarDAPcons';
import { ButtonSolicitarVerificacion } from './ButtonSolicitarVerificacion';
import { GetIncidenciasJSON, PostIncidenciaJSON } from '../../../../services/incidencia.service';
import { ModalVerIncidencias } from '../../../../components/ModalVerIncidencias/ModalVerIncidencias';
import { useLocation } from 'react-router-dom';
import { getModelos, isAdmin, isModuleAdministrador } from '../../../../helpers/helpers';
import { TooltipLock } from '../../../../components/TooltipLock/TooltipLock';
import { DropdownLanguage } from '../../../../components/DropdownLanguage/DropdownLanguage';
import { ModalStatusError } from '../../../../components/ModalStatusServer/ModalStatusError';

export const DAPconConsultor = ({ idDapcon, idiomaDAPcon, setIdiomaDAPcon, bloqueado, fetchDAPconsValores, 
    fetchEstadosDAPconsData, setVistaConsultorAdmin }) => {

    const location = useLocation()

    const { user } = useContext(AuthContext);

    // const [fetchDAPconsDatReq, fetchDAPconsDatReqData] = useAxios('get', `/dapcons/${idDapcon}/dapcons-valores`)
    const [fetchDAPconsDatReq, fetchDAPconsDatReqData] = useAxios('get', `/dapcons/${idDapcon}/form/datos-requeridos`)

    useEffect(() => {
        fetchDAPconsDatReq({
            params: {
                idioma_id: idiomaDAPcon
            }
        })
    }, [])

    const [datBasic, setDatBasic] = useState(null)

    useEffect(() => {
        console.log(fetchDAPconsDatReqData)
        if (fetchDAPconsDatReqData.data) {

            const formDAPconTemp = fetchDAPconsDatReqData.data?.data?.data

            console.log(formDAPconTemp)

            if (formDAPconTemp) {

                setDatBasic({
                    bloqueado: formDAPconTemp.bloqueado,
                    verificador: formDAPconTemp.verificador,
                    consultor: formDAPconTemp.consultor,
                    dapcons_codigo: formDAPconTemp.dapcons_codigo,
                    all_idiomas: formDAPconTemp.all_idiomas,
                    estadoDAPconsGlobal: formDAPconTemp.dapcons_estado_param_id,
                    estadoDAPcons: formDAPconTemp.idiomas?.[0]?.dapcons_idioma_estado,
                    dv_nombre_producto: formDAPconTemp.dapcons_valores?.[0]?.dv_nombre_producto,

                    dapcons_modelos: formDAPconTemp.dapcons_modelos,
                    modelos_series: getModelos(formDAPconTemp.dapcons_modelos),
                    modulos: formDAPconTemp.modulos,
                    dapcons_tabla5_tiene_a1a3: formDAPconTemp.dapcons_tabla5_tiene_a1a3,
                    dapcons_tabla6_tiene_a1a3: formDAPconTemp.dapcons_tabla6_tiene_a1a3,
                    dapcons_tabla_fin_vida_tiene_col_mix: formDAPconTemp.dapcons_tabla_fin_vida_tiene_col_mix,
                    dapcons_incluir_tabla_6: formDAPconTemp.dapcons_incluir_tabla_6
                })

            } else {

                history.push('/DAPcons')
            }
        }

        // if (fetchDAPconsDatReqData.error) {
        //     history.push('/DAPcons')
        // }
    }, [fetchDAPconsDatReqData])

    const history = useHistory();

    const inputRef = useRef([]);

    const [hideNav, setHideNav] = useState(false);
    const [activeNavDAPcon, setActiveNavDAPcon] = useState(0);

    useEffect(() => {
        // console.log(hideNav)
        const timer = setTimeout(() => {

            // setPointerEvents(true)
        }, 500);

        return () => clearTimeout(timer);

    }, [hideNav])

    const box = useRef(0);

    const [series, setSeries] = useState([
        ''
    ])

    const handleChangeSerie = (e, index) => {
        const seriesTemp = [...series]
        seriesTemp[index] = e.target.value
        setSeries(seriesTemp)
    }

    const [showModalCrearIncidencia, setShowModalCrearIncidencia] = useState({
        value: false,
        item: null
    });

    const handleCloseModalCrearIncidencia = () => setShowModalCrearIncidencia({
        value: false,
        item: null
    })
    const handleShowModalCrearIncidencia = (item) => setShowModalCrearIncidencia({
        value: true,
        item
    });

    // Visualizar DAPcon

    const [fetchDAPconHtml, fetchDAPconHtmlData, resetFetchDAPconHtml] = useAxios('get', `/dapcons-pdf/${idDapcon}`, '', 'blob')

    const handleClickVisualizarDAPcons = () => {

        handleFetchDAPcons()

        handleShowModalHtml()
    }

    const handleFetchDAPcons = () => {
        // const arrayLang = ['es', 'en']

        // console.log(arrayLang[idiomaDAPcon])
        fetchDAPconHtml({
            params: {
                idioma_id: idiomaDAPcon
            }
        })
    }



    useEffect(() => {
        console.log(fetchDAPconHtmlData)
        if (fetchDAPconHtmlData.data) {
            // window.open(URL.createObjectURL(fetchDAPconHtmlData.data?.data));
            if (!showModalHtml) {

                // 2. Create blob link to download
                const instanceBlob = new Blob([fetchDAPconHtmlData.data?.data])

                const date = new Date()

                const url = window.URL.createObjectURL(instanceBlob);
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', `DAPcons_${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}.${instanceBlob.type || 'pdf'}`);
                // 3. Append to html page
                document.body.appendChild(link);

                // 4. Force download
                link.click();

                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);

                fetchDAPconHtmlData.setState({
                    loading: false,
                    error: null,
                    data: null,
                })
            }

        }
    }, [fetchDAPconHtmlData])


    const [showModalHtml, setShowModalHtml] = useState(false);

    const handleCloseModalHtml = () => {
        setShowModalHtml(false)
        resetFetchDAPconHtml()
    }
    const handleShowModalHtml = () => setShowModalHtml(true);

    // Plugin de firmado

    // Web pki

    const [pki, setPki] = useState()
    const [certificates, setCertificates] = useState()

    const [readyPKI, setreadyPKI] = useState(false)

    function onWebPkiNotInstalled(status, message) {
        console.log('InstalÂ·laciÃ³ incorrecta: ' + message);
        setreadyPKI(false)
        handleShowModalPlugin()
    }

    function onWebPkiReady() {
        console.log('Component ready.');
        loadCertificates();
        setreadyPKI(false)
        handleShowModalFirmarDAPcons()
    }

    function loadCertificates() {
        console.log('Llistant certificats ...');

        pki.listCertificates({
            filter: pki.isWithinValidity
        }).success(function (certificates) {
            console.log('Certificats llistats.', certificates);
            setCertificates(certificates)
        });
    }

    function onWebPkiError(message, error, origin) {
        console.log('Web PKI error originat a ' + origin + ': ' + error);
        setreadyPKI(false)
    }

    useEffect(() => {

        if (readyPKI) {

            let pkiT = new LacunaWebPKI({
                "format": 2,
                "allowedDomains": [
                    "*.arescoop.es"
                ],
                "homologDomains": [
                    "ip4:10.0.0.0/8",
                    "ip4:127.0.0.0/8",
                    "ip4:172.16.0.0/12",
                    "ip4:192.168.0.0/16"
                ],
                "productLevel": "Pro",
                "expiration": "2022-12-31 03:00:00Z",
                "signature": "rdSl3ekA8zA3+iZgN/SbGP/6z+XH5V4ttn2Ww7FiQhoCqVaOQGpALoSpvUnn7/sskg94b3J5eF4GjnxYJv/qcGopKouHM1AMZVy3pMyR8X/03U3FC0GCWWJS+Ushqwsc0ZD+Aw+LF7Q5SxBsOIFKAoHMQ6BRnnzi1NqPWYroiwWBdcoCfn+hhr2DhtDirNp16FdNbM2ntWoAO9PF3uoixwFqluUxeDbcynHIhplgFChksxlilMNBlknxHIzaZf6SpcWLUy0L/z5DTn6FIafAOlPaA4sv6UESp1LlQLlBKoXMJFLyHHdxXEQOHE1KHjhdOlR9Fz+A4xt6O1M9nJGivg=="
            });

            setPki(pkiT)
        }


    }, [readyPKI])

    useEffect(() => {

        if (pki) {

            pki.init({
                ready: onWebPkiReady,
                notInstalled: onWebPkiNotInstalled,
                defaultError: onWebPkiError
            });
        }

    }, [pki])

    // Instalar plugin

    const [showModalPlugin, setShowModalPlugin] = useState(false);

    const handleCloseModalPlugin = () => setShowModalPlugin(false);
    const handleShowModalPlugin = () => setShowModalPlugin(true);

    // Modal de firmar

    const [showModalFirmarDAPcons, setShowModalFirmarDAPcons] = useState(false);

    const handleCloseModalFirmarDAPcons = () => setShowModalFirmarDAPcons(false);
    const handleShowModalFirmarDAPcons = () => setShowModalFirmarDAPcons(true);

    // Solicitar verificacion

    const [fetchDAPconsSoliVeri, fetchDAPconsSoliVeriData] = PatchDAPconsSolicitarVerificacionJSON(idDapcon)

    const handleClickSolicitarVerificacion = () => {

        fetchDAPconsSoliVeri({
            params: {
                idioma_id: idiomaDAPcon
            }
        })

    }

    const [showSuccessSoli, setShowSuccessSoli] = useState(false);

    useEffect(() => {
        console.log(fetchDAPconsSoliVeriData.data)
        if (fetchDAPconsSoliVeriData.status === 200) {
            setShowSuccessSoli(true)
            // fetchDAPconsDatReq({
            //     params: {
            //         idioma_id: idiomaDAPcon
            //     }
            // })

            fetchDAPconsValores({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchDAPconsSoliVeriData])

    const [fetchCrearIncidencia, fetchCrearIncidenciaData] = PostIncidenciaJSON()

    const [showSuccessCrearIncidencia, setShowSuccessCrearIncidencia] = useState(false);

    useEffect(() => {
        fetchIncidencias({
            params: {
                idioma_id: idiomaDAPcon,
                dapcons_id: idDapcon,
                esta_resuelta: false,
                perfil: 'consultor'
            }
        })
    }, [])



    // Ver incidencias

    const [fetchIncidencias, fetchIncidenciasData] = GetIncidenciasJSON()


    const [showModalVerIncidencias, setShowModalVerIncidencias] = useState(false);

    const handleCloseModalVerIncidencias = () => setShowModalVerIncidencias(false);
    const handleShowModalVerIncidencias = () => setShowModalVerIncidencias(true);

    // Status firmado

    const [showSuccessFirmarDapcons, setShowSuccessFirmarDapcons] = useState(false);


    // Consultar Solicitud verificacion y secciones completas

    const [fetchCheckForms, fetchCheckFormsData] = useAxios('get', `/dapcons/${idDapcon}/checkForms`)

    useEffect(() => {

        fetchCheckForms({
            params: {
                idioma_id: idiomaDAPcon
            }
        })

    }, [])

    useEffect(() => {

        if (fetchCheckFormsData.data) {
            console.log(fetchCheckFormsData.data?.data?.data)
        }

    }, [fetchCheckFormsData])


    return (
        <>
            {
                fetchDAPconsDatReqData.loading || !fetchDAPconsDatReqData.data?.data?.data ?
                    <div className="py-5">
                        <Loader />
                    </div> : <>
                        <div className='d-flex justify-content-between align-items-center mb-3 Nav-top-DAPcon'>
                            <div className='d-flex'>
                                <Breadcrumb className="Breadcrumb-inner Breadcrumb-DAPcon">
                                    <Breadcrumb.Item href="#">
                                        <FontAwesomeIcon icon={faHome} />
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item onClick={() => history.push('/DAPcons')}>
                                        Dapcons
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active className='fw-500'>
                                        {datBasic?.dapcons_codigo ? datBasic?.dapcons_codigo : 'DAPcons ' + idDapcon}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <div className='ms-3'>
                                    <Badge pill bg="secondary" className='fw-500'>
                                        {fetchEstadosDAPconsData?.data?.find(item => item.id === datBasic?.estadoDAPcons)?.nombre}
                                    </Badge>
                                    {
                                        bloqueado && <Form.Label className='fw-500 ms-3'>
                                            <TooltipLock id='dapcons_verificador_id' position='right'
                                                text='Algunas opciones del DAPcons han sido bloqueadas'
                                            />
                                        </Form.Label>
                                    }
                                </div>
                            </div>

                        </div>

                        <div className={`col-12 ${hideNav ? 'HideNav' : ''}`} >
                            <div className="row">

                                <div className='col-xl-3' ref={box} id='dapcon-col-start'>
                                    <div className='Column-small shadow-sm  p-0' id='listgroup-dapcon'>

                                        {/* <h5 className='ps-3 pt-3'>Formulario</h5> */}

                                        <ListGroup variant="flush" className='mt-0'>
                                            {
                                                console.log(datBasic?.consultor?.consultor_nif, user.user.usuario)
                                            }
                                            {
                                                ((isModuleAdministrador(user?.modulo_app) || datBasic?.consultor?.consultor_nif === user.user.usuario) &&
                                                    (datBasic?.estadoDAPcons === 'borrador' || datBasic?.estadoDAPcons === 'incidencias')) &&

                                                <>
                                                    <p className={`m-0 listgroup-subtitle py-1 mb-1 d-flex ${hideNav ? 'justify-content-center' : 'justify-content-between'}`}>
                                                        <small className=''>
                                                            {
                                                                !hideNav && <span>Datos generales</span>
                                                            }
                                                        </small>
                                                        <FontAwesomeIcon icon={faBars} className='cursor-pointer' onClick={() => setHideNav(!hideNav)} />
                                                    </p>

                                                    <DAPconLinkMenu
                                                        activeNavDAPcon={activeNavDAPcon}
                                                        setActiveNavDAPcon={setActiveNavDAPcon}
                                                        hideNav={hideNav}
                                                        label='Datos requeridos'
                                                        id={0}
                                                        status={fetchCheckFormsData.data?.data?.data?.form_datos_requeridos ? 'completo' : ''}
                                                    />

                                                    <DAPconLinkMenu
                                                        activeNavDAPcon={activeNavDAPcon}
                                                        setActiveNavDAPcon={setActiveNavDAPcon}
                                                        hideNav={hideNav}
                                                        label='Diseño del documento'
                                                        id={9}
                                                        status={fetchCheckFormsData.data?.data?.data?.form_disenio_documento ? 'completo' : ''}
                                                    />


                                                    <p className='m-0 listgroup-subtitle py-1 mb-1'>
                                                        <small>
                                                            {
                                                                !hideNav && <span>Declaración ambiental del producto</span>
                                                            }
                                                        </small>
                                                    </p>

                                                    <DAPconLinkMenu
                                                        activeNavDAPcon={activeNavDAPcon}
                                                        setActiveNavDAPcon={setActiveNavDAPcon}
                                                        hideNav={hideNav}
                                                        label='1. Descripción del producto y su uso'
                                                        id={2}
                                                        status={fetchCheckFormsData.data?.data?.data?.form_descripcion_prod_uso ? 'completo' : ''}
                                                    />

                                                    <DAPconLinkMenu
                                                        activeNavDAPcon={activeNavDAPcon}
                                                        setActiveNavDAPcon={setActiveNavDAPcon}
                                                        hideNav={hideNav}
                                                        label='2. Descripción etapas ciclo de vida'
                                                        id={3}
                                                        status={fetchCheckFormsData.data?.data?.data?.form_descripcion_etapas_ciclo_vida ? 'completo' : ''}
                                                    />

                                                    <DAPconLinkMenu
                                                        activeNavDAPcon={activeNavDAPcon}
                                                        setActiveNavDAPcon={setActiveNavDAPcon}
                                                        hideNav={hideNav}
                                                        label='3. Análisis del ciclo de vida'
                                                        id={4}
                                                        status={fetchCheckFormsData.data?.data?.data?.form_analisis_ciclo_vida ? 'completo' : ''}
                                                    />

                                                    <DAPconLinkMenu
                                                        activeNavDAPcon={activeNavDAPcon}
                                                        setActiveNavDAPcon={setActiveNavDAPcon}
                                                        hideNav={hideNav}
                                                        label='4. Información técnica adicional y escenarios'
                                                        id={5}
                                                        status={fetchCheckFormsData.data?.data?.data?.form_inf_tecnica_adicional_escenario ? 'completo' : ''}
                                                    />

                                                    <DAPconLinkMenu
                                                        activeNavDAPcon={activeNavDAPcon}
                                                        setActiveNavDAPcon={setActiveNavDAPcon}
                                                        hideNav={hideNav}
                                                        label='5. Información adicional (Opcional)'
                                                        id={7}
                                                        status={fetchCheckFormsData.data?.data?.data?.form_inf_adicional ? 'completo' : ''}
                                                        invisible={true}
                                                    />

                                                    <DAPconLinkMenu
                                                        activeNavDAPcon={activeNavDAPcon}
                                                        setActiveNavDAPcon={setActiveNavDAPcon}
                                                        hideNav={hideNav}
                                                        label='6. RCP y verificación'
                                                        id={8}
                                                        status={fetchCheckFormsData.data?.data?.data?.form_rcp_verificacion ? 'completo' : ''}
                                                    />

                                                    <DAPconLinkMenu
                                                        activeNavDAPcon={activeNavDAPcon}
                                                        setActiveNavDAPcon={setActiveNavDAPcon}
                                                        hideNav={hideNav}
                                                        label='Documentos adjuntos obligatorios'
                                                        id={6}
                                                        status={fetchCheckFormsData.data?.data?.data?.form_doc_adjuntos_obligatorios ? 'completo' : ''}
                                                    />
                                                </>
                                            }

                                        </ListGroup>

                                        {
                                            isModuleAdministrador(user?.modulo_app) &&
                                            <Button size='sm' variant='outline-primary' className='mt-3 d-flex justify-content-center' onClick={() => setVistaConsultorAdmin('admin')}>
                                                Ver como Administrador
                                            </Button>
                                        }
                                    </div>
                                </div>
                                <div className='col-xl-9' id='dapcon-col-end'>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <div className="d-flex align-items-sm-center justify-content-between flex-wrap flex-column flex-sm-row">
                                                <div className='d-flex'>
                                                    <DropdownLanguage
                                                        all_idiomas={datBasic?.all_idiomas}
                                                        setIdiomaDAPcon={setIdiomaDAPcon}
                                                        idiomaDAPcon={idiomaDAPcon}
                                                        id_dapcon={idDapcon}
                                                    />

                                                    {
                                                        (fetchIncidenciasData.data?.total > 0) && <Button variant="outline-danger" size='' className="ms-3 px-3 d-flex align-items-center bg-white"
                                                            onClick={handleShowModalVerIncidencias}
                                                        >
                                                            <FontAwesomeIcon icon={faExclamationTriangle} className='me-2' /> Incidencias
                                                        </Button>
                                                    }

                                                </div>

                                                {
                                                    ((isModuleAdministrador(user?.modulo_app) || datBasic?.consultor?.consultor_nif === user.user.usuario) &&
                                                        (datBasic?.estadoDAPcons === 'borrador' || datBasic?.estadoDAPcons === 'incidencias')) &&
                                                    <div className='d-flex align-items-center'>

                                                        <Button variant="outline-primary" size='' className="px-3 d-flex align-items-center"
                                                            onClick={handleClickVisualizarDAPcons}
                                                        >
                                                            <FontAwesomeIcon icon={faFilePdf} className='me-2' /> Previsualizar
                                                        </Button>

                                                        <ButtonSolicitarVerificacion
                                                            estadoDAPcons={datBasic?.estadoDAPcons}
                                                            handleClickSolicitarVerificacion={handleClickSolicitarVerificacion}
                                                            fetchDAPconsSoliVeriData={fetchDAPconsSoliVeriData}
                                                            isCompleteForm={fetchCheckFormsData.data?.data?.data?.isCompleted}
                                                        />

                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    {
                                        ((isModuleAdministrador(user?.modulo_app) || datBasic?.consultor?.consultor_nif === user.user.usuario) &&
                                            (datBasic?.estadoDAPcons === 'borrador' || datBasic?.estadoDAPcons === 'incidencias')) && <>

                                            {
                                                activeNavDAPcon === 0 &&
                                                <DAPconConsultorSeccion0
                                                    id={idDapcon}
                                                    setSeries={setSeries}
                                                    series={series}
                                                    handleChangeSerie={handleChangeSerie}
                                                    idiomaDAPcon={idiomaDAPcon}
                                                    fetchDAPconsDatReq={fetchDAPconsDatReq}
                                                    fetchDAPconsDatReqData={fetchDAPconsDatReqData}
                                                    bloqueado={bloqueado}
                                                    fetchCheckForms={fetchCheckForms}
                                                />
                                            }

                                            {/* {activeNavDAPcon === 1 && <DAPconSection1 id={idDapcon} />} */}

                                            {
                                                activeNavDAPcon === 9 &&
                                                <DAPconConsultorSeccion2
                                                    id={idDapcon}
                                                    idiomaDAPcon={idiomaDAPcon}
                                                    bloqueado={bloqueado}
                                                    fetchCheckForms={fetchCheckForms}
                                                />
                                            }

                                            {activeNavDAPcon === 2 &&
                                                <DAPconConsultorSeccion3
                                                    id={idDapcon}
                                                    idiomaDAPcon={idiomaDAPcon}
                                                    bloqueado={bloqueado}
                                                    fetchCheckForms={fetchCheckForms}
                                                />
                                            }

                                            {
                                                activeNavDAPcon === 3 &&
                                                <DAPconConsultorSeccion4
                                                    id={idDapcon}
                                                    datBasic={datBasic}
                                                    idiomaDAPcon={idiomaDAPcon}
                                                    bloqueado={bloqueado}
                                                    fetchCheckForms={fetchCheckForms}
                                                />
                                            }

                                            {
                                                activeNavDAPcon === 4 &&
                                                <DAPconConsultorSeccion5
                                                    series={series}
                                                    idDapcon={idDapcon}
                                                    idiomaDAPcon={idiomaDAPcon}
                                                    datBasic={datBasic}
                                                    bloqueado={bloqueado}
                                                    fetchCheckForms={fetchCheckForms}
                                                />
                                            }

                                            {
                                                activeNavDAPcon === 5 &&
                                                <DAPconConsultorSeccion6
                                                    series={series}
                                                    idiomaDAPcon={idiomaDAPcon}
                                                    datBasic={datBasic}
                                                    idDapcon={idDapcon}
                                                    bloqueado={bloqueado}
                                                    fetchCheckForms={fetchCheckForms}
                                                />
                                            }

                                            {
                                                activeNavDAPcon === 6 &&
                                                <DAPconConsultorSeccion7
                                                    idDapcon={idDapcon}
                                                    idiomaDAPcon={idiomaDAPcon}
                                                    bloqueado={bloqueado}
                                                    fetchCheckForms={fetchCheckForms}
                                                />
                                            }

                                            {
                                                activeNavDAPcon === 7 &&
                                                <DAPconConsultorSeccion8
                                                    idDapcon={idDapcon}
                                                    idiomaDAPcon={idiomaDAPcon}
                                                    bloqueado={bloqueado}
                                                    fetchCheckForms={fetchCheckForms}
                                                />
                                            }

                                            {
                                                activeNavDAPcon === 8 &&
                                                <DAPconConsultorSeccion9
                                                    idDapcon={idDapcon}
                                                    idiomaDAPcon={idiomaDAPcon}
                                                    bloqueado={bloqueado}
                                                    fetchCheckForms={fetchCheckForms}
                                                />
                                            }

                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                        <ModalCrearIncidencia
                            showModalCrearIncidencia={showModalCrearIncidencia}
                            handleCloseModalCrearIncidencia={handleCloseModalCrearIncidencia}
                            idiomaDAPcon={idiomaDAPcon}
                            id={idDapcon}
                            fetchDAPconsDatReq={fetchDAPconsDatReq}
                            fetchCrearIncidencia={fetchCrearIncidencia}
                            fetchCrearIncidenciaData={fetchCrearIncidenciaData}
                        />

                        <ModalPlugin
                            handleCloseModalPlugin={handleCloseModalPlugin}
                            handleShowModalPlugin={handleShowModalPlugin}
                            showModalPlugin={showModalPlugin}
                            pki={pki}
                        />

                        <ModalFirmarDAPcons
                            handleCloseModalFirmarDAPcons={handleCloseModalFirmarDAPcons}
                            handleShowModalFirmarDAPcons={handleShowModalFirmarDAPcons}
                            showModalFirmarDAPcons={showModalFirmarDAPcons}
                            pki={pki}
                            certificates={certificates}
                            idDapcon={idDapcon}
                            idiomaDAPcon={idiomaDAPcon}
                            setShowSuccessFirmarDapcons={setShowSuccessFirmarDapcons}
                        />
                    </>
            }
            <Modal show={showModalHtml} onHide={handleCloseModalHtml} size='lg' scrollable className='visor-plantilla-pdf'>
                <Modal.Body className='px-4 py-4 p-0'>
                    <div className="">
                        <div className="col-12">
                            {
                                fetchDAPconHtmlData.loading ? (
                                    <div className="position-absolute w-100 h-100">
                                        <div className="d-flex justify-content-center align-content-center py-5 h-100 flex-column">
                                            <div>
                                                <Loader />
                                            </div>
                                            <p className='text-center'>Este proceso puede durar hasta 1 minuto...</p>
                                        </div>
                                    </div>

                                ) : (
                                    fetchDAPconHtmlData.data?.data &&
                                    <embed
                                        src={URL.createObjectURL(fetchDAPconHtmlData.data?.data)}
                                        width="100%" height="100%"
                                        className='position-absolute h-100'
                                    ></embed>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {
                readyPKI && <div id="cover-spin2" className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }
            <ModalStatus setShowStatus={setShowSuccessSoli} showStatus={showSuccessSoli}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>
                        DAPcons enviado a verificación, se le notificará cuando el DAPcons termine de ser verificado.
                    </p>
                </div>
            </ModalStatus>
            <ModalStatus setShowStatus={setShowSuccessCrearIncidencia} showStatus={showSuccessCrearIncidencia}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>
                        Se ha creado la incidencia correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatus setShowStatus={setShowSuccessFirmarDapcons} showStatus={showSuccessFirmarDapcons}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>
                        Se ha Firmado el DAPcons correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalVerIncidencias
                showModalVerIncidencias={showModalVerIncidencias}
                handleCloseModalVerIncidencias={handleCloseModalVerIncidencias}
                idiomaDAPcon={idiomaDAPcon}
                fetchIncidencias={fetchIncidencias}
                fetchIncidenciasData={fetchIncidenciasData}
                dapcons_id={idDapcon}
                perfil={'consultor'}
            />

            <ModalStatusError fetchData={fetchDAPconHtmlData} />

        </>
    )
}
