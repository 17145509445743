import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { MsgInput } from '../../../../components/MsgInput/MsgInput';
import Wizard from "../../../../components/Wizard/Wizard";
import {DatosFacturacion} from '../../DAPconsMain/DatosFacturacion'
import { Loader } from '../../../../components/Loader/Loader';

// import './ProcessosMain.css'

export const DAPconFacturacion = ({ data, handleCloseModal, history}) => {
    const [indexFocusWizard, setIndexFocusWizard] = useState(0);
    const listWizard = [
       'Datos de Facturación'
    ];

    const handleShowModalSuccess = () => {
        history.push(`/DAPcons/${data?.id}/1`)
    }
    return (
        <Modal show={data.value} onHide={handleCloseModal} size='lg' backdrop="static" keyboard={false}>
            <Modal.Body className='px-4 py-4'>
                <Wizard>
                    <Wizard.Header
                        listWizard={listWizard}
                        indexFocusWizard={indexFocusWizard}
                        setIndexFocusWizard={setIndexFocusWizard}
                        enableBack={true}
                    />
                    <Wizard.Body indexFocusWizard={indexFocusWizard}>
                        <section>
                            <DatosFacturacion
                                dapconsId={data?.id}
                                handleCloseModal={handleCloseModal}
                                handleShowModalSuccess={handleShowModalSuccess}
                            />
                        </section>
                        <section>
                        </section>
                    </Wizard.Body>
                </Wizard>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={() => {
                    handleCloseModal()
                }}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
