import React from 'react'
import { faInf, faInfoCircle, faInfoCircleoCirclefaInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Overlay, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap'
import './Tablas.css'

const Table4TH = ({ item }) => {
    return (
        <th>
            <OverlayTrigger
                key={item.id}
                placement='top'
                overlay={
                    <Popover id={item.id}>
                        <Popover.Body>
                            <span>{item.text}</span>
                        </Popover.Body>
                    </Popover>
                }
            >
                <Button variant="transparent" size="sm" className='lh-base' tabIndex="-1">
                    <div className='mt-1'>
                        <strong>{item.id}</strong>
                    </div>
                </Button>
            </OverlayTrigger>
        </th>
    )
}

export const Table4 = ({ data, handleChangeModulos, bloqueado }) => {

    const th = [
        { id: 'A1', text: 'Extracción y procesado de materias primas' },
        { id: 'A2', text: 'Transporte al fabricante' },
        { id: 'A3', text: 'Fabricación' },
        { id: 'A4', text: 'Transporte del producto a la obra' },
        { id: 'A5', text: 'Instalación del producto y construcción' },
        { id: 'B1', text: 'Uso' },
        { id: 'B2', text: 'Mantenimiento' },
        { id: 'B3', text: 'Reparación' },
        { id: 'B4', text: 'Substitución' },
        { id: 'B5', text: 'Rehabilitación' },
        { id: 'B6', text: 'Uso de la energía operacional' },
        { id: 'B7', text: 'Uso del agua operacional' },
        { id: 'C1', text: 'Decosntrucción y derribo' },
        { id: 'C2', text: 'Transporte' },
        { id: 'C3', text: 'Gestión de los residuos para reutilización, recuperación y reciclaje' },
        { id: 'C4', text: 'Eliminación final' },
        { id: 'D', text: 'Potencial de reutilización, recuperación y reciclaje' }
    ]

    return (
        <>
            <div className='caption text-center d-block'>Tabla Módulos declarados</div>

            <Table responsive id='Table4' className='DAPconTableno'>
                <thead>
                    <tr>
                        <th colSpan={3}>Fabricación</th>
                        <th colSpan={2}>Construcción</th>
                        <th colSpan={7}>Uso del producto</th>
                        <th colSpan={4}>Fin de vida</th>
                        <th colSpan={1}>Beneficios y cargas ambientales más allá del límite sistema</th>
                    </tr>
                    <tr>
                        {
                            th.map((item) => {
                                return <Table4TH key={item.id} item={item} />
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {
                            Object.keys(data).map(key => {
                                let propTb = bloqueado ? {tabIndex: 1} : {};
                                return (
                                    <td className='text-center' key={key}>
                                        <Form.Check
                                            type='checkbox'
                                            id={key}
                                            name={key}
                                            defaultChecked={data[key]}
                                            onChange={(e) => handleChangeModulos(e, key)}
                                            disabled={(key === 'a1' && data[key]) || (key === 'a2' && data[key]) || (key === 'a3' && data[key]) || bloqueado}
                                            {...propTb}
                                        />
                                    </td>
                                )
                            })
                        }
                    </tr>
                </tbody>
            </Table>
            <small className=''>Los módulos que no estén seleccionados, no se mostrarán en los demás formularios</small>
        </>
    )
}
