import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { imagesFlags } from '../../helpers/imagesFlags';
import './DropdownLanguage.css'

export const DropdownLanguage = ({ all_idiomas = [], setIdiomaDAPcon, idiomaDAPcon, id_dapcon, disabledChange, 
    handleShowModal__ModificarIdiomas, siempreActivo }) => {

    console.log(all_idiomas)

    const history = useHistory();

    return (
        <>
            {
                ((all_idiomas?.length === 1 || disabledChange) && !siempreActivo) ?
                    (
                        <Button variant='outline-secondary' className='mb-3 mb-sm-0 bg-white'>
                            {/* <FontAwesomeIcon icon={faGlobe} className='me-2' /> {all_idiomas[0]?.idioma_nombre} */}
                            <img
                                className='dropdown-icon-lang me-2'
                                src={imagesFlags(`./${idiomaDAPcon}.png`)}
                                alt="" /> {all_idiomas?.find(item => item.id === Number(idiomaDAPcon))?.idioma_nombre}
                        </Button>
                    ) : (
                        <DropdownButton align="start" title={<span><img className='dropdown-icon-lang me-2' src={imagesFlags(`./${idiomaDAPcon}.png`)} alt="" />{all_idiomas?.find(item => Number(item.id) === idiomaDAPcon)?.idioma_nombre || 'Español'}</span>}
                            id="dropdown-menu-align-end" variant='outline-secondary' className='mb-3 mb-sm-0 bg-white'>
                            {
                                all_idiomas?.map(item => {
                                    console.log(item)
                                    return (
                                        <Dropdown.Item key={item.id} onClick={
                                            () => {
                                                setIdiomaDAPcon(item.id)
                                                history.push({
                                                    pathname: `/DAPcons/${id_dapcon}/${item.id}`,
                                                    // state: {
                                                    //     idiomaDAPconURL: item.id
                                                    // }
                                                })
                                            }
                                        } active={item.id === idiomaDAPcon} >{item.idioma_nombre}</Dropdown.Item>
                                    )
                                })
                            }

                            {
                                handleShowModal__ModificarIdiomas && <>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={
                                        () => {
                                            handleShowModal__ModificarIdiomas({})
                                        }
                                    } >Modificar idiomas</Dropdown.Item>
                                </>
                            }

                        </DropdownButton>
                    )
            }
        </>
    )
}
