import React, { useEffect } from 'react'
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../../hooks/useAxios';
import { MsgInputServer } from '../../../../components/MsgInput/MsgInputServer';
import { InputText } from '../../../../components/Inputs';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';

export const ModalPayment = ({data, handleCloseModal, total=0, selectedRows, reloadComisiones, setTotalCommission}) => {
     const [fetchPagar, fetchPagarData] = useAxios('POST', `/comisiones/pagar`); 

     const [formPago, handleInputChangePago, handleFormChangeCrearDAPcons, handleResetCrearDAPcons]
        = useForm({
            pagado: '', 
            observaciones: '',
            pagoids: []
        })
    const [valuesValidPago, handleVerifiedValuesPago, handleResetValuesPago] = useValidator({
        pagado: { isOk: true, msgError: [`El campo es obligatorio.`] },
    })
     
     const handleSubmitPagar = (e) => {
        if (handleVerifiedValuesPago(formPago)) {
            fetchPagar({
                body: {...formPago, pagoids: selectedRows}
            })
        }
    }
    
    useEffect(() => {

        if(!data.value){
            handleResetValuesPago();
            handleResetCrearDAPcons()
        }

    },[data.value])

    useEffect(() => {
        if (fetchPagarData?.data?.status === 200) {
            reloadComisiones();
            setTotalCommission(0);
            handleCloseModal();
        }
    }, [fetchPagarData.data])

	 return (
        <Modal show={data?.value} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Confirmación de pago</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                 <b className="mb-4">¿Realmente desea pagar al consultor la suma de € {total.toFixed(2)} ?</b>
                 <div className="col-12">
                     <Form.Group className="mb-4 col-lg-12">
                         <InputText
                                label='Fecha de pago:'
                                type="date"
                                className=''
                                name='pagado'
                                placeholder="Ingrese una fecha"
                                value={formPago.pagado}
                                onChange={handleInputChangePago}
                                validation={valuesValidPago}
                                fetchData={fetchPagarData}
                            />
                    </Form.Group>

                    <Form.Group className="mb-4 col-lg-12">
                         <InputText
                            label='Observaciones:'
                            className=''
                            as='textarea'
                            rows={2}
                            name='observaciones'
                            placeholder="Ingrese una observación"
                            value={formPago.observaciones}
                            onChange={handleInputChangePago}
                            validation={valuesValidPago}
                            fetchData={fetchPagarData}
                        />
                    </Form.Group>

                 </div>
                 <div className="col-12 text-center">
                    <MsgInputServer fetchData={fetchPagarData} className='mt-2' />
                 </div>  
            </Modal.Body>
            <Modal.Footer>
                <ButtonSpinner
                    variant="success" type="submit" className="ms-2"
                    handleSubmit={handleSubmitPagar} fetchDataLoading={[fetchPagarData.loading]}
                    value='Confirmar' icon={<FontAwesomeIcon icon={faCreditCard} className='me-1' />}
                />   
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
     )   
}