import { useAxiosFake } from "../hooks/useAxiosFake";
import { useAxios2 } from "../hooks/useAxios2";
import {archivosAdapter, archivosAdapterSchema, postArchivoAdapterSchema, getArchivoAdapter, postArchivoMultipar, patchContenidoMultipar} from '../adapters/contenidoarchivo.adapter'

export const GetArchivosJSON = () => {
    return useAxios2('get', 'archivos-contenido', 'json', {
        receiveAdapter: archivosAdapter
    });
};

export const PostArchivoJSON = () => {
    return useAxios2('post', '/archivos-contenido', 'json', {
        receiveSchemaAdapter: archivosAdapterSchema,
        sendAdapter: postArchivoMultipar
    });
};

export const GetArchivoJson = (id) => {
    return useAxios2('get', `/archivos-contenido`, 'json', {
        receiveAdapter: getArchivoAdapter
    });
};

export const PatchArchivoJson = () => {
    return useAxios2('post', `/archivos-contenido`, 'json', {
        receiveAdapter: getArchivoAdapter,
        sendAdapter: patchContenidoMultipar
    });
};

export const DeleteArchivoJson = () => {
    return useAxios2('delete', `/archivos-contenido`, 'json', {
        receiveAdapter: getArchivoAdapter
    });
};
