import React, { useContext, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { DAPcons } from '../pages/DAPcons/DAPcons';
import { Usuaris } from '../pages/Usuaris/Usuaris';
import { Logs } from '../pages/Logs/Logs';

import { AuthContext } from '../auth/AuthContext';
import { LayoutNavs } from '../components/LayoutNavs/LayoutNavs';
import { Maintenance } from '../pages/Maintenance/Maintenance';
import { useLang } from '../language';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { RolRoute } from './RolRoute';
import { isAdmin, isModuleAdministrador, isModuleConsultor, isModuleOrganizacion, isConsultor, isVerificador, isOrganizacion } from '../helpers/helpers';
import { Parametros } from '../pages/Parametros/Parametros';
// import { MisDatosOrganizacion } from '../pages/MisDatos/MisDatosOrganizacion';
// import { MisDatosConsultor } from '../pages/MisDatos/MisDatosConsultor';
import { MisDatos } from '../pages/MisDatos/MisDatos';
import { OrganizacionesContent } from '../pages/Organizaciones/OrganizacionesContent/OrganizacionesContent';
import { Consultores } from '../pages/Consultores/Consultores';
import { useLocation } from 'react-router-dom';
import { ConsultoresContent } from '../pages/Consultores/ConsultoresContent/ConsultoresContent';
import { Consultor } from '../pages/Consultores/Consultor/Consultor';
import { Organizaciones } from '../pages/Organizaciones/Organizaciones';
import { PlantillasMensajes } from '../pages/PlantillasMensajes/PlantillasMensajes';
import { RCPsContent } from '../pages/RCPs/RCPsContent/RCPsContent';
import { Usuarios } from '../pages/Usuarios/Usuarios';
import { Pagos } from '../pages/Pagos/Pagos';
import { Documentos } from '../pages/Documentos/Documentos';
import { DocumentosPublic } from '../pages/Documentos/DocumentosPublic/DocumentosPublic';
import { Contenidos } from '../pages/Contenidos/Contenidos';
//import { ContenidosPublic } from '../pages/Contenidos/ContenidosPublic/ContenidosPublic';
import { ComunicadosPublic } from '../pages/Contenidos/ContenidosPublic/ComunicadosPublic';
import {Comunicaciones} from '../pages/Comunicaciones/Comunicaciones'
import {Tarifas} from '../pages/Tarifas/Tarifas'

export const DashboardRoutes = () => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    console.log(perfil)

    const location = useLocation();
    console.log(location)

    const Lang = useLang();


    return (
        <>
            <LayoutNavs />

            <div className="Side-end container-fluid">
                <Switch>
                    <Route
                        // exact
                        path="/inicio"
                        component={() => <Dashboard />}
                    />

                    <Route
                        // exact
                        path="/DAPcons"
                        component={() => <DAPcons namePage={'DAPcons'} />}
                    />

                    <Route
                        // exact
                        path="/verificar-DAPcons"
                        component={() => <DAPcons namePage={'DAPcons'} />}
                    />

                    <RolRoute
                        exact
                        path="/plantillas-mensajes"
                        component={() => <PlantillasMensajes namePage={Lang('COLEGIOS')} />}
                        permissionRol={isModuleAdministrador(modulo_app)}
                    />

                    <RolRoute
                        exact
                        path="/RCPs"
                        component={() => <RCPsContent namePage='RCPs' />}
                        permissionRol={isModuleAdministrador(modulo_app)}
                    />

                    <RolRoute
                        // exact
                        path="/usuarios"
                        component={() => <Usuarios namePage={Lang('USUARIOS')} />}
                        permissionRol={isModuleAdministrador(modulo_app)}
                    />

                    <RolRoute
                        exact
                        path="/logs"
                        component={() => <Logs namePage='Logs' />}
                        permissionRol={isAdmin(perfil)}
                    />

                    <RolRoute
                        exact
                        path="/maintenance"
                        component={() => <Maintenance namePage={Lang('MANTENIMIENTO')} />}
                        permissionRol={isAdmin(perfil)}
                    />

                    <RolRoute
                        exact
                        path="/parametros"
                        component={() => <Parametros namePage='Parámetros' />}
                        permissionRol={isModuleAdministrador(modulo_app)}
                    />

                    <RolRoute
                        // exact
                        path="/empresas"
                        component={() => <Organizaciones namePage='Empresas' />}
                        permissionRol={isModuleAdministrador(modulo_app)}
                    />
                    
                    <RolRoute
                        // exact
                        path="/pagos"
                        component={() => <Pagos namePage='Pagos' />}
                        permissionRol={isModuleAdministrador(modulo_app)}
                    />

                    <RolRoute
                        // exact
                        path="/consultores"
                        component={() => <Consultores namePage='Consultores' />}
                        permissionRol={isModuleAdministrador(modulo_app)}
                    />


                     <Route
                        // exact
                        path="/contenidos"
                        component={() => <Contenidos />}
                        permissionRol={isModuleAdministrador(modulo_app)}
                    />

                    <Route
                        // exact
                        path="/biblioteca-documentos"
                        component={() => <Documentos />}
                        permissionRol={isModuleAdministrador(modulo_app)}
                    />

                    <Route
                        // exact
                        path="/comunicaciones"
                        component={() => <Comunicaciones />}
                        permissionRol={isModuleAdministrador(modulo_app)}
                    />

                    <Route
                        // exact
                        path="/tarifas"
                        component={() => <Tarifas />}
                        permissionRol={isModuleAdministrador(modulo_app)}
                    />

                    
                    <Route
                        // exact
                        path="/mis-datos"
                        component={() => <MisDatos />}
                    />

                     <Route
                        // exact
                        path="/public-documentos"
                        component={() => <DocumentosPublic />}
                        permissionRol={isConsultor(perfil) || isVerificador(perfil) || isOrganizacion(perfil)}
                    />
                    {/*
                     <Route
                        // exact
                        path="/public-noticias"
                        component={() => <ContenidosPublic />}
                        permissionRol={isConsultor(perfil) || isVerificador(perfil) || isOrganizacion(perfil)}
                    />
                    */}

                    <Route
                        // exact
                        path="/public-comunicados"
                        component={() => <ComunicadosPublic />}
                        permissionRol={isConsultor(perfil) || isVerificador(perfil) || isOrganizacion(perfil)}
                    />

                    


                    <Redirect to="/inicio" />
                </Switch>
            </div>

        </>
    )
}
