import React, { useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers';

export const InputFile = ({
    label, className = '', name, type = 'text', value, onChange,
    placeholder, validation = {}, fetchData = {}, disabled, as, style, readonly, min, referencia
}) => {

    const [errorServer, setErrorServer] = useState(false)
    
    const handleOnchange = (e) => {

        if (validation[name])
            validation[name].isOk = true

        if (fetchData.data)
            fetchData.data.status = 'Success'

        fetchData.error = null

        if (min !== undefined && Number(e.target.value) < min)
            return

        if (errorServer)
            setErrorServer(false)

        onChange(e)
    }

    return (
        <>
            { label && <Form.Label>{label}</Form.Label>}
            <Form.Control 
                type="file" 
                value={value}
                ref={referencia}
            />
            { validation && <MsgInput obj={validation} name={name} /> }
            <MsgInputServer fetchData={fetchData} name={name} setErrorServer={setErrorServer} errorServer={errorServer} />
        </>
    )
}
