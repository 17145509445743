import React, { useEffect } from 'react'
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { InputSelect, InputText } from '../../../../components/Inputs'

export const ModalFilter = ({data, handleCloseModal, handleFormChange, formValuesSearch, handleInputChangeSearch, handleSearch}) => {

     const handleChangePagado = (e) => {
        handleFormChange({
            ...formValuesSearch,
            es_pagado: e.target.checked ? true : ''
        })
    }

    const handleChangeNoPagado = (e) => {
        handleFormChange({
            ...formValuesSearch,
            no_pagado: e.target.checked ? true : ''
        })
    }

	 return (
        <Modal show={data?.value} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Filtros de busqueda</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                <Form className='row'>

                    <Form.Group className="col-12 col-lg-6 mb-3">
                        <InputSelect
                            label='Tipo de comisión:'
                            name='tipo'
                            values={
                                [
                                    {id: 'comision_alta_empresa', nombre: 'Alta Empresa'},
                                    {id: 'comision_renovacion_empresa', nombre: 'Renovación Empresa'},
                                    {id: 'comision_alta_dapcons', nombre: 'Alta de Dapcons'},
                                    {id: 'comision_renovacion_dapcons', nombre: 'Renovación de Dapcons'},
                                ]
                            }
                            value={formValuesSearch.tipo}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-lg-6 mb-3">
                        <InputText
                            label='Fecha de pago desde:'
                            type="date"
                            className=''
                            name='fecha_desde'
                            value={formValuesSearch.fecha_desde}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-lg-6 mb-3">
                        <InputText
                            label='Fecha de pago hasta:'
                            className=''
                            type="date"
                            name='fecha_hasta'
                            value={formValuesSearch.fecha_hasta}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-12 col-lg-6 mb-3">
                        <InputSelect
                            label='Limite por pagina:'
                            name='limit'
                            values={
                                [
                                    {id: 20, nombre: 20},
                                    {id: 40, nombre: 40},
                                    {id: 60, nombre: 60},
                                    {id: 80, nombre: 60},
                                    {id: 100, nombre: 60},
                                ]
                            }
                            value={formValuesSearch.limit}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-lg-2 mb-3">
                    <Form.Check
                        type='checkbox'
                        name='es_pagado'
                        value={formValuesSearch.es_pagado}
                        onChange={handleChangePagado}
                        checked={formValuesSearch.es_pagado}
                        id={`default-checkbox`}
                        label='Pagados'
                        disabled={formValuesSearch.no_pagado}
                    />
                   </Form.Group>

                    <Form.Group className="col-6 col-lg-2 mb-3">
                    <Form.Check
                        type='checkbox'
                        name='no_pagado'
                        value={formValuesSearch.no_pagado}
                        onChange={handleChangeNoPagado}
                        checked={formValuesSearch.no_pagado}
                        id={`default-checkbox`}
                        label='No pagado'
                        disabled={formValuesSearch.es_pagado}
                    />
                   </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary"  onClick={handleSearch}>
                    <FontAwesomeIcon icon={faSearch} /> Filtrar
                </Button>
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
     )   
}