import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, Form, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';
import { InputSelect} from '../../../../components/Inputs';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../../hooks/useAxios';
import { MsgInputServer } from '../../../../components/MsgInput/MsgInputServer';
import { Loader } from '../../../../components/Loader/Loader';

export const ModalRema = ({data, handleCloseModal, consultor, setConsultor, reloadComisiones}) => {
     const [fetchCambiarRema, fetchCambiarRemaData] = useAxios('PATCH', `/comisiones/cambiar_rema`); 
     const [fetchConsultores, fetchConsultoresData] = useAxios('get', `/consultores/obtener_listado`)  
     const [fetchParameters, fetchParametersData] = useAxios('POST', `/parametros_generales`)
     const [formRema, 
        handleInputChangeRema, 
        handleFormChangeRema, 
        handleResetRema] = useForm({comisionista_id: '', tipo_comision:'', sincronizar: ''})

     const [valuesValidRema, handleVerifiedValuesRema, handleResetValuesRema] = useValidator({
        comisionista_id: { isOk: true, msgError: [`Ingrese un Comisionista`] },
        tipo_comision: { isOk: true, msgError: [`Ingrese un tipo de comisión`] },
     })

     const handleSubmitUpdateRema = (e) => {
        if(handleVerifiedValuesRema(formRema)){
            fetchCambiarRema({
                id: consultor?.org_id,
                body: formRema
            })
        }
    }

    useEffect(() => {
        if(data?.value){
            fetchConsultores({
                params:{
                    es_comisionista: true
                }
            });

            fetchParameters({
                body: [
                    {
                        "param": "tipo_comision",
                        "locale": 1,
                        "active": true
                    },
                ]
            });

            handleFormChangeRema({...formRema, comisionista_id: consultor?.isConsultor, tipo_comision: consultor?.tipo_comision});
        }
    }, [data?.value])

    useEffect(() => {
        if (fetchCambiarRemaData?.data?.data?.data) {
           const data = fetchCambiarRemaData.data?.data?.data; 
           const fullName = data.consultor_nombre +' '+data.consultor_apellidos +' '+data.consultor_nif;
           setConsultor(
            {...consultor,
             isConsultor: data.id,
             fullName
            }
           )
           handleResetRema();
           //reloadComisiones(data.id)
           handleCloseModal();
            setTimeout(() => {
                window.location.reload();
            },100)
        }else if(fetchCambiarRemaData?.data?.data?.status === 'Success'){
            handleCloseModal();
            setTimeout(() => {
                window.location.reload();
            },100)
            
        }

    }, [fetchCambiarRemaData.data])

    const handleChangeSincronizar = (e) => {
        handleFormChangeRema({
            ...formRema,
            sincronizar: e.target.checked ? true : ''
        })
    }

	return (
        <Modal show={data?.value} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Asignar Comisionista</Modal.Title>
            </Modal.Header>
             {fetchConsultoresData.loading || fetchParametersData.loading ? <Loader /> :
                <>
                <Modal.Body className='px-4 py-4'>
                    <Form.Group className="mb-3 col-lg-12">
                        <InputSelect
                            //label='REMA:'
                            label='Comisionista'
                            name='comisionista_id'
                            values={
                                fetchConsultoresData.data?.data?.data?.map((item, index) => {
                                    return {
                                       id: item.id,
                                       nombre: item.full_name
                                    }
                                })
                              }
                            value={formRema.comisionista_id}
                            onChange={handleInputChangeRema}
                            validation={valuesValidRema}
                            fetchData={fetchCambiarRemaData}
                        />        
                    </Form.Group>   
                     <Form.Group className="mb-3 col-lg-12">
                        <InputSelect
                            label='Tipo de comisión'
                            name='tipo_comision'
                            values={
                                fetchParametersData.data?.data?.data?.tipo_comision?.map((item, index) => {
                                    return {
                                       id: item.param_id,
                                       nombre: item.pt_texto
                                    }
                                })
                            }
                            value={formRema.tipo_comision}
                            onChange={handleInputChangeRema}
                            validation={valuesValidRema}
                            fetchData={fetchCambiarRemaData}
                        />           
                     </Form.Group>
                     {formRema.rema_id &&
                        <Form.Group className=" mb-3 col-lg-12">
                            <Form.Check
                                type='checkbox'
                                name='renovacion'
                                value={formRema.sincronizar}
                                onChange={handleChangeSincronizar}
                                id={`default-checkbox`}
                                className="mb-0 fw-500"
                                label='Sincronizar'
                            />
                       </Form.Group>
                    }
                     <div className="col-12 text-center">
                        <MsgInputServer fetchData={fetchCambiarRemaData} className='mt-2' />
                     </div>  
                </Modal.Body>
                <Modal.Footer>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleSubmitUpdateRema} fetchDataLoading={[fetchCambiarRemaData.loading]}
                        value='Guardar' icon={<FontAwesomeIcon icon={faBook} className='me-1' />}
                    />   
                    <Button variant="default"  onClick={handleCloseModal}>
                        Salir
                    </Button>
                </Modal.Footer>
             </> 
           }
        </Modal>
     )   
}