import { useAxiosFake } from "../hooks/useAxiosFake";
import { useAxios2 } from "../hooks/useAxios2";

import { getRCPAdapterSchema, getRCPsAdapter, postRCPAdapter } from "../adapters/rcp.adapter";

export const GetRCPsJSON = () => {
    return useAxios2('get', '/rcp', 'json', {
        receiveAdapter: getRCPsAdapter
    });
};

export const PostRCPJSON = () => {
    return useAxios2('post', '/rcp', 'json', {
        receiveSchemaAdapter: getRCPAdapterSchema,
        sendAdapter: postRCPAdapter
    });
};

export const PatchRCPJSON = () => {
    return useAxios2('patch', '/rcp', 'json', {
        receiveSchemaAdapter: getRCPAdapterSchema,
        sendAdapter: postRCPAdapter
    });
};