import React, { useContext } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import { AuthContext } from '../auth/AuthContext';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import { Login } from '../pages/Login/Login';
import { DashboardRoutes } from './DashboardRoutes';
import { AvisLegal } from '../pages/AvisLegal/AvisLegal';
import { PoliticaPrivacitat } from '../pages/PoliticaPrivacitat/PoliticaPrivacitat';
import { PoliticaCookies } from '../pages/PoliticaCookies/PoliticaCookies';

export const AppRouter = () => {

    const { user } = useContext(AuthContext);

    return (
        <Router
            basename={process.env.REACT_APP_SUBDOMAIN}
        // basename={"/"}
        >
            <div>
                <Switch>
                    <PublicRoute
                        exact
                        path="/login"
                        component={Login}
                        isAuthenticated={user.logged}
                    />

                    <Route
                        exact
                        path="/avis-legal"
                        component={AvisLegal}
                    />

                    <Route
                        exact
                        path="/politica-privacitat"
                        component={PoliticaPrivacitat}
                    />

                    <Route
                        exact
                        path="/politica-cookies"
                        component={PoliticaCookies}
                    />

                    <PrivateRoute
                        path="/"
                        component={DashboardRoutes}
                        isAuthenticated={user.logged}
                    />
                </Switch>
            </div>
        </Router>
    )
}
