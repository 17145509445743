import React, { useEffect, useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner'
import { InputText } from '../../components/Inputs'
import { MsgInputServer } from '../../components/MsgInput/MsgInputServer'
import { useForm } from '../../hooks/useForm'
import { useValidator } from '../../hooks/useValidator'
import { useAxios } from '../../hooks/useAxios'
import { ModalStatus } from '../../components/ModalStatusServer/ModalStatus'

const ModalRecuperarContrasenia = ({ showModalRecuperarContrasenia, handleCloseModalRecuperarContrasenia }) => {

    const [fetchRecuperarContrasenia, fetchRecuperarContraseniaData, resetFetchRecuperarContrasenia] = useAxios('post', `/auth/reiniciarPassword`)

    useEffect(() => {
        console.log(fetchRecuperarContraseniaData)

        if (fetchRecuperarContraseniaData.data?.status === 200) {
            handleCloseModalRecuperarContrasenia()
            setShowStatusRecuperarPassword(true)
        }

    }, [fetchRecuperarContraseniaData])

    const [formRegisterExterno, handleInputChangeRegisterExterno, handleFormChangeRegisterExterno, handleResetRegisterExterno] = useForm({
        usuario: '',
    })

    const [valuesValidRecuperarContrasenia, handleVerifiedValuesRegisterExterno, handleResetValuesRegisterExterno] = useValidator({
        usuario: { isOk: true, msgError: [`El usuario es obligatorio.`] }
    })

    const handleSubmitFormRegisterExterno = (e) => {

        console.log('formRegisterExterno', formRegisterExterno)

        e.preventDefault()

        if (handleVerifiedValuesRegisterExterno(formRegisterExterno)) {

            let bodyTemp = {}

            bodyTemp.modulo_app = showModalRecuperarContrasenia.payload

            if(showModalRecuperarContrasenia.payload === 'organizacion'){
                bodyTemp.org_cif = formRegisterExterno.usuario
            } else if (showModalRecuperarContrasenia.payload === 'consultor'){
                bodyTemp.consultor_nif = formRegisterExterno.usuario
            } else if (showModalRecuperarContrasenia.payload === 'administrador'){
                bodyTemp.usuario = formRegisterExterno.usuario
            }
                
            fetchRecuperarContrasenia({
                body: {...bodyTemp}
            })
        }
    }
    
    const [showStatusRecuperarPassword, setShowStatusRecuperarPassword] = useState(false);

    useEffect(() => {

        if (showModalRecuperarContrasenia.value) {
            handleResetRegisterExterno()
            handleResetValuesRegisterExterno()
            resetFetchRecuperarContrasenia()
        }
    }, [showModalRecuperarContrasenia.value])

    return (
        <>
            <Modal show={showModalRecuperarContrasenia.value} onHide={handleCloseModalRecuperarContrasenia} size='' id='ModalRegistro'>
                <Modal.Header className=''>
                    <Modal.Title className='h5 mx-auto'>Recuperar contraseña</Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModalRecuperarContrasenia}></button>
                </Modal.Header>
                <Modal.Body className='p-0'>

                    <section style={{ 'background-color': '#f2f4f6' }}>
                        <div className="container">
                            <div className="row pt-3 pb-4 px-3">

                                <Form.Group className="mb-3 mt-4 col-12">
                                    <InputText
                                        label={showModalRecuperarContrasenia.payload === 'organizacion' ? 'CIF *' : 'NIF *'}
                                        className=''
                                        name='usuario'
                                        value={formRegisterExterno.usuario}
                                        onChange={handleInputChangeRegisterExterno}
                                        validation={valuesValidRecuperarContrasenia}
                                        fetchData={fetchRecuperarContraseniaData}
                                    />
                                </Form.Group>

                                <div className="col-12 text-center">
                                    <MsgInputServer fetchData={fetchRecuperarContraseniaData} name='org_cif' className='' />
                                    <MsgInputServer fetchData={fetchRecuperarContraseniaData} name='consultor_nif' className='' />
                                    {/* <MsgInputServer fetchData={fetchRecuperarContraseniaData} name='usuario' className='' /> */}
                                </div>

                            </div>
                        </div>
                    </section>
                </Modal.Body>
                <Modal.Footer className=''>
                    <Button variant="default" onClick={handleCloseModalRecuperarContrasenia} className='px-3'>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-3 px-3"
                        handleSubmit={handleSubmitFormRegisterExterno} fetchDataLoading={[fetchRecuperarContraseniaData.loading]}
                        value='Continuar'
                    />
                </Modal.Footer>
            </Modal>

            <ModalStatus setShowStatus={setShowStatusRecuperarPassword} showStatus={showStatusRecuperarPassword}>
                <div className="py-3 text-center">
                    <h5 className='mb-0 fw-normal'>{fetchRecuperarContraseniaData.data?.data?.message}</h5>
                </div>
            </ModalStatus>
        </>
    )
}

export default ModalRecuperarContrasenia