import React, { useEffect, useState, useContext, useRef  } from 'react'
import { faCreditCard, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, Table, Badge } from 'react-bootstrap'
import { Loader } from '../../../components/Loader/Loader'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../hooks/useAxios';
import { AuthContext } from '../../../auth/AuthContext'
import jwt from 'jwt-decode' // import dependency
import { types } from '../../../types/types';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputText } from '../../../components/Inputs';
import './PaymentInfoTable.css';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import Wizard from "../../../components/Wizard/Wizard";
import {DatosFacturacion} from "./DatosFacturacion"
import {DocumentoSepa} from "./DocumentoSepa"
import {Pago} from "./Pago"

export const PaymentModal = ({isOpen}) => {
     const { dispatch } = useContext(AuthContext);
     const [fetchTarifa, fetchTarifaData] = useAxios('GET', `/organizaciones/detalle_tarifa`)
     const [fetchPagar, fetchPagarData] = useAxios('POST', `/organizaciones/pagar`);
     const [fetchLogout, fetchLogoutData] = useAxios('delete', `/auth/logout`)
     const inputFile = useRef(null)
     const [formTarifa, 
        handleInputChangeTarifa, 
        handleFormChangeTarifa, 
        handleResetTarifa] = useForm({
            org_iban: '',
            org_nif_propietario_cuenta: '',
        })

     const [valuesValidTarifa, handleVerifiedValuesTarifa, handleResetValuesTarifa] = useValidator({
        org_iban: { isOk: true, msgError: [`Ingrese un N° IBAN`] },
        org_nif_propietario_cuenta: { isOk: true, msgError: [`Ingrese un NIF`] },
     })

     const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formTarifa);
        if (handleVerifiedValuesTarifa(formTarifa)) {
            let formData = new FormData();
            for (const key in formTarifa) {
                formData.append(key, formTarifa[key])
            }
            formData.append('org_sepa', inputFile.current?.files?.[0] || '')
            fetchPagar({
                body: formData
            });
        }    
     }

     const handleLogout = (e) => {
        e.preventDefault()
        fetchLogout()
        const lang = JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE))?.lang || process.env.REACT_APP_LANGUAGE_DEFAULT;
        dispatch({
            type: types.logout,
            lang
        });
    }

     useEffect(() => {
        fetchTarifa();
     },[isOpen])

     useEffect(() => {
        if(fetchPagarData.data?.data?.data?.token){
            const token = fetchPagarData?.data?.data?.data?.token;
            const lang = JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE))?.lang || process.env.REACT_APP_LANGUAGE_DEFAULT;
            const data = jwt(token)
            dispatch({
                type: types.login,
                payload: {
                    ...data,
                    token: token,
                },
                lang
            });
        }
     },[fetchPagarData.data])

     const [indexFocusWizard, setIndexFocusWizard] = useState(0);
     const listWizard = [
       'Datos Facturación',
       'Documento Sepa',
       'Pago'
    ];

	return (
        <Modal show={isOpen} size={'lg'} style={{backdropFilter: ' blur(10px)'}} centered>
            {fetchTarifaData.loading ? <Loader /> : <>
            <Modal.Body className='px-4 py-4'>
                  <h5 className="text-center">Cuota de afiliación</h5>  
                  <Wizard>
                    <Wizard.Header
                        listWizard={listWizard}
                        indexFocusWizard={indexFocusWizard}
                        setIndexFocusWizard={setIndexFocusWizard}
                        enableBack={false}
                    />
                    <Wizard.Body indexFocusWizard={indexFocusWizard}>
                     <section className="bg-white">
                         <DatosFacturacion
                         fetchTarifaData={fetchTarifaData}
                         formTarifa={formTarifa}
                         handleInputChangeTarifa={handleInputChangeTarifa}
                         valuesValidTarifa={valuesValidTarifa}
                         fetchPagarData={fetchPagarData}
                         setIndexFocusWizard={setIndexFocusWizard}
                         handleFormChangeTarifa={handleFormChangeTarifa}
                         />
                     </section>
                     <section className="bg-white">
                        <DocumentoSepa
                             fetchTarifaData={fetchTarifaData}
                             formTarifa={formTarifa}
                             handleInputChangeTarifa={handleInputChangeTarifa}
                             valuesValidTarifa={valuesValidTarifa}
                             fetchPagarData={fetchPagarData}
                             setIndexFocusWizard={setIndexFocusWizard}
                         />
                     </section>
                     <section className="bg-white">
                         <Pago
                             fetchTarifaData={fetchTarifaData}
                             formTarifa={formTarifa}
                             handlePaymentSubmit={handleSubmit}
                             fetchPagarData={fetchPagarData}
                             setIndexFocusWizard={setIndexFocusWizard}
                             inputFileRef={inputFile}
                         />
                     </section>
                    </Wizard.Body>
                 </Wizard>    
                 <div className="col-12 text-center">
                    <MsgInputServer fetchData={fetchPagarData} className='mt-2' />
                 </div>  

                {
                    /*
              
                <div className="row">
                  <div className="col-12">
                        <Table bordered hover responsive>
                            <tbody>
                            <tr>
                                <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                  <strong>Detalle de tarifa:</strong><br/>
                                </td>
                                <td>
                                 {fetchTarifaData.data?.data?.data?.detalle || ''}
                                </td>
                                
                              </tr>
                              <tr>
                                <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                  <strong>Total :</strong><br/>
                                </td>
                                <td>
                                  € {fetchTarifaData.data?.data?.data?.precio || ''}
                                </td>
                              </tr>
                              <tr>
                                <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                  <strong>Número de cuenta IBAN:</strong><br/>
                                </td>
                                <td>
                                  <InputText
                                        //label='N° cuenta Ib:'
                                        className=''
                                        name='org_iban'
                                        placeholder="Ingrese un IBAN"
                                        value={formTarifa.org_iban}
                                        onChange={handleInputChangeTarifa}
                                        validation={valuesValidTarifa}
                                        fetchData={fetchPagarData}
                                    />
                                </td>
                              </tr>

                               <tr>
                                <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                  <strong>NIF Propietario cuenta:</strong><br/>
                                </td>
                                <td>
                                  <InputText
                                        //label='N° cuenta Ib:'
                                        className=''
                                        name='org_nif_propietario_cuenta'
                                        placeholder="Ingrese un CIF / NIF"
                                        value={formTarifa.org_nif_propietario_cuenta}
                                        onChange={handleInputChangeTarifa}
                                        validation={valuesValidTarifa}
                                        fetchData={fetchPagarData}
                                    />
                                </td>
                              </tr>

                            </tbody>
                        </Table>   
                 </div>    
                <div className="col-12 text-center">
                    <MsgInputServer fetchData={fetchPagarData} className='mt-2' />
                </div>  
                <div className="col-12 text-center mt-3">
                     <ButtonSpinner
                        variant="success" type="submit" className="ms-2"
                        handleSubmit={handleSubmit} fetchDataLoading={[fetchPagarData.loading]}
                        value='Pagar Cuota' icon={<FontAwesomeIcon icon={faCreditCard} className='me-1' />}
                    />
                    <Button variant="danger" className="ms-2" onClick={handleLogout}><FontAwesomeIcon icon={faTimes} className='me-1'/> Cerrar sesión</Button>
                </div> 
              </div>  
              */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" className="mt-2 mb-1" size="sm" onClick={handleLogout}><FontAwesomeIcon icon={faTimes} className='me-1'/> Cerrar sesión</Button>
            </Modal.Footer>
            </>
          }  
        </Modal>
    )
}