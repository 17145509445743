import React, { useContext, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faSort, faEdit, faDownload, faNewspaper, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../../../components/Loader/Loader';
import { PaginationT } from '../../../../../components/PaginationT/PaginationT';

import { useLang } from '../../../../../language/index';
import { useHistory } from 'react-router-dom';
import ButtonOrderBy from '../../../../../components/ButtonOrderBy/ButtonOrderBy';
import { useModalData } from '../../../../../hooks/useModalData';
import {BtnDelete} from '../Acciones/BtnDelete'
import {BtnEditar} from '../Acciones/BtnEditar'

export const ArchivosMain = ({ fetchArchivosData, formValuesSearch, namePage = 'default', handleFormChange, fetchDocumentos, reloadTable, addDataForm }) => {

    const history = useHistory();

    const Lang = useLang();

    useEffect(() => {
    }, [])

    return (
        <>
            <div className="col">
                    {
                        fetchArchivosData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    fetchArchivosData?.data?.total ?
                                        <>
                                            <Table hover bordered responsive className='tableMain'>
                                                <thead>
                                                    <tr className="text-center">
                                                        <th className='text-nowrap'>Título</th>
                                                        <th className='text-nowrap text-center'>Descargar</th>
                                                        <th className='text-center'>Editar</th>
                                                        <th className='text-center'>Eliminar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fetchArchivosData?.data?.items?.map((item) => {
                                                        return (
                                                            <tr key={item.id} className="text-center">
                                                                <td valign='middle'>{item.titulo}</td>
                                                                <td valign='middle'>
                                                                     <Button href={item.ruta} target="_blank" download variant="info" size='sm' className='ms-3 my-0'>
                                                                        <FontAwesomeIcon icon={faDownload} /> Descargar
                                                                     </Button>
                                                                </td>
                                                                <td valign='middle'>
                                                                    <BtnEditar
                                                                     data={item.id}
                                                                     addDataForm={addDataForm}
                                                                    />
                                                                </td>
                                                                <td valign='middle' className='text-center'>
                                                                    <BtnDelete
                                                                    data={item.id}
                                                                    reloadTable={reloadTable}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchArchivosData?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchArchivosData?.data?.per_page}
                                            />
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No se ha encontrado resultados</h5>
                                }
                            </>
                    }
            </div>
        </>
    )
}
