import React, { useContext, useEffect } from 'react'
import { faFile, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, ListGroup, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext'
import { isModuleConsultor, isModuleOrganizacion } from '../../helpers/helpers'
import { useAxios } from '../../hooks/useAxios'
import { GetOrganizacionJSON } from '../../services/organizacion.service'
import { Loader } from '../../components/Loader/Loader'
import {ContenidosPublic} from '../Contenidos/ContenidosPublic/ContenidosPublic'
import { DocumentosResaltados } from './DocumentosResaltados/DocumentosResaltados'

export const DashboardOrganizacion = () => {

    const { user } = useContext(AuthContext);

    const history = useHistory()

    const [fetchOrganizacion, fetchOrganizacionData] = GetOrganizacionJSON()

    useEffect(() => {
        fetchOrganizacion({
            params: {
                org_cif: user.user?.usuario
            }
        })
    }, [])

    // useEffect(() => {

    //     if (fetchOrganizacionData.status === 200) {

    //         handleFormChangeOrganizacion(fetchOrganizacionData.data)
    //     }

    // }, [fetchOrganizacionData])

    return (
        <div className="container-inner row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Inicio
                </Breadcrumb.Item>
            </Breadcrumb>
            
            <DocumentosResaltados/>
        
            <div className="col-12 mb-4 mb-xl-0">
                {
                    fetchOrganizacionData.loading ?

                        <div className='text-center' colSpan={5}>
                            <Loader />
                        </div> : (
                            !fetchOrganizacionData.data?.logo && <Alert variant='warning'>
                                No está definido el logotipo de la empresa, <Alert.Link onClick={() => history.push('/mis-datos')}>haga click aquí</Alert.Link> para añadirlo
                            </Alert>
                        )
                }

            </div>

            <div className="col-12">
                <ContenidosPublic/>
            </div>

        </div>
    )
}
